import type { DetailedHTMLProps, InputHTMLAttributes } from "react";

/**
 * A really simple wrapper around HTML input elements to add a minimal UI, just so that
 * it remains consistent across the product.
 *
 * NOTE: This isn't based on a design spec, it's just very basic placeholder until we
 * create our own component library.
 */
export function SimpleTextInput({
  className,
  ...otherProps
}: DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>): JSX.Element {
  return (
    <input
      className={`rounded-xl border px-3 py-2 text-sm ${className ?? ""}`}
      {...otherProps}
    />
  );
}
