import type { PropsWithChildren } from "react";
import { SectionHeadline } from "./SectionHeadline";

export function Section({
  className,
  title,
  headerIcon,
  children,
}: PropsWithChildren<{
  readonly title: React.ReactNode;
  readonly headerIcon: React.ReactElement;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <section
      className={`flex flex-col gap-y-4 rounded-md bg-white pb-5 pl-3 pr-5 pt-4 shadow-md ${
        className ?? ""
      }`}
    >
      <SectionHeadline icon={headerIcon}>{title}</SectionHeadline>
      <SectionContentBox>{children}</SectionContentBox>
    </section>
  );
}

function SectionContentBox({
  className,
  children,
}: PropsWithChildren<{
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <div
      className={`ml-9 flex h-full flex-col rounded-md bg-[rgb(245,247,247)] px-5 py-6 ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  );
}
