export function CloseDialogIcon({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.9998 7.40005L2.0998 11.3C1.91647 11.4834 1.68314 11.575 1.3998 11.575C1.11647 11.575 0.883138 11.4834 0.699804 11.3C0.516471 11.1167 0.424805 10.8834 0.424805 10.6C0.424805 10.3167 0.516471 10.0834 0.699804 9.90005L4.5998 6.00005L0.699804 2.10005C0.516471 1.91672 0.424805 1.68338 0.424805 1.40005C0.424805 1.11672 0.516471 0.883382 0.699804 0.700048C0.883138 0.516715 1.11647 0.425049 1.3998 0.425049C1.68314 0.425049 1.91647 0.516715 2.0998 0.700048L5.9998 4.60005L9.8998 0.700048C10.0831 0.516715 10.3165 0.425049 10.5998 0.425049C10.8831 0.425049 11.1165 0.516715 11.2998 0.700048C11.4831 0.883382 11.5748 1.11672 11.5748 1.40005C11.5748 1.68338 11.4831 1.91672 11.2998 2.10005L7.3998 6.00005L11.2998 9.90005C11.4831 10.0834 11.5748 10.3167 11.5748 10.6C11.5748 10.8834 11.4831 11.1167 11.2998 11.3C11.1165 11.4834 10.8831 11.575 10.5998 11.575C10.3165 11.575 10.0831 11.4834 9.8998 11.3L5.9998 7.40005Z" />
    </svg>
  );
}
