import type { ReactElement } from "react";
import { useCallback } from "react";
import type { StringKeyOf } from "type-fest";
import { GreenButton, RedButton } from "../Buttons";
import { EMPTY_USER, NewUserForm, type UserDetails } from "./NewUserForm";

export function BulkUserForm({
  users,
  minUsers,
  updateUserList,
}: {
  users: readonly UserDetails[];
  minUsers: number;
  updateUserList: (userList: readonly UserDetails[]) => void;
}): ReactElement {
  const addUser = useCallback(() => {
    updateUserList([...users, { ...EMPTY_USER }]);
  }, [users, updateUserList]);

  const removeUser = useCallback(() => {
    if (users.length > minUsers) {
      updateUserList(users.slice(0, -1));
    }
  }, [users, updateUserList, minUsers]);

  // This doesn't feel right, I wonder what's the right way to do this :p
  const updateUserDetails = useCallback(
    (i: number, key: StringKeyOf<UserDetails>, value: string) => {
      const currentUserDetails = users[i] ?? { ...EMPTY_USER };
      updateUserList([
        ...users.slice(0, i),
        { ...currentUserDetails, [key]: value },
        ...users.slice(i + 1, users.length),
      ]);
    },
    [users, updateUserList],
  );

  return (
    <>
      <GreenButton onClick={addUser}>+ Add User</GreenButton>
      <RedButton onClick={removeUser}>- Remove User</RedButton>
      {Array.from({ length: users.length }).map((_, i) => (
        // I only allow adding / removing users from the end of the list
        // eslint-disable-next-line react/no-array-index-key
        <div key={`new_user_${i}`} className="mt-3">
          <h5 className="text-lg">User {i + 1}</h5>
          <NewUserForm index={i} onUpdateDetails={updateUserDetails} />
        </div>
      ))}
    </>
  );
}
