/* eslint-disable max-lines */
import { Box, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import {
  type MouseEvent as ReactMouseEvent,
  useCallback,
  useState,
  type ChangeEvent,
} from "react";
import { exceptionToErrorMessage } from "../../../utils/error";
import { GreenButton } from "../../Buttons";
import { CallToActions } from "../common/CallToActions";
import { useApiRequest } from "../useApiRequest";
import { Instructions } from "./Instructions";
import { getSnowflakeInstructionsScript } from "./PermissionsScriptTemplate";
import { BASE_URL_PREFIX } from "./consts";

type SnowflakeNewSource = {
  /* eslint-disable @typescript-eslint/naming-convention -- defined by the API */
  readonly source_identifier: string;
  readonly credentials: {
    readonly account_url: string;
    readonly warehouse: string;
    readonly database: string;
    readonly user_name: string;
    readonly private_key: string;
    readonly private_key_password: string;
    readonly ingestion_role: string;
  };
  /* eslint-enable @typescript-eslint/naming-convention */
};

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function NewSourceScreen({
  onDone,
}: {
  readonly onDone: (isCanceled: boolean) => void;
}): JSX.Element {
  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState<string>();
  const [isAccountUrlEdited, setIsAccountUrlEdited] = useState(false);
  const [isUserNameEdited, setIsUserNameEdited] = useState(false);
  const [isPrivateKeyEdited, setIsPrivateKeyEdited] = useState(false);
  const [isPrivateKeyPasswordEdited, setIsPrivateKeyPasswordEdited] = useState(false);
  const [isIngestionRoleEdited, setIsIngestionRoleEdited] = useState(false);

  const [accountUrl, setAccountUrl] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [database, setDatabase] = useState("");
  const [userName, setUserName] = useState("foundational_user");
  const [privateKey, setPrivateKey] = useState("");
  const [privateKeyPassword, setPrivateKeyPassword] = useState("");
  const [ingestionRole, setIngestionRole] = useState("foundational_role");

  const api = useApiRequest();

  const handleSave = useCallback(() => {
    setError(undefined);
    setIsInProgress(true);

    const newSource: SnowflakeNewSource = {
      source_identifier: `${accountUrl},${warehouse}`,
      credentials: {
        account_url: accountUrl,
        warehouse,
        database,
        user_name: userName,
        private_key: privateKey,
        private_key_password: privateKeyPassword,
        ingestion_role: ingestionRole,
      },
    };

    const toServer = async (): Promise<void> => {
      try {
        await api(`${BASE_URL_PREFIX}/keys/`, [newSource], "PUT");
        onDone(false);
      } catch (serverError) {
        const message = await exceptionToErrorMessage(serverError);
        setError(message);
      } finally {
        setIsInProgress(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    toServer();
  }, [
    accountUrl,
    api,
    database,
    ingestionRole,
    onDone,
    privateKey,
    privateKeyPassword,
    userName,
    warehouse,
  ]);

  const handleAccountUrlBlur = useCallback(() => {
    setIsAccountUrlEdited(true);
  }, []);

  const handleAccountUrlChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setAccountUrl(currentTarget.value);
    },
    [],
  );

  const handleWarehouseChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setWarehouse(currentTarget.value);
    },
    [],
  );
  const handleDatabaseChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setDatabase(currentTarget.value);
    },
    [],
  );
  const handleUserNameBlur = useCallback(() => {
    setIsUserNameEdited(true);
  }, []);

  const handleUserNameChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setUserName(currentTarget.value);
    },
    [],
  );

  const handlePrivateKeyBlur = useCallback(() => {
    setIsPrivateKeyEdited(true);
  }, []);

  const handlePrivateKeyChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setPrivateKey(currentTarget.value);
    },
    [],
  );

  const handlePrivateKeyPasswordBlur = useCallback(() => {
    setIsPrivateKeyPasswordEdited(true);
  }, []);

  const handlePrivateKeyPasswordChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setPrivateKeyPassword(currentTarget.value);
    },
    [],
  );

  const handleIngestionRoleBlur = useCallback(() => {
    setIsIngestionRoleEdited(true);
  }, []);

  const handleIngestionRoleChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setIngestionRole(currentTarget.value);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    onDone(true);
  }, [onDone]);

  const handleDownloadClick = useCallback(
    ({ currentTarget }: ReactMouseEvent<HTMLAnchorElement>) => {
      const databaseToFill = database === "" ? "<your-database>" : database;
      const warehouseToFill = warehouse === "" ? "<your-warehouse>" : warehouse;
      const ingestionRoleToFill =
        ingestionRole === "" ? "<your-ingestion-role>" : ingestionRole;
      const userNameToFill = userName === "" ? "<your-user-name>" : userName;
      const snowflakeInfo = {
        database: databaseToFill,
        warehouse: warehouseToFill,
        ingestionRole: ingestionRoleToFill,
        userName: userNameToFill,
      };
      const blob = new Blob([getSnowflakeInstructionsScript(snowflakeInfo)], {
        type: "text/plain",
      });
      const url = URL.createObjectURL(blob);

      // eslint-disable-next-line no-param-reassign
      currentTarget.href = url;

      setTimeout(() => {
        // Clean up the temporary URL object when the browser finishes download.
        URL.revokeObjectURL(url);
        // eslint-disable-next-line no-param-reassign
        currentTarget.href = ".";
      });
    },
    [database, warehouse, ingestionRole, userName],
  );

  return (
    <Box
      sx={{
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
        mt: 2,
      }}
    >
      <form>
        <TextField
          fullWidth
          required
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- what's the problem here :(
            isAccountUrlEdited && accountUrl === ""
          }
          label="Account URL"
          placeholder="https://<snowflake-account-id>.snowflakecomputing.com"
          type="url"
          value={accountUrl}
          variant="standard"
          onBlur={handleAccountUrlBlur}
          onChange={handleAccountUrlChange}
        />
        <TextField
          fullWidth
          label="Warehouse"
          placeholder="COMPUTE_WH"
          type="text"
          value={warehouse}
          variant="standard"
          onChange={handleWarehouseChange}
        />
        <TextField
          fullWidth
          label="Database"
          placeholder="my_database"
          type="text"
          value={database}
          variant="standard"
          onChange={handleDatabaseChange}
        />
        <TextField
          fullWidth
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- i dunno what's the problem here :(
            isIngestionRoleEdited && ingestionRole === ""
          }
          label="Ingestion Role"
          placeholder="foundational_role"
          type="text"
          value={ingestionRole}
          variant="standard"
          onBlur={handleIngestionRoleBlur}
          onChange={handleIngestionRoleChange}
        />
        <TextField
          fullWidth
          required
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- i dunno what's the problem here :(
            isUserNameEdited && userName === ""
          }
          label="User Name"
          placeholder="foundational_user"
          type="text"
          value={userName}
          variant="standard"
          onBlur={handleUserNameBlur}
          onChange={handleUserNameChange}
        />
        <br />
        <br />
        <TextField
          fullWidth
          multiline
          error={
            (isPrivateKeyEdited && privateKey === "") ||
            (privateKey !== "" &&
              (!privateKey.startsWith("-----BEGIN ENCRYPTED PRIVATE KEY-----") ||
                privateKey.endsWith("-----END ENCRYPTED PRIVATE KEY-----")))
          }
          label="Private Key"
          placeholder="Copy paste your private key here"
          rows={2}
          value={privateKey}
          onBlur={handlePrivateKeyBlur}
          onChange={handlePrivateKeyChange}
        />
        <TextField
          fullWidth
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- i dunno what's the problem here :(
            isPrivateKeyPasswordEdited && privateKeyPassword === ""
          }
          label="Private Key Passphrase"
          type="text"
          value={privateKeyPassword}
          variant="standard"
          onBlur={handlePrivateKeyPasswordBlur}
          onChange={handlePrivateKeyPasswordChange}
        />
        <br />
        <Instructions />
        <br />
        <Typography fontWeight="bold" mt={1} variant="subtitle1">
          Ingestion Role & User Name
        </Typography>
        In order to configure your Snowflake account with all the required permissions,
        just download the Snowflake script <strong>after</strong> you fill in all the
        details. You will still need to replace the{" "}
        <strong>&lt;add-your-public-key-here&gt;</strong> with your own public key (i.e.
        without the &quot;--BEGIN...&quot;, just the part that starts with
        &quot;MIIB...&quot;).
        <br />
        <a download="snowflake_permissions.sql" href="." onClick={handleDownloadClick}>
          <GreenButton>Download Snowflake Script</GreenButton>
        </a>
        <br />
        <CallToActions
          disabled={
            accountUrl === "" ||
            warehouse === "" ||
            userName === "" ||
            ingestionRole === "" ||
            privateKey === "" ||
            privateKeyPassword === ""
          }
          inProgress={isInProgress}
          onCancel={handleCancel}
          onSave={handleSave}
        />
        {error !== undefined && <Alert severity="error">{error}</Alert>}
      </form>
    </Box>
  );
}
