/* eslint-disable @typescript-eslint/no-magic-numbers -- this is dummy data */

import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  type ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OPTIONS: ChartOptions<"bar"> = {
  plugins: { title: { display: true, text: "PRs Analyzed" } },
  responsive: true,
  scales: { x: { stacked: true }, y: { stacked: true } },
};

const LABELS = ["July", "August", "September", "October", "November", "December"];

export function ChartAnalyzedPRs(): JSX.Element {
  return (
    <Bar
      data={{
        labels: LABELS,
        datasets: [
          {
            label: "Good",
            data: [20, 25, 12, 56, 9, 3],
            backgroundColor: "rgb(101,202,132)",
            borderRadius: 2,
          },
          {
            label: "With Issues",
            data: [1, 5, 2, 5, 1, 6],
            backgroundColor: "rgb(255,134,108)",
            borderRadius: 2,
          },
        ],
      }}
      options={OPTIONS}
    />
  );
}
