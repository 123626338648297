/* eslint-disable import/no-deprecated */
import { useCallback, useMemo } from "react";
import { prop } from "remeda";
import {
  type TableOwner,
  useAddTableOwnerMutation,
  useRemoveTableOwnerMutation,
  useTableOwners,
} from "../../api";
import type { LineageTable } from "../../utils/graph/type";
import { XIcon } from "../icons/XIcon";
import { AddOwnerForm } from "./AddOwnerForm";
import { SectionHeader } from "./SectionHeader";
import { User } from "./User";

export function Owners({ table }: { readonly table: LineageTable }): JSX.Element {
  const { data: owners } = useTableOwners(table);
  const { mutate: addOwner, isLoading: isLoadingAdd } = useAddTableOwnerMutation(table);
  const { mutate: removeOwner, isLoading: isLoadingRemove } =
    useRemoveTableOwnerMutation(table);

  const ownerEmails = useMemo(() => owners?.map(prop("email")) ?? [], [owners]);

  return (
    <section className="flex flex-col items-start gap-3">
      <SectionHeader>Owners</SectionHeader>
      {owners === undefined ? (
        <div className="flex flex-1 animate-pulse items-center justify-center text-sm font-medium text-gray-400">
          Fetching owners...
        </div>
      ) : (
        <>
          <ul
            className={`flex max-h-40 w-full flex-col gap-2 overflow-hidden overflow-y-auto ${
              isLoadingAdd || isLoadingRemove ? "animate-pulse" : ""
            }`}
          >
            {owners.map((owner) => (
              <Owner
                key={owner.email}
                disabled={isLoadingRemove}
                owner={owner}
                onDelete={removeOwner}
              />
            ))}
          </ul>
          <AddOwnerForm excludedEmails={ownerEmails} onAdd={addOwner} />
        </>
      )}
    </section>
  );
}

function Owner({
  owner,
  onDelete,
  disabled,
}: {
  readonly owner: TableOwner;
  readonly onDelete: (variables: { readonly owner: TableOwner }) => void;
  readonly disabled: boolean;
}): JSX.Element {
  const handleDelete = useCallback(() => {
    onDelete({ owner });
  }, [onDelete, owner]);

  return (
    <li className="group flex items-center justify-center gap-3 rounded-md bg-[rgb(247,249,248)] p-2">
      <User {...owner} />
      <button
        className="invisible flex h-6 w-6 flex-none items-center justify-center rounded p-2 text-[rgb(165,173,176)] hover:bg-[rgb(239,242,241)] group-hover:visible"
        disabled={disabled}
        title="Remove"
        type="button"
        onClick={handleDelete}
      >
        <XIcon />
      </button>
    </li>
  );
}
