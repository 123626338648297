import { autoUpdate, offset, shift, useFloating } from "@floating-ui/react-dom";
import { Popover } from "@headlessui/react";
import type { PropsWithChildren } from "react";
import { ChevronLeftIcon } from "../../icons/ChevronLeftIcon";
import { withWordBreakOpportunities } from "../../render_utils";

export function Selector({
  label,
  value,
  autoFocus,
  children,
}: PropsWithChildren<{
  readonly label: JSX.Element;
  readonly value: string;
  readonly autoFocus?: boolean;
}>): JSX.Element {
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: "right-start",
    middleware: [
      /* eslint-disable @typescript-eslint/no-magic-numbers */
      offset(4),
      shift({ padding: 4 }),
      /* eslint-enable @typescript-eslint/no-magic-numbers */
    ],
    whileElementsMounted: autoUpdate,
  });

  return (
    <Popover>
      <Popover.Button
        ref={setReference}
        autoFocus={autoFocus}
        className="flex w-full flex-col items-stretch gap-2 border-b border-[rgba(82,97,100,0.1)] px-5 pb-4 pt-3 transition-colors duration-75 hover:bg-[rgb(247,249,248)] focus:outline-none ui-open:border-l-4 ui-open:border-l-[rgb(101,202,132)] ui-open:bg-[rgb(247,249,248)] ui-open:pl-4"
      >
        {label}
        <span className="flex items-end gap-1 overflow-clip overflow-ellipsis text-left font-semibold leading-5 text-[rgb(82,97,100)]">
          <span className="flex-1">{withWordBreakOpportunities(value)}</span>
          <ChevronLeftIcon className="h-5 w-5 flex-none p-1 text-[rgba(165,173,176,0.6)] transition-transform duration-75 ui-not-open:rotate-180" />
        </span>
      </Popover.Button>
      <Popover.Panel
        ref={setFloating}
        unmount
        className="z-50 flex max-h-[85vh] w-96 flex-col overflow-hidden overflow-y-auto rounded bg-white shadow-[0px_4px_10px_rgba(44,57,63,0.2)]"
        style={{ position: strategy, top: y, left: x }}
      >
        {children}
      </Popover.Panel>
    </Popover>
  );
}
