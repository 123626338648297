import type { LineageRootEntity } from "../../api";

export function ColumnDescriptor({
  columnRoot,
}: {
  readonly columnRoot: LineageRootEntity;
}): JSX.Element {
  const schema = columnRoot.type === "table" ? columnRoot.dbSchema : "";
  return (
    <div className="flex gap-x-2 text-xs text-[rgb(165,173,176)]">
      <Filler />
      <span
        className="max-w-fit flex-1 basis-2/3 overflow-hidden overflow-ellipsis whitespace-nowrap uppercase"
        title={columnRoot.entityName}
      >
        {columnRoot.entityName}
      </span>
      <Divider />
      <span
        className="max-w-fit flex-1 basis-1/3 overflow-hidden overflow-ellipsis whitespace-nowrap"
        title={schema}
      >
        {schema}
      </span>
    </div>
  );
}

function Filler(): JSX.Element {
  return <div className="w-4 flex-none" />;
}

function Divider(): JSX.Element {
  return <div className="h-4 w-1 flex-none border-r border-[rgb(225,231,234)]" />;
}
