import { useCallback, useState } from "react";
import invariant from "tiny-invariant";
import {
  useDeletePolicyMutation,
  usePolicy,
  useUpdatePolicyMutation,
} from "../../../../api";
import { DeletePolicyDialog } from "../DeletePolicyDialog";
import { PolicyRowPending } from "./PolicyRowPending";
import { PolicyView } from "./PolicyView";

export function PoliciesTablePolicyRow({
  policyId,
  policyName,
}: {
  readonly policyId: string;
  readonly policyName: string;
}): JSX.Element {
  // TODO: Implement policy editing.

  const { isLoading, isError, data: policy } = usePolicy(policyId);
  invariant(!isError, `Failed to load policy ${policyId}`);
  const updatePolicyMutation = useUpdatePolicyMutation(policyId);
  const deletePolicyMutation = useDeletePolicyMutation(policyId);
  const [isDeletionPending, setIsDeletionPending] = useState(false);

  const updatePolicyStatus = useCallback(
    (isEnabled: boolean) => {
      updatePolicyMutation.mutate({ isEnabled });
    },
    [updatePolicyMutation],
  );
  const handleDelete = useCallback(() => {
    // Just show the deletion dialog.
    setIsDeletionPending(true);
  }, [setIsDeletionPending]);
  const handleDeletePolicyApproval = useCallback(() => {
    // Close the deletion approval dialog and delete the policy.
    setIsDeletionPending(false);
    deletePolicyMutation.mutate();
  }, [deletePolicyMutation, setIsDeletionPending]);
  const handleDeletePolicyCancellation = useCallback(() => {
    // Close the deletion approval dialog.
    setIsDeletionPending(false);
  }, [setIsDeletionPending]);

  if (isLoading) {
    return <PolicyRowPending policyName={policyName} />;
  }

  return (
    <>
      <PolicyView
        policy={policy}
        onDelete={handleDelete}
        onStatusChange={updatePolicyStatus}
      />
      <DeletePolicyDialog
        isOpen={isDeletionPending}
        policyName={policyName}
        onApprove={handleDeletePolicyApproval}
        onCancel={handleDeletePolicyCancellation}
      />
    </>
  );
}
