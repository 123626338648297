import { useCallback } from "react";
import { apiRequest } from "../../api/apiRequest";
import { useAuthToken, useLogout } from "../login/authProvider";

export function useApiRequest(): (
  endpoint: string,
  body?: unknown,
  method?: string,
) => Promise<unknown> {
  const authToken = useAuthToken();
  const logout = useLogout();

  return useCallback(
    async (endpoint, body, method) => {
      if (authToken === null) {
        throw new Error(`Missing authToken for API request: ${endpoint}`);
      }

      return apiRequest({
        endpoint,
        authToken,
        onUnauthorized: logout,
        body,
        ...(method !== undefined && { requestInit: { method } }),
      });
    },
    [authToken, logout],
  );
}
