import { pipe, sortBy } from "remeda";
import type { LineageEntity, LineageEntityType, LineageRootEntity } from "../../api";
import type { MatchDescriptor, SearcherFunction } from "./SEARCHERS";
import { entitySearch } from "./entitiesSearch";

export type LineageSearchResult = LineageSearchEntityDescriptor & MatchDescriptor;

export type LineageSearchEntityDescriptor = {
  readonly rootEntity: LineageRootEntity;
  readonly result: LineageEntity;

  /**
   * the name of the result entity relative to its root entity. @example if the root is
   * "table1" which has a column "col1" and the result is a sub-column of "col1" this
   * will be: ".col1.subcol1". If the result is the root entity this is empty.
   */
  readonly resultNameRelativeToRoot?: string | undefined;
};

const MATCH_TYPE_ORDER: readonly MatchDescriptor["matchType"][] = ["exact", "contains"];
const ENTITY_TYPE_ORDER: readonly LineageEntityType[] = [
  "table",
  "file",
  "dashboard",
  "column",
];

// TODO this should be implemented in the backend so that we wont need to fetch the entire lineage.
export const searchLineage = (
  rootEntities: readonly LineageRootEntity[],
  query: string,
): readonly LineageSearchResult[] =>
  pipe(
    rootEntities,
    searchByName(query),
    sortBy(
      // Our very crude "ranking algorithm" for search results
      ({ matchType }) => MATCH_TYPE_ORDER.indexOf(matchType),
      ({ isMatchCase }) => isMatchCase,
      ({ result: { type } }) => ENTITY_TYPE_ORDER.indexOf(type),
      ({ rootEntity }) => rootEntity.entityName,
    ),
  );

function searchByName(query: string) {
  return (entities: readonly LineageRootEntity[]) =>
    entitySearch(entities, query, searchEntityTreeByName);
}

function searchEntityTreeByName(root: LineageRootEntity, searcher: SearcherFunction) {
  return searchEntityTree(root, searcher, false);
}

export function searchById(
  query: string,
): (entities: readonly LineageRootEntity[]) => readonly LineageSearchResult[] {
  return (entities: readonly LineageRootEntity[]) =>
    entitySearch(entities, query, searchEntityTreeById);
}

function searchEntityTreeById(root: LineageRootEntity, searcher: SearcherFunction) {
  return searchEntityTree(root, searcher, true);
}

// eslint-disable-next-line max-params
function searchEntityTree(
  rootEntity: LineageRootEntity,
  searcher: SearcherFunction,
  shouldSearchById: boolean,
  currentEntity?: LineageEntity,
  resultNameRelativeToRoot?: string,
): readonly LineageSearchResult[] {
  const result = currentEntity ?? rootEntity;
  const { id, childEntities, entityName } = result;

  const searchCurrent = shouldSearchById ? searcher(id) : searcher(entityName);

  const currentResults =
    searchCurrent === undefined
      ? []
      : [{ rootEntity, result, resultNameRelativeToRoot, ...searchCurrent }];

  return [
    ...currentResults,
    ...childEntities.flatMap((child) =>
      searchEntityTree(
        rootEntity,
        searcher,
        shouldSearchById,
        child,
        `${resultNameRelativeToRoot ?? ""}.${child.entityName}`,
      ),
    ),
  ];
}

export function fullSearchResultPathTitle({
  rootEntity,
  resultNameRelativeToRoot,
}: LineageSearchEntityDescriptor): string {
  return `${rootEntity.entityName}${resultNameRelativeToRoot ?? ""}`.toUpperCase();
}
