/* eslint-disable import/no-deprecated */

import { useState } from "react";
import type { LineageTable } from "../../utils/graph/type";
import { CodeIcon } from "../icons/CodeIcon";
import { DependenciesIcon } from "../icons/DependenciesIcon";
import { InformationIcon } from "../icons/InformationIcon";
import { CodePointersPanel } from "./CodePointersPanel";
import { DependenciesPanel } from "./DependenciesPanel";
import { InformationPanel } from "./InformationPanel";
import { InspectorSection } from "./InspectorSection";

const DEFAULT_SECTION_ID = "information";

export function InspectorSections({
  table,
}: {
  readonly table: LineageTable;
}): JSX.Element {
  const [activeSectionId, setActiveSectionId] = useState(DEFAULT_SECTION_ID);
  return (
    <>
      <InspectorSection
        activeSectionId={activeSectionId}
        icon={<InformationIcon />}
        sectionId={DEFAULT_SECTION_ID}
        title="Information"
        onClick={setActiveSectionId}
      >
        <InformationPanel table={table} />
      </InspectorSection>
      <InspectorSection
        activeSectionId={activeSectionId}
        icon={<DependenciesIcon />}
        sectionId="deps"
        title="Dependencies"
        onClick={setActiveSectionId}
      >
        <DependenciesPanel table={table} />
      </InspectorSection>
      <InspectorSection
        activeSectionId={activeSectionId}
        icon={<CodeIcon />}
        sectionId="code"
        title="Code pointers"
        onClick={setActiveSectionId}
      >
        <CodePointersPanel table={table} />
      </InspectorSection>
    </>
  );
}
