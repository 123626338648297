/* eslint-disable import/no-deprecated */
import { canonicalTableIdentifier } from "../../utils/graph/canonical";
import type { LineageTable } from "../../utils/graph/type";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";

// TODO: Use whatever type we already have that covers users, if we have
// one. We don't need a special type here...
export type TableOwner = {
  readonly email: string;
  readonly firstName?: string;
  readonly lastName?: string;
};

const FAKE_OWNERS: readonly TableOwner[] = [
  { firstName: "John", lastName: "Doe", email: "john.doe@acme.com" },
  {
    firstName: "JohnWhoHas",
    lastName: "AReallyReallyLooooongFamilyName",
    email:
      "his.email.is.also.really.really.long.should.have.gotten.a.shorter.one@acme.com",
  },
];

export const tableOwnersQueryKey = ({
  table: { table_identifier },
}: {
  readonly table: LineageTable;
}) => ["tableOwners", canonicalTableIdentifier(table_identifier)] as const;

export function useTableOwners(table: LineageTable) {
  return useQueryRetriesServerErrors({
    queryKey: tableOwnersQueryKey({ table }),
    queryFn: () => fakeImpl(table),
    staleTime: STALE_TIME_MS,
  });
}

// TODO: Implement the real backend API call.
const fakeImpl = (_table: LineageTable) => FAKE_OWNERS;
