import { useCallback } from "react";
import { useReactFlow } from "reactflow";
import { concat, createPipe } from "remeda";
import type { LineageRootEntity } from "../api";
import { repositionElementsInGridLayout } from "../utils/discovery/repositionElementsInGridLayout";
import type { LineageRootData } from "./graph/LineageRootNode";
import { toLineageRootNode } from "./graph/LineageRootNode";

const GO_TO_NODE_ANIMATION_DURATION_MS = 1000;

export function useMoveToRootNode(nodeId: string): () => void {
  const moveToNode = useMoveToNode();
  return useCallback(() => {
    moveToNode(nodeId);
  }, [moveToNode, nodeId]);
}

export function useAddRootNodeIfNeededAndFocus(): (
  rootEntity: LineageRootEntity,
) => boolean {
  const { getNode, setNodes } = useReactFlow();
  const moveToNode = useMoveToNode();

  return useCallback(
    (rootEntity: LineageRootEntity) => {
      const { id: nodeId } = rootEntity;
      const node = getNode(nodeId);
      const shouldAddNewNode = node === undefined;
      if (shouldAddNewNode) {
        // Add the node
        setNodes(
          createPipe(
            concat([toLineageRootNode(rootEntity, 0 /* tier */)]),
            repositionElementsInGridLayout,
          ),
        );
      }

      setTimeout(() => {
        // We use the timeout so that we run the goto logic *after* the node is added to
        // the graph.
        moveToNode(nodeId);
      });
      return shouldAddNewNode;
    },
    [getNode, moveToNode, setNodes],
  );
}

function useMoveToNode(): (nodeId: string) => void {
  const { fitBounds, getNode } = useReactFlow<LineageRootData>();
  return useCallback(
    (nodeId: string) => {
      const node = getNode(nodeId);
      if (node === undefined) {
        throw new Error(`Couldn't find node ${nodeId} in graph!`);
      }

      const {
        position: { x, y },
        width,
        height,
      } = node;

      fitBounds(
        { x, y, width: width ?? 0, height: height ?? 0 },
        { duration: GO_TO_NODE_ANIMATION_DURATION_MS },
      );
    },
    [fitBounds, getNode],
  );
}
