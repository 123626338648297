import { Combobox } from "@headlessui/react";
import { useMemo } from "react";
import { useQueriedUsers } from "../../api";
import type { UsersSearchResult } from "../../utils/search/users";
import { PlusSignIcon } from "../icons/PlusSignIcon";
import { NoResults } from "../search/NoResults";
import { User } from "./User";

// Copied from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
const EMAIL_VALIDATOR =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/u;

export function AddUserFormOptions({
  query,
  excludedEmails,
}: {
  readonly query: string;
  readonly excludedEmails: readonly string[];
}): JSX.Element {
  const { data } = useQueriedUsers(query);

  const adjustedUsers = useMemo(() => {
    if (data === undefined) {
      return;
    }

    let users = data;

    if (EMAIL_VALIDATOR.test(query)) {
      // If the input is a valid email add it to the list at the top so that the user
      // can add arbitrary emails even if the server doesn't know them. To add it to the
      // list though we need to fake it as a search result.
      const emailResult = searchResultFromEmail(query);
      users = [emailResult, ...users];
    }

    return users.filter(({ item: { email } }) => !excludedEmails.includes(email));
  }, [data, excludedEmails, query]);

  if (adjustedUsers === undefined) {
    return (
      <div className="flex w-full animate-pulse items-center justify-center py-8 text-xs font-medium text-[rgb(165,173,176)]">
        Querying server...
      </div>
    );
  }

  if (adjustedUsers.length === 0) {
    return (
      <NoResults subtitle="To add a new user use a full email address">
        No users found
      </NoResults>
    );
  }

  return (
    <>
      {adjustedUsers.map(
        (
          // TODO: We currently don't do match highlighting on the results.
          { item: user },
        ) => (
          <Combobox.Option
            key={user.email}
            className="group flex items-center justify-center gap-3 border-b border-[rgba(82,97,100,0.15)] p-2 last:border-0 hover:bg-[rgb(247,249,248)] "
            value={user}
          >
            <User {...user} />
            <PlusSignIcon className="invisible h-5 w-5 text-[rgb(165,173,176)] group-hover:visible" />
          </Combobox.Option>
        ),
      )}
    </>
  );
}

const searchResultFromEmail = (email: string): UsersSearchResult => ({
  entityType: "email",
  item: { email },
  matchType: "exact",
  isMatchCase: true,
  segments: [{ start: 0, len: email.length }],
});
