import { useCallback } from "react";
import invariant from "tiny-invariant";
import type { PolicyRuleSubjectEntityType } from "../../../api";
import { useSnapshot } from "../../../api";
import type { LineageSearchResult } from "../../../utils/search/lineage";
import { fullSearchResultPathTitle } from "../../../utils/search/lineage";
import { LineageEntitySelector } from "../../discovery/LineageEntitySelector";
import { DeletePolicyButton } from "../common/policies_table/PolicyActionButtons";
import {
  type ChangeType,
  TriggerChangeTypeSelector,
} from "./TriggerChangeTypeSelector";

export type PartialRuleData = {
  readonly entityType?: PolicyRuleSubjectEntityType | undefined;
  readonly entityId?: string | undefined;
  readonly changeType?: ChangeType | undefined;
};

export function SingleRuleConfigBox({
  ruleId,
  ruleData,
  onRuleChange,
  hideDeleteButton,
  className,
}: {
  readonly ruleId: number;
  readonly ruleData: PartialRuleData;
  readonly onRuleChange: (
    updatedRuleId: number,
    updatedRuleData?: PartialRuleData,
  ) => void;
  readonly hideDeleteButton: boolean;
  readonly className?: string | undefined;
}): JSX.Element {
  const { isError, isFetching, data: lineageData } = useSnapshot();

  const handleSearchResultSelected = useCallback(
    (result: LineageSearchResult | undefined) => {
      if (result === undefined) {
        const { entityType, entityId, ...newRuleData } = ruleData;
        onRuleChange(ruleId, newRuleData);
        return;
      }

      const {
        result: { type },
      } = result;

      const entityType = type === "file" ? ("table" as const) : type;
      const entityId = fullSearchResultPathTitle(result);
      const newRuleData = { ...ruleData, entityType, entityId };
      onRuleChange(ruleId, newRuleData);
    },
    [onRuleChange, ruleId, ruleData],
  );

  const handleChangeTypeSelect = useCallback(
    (changeType: ChangeType) => {
      const newRuleData = { ...ruleData, changeType };
      onRuleChange(ruleId, newRuleData);
    },
    [onRuleChange, ruleId, ruleData],
  );

  const handleDeleteButtonClicked = useCallback(() => {
    onRuleChange(ruleId);
  }, [onRuleChange, ruleId]);

  invariant(!isError, "Error fetching lineage data");

  return (
    <div className={`flex flex-col gap-y-6 ${className ?? ""} w-1/2 min-w-[280px]`}>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row items-center justify-between">
          <span className="text-base font-semibold leading-4 text-[rgb(74,89,92)]">
            What elements would you like to create a policy for?
          </span>
          {hideDeleteButton ? null : (
            <DeletePolicyButton onClick={handleDeleteButtonClicked} />
          )}
        </div>
        <LineageEntitySelector
          className={`${isFetching ? "animate-pulse" : ""}`}
          lineage={lineageData}
          searchBoxPlaceholder="Search for an element"
          onResultSelect={handleSearchResultSelected}
        />
      </div>
      <div className="flex flex-col gap-y-4">
        <span className="text-base font-semibold leading-4 text-[rgb(74,89,92)]">
          What type of changes should trigger an action?
        </span>
        <TriggerChangeTypeSelector
          initialChangeType={ruleData.changeType}
          onChangeTypeSelect={handleChangeTypeSelect}
        />
      </div>
    </div>
  );
}
