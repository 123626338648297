import { redirect } from "react-router-dom";
import { getTokenFromStorage, logout } from "../utils/auth/foundational";
import { apiRequest } from "./apiRequest";

export async function loaderApiCall(
  options: Omit<Parameters<typeof apiRequest>[0], "authToken" | "onUnauthorized">,
): Promise<unknown> {
  const { authToken } = getTokenFromStorage();
  if (authToken === null) {
    throw redirect("/login");
  }

  return apiRequest({
    authToken,
    onUnauthorized: () => {
      logout();
      throw redirect("/login");
    },
    ...options,
  });
}
