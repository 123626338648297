import mixpanel from "mixpanel-browser";
import type { ChangeStatus, LineageEntity, LineageEntityType } from "../../api";
import type { Direction } from "../../components/discovery/utils/useDiscoveryModeExpandAction";
import type { ChangeType } from "../../components/pr_explorer/filters/FiltersPanelSearchResultsView";
import type { GraphType } from "../graph/useGraphType";
import type { FilterType } from "../search/lineageType";

export function logEventLoginPageImpression(): void {
  mixpanel.track("Login Page Viewed");
}

export function logEventLoginSuccess(): void {
  mixpanel.people.set({ "Last Login Date": new Date().toISOString() });
  mixpanel.track("Login Finished Successfully");
}

export function logEventLogout(): void {
  mixpanel.track("User Logged Out");
  mixpanel.reset();
}

export function logEventLineageGraphImpression(): void {
  mixpanel.track("Lineage Graph Viewed");
}

export function logEventChangeGraphImpression(prId: string, repoName: string): void {
  mixpanel.track("Change Graph Viewed", {
    "Change Type": "PR",
    "Change ID": prId,
    "Repository Name": repoName,
  });
}

export function logEventModifiedTabCategoryClicked(
  filterType: FilterType,
  changeStatus: ChangeStatus,
  itemsCount: number,
): void {
  mixpanel.track("Diff Box Modified Category Clicked", {
    "Category Type": changeStatus,
    "Entity Type": filterType,
    "Category Items Count": itemsCount,
  });
}

export function logEventModifiedTabItemClicked(
  filterType: FilterType,
  changeStatus: ChangeStatus,
  itemsCount: number,
): void {
  mixpanel.track("Diff Box Modified Item Clicked", {
    "Category Type": changeStatus,
    "Entity Type": filterType,
    "Category Items Count": itemsCount,
  });
}

export function logEventDiffBoxSearched(
  query: string,
  changeType: ChangeType,
  resultsCount: number,
): void {
  mixpanel.track("Diff Box Searched", {
    "Diff Impact": changeType,
    "Search Query": query,
    "Results Count": resultsCount,
  });
}

export function logEventDiffBoxSearchResultClicked(
  entityType: LineageEntityType,
  changeType: "affected" | "modified",
  resultsCount: number,
): void {
  mixpanel.track("Diff Box Search Result Clicked", {
    "Diff Impact": changeType,
    "Entity Type": entityType,
    "Results Count": resultsCount,
  });
}

export function logEventRepositorySelectionClicked(reposCount: number): void {
  mixpanel.track("Repository Selection Clicked", {
    "Repositories Count": reposCount,
  });
}

export function logEventRepositorySelected(repoName: string): void {
  mixpanel.track("Repository Selected", {
    "Repository Name": repoName,
  });
}

export function logEventVersionSelectionClicked(): void {
  mixpanel.track("Version Selection Clicked");
}

export function logEventVersionSearched(query: string, resultsCount: number): void {
  mixpanel.track("Version Searched", {
    "Search Query": query,
    "Results Count": resultsCount,
  });
}

export function logEventVersionSelected(prId: string): void {
  mixpanel.track("Version Selected", {
    "Change Type": "PR",
    "Change ID": prId,
  });
}

export function logEventGraphExpandButtonClicked(
  graphType: GraphType | undefined,
  entityType: "column" | "table",
  {
    isExpand,
    direction,
    numAffectedEntities,
  }: {
    readonly isExpand: boolean;
    readonly direction: Direction;
    readonly numAffectedEntities: number;
  },
): void {
  mixpanel.track("Graph Expand Button Clicked", {
    "Graph Type": graphTypeWithUnknown(graphType),
    "Entity Type": entityType,
    "Expand Direction": expandDirectionFromDirection(direction),
    "Expand Type": isExpand ? "Expand" : "Hide",
    "Affected Entities Count": numAffectedEntities,
    "Expand Levels": "One Level",
  });
}

export function logEventGraphSearchFocused(graphType: GraphType | undefined): void {
  mixpanel.track("Graph Search Clicked", {
    "Graph Type": graphTypeWithUnknown(graphType),
  });
}

export function logEventGraphItemsSearched(
  graphType: GraphType | undefined,
  query: string,
  resultsCount: number,
): void {
  mixpanel.track("Graph Items Searched", {
    "Graph Type": graphTypeWithUnknown(graphType),
    "Search Query": query,
    "Results Count": resultsCount,
  });
}

export function logEventGraphSearchResultClicked(
  graphType: GraphType | undefined,
  { type, entityName }: LineageEntity,
  resultIsVisible: boolean,
): void {
  mixpanel.track("Graph Search Results Clicked", {
    "Graph Type": graphTypeWithUnknown(graphType),
    "Entity Type": type,
    "Entity Name": entityName,
    "Entity Already Visible": resultIsVisible,
  });
}

export function logEventGraphResetClicked(graphType: GraphType | undefined): void {
  mixpanel.track("Graph Reset Clicked", {
    "Graph Type": graphTypeWithUnknown(graphType),
  });
}

export function logEventGraphToggleClicked(
  graphType: GraphType | undefined,
  checked: boolean,
): void {
  mixpanel.track("Graph Toggle Clicked", {
    "Graph Type": graphTypeWithUnknown(graphType),
    "New Toggle State": checked ? "Collapse" : "Expand",
  });
}

function graphTypeWithUnknown(graphType: GraphType | undefined): string {
  return graphType ?? "Unknown";
}

function expandDirectionFromDirection(direction: Direction): string {
  switch (direction) {
    case "inbound":
      return "Upstream";
    case "outbound":
      return "Downstream";
  }
}
