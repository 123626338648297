import type { Edge } from "reactflow";
import type { EdgeState } from "../../api";
import { edgeStyle, edgeZIndex, markerEndSettings } from "./edge_visuals";

export type EdgeData = {
  readonly edgeState: EdgeState;
};

export function toEdge(
  edge: EdgeState,
  isHighlighted = false,
  isSelected = false,
): Edge<EdgeData> {
  const { id, source, dest, changeStatus, relationType } = edge;
  const isFocused = isHighlighted || isSelected;

  return {
    id,
    data: { edgeState: edge },
    source: source.rootId,
    sourceHandle: source.entityName,
    target: dest.rootId,
    targetHandle: dest.entityName,

    // Visuals
    style: edgeStyle(changeStatus, relationType, isFocused),
    zIndex: edgeZIndex(changeStatus, relationType, isFocused),
    markerEnd: markerEndSettings(changeStatus, isFocused),

    ...(isSelected && { className: "animate-pulse" }),
    selected: isSelected,
  };
}
