import type { PropsWithChildren } from "react";
import invariant from "tiny-invariant";
import { useOrgPolicyList } from "../../../api";
import { PoliciesTable } from "../common/policies_table/PoliciesTable";

export function PoliciesScreenBody(): JSX.Element {
  const { data: orgPolicyList, isLoading, isError } = useOrgPolicyList();
  invariant(!isError, "Failed to load organization policies");

  if (isLoading) {
    return <LoadingPoliciesMessage />;
  }

  if (orgPolicyList.policies.length === 0) {
    return <NoPoliciesFoundMessage />;
  }

  // TODO: Add a search bar and the expand/collapse policies buttons right
  // above the table.
  return (
    <div className="flex flex-col">
      <PoliciesTable policies={orgPolicyList.policies} />
    </div>
  );
}

function LoadingPoliciesMessage(): JSX.Element {
  return <Message>Loading organization policies...</Message>;
}

function NoPoliciesFoundMessage(): JSX.Element {
  return (
    <div className="flex flex-col gap-y-3">
      <Message>No policies have been defined for your organization yet.</Message>
      <Message>
        Go ahead and create your first policy by clicking the{" "}
        <span className="font-semibold">&quot;+ Add New Policy&quot;</span> button.
      </Message>
    </div>
  );
}

function Message({ children }: PropsWithChildren): JSX.Element {
  return <div className="text-lg text-[rgb(165,175,176)]">{children}</div>;
}
