import { type PropsWithChildren, useState, useEffect, useCallback } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { lineageFromDeprecated } from "../../../api/lineage/conversionsToNew";
import { TopGradient } from "../../../components/discovery/TopGradient";
import { TopToolbar } from "../../../components/discovery/TopToolbar";
import { useCollapsedModeActions } from "../../../components/discovery/utils/collapsedModeStore";
import { useGraphInitializer } from "../../../components/discovery/utils/useGraphInitializer";
import {
  LINEAGE_NODE_TYPE,
  LineageRootNode,
} from "../../../components/graph/LineageRootNode";
import { InspectorPanel } from "../../../components/inspector/InspectorPanel";
import { StyledFileUploader } from "../../../components/settings/bigquery/StyledFileUploader";
import type { LineageState } from "../../../utils/graph/type";

export default function ParseJsonPage(): JSX.Element {
  return (
    <div className="flex h-screen w-screen items-stretch">
      <main className="flex-1 overflow-auto">
        <JsonDiscoveryGraph toolbarShown="nonempty">
          <JsonLoader />
        </JsonDiscoveryGraph>
      </main>
    </div>
  );
}

type InterviewTableColumn = {
  readonly col_name: string;
  readonly type: string;
};

type InterviewTable = {
  readonly table_name: string;
  readonly columns: readonly InterviewTableColumn[];
};

type InterviewColumn = {
  readonly table_name: string;
  readonly col_name: string;
};

type InterviewEdgeState = {
  readonly src_column: InterviewColumn;
  readonly dst_column: InterviewColumn;
  readonly confidence: number;
};

type InterviewLineageState = {
  readonly tables: readonly InterviewTable[];
  readonly edges: readonly InterviewEdgeState[];
};

function JsonLoader(): JSX.Element | null {
  const { enable } = useCollapsedModeActions();
  const [lineage, setLineage] = useState<InterviewLineageState>();

  useEffect(() => {
    enable(false);
  }, [enable]);

  const handleDrop = async (acceptedFiles: readonly File[]) => {
    const file = acceptedFiles[0];
    if (file === undefined) {
      return;
    }
    const parsed = JSON.parse(await file.text()) as InterviewLineageState;

    setLineage(parsed);
  };

  const dropHandler = useCallback((acceptedFiles: readonly File[]) => {
    // NOTICE: The original onDrop method is not async which means it
    // assumes we can accept and reject files without reading them.
    // Because we await the file's text before we make the decision, we
    // need to wrap the method and handle the rejected files externally in
    // our component
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleDrop(acceptedFiles);
  }, []);

  const newLineage = lineageFromDeprecated(convertInterviewLineage(lineage));
  const initializeGraph = useGraphInitializer(newLineage.entities, newLineage.edges);
  useEffect(() => {
    initializeGraph();
  }, [initializeGraph]);

  if (lineage === undefined) {
    return (
      <div className="z-50 m-auto flex h-full w-72 flex-col items-center justify-center gap-3">
        <StyledFileUploader
          accept={{ "application/json": [] }}
          multiple={false}
          text="Drop your output .json file here, or click to select"
          onDrop={dropHandler}
        />
      </div>
    );
  }

  // if (isError) {
  //     return <PullRequestDiffError error={error} prId={prId} repoName={repoName} />;
  // }

  // if (isLoading) {
  //     return <FullscreenMessage loading>Loading data...</FullscreenMessage>;
  // }

  // if (data !== undefined && isEmptyDiff(data.diff)) {
  //     return <EmptyDiffMessage />;
  // }

  // No interstitial/overlay to show, we let the graph show instead.
  return null;
}

// TODO: Move this to a utils file. It's here because I pulled it off
// main component and I just wanted to make sure I run the same logic.
// export const isEmptyDiff = ({ entities, edges }: MultiLineage["diff"]) =>
//     entities.length === 0 && edges.length === 0;

function JsonDiscoveryGraph({
  toolbarShown = "nonempty",
  children,
}: PropsWithChildren<{
  readonly toolbarShown?: "always" | "nonempty";
}>): JSX.Element {
  return (
    <ReactFlowProvider>
      <div className="flex h-full flex-1">
        <JsonDiscoveryGraphInternal toolbarShown={toolbarShown}>
          {children}
        </JsonDiscoveryGraphInternal>
        <InspectorPanel />
      </div>
    </ReactFlowProvider>
  );
}

function JsonDiscoveryGraphInternal({
  toolbarShown,
  children,
}: PropsWithChildren<{
  readonly toolbarShown: "always" | "nonempty";
}>): JSX.Element {
  const [nodes, , onNodesChange] = useNodesState([]);
  const [edges, , onEdgesChange] = useEdgesState([]);

  const MIN_ZOOM = 0.0001;
  const NODE_TYPES = {
    [LINEAGE_NODE_TYPE]: LineageRootNode,
  };

  return (
    <ReactFlow
      fitView
      className="flex h-full w-full flex-row bg-[rgb(229,233,231)]"
      edges={edges}
      minZoom={MIN_ZOOM}
      nodeTypes={NODE_TYPES}
      nodes={nodes}
      nodesDraggable={false}
      proOptions={{ hideAttribution: true }}
      onEdgesChange={onEdgesChange}
      onNodesChange={onNodesChange}
    >
      {nodes.length > 0 && (
        <>
          <Background
            color="rgba(0,0,0,0.1)"
            gap={20}
            size={3}
            variant={BackgroundVariant.Dots}
          />
          <Controls position="bottom-right" showInteractive={false} />
        </>
      )}
      {(toolbarShown === "always" || nodes.length > 0) && (
        <>
          <TopGradient />
          <TopToolbar />
        </>
      )}
      {children}
    </ReactFlow>
  );
}

function convertInterviewLineage(
  lineage: InterviewLineageState | undefined,
): LineageState {
  if (lineage === undefined) {
    return { tables: [], edges: [] };
  }
  const tables = lineage.tables.map((table) => ({
    table_identifier: {
      table_name: table.table_name,
      db_name: "default",
      db_schema: "default",
      identifier_type: "db" as const,
      identifier: table.table_name,
    }, // we might need to use canonicalTableId
    columns: table.columns.map((column) => ({
      col_name: column.col_name,
      type: "" as const,
    })),
  }));
  const edges = lineage.edges.map((edge) => ({
    sourceColumn: {
      col_name: edge.src_column.col_name,
      type: "" as const,
      table_identifier: {
        table_name: edge.src_column.table_name,
        db_name: "default",
        db_schema: "default",
        identifier_type: "db" as const,
        identifier: edge.src_column.table_name, // we might need to use canonicalTableId
      },
    },
    destinationColumn: {
      col_name: edge.dst_column.col_name,
      type: "" as const,
      table_identifier: {
        table_name: edge.dst_column.table_name,
        db_name: "default",
        db_schema: "default",
        identifier_type: "db" as const,
        identifier: edge.dst_column.table_name, // we might need to use canonicalTableId
      },
    },
    relationType: "relation" as const,
    changeStatus: undefined,
  }));
  return { tables, edges };
}
