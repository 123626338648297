import { useEffect, useMemo, useState } from "react";
import { groupBy, pipe, sortBy, sumBy, toPairs } from "remeda";
import type { PrItem } from "../../../../api";
import { logEventVersionSearched } from "../../../../utils/analytics/eventLogging";
import { searchPullRequests } from "../../../../utils/search/pullRequests";
import { SimpleSearchBox } from "../../../SimpleSearchBox";
import { VersionsPanelBody } from "./VersionsPanelBody";

// This also defines the tabs visible order (from left to right), change it accordingly
const ALL_EXPANDEDS_PR_STATUSES = ["open", "merged", "closed", "draft"] as const;
export type ExpandedPrStatus = (typeof ALL_EXPANDEDS_PR_STATUSES)[number];

export function LoadedVersionsPanel({
  prItems,
}: {
  readonly prItems: readonly PrItem[];
}): JSX.Element {
  const [query, setQuery] = useState("");

  const results = useMemo(
    () =>
      pipe(
        prItems,
        ($) => searchPullRequests($, query),
        groupBy.strict(({ item }) => getExpandedStatus(item)),
        toPairs.strict,
        sortBy(([prStatus]) => ALL_EXPANDEDS_PR_STATUSES.indexOf(prStatus)),
      ),
    [prItems, query],
  );

  useEffect(() => {
    if (query === "") {
      return;
    }

    const resultsCount = sumBy(results, ([, items]) => items.length);

    logEventVersionSearched(query, resultsCount);
  }, [query, results]);

  return (
    <>
      <header className="flex flex-col gap-2 px-5 pb-1 pt-5">
        <SimpleSearchBox autoFocus onSearch={setQuery} />
        <hr className="h-px w-full px-5" />
      </header>
      <VersionsPanelBody searchResults={results} />
    </>
  );
}

export const isExpandedPrStatus = (raw: string): raw is ExpandedPrStatus =>
  ALL_EXPANDEDS_PR_STATUSES.includes(raw as ExpandedPrStatus);

const getExpandedStatus = ({
  pr_status,
  is_draft,
  merged_at,
}: PrItem): ExpandedPrStatus =>
  pr_status === "open"
    ? is_draft
      ? "draft"
      : "open"
    : merged_at === undefined
    ? "closed"
    : "merged";
