import type { PullRequestsSearchResult } from "../../../../utils/search/pullRequests";
import type { ExpandedPrStatus } from "./LoadedVersionsPanel";
import { VersionItem } from "./VersionItem";
import { DATA_ATTRIBUTE_NAME_PR_STATUS } from "./VersionsPanelBody";

export function ResultsSection({
  items,
  prStatus,
}: {
  readonly items: readonly PullRequestsSearchResult[];
  readonly prStatus: ExpandedPrStatus;
}): JSX.Element | null {
  if (items.length === 0) {
    return null;
  }

  return (
    <section
      {...{ [`data-${DATA_ATTRIBUTE_NAME_PR_STATUS}`]: prStatus }}
      className="[&:only-child>h6]:hidden"
    >
      <h6 className="border-b bg-[rgb(247,250,249)] px-4 py-3 text-xs font-semibold capitalize text-[rgb(136,152,148)]">
        {prStatus}
      </h6>
      <ol>
        {items.map((result) => (
          <li key={result.item.pr_number}>
            <VersionItem {...result} />
          </li>
        ))}
      </ol>
    </section>
  );
}
