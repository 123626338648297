import { DEMO_MODE } from "../utils/demo/demoMode";
import { queryApiCall } from "./queryApiCall";
import { useQueryRetriesServerErrors } from "./useQueryDefaultRetry";
import { STALE_TIME_MS } from "./utils";

type CurrentUserProfileIdentity = {
  /* eslint-disable @typescript-eslint/naming-convention -- Defined by the API */
  readonly id: string;
  readonly org_name: string;
  readonly email: string;
  readonly is_active: boolean;
  readonly is_superuser: boolean;
  readonly is_verified: boolean;
  readonly first_name: string;
  readonly last_name: string;
  /* eslint-enable @typescript-eslint/naming-convention */
};

const EXAMPLE_PROFILE: CurrentUserProfileIdentity = {
  id: "not-a-real-id",
  org_name: "foundational",
  email: "demo@foundational.io",
  is_active: true,
  is_superuser: true,
  is_verified: false,
  first_name: "Alon",
  last_name: "Nafta",
};

export const useMe = () =>
  useQueryRetriesServerErrors({
    queryKey: ["me"],
    queryFn,
    staleTime: STALE_TIME_MS,
  });

const queryFn = async (): Promise<CurrentUserProfileIdentity> =>
  DEMO_MODE.isEnabled ? EXAMPLE_PROFILE : queryApiCall("users/me");
