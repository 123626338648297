/* eslint-disable @typescript-eslint/naming-convention */

import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";

const ALL_LINEAGE_ISSUE_SEVERITIES = ["critical", "error", "info", "warning"] as const;
export type LineageIssueSeverity = (typeof ALL_LINEAGE_ISSUE_SEVERITIES)[number];

const ALL_ISSUE_CHANGE_STATUSES = ["unchanged", "added", "removed"] as const;
type IssueChangeStatus = (typeof ALL_ISSUE_CHANGE_STATUSES)[number];

/**
 * A single issue of a pull request.
 * @see [LineageIssue](../../../backend/backend/app/core/lineage_api_models.py)
 */
export type LineageIssue = {
  /**
   * The issue UUID, it is unique within the whole system.
   */
  readonly id: number;

  /**
   * The issue type, we use this to group similar issues together.
   * @example Cycle
   */
  readonly issue_type: string;

  /**
   * A detailed description of the issue.
   */
  readonly description: string;

  /**
   * The severity of the issue.
   * @example error
   */
  readonly severity: LineageIssueSeverity;

  /**
   * An indicator of whether the issue is new to this PR, already existed in the base
   * commit, or was removed in the PR. We use this to split the issues into different
   * views.
   */
  readonly change_status: IssueChangeStatus;
};

/**
 * A collection of issues for a given pull request.
 * @see [PrLineageIssuesInfo](../../../backend/backend/app/core/lineage_api_models.py)
 */
type PrLineageIssuesInfo = {
  readonly repo_name: string;
  readonly pr_number: string;
  readonly issues: readonly LineageIssue[];
};

/**
 * Get a list of issues for a pull request.
 * @see [pr_issues_info](../../../backend/backend/app/api/lineage_v1.py)
 */
export const usePrIssuesInfo = (repoName: string, prNumber: number) =>
  useQueryRetriesServerErrors({
    queryKey: ["prIssuesInfo", repoName, prNumber],
    queryFn: async () => prIssuesInfo(repoName, prNumber),
    staleTime: STALE_TIME_MS,
  });

async function prIssuesInfo(
  repoName: string,
  prNumber: number,
): Promise<PrLineageIssuesInfo["issues"]> {
  const { issues } = await queryApiCall<PrLineageIssuesInfo>(
    `lineage/${repoName}/pull/${prNumber}/issues`,
  );
  return issues;
}
