import type { PropsWithChildren, ReactNode } from "react";

export function SelectorLabel({
  indicator,
  className,
  children,
}: PropsWithChildren<{
  readonly indicator?: ReactNode;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <header className={`flex items-center ${className ?? ""}`}>
      <h6 className="flex-1 text-left text-xs text-[rgb(143,153,157)]">{children}</h6>
      {indicator}
    </header>
  );
}
