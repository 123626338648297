import { Switch } from "@headlessui/react";
import { ChevronDown } from "../icons/ChevronDown";
import { stopPropagation } from "../stopPropagation";

export const EXPAND_COLUMN_TOGGLE_HEIGHT = 32; // h-8

export function ExpandColumnsToggle({
  expanded,
  columnsCount,
  onChange,
}: {
  readonly expanded: boolean;
  readonly columnsCount: number;
  readonly onChange: (newValue: boolean) => void;
}): JSX.Element {
  return (
    <Switch
      checked={expanded}
      className="flex h-8 w-full items-center justify-between border-b border-[rgba(82,97,100,0.15)] bg-[rgb(247,249,248)] px-4 ui-checked:rounded-b"
      onChange={onChange}
      onClick={stopPropagation}
    >
      <span className="text-xs tabular-nums text-[rgb(136,152,148)]">
        {columnsCount} column{columnsCount > 1 && "s"}
      </span>
      <ChevronDown className="w-[10px] text-[rgb(165,173,176)] ui-checked:rotate-180" />
    </Switch>
  );
}
