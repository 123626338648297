import { useCallback } from "react";
import { useReactFlow } from "reactflow";
import { createPipe, filter } from "remeda";
import type { EdgeState } from "../../../api";
import { repositionElementsInGridLayout } from "../../../utils/discovery/repositionElementsInGridLayout";
import type { LineageRootData } from "../../graph/LineageRootNode";
import type { EdgeData } from "../../graph/edge";

export function useDiscoveryModeContractAction(
  connectedEdges: readonly EdgeState[],
  clickedNodeId: string,
): () => void {
  const { setNodes, getEdges, setEdges } = useReactFlow<LineageRootData, EdgeData>();

  return useCallback(() => {
    const connectedEdgesIds = new Set(connectedEdges.map((rel) => rel.id));
    const allRemainingEdges = getEdges().filter(({ id }) => !connectedEdgesIds.has(id));

    // Precompute for perf
    const allConnectedTables = new Set(
      allRemainingEdges.flatMap(({ source, target }) => [source, target]),
    );

    setEdges(allRemainingEdges);

    setNodes(
      createPipe(
        // Only show nodes that are connected to an edge OR the clicked node OR have a changeStatus.
        filter(
          ({
            id,
            data: {
              entity: { changeStatus },
            },
          }) =>
            id === clickedNodeId ||
            allConnectedTables.has(id) ||
            changeStatus !== undefined,
        ),
        repositionElementsInGridLayout,
      ),
    );
  }, [clickedNodeId, connectedEdges, getEdges, setEdges, setNodes]);
}
