import type { CSSProperties } from "react";
import { type Edge, MarkerType } from "reactflow";
import type { ChangeStatus, RelationType } from "../../api";

// I don't know what unit this number represents, SVG has some notion of an
// abstract unit type
const MARKER_SIZE = 18;

const HIGHLIGHTED_STROKE_WIDTH = 2;
const HIGHLIGHTED_MARKER_END_SIZE = 0.65;
const HIGHLIGHTED_MARKER_END_STROKE_WIDTH = 1.25;

// This defines how dashed lines look
// @see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray
const DASH_ARRAY = 5;

export function edgeStyle(
  changeStatus: ChangeStatus | undefined,
  relationType: RelationType,
  isFocused: boolean,
): CSSProperties {
  const stroke = edgeColor(changeStatus, isFocused);
  const lineStyleClassName = edgeLineStyle(relationType);

  return {
    ...(stroke !== undefined && { stroke }),
    ...(isFocused && { strokeWidth: HIGHLIGHTED_STROKE_WIDTH }),
    ...lineStyleClassName,
  };
}

export function markerEndSettings(
  changeStatus: ChangeStatus | undefined,
  isFocused: boolean,
): NonNullable<Edge["markerEnd"]> {
  // Because we make the line's stroke wider when highlighting it makes the
  // arrowhead kinda ugly so we scale it down
  const size = MARKER_SIZE * (isFocused ? HIGHLIGHTED_MARKER_END_SIZE : 1);

  const color = edgeColor(changeStatus, isFocused);

  return {
    type: MarkerType.Arrow,
    height: size,
    width: size,
    ...(color !== undefined && { color }),
    // For similar reasons to above, the arrowhead just doesn't look nice when
    // the line stroke is wider
    ...(isFocused && { strokeWidth: HIGHLIGHTED_MARKER_END_STROKE_WIDTH }),
  };
}

function edgeColor(
  changeStatus: ChangeStatus | undefined,
  isHighlightedOrSelected: boolean,
): string | undefined {
  if (changeStatus === undefined) {
    return isHighlightedOrSelected ? "black" : undefined;
  }

  switch (changeStatus) {
    case "new":
      return "green";
    case "changed":
    case "renamed":
      return "orange";
    case "removed":
      return "DarkRed";
  }
}

// eslint-disable-next-line complexity
export function edgeZIndex(
  changeStatus: ChangeStatus | undefined,
  relationType: RelationType,
  isFocused: boolean,
): number {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  if (isFocused) {
    return 100;
  }

  switch (relationType) {
    case "relation":
      return changeStatusZIndex(changeStatus);

    case "case":
    case "group":
    case "having":
    case "join":
    case "order":
    case "qualify":
    case "select":
    case "unknown":
    case "where":
    case "window":
    case "<<MISSING>>":
      return 30;
  }
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}

function changeStatusZIndex(changeStatus: ChangeStatus | undefined): number {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  if (changeStatus === undefined) {
    return 30;
  }

  switch (changeStatus) {
    case "new":
      return 40;
    case "changed":
    case "renamed":
      return 50;
    case "removed":
      return 40;
  }
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}

// eslint-disable-next-line complexity
function edgeLineStyle(relationType: RelationType): CSSProperties | undefined {
  switch (relationType) {
    case "relation":
      return;

    case "case":
    case "group":
    case "having":
    case "join":
    case "order":
    case "qualify":
    case "select":
    case "unknown":
    case "where":
    case "window":
    case "<<MISSING>>":
      return { strokeDasharray: DASH_ARRAY };
  }
}
