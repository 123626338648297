import mixpanel from "mixpanel-browser";
import { ENV, MIXPANEL_ENABLED, MIXPANEL_TOKEN } from "../env";

const FOUNDATIONAL_SUPPORT_ORG_NAME = "Foundational Support";

export function initMixpanel(): void {
  if (!MIXPANEL_ENABLED) {
    // Currently seems like this is the only way to make mixpanel not send events but still allow calling the "track"
    // method without throwing exceptions.
    mixpanel.init("<<DUMMY_MIXPANEL_TOKEN>>");
    mixpanel.disable();
    return;
  }

  mixpanel.init(MIXPANEL_TOKEN, { debug: ENV === "local" });
}

export function setMixpanelUser({
  userId,
  email,
  orgName,
  fullName,
  isSuperuser,
}: Readonly<
  Record<"email" | "fullName" | "orgName" | "userId", string> & { isSuperuser: boolean }
>): void {
  mixpanel.identify(userId);
  mixpanel.people.set({
    $email: email,
    $name: fullName,
  });
  mixpanel.set_group("Account", isSuperuser ? FOUNDATIONAL_SUPPORT_ORG_NAME : orgName);
}
