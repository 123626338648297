import { useCallback, type ChangeEvent } from "react";
import { SearchIcon } from "../icons/SearchIcon";

/**
 * This component is largely copy-pasted from our SimpleSearchBox component so if
 * anything is acting weird it's a good idea to look there if there's a fix.
 * It differs from that component by:
 * * Being controlled, vs being an uncontrolled element accessed via a ref.
 * * It's smaller, and uses slightly different styles.
 * * It doesn't use the "incremental" attribute to delay updates, it's immediate.
 */
export function DependencySearchInput({
  disabled,
  value,
  onChange,
}: {
  readonly disabled: boolean;
  readonly value: string;
  readonly onChange: (newValue: string) => void;
}): JSX.Element {
  const handleSearchChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      onChange(currentTarget.value);
    },
    [onChange],
  );

  return (
    <div className="flex items-center">
      <input
        autoFocus
        required
        autoComplete="off"
        autoCorrect="off"
        className="peer w-full rounded-md px-3 py-2 text-xs font-medium text-neutral-600 shadow-[0_2px_4px_0_rgba(115,130,133,0.15),0_0_0_1px_rgba(44,57,63,0.13)] outline-none placeholder:text-neutral-300"
        disabled={disabled}
        placeholder="Search elements..."
        spellCheck={false}
        // We use 'title' here to disable the browser's default behavior for
        // 'required' fields to show a tooltip "please fill out this field". I don't
        // know why this works, i just found it on stack overflow
        title=""
        type="search"
        value={value}
        onChange={handleSearchChange}
      />
      <SearchIcon className="invisible absolute right-9 w-3 text-[rgb(185,194,195)] peer-invalid:visible" />
    </div>
  );
}
