import type { OrgDetails } from "../../utils/adminSiteTypes";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";

export const useOrgList = () =>
  useQueryRetriesServerErrors({
    queryKey: ["orgList"],
    queryFn: getOrgList,
  });

const getOrgList = async (): Promise<readonly OrgDetails[]> =>
  queryApiCall("admin/org-list");
