import { useCallback } from "react";
import type { MatchDescriptor } from "../../../utils/search/SEARCHERS";
import type { LineageSearchEntityDescriptor } from "../../../utils/search/lineage";
import { SearchResultsItem } from "../../discovery/SearchResultsItem";
import { useMoveToRootNode } from "../../useMoveToRootNode";

const DEFAULT_MATCH_DESCRIPTOR: MatchDescriptor = {
  matchType: "exact",
  isMatchCase: true,
  segments: [{ start: 0, len: 0 }],
};

export function FilterSearchResultsItem({
  entity,
  onClick,
}: {
  readonly entity: LineageSearchEntityDescriptor;
  readonly onClick: () => void;
}): JSX.Element {
  const moveToTableNode = useMoveToRootNode(entity.rootEntity.id);

  const handleClick = useCallback(() => {
    moveToTableNode();
    onClick();
  }, [moveToTableNode, onClick]);

  return (
    <SearchResultsItem
      className="p-3"
      result={{
        ...entity,
        ...DEFAULT_MATCH_DESCRIPTOR,
      }}
      onClick={handleClick}
    />
  );
}
