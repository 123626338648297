import { Tab } from "@headlessui/react";
import { Fragment, type PropsWithChildren } from "react";
import type { ChangeStatus } from "../../../api";
import type { FilterType } from "../../../utils/search/lineageType";
import { NoResults } from "../../search/NoResults";
import { AffectedTab } from "./AffectedTab";
import { ModifiedTab } from "./ModifiedTab";
import { useFiltersPanelContext } from "./SidebarFiltersPanel";

export function FiltersView({
  onClick,
}: {
  readonly onClick: (type: FilterType, changeStatus: ChangeStatus) => void;
}): JSX.Element {
  const { isDisabled } = useFiltersPanelContext();

  return (
    <Tab.Group
      as="section"
      className="flex flex-1 flex-col overflow-hidden"
      defaultIndex={isDisabled ? -1 : 0}
    >
      <Tab.List className="flex flex-none text-sm">
        <FilterPanelTab>Modified</FilterPanelTab>
        <FilterPanelTab>Affected</FilterPanelTab>
      </Tab.List>
      {isDisabled ? (
        <NoResults
          // Intentionally left blank
          subtitle=""
        >
          No entities changed
        </NoResults>
      ) : (
        <Tab.Panels as={Fragment}>
          <FilterPanelTabPanel>
            <ModifiedTab onClick={onClick} />
          </FilterPanelTabPanel>
          <FilterPanelTabPanel>
            <AffectedTab />
          </FilterPanelTabPanel>
        </Tab.Panels>
      )}
    </Tab.Group>
  );
}

function FilterPanelTab({ children }: PropsWithChildren): JSX.Element {
  const { isDisabled } = useFiltersPanelContext();

  return (
    <Tab
      className="flex-1 border-b-4 py-3 font-medium text-[rgb(143,153,157)] outline-none ui-selected:border-[rgb(101,202,132)] ui-selected:font-semibold ui-selected:text-[rgb(82,97,100)]"
      disabled={isDisabled}
    >
      {children}
    </Tab>
  );
}

function FilterPanelTabPanel({ children }: PropsWithChildren): JSX.Element {
  return (
    <Tab.Panel as="ol" className="flex-1 overflow-y-auto">
      {children}
    </Tab.Panel>
  );
}
