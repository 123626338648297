/* eslint-disable @typescript-eslint/explicit-module-boundary-types --
 * This is a mini framework for searching, it uses several concepts which aren't
 * standard.
 */

type MatchSegment = {
  readonly start: number;
  readonly len: number;
};

type NonEmptyArray<T> = [T, ...T[]];

export type MatchDescriptor = {
  readonly matchType: "contains" | "empty" | "exact";
  readonly isMatchCase: boolean;
  readonly segments: Readonly<NonEmptyArray<MatchSegment>>;
};

export type SearcherFunction = (entity: string) => MatchDescriptor | undefined;

type SearcherGenerator = (query: string) => SearcherFunction;

const EMPTY_MATCH = {
  matchType: "empty",
  isMatchCase: true,
  segments: [{ start: 0, len: 0 }],
} as const;

// TODO: Add a fuzzy search generator
export const SEARCHERS: readonly SearcherGenerator[] = [
  // The first searcher is a special in that it only handles the case where the query is
  // empty. Because search should be seen as a filter (it gradually removes more
  // entities the more characters are in the query) then the empty string should be
  // treated as the most permissive filter (i.e. it should match everything). It should
  // be first so that it short-circuits any other searchers.
  (query) =>
    query === ""
      ? () => EMPTY_MATCH
      : // eslint-disable-next-line unicorn/no-useless-undefined -- we intentionally want to return undefined here...
        () => undefined,

  (query) => {
    const match = {
      matchType: "exact",
      isMatchCase: true,
      segments: [{ start: 0, len: query.length }],
    } as const;
    return (entity) => (entity === query ? match : undefined);
  },

  (query) => {
    const match = {
      matchType: "exact",
      isMatchCase: false,
      segments: [{ start: 0, len: query.length }],
    } as const;
    return (entity) => (isEqualCaseInsensitive(entity, query) ? match : undefined);
  },

  (query) => (entity) => {
    const start = entity.indexOf(query);
    return start >= 0
      ? {
          matchType: "contains",
          isMatchCase: true,
          segments: [{ start, len: query.length }],
        }
      : undefined;
  },

  (query) => {
    // avoiding regex usage to skip handling escape characters
    // (otherwise they'll raise an exception on bad patterns)
    const lowerCaseQuery = query.toLocaleLowerCase();
    return (entity) => {
      const start = entity.toLocaleLowerCase().indexOf(lowerCaseQuery);
      return start >= 0
        ? {
            matchType: "contains",
            isMatchCase: false,
            segments: [{ start, len: query.length }],
          }
        : undefined;
    };
  },
];

/**
 * I copied this impl from stackoverflow, it's supposed to be a performant way to do
 * case-insensitive string comparisson. I'm hoping it is more efficient than lowering
 * the case for both strings and doing a case-sensitive comparisson.
 */
const isEqualCaseInsensitive = (a: string, b: string) =>
  a.localeCompare(b, undefined /* locale */, {
    sensitivity: "base",
    usage: "search",
  }) === 0;
