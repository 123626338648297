import type { LineageRootEntity } from "../../api";
import { VerticalTextDivider } from "../VerticalTextDivider";
import { BigQueryIcon } from "../icons/BigQueryIcon";
import { HdfsIcon } from "../icons/HdfsIcon";
import { S3Icon } from "../icons/S3Icon";
import { TableauIcon } from "../icons/TableauIcon";

export const META_LINE_HEIGHT = 16; // Class: text-xs

export function NodeDescriptor({
  entity,
}: {
  readonly entity: LineageRootEntity;
}): JSX.Element {
  return (
    <div className="flex items-center gap-x-2 text-xs text-[rgb(165,173,176)]">
      <PlatformIcon className="h-4 w-4 flex-none" platform={entity.platform} />
      <NodeDescriptorText entity={entity} />
    </div>
  );
}

function PlatformIcon({
  className,
  platform,
}: {
  readonly platform: LineageRootEntity["platform"];
  readonly className?: string | undefined;
}): JSX.Element {
  switch (platform) {
    case "bigquery":
      return <BigQueryIcon className={className} />;

    case "tableau":
      return <TableauIcon className={className} />;

    case "s3":
      return <S3Icon className={className} />;

    case "hdfs":
      return <HdfsIcon className={className} />;
  }
}

function NodeDescriptorText({
  entity,
}: {
  readonly entity: LineageRootEntity;
}): JSX.Element {
  switch (entity.type) {
    case "table":
      return (
        <InnerNodeDescriptorText leftText={entity.dbName} rightText={entity.dbSchema} />
      );
    case "file":
      return (
        <InnerNodeDescriptorText
          leftText={entity.rootFolder ?? ""}
          rightText={entity.dirName ?? ""}
        />
      );
    case "dashboard":
      return (
        <InnerNodeDescriptorText
          leftText={entity.platform}
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
          rightText={entity.projectName}
        />
      );
  }
}

function InnerNodeDescriptorText({
  leftText,
  rightText,
}: {
  readonly leftText: string;
  readonly rightText?: string;
}): JSX.Element {
  return (
    <>
      <span
        className="max-w-fit flex-1 basis-1/3 overflow-hidden overflow-ellipsis whitespace-nowrap"
        title={leftText}
      >
        {leftText}
      </span>
      <VerticalTextDivider />
      {rightText === undefined ? null : (
        <span
          className="max-w-fit flex-1 basis-2/3 overflow-hidden overflow-ellipsis whitespace-nowrap"
          title={rightText}
        >
          {rightText}
        </span>
      )}
    </>
  );
}
