import type { PropsWithChildren } from "react";
import type { PrItem } from "../../api";
import { PrettyTimeSince } from "../PrettyTimeSince";
import { ClosedPullRequestIcon } from "../icons/ClosedPullRequestIcon";
import { DraftPullRequestIcon } from "../icons/DraftPullRequestIcon";
import { MergedPullRequestIcon } from "../icons/MergedPullRequestIcon";
import { OpenPullRequestIcon } from "../icons/OpenPullRequestIcon";

export function PullRequestStatus({
  item: {
    created_at: createdAt,
    is_draft: isDraft,
    pr_status: state,
    closed_at: closedAt,
    merged_at: mergedAt,
  },
  iconClassName = "",
  children: userElement,
}: PropsWithChildren<{
  readonly item: Pick<
    PrItem,
    "closed_at" | "created_at" | "is_draft" | "merged_at" | "pr_status"
  >;
  readonly iconClassName?: string | undefined;
}>): JSX.Element {
  switch (state) {
    case "open":
      return (
        <span className="flex items-center gap-2 overflow-hidden">
          {isDraft ? (
            <DraftPullRequestIcon
              className={`text-[rgb(101,109,118)] ${iconClassName}`}
            />
          ) : (
            <OpenPullRequestIcon
              className={`text-[rgb(73,161,100)] ${iconClassName}`}
            />
          )}
          <span className="truncate">
            opened <PrettyTimeSince dateTime={createdAt} /> by {userElement}
          </span>
        </span>
      );

    case "closed":
      return (
        <span className="flex items-center gap-2 overflow-hidden">
          {mergedAt === undefined ? (
            // No merge date so the PR was probably just closed/abandoned.
            <>
              <ClosedPullRequestIcon
                className={`text-[rgb(209,36,47)] ${iconClassName}`}
              />
              <span className="truncate">
                by {userElement} was closed{" "}
                {closedAt === undefined ? null : (
                  <PrettyTimeSince dateTime={closedAt} />
                )}
              </span>
            </>
          ) : (
            <>
              <MergedPullRequestIcon
                className={`text-[rgb(130,80,223)] ${iconClassName}`}
              />
              <span className="truncate">
                by {userElement} was merged <PrettyTimeSince dateTime={mergedAt} />
              </span>
            </>
          )}
        </span>
      );
  }
}
