import Typography from "@mui/material/Typography";

export function Instructions(): JSX.Element {
  return (
    <>
      <Typography component="div" mt={1} variant="h6">
        Instructions
      </Typography>
      <Typography fontWeight="bold" variant="subtitle1">
        Account Url
      </Typography>
      The URL of your Snowflake account, for example:
      https://myaccount.snowflakecomputing.com
      <Typography fontWeight="bold" mt={1} variant="subtitle1">
        Warehouse
      </Typography>
      The Snowflake warehouse to use for compute
      <Typography fontWeight="bold" mt={1} variant="subtitle1">
        Database
      </Typography>
      The Database you want to provide Foundational access to. If you leave this empty,
      then Foundational will try to access all databases in your Snowflake account.
      <Typography fontWeight="bold" mt={1} variant="subtitle1">
        Private Key
      </Typography>
      The encrypted private key (starts with: &quot;-----BEGIN ENCRYPTED PRIVATE
      KEY-----&quot;)
      <Typography fontWeight="bold" mt={1} variant="subtitle1">
        Private Key Passphrase
      </Typography>
      The passphrase/password for the encrypted private key
    </>
  );
}
