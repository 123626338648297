import { useCallback } from "react";
import { type Edge, type Node as ReactFlowNode, useReactFlow } from "reactflow";
import { concat, filter, indexBy, map, pipe, prop, uniq } from "remeda";
import invariant from "tiny-invariant";
import {
  useRouteLineage,
  EMPTY_STATE,
  type EdgeState,
  type LineageRootEntity,
} from "../../../api";
import {
  hasLayoutPosition,
  repositionElementsInGridLayout,
} from "../../../utils/discovery/repositionElementsInGridLayout";
import { type LineageRootData, toLineageRootNode } from "../../graph/LineageRootNode";
import { type EdgeData, toEdge } from "../../graph/edge";

export type Direction = "inbound" | "outbound";

export function useDiscoveryModeExpandAction(
  connectedEdges: readonly EdgeState[],
  direction: Direction,
  clickedNodeId: string,
): () => void {
  const {
    snapshot: { entities },
  } = useRouteLineage({ assertIsLoaded: true }) ?? {
    snapshot: EMPTY_STATE,
  };

  const { setNodes, setEdges } = useReactFlow<LineageRootData, EdgeData>();

  return useCallback(() => {
    setEdges((currentEdges) => edgesAfterAdd(currentEdges, connectedEdges));

    setNodes((currentNodes) =>
      nodesAfterAdd(currentNodes, entities, connectedEdges, clickedNodeId, direction),
    );
  }, [clickedNodeId, connectedEdges, direction, setEdges, setNodes, entities]);
}

function edgesAfterAdd(
  currentEdges: readonly Edge<EdgeData>[],
  connectedEdges: readonly EdgeState[],
): Edge<EdgeData>[] {
  // Precompute for perf
  const currentEdgeIds = new Set(currentEdges.map(prop("id")));

  const newEdges = pipe(
    connectedEdges,
    // Remove all connected edges which are already shown in the graph
    filter((relation) => !currentEdgeIds.has(relation.id)),
    // Create new edges for new relations being added to the graph
    map(toEdge),
  );

  // Return all existing edges, and add the new edges to them
  return [...currentEdges, ...newEdges];
}

// eslint-disable-next-line max-params
function nodesAfterAdd(
  currentNodes: readonly ReactFlowNode<LineageRootData>[],
  allRootNodes: readonly LineageRootEntity[],
  connectedEdges: readonly EdgeState[],
  clickedNodeId: string,
  direction: Direction,
): ReactFlowNode<LineageRootData>[] {
  const currentNodesById = indexBy(currentNodes, prop("id"));

  // Find the X layout position for any new nodes that would be added based on the
  // layout position of the current (clicked on) node.
  const { [clickedNodeId]: clickedNode } = currentNodesById;
  invariant(
    clickedNode !== undefined,
    `Couldn't find node ${clickedNodeId} in the graph!`,
  );
  invariant(
    hasLayoutPosition(clickedNode),
    `Node ${clickedNodeId} does not have a layout position!`,
  );
  const tier =
    clickedNode.data.layoutPosition.tier + (direction === "outbound" ? 1 : -1);

  const allRootsById = indexBy(allRootNodes, prop("id"));

  return pipe(
    connectedEdges,

    // Find all tables connected in the right direction...
    map(({ source: { rootId: srcRootId }, dest: { rootId: dstRootId } }) =>
      direction === "outbound" ? dstRootId : srcRootId,
    ),

    // Remove all nodes already present in the graph, they will be concatenated later
    filter((rootId) => !(rootId in currentNodesById)),

    // TODO: Here we filter out duplicate tables, but are there ever
    // duplicates? A duplicate here would mean that there are multiple relations
    // with the same source and target.
    uniq(),

    // Create new nodes and set their initial position
    map((rootId) => {
      const { [rootId]: root } = allRootsById;
      invariant(root !== undefined, `Couldn't find table: ${rootId}`);
      return toLineageRootNode(root, tier);
    }),

    // Add all existing nodes
    concat(currentNodes),

    repositionElementsInGridLayout,
  );
}
