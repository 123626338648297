import { Link } from "react-router-dom";
import { FoundationalIcon } from "../icons/FoundationalIcon";
import { useNavStateContext } from "./Nav";
import { NavigationHamburgerButton } from "./NavigationHamburgerButton";

export function NavigationHeader({
  onHamburgerClick,
}: {
  readonly onHamburgerClick: () => void;
}): JSX.Element {
  const { isOpen } = useNavStateContext();

  return (
    <header className="relative flex h-20 flex-none items-center bg-[rgba(19,28,32,0.4)] text-base font-semibold leading-5 text-white">
      {isOpen ? (
        <Link className="flex flex-1 items-center" to="/">
          <FoundationalIcon className="ml-5 mr-2 w-6" />
          Foundational
        </Link>
      ) : (
        <FoundationalIcon className="ml-5 w-6 group-hover/nav:hidden" />
      )}
      <NavigationHamburgerButton onClick={onHamburgerClick} />
    </header>
  );
}
