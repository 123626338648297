import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PoliciesScreenBody } from "./PoliciesScreenBody";
import { PoliciesScreenHeader } from "./PoliciesScreenHeader";

export function PoliciesScreen({
  createPolicyScreenPath,
}: {
  readonly createPolicyScreenPath: string;
}): JSX.Element {
  const navigate = useNavigate();
  const handleCreatePolicy = useCallback(() => {
    navigate(createPolicyScreenPath);
  }, [createPolicyScreenPath, navigate]);

  return (
    <div className="flex-column h-full w-full space-y-3 overflow-auto bg-[rgb(240,242,242)] px-20 pb-5 pt-3">
      <PoliciesScreenHeader onCreatePolicy={handleCreatePolicy} />
      <div className="h-px w-full bg-gray-300" />
      <PoliciesScreenBody />
    </div>
  );
}
