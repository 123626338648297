import type { PropsWithChildren } from "react";

export function DashboardList({
  className,
  title,
  children,
}: PropsWithChildren<{
  readonly title: string;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <section className={className}>
      <h5 className="text-xl font-bold">{title}</h5>
      <div className="mt-4 grid grid-cols-[1fr_1fr_3fr] border-b border-l">
        {children}
      </div>
    </section>
  );
}

export function HeaderCell({
  children,
  className = "",
}: PropsWithChildren<{
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <span
      className={`border-r border-t px-2 py-1 text-sm font-semibold uppercase text-black/30 ${className}`}
    >
      {children}
    </span>
  );
}

export function Cell({
  children,
  className = "",
}: PropsWithChildren<{
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <span className={`flex items-center border-r border-t p-2 ${className}`}>
      {children}
    </span>
  );
}
