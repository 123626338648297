import { filter, isDefined, map, pipe, sortBy, toPairs } from "remeda";
import type { TableOwner } from "../../api";
import type { MatchDescriptor, SearcherFunction } from "./SEARCHERS";
import { entitySearch } from "./entitiesSearch";

export type UsersSearchResult = MatchDescriptor & UsersSearchEntityDescriptor;

type UsersSearchEntityDescriptor = {
  readonly item: TableOwner;
  readonly entityType: keyof TableOwner;
};

const MATCH_TYPE_ORDER: readonly MatchDescriptor["matchType"][] = ["exact", "contains"];

export const searchUsers = (
  users: readonly TableOwner[],
  query: string,
): readonly UsersSearchResult[] =>
  pipe(
    users,
    search(query),
    sortBy(
      // Our very crude "ranking algorithm" for search reuslts
      ({ matchType }) => MATCH_TYPE_ORDER.indexOf(matchType),
      ({ isMatchCase }) => isMatchCase,
    ),
  );

const search = (query: string) => (users: readonly TableOwner[]) =>
  entitySearch(users, query, searchUser);

const searchUser = (
  item: TableOwner,
  searcher: SearcherFunction,
): readonly UsersSearchResult[] =>
  pipe(
    item,
    toPairs.strict,
    map(([field, value]) => {
      const match = searcher(value);
      return match === undefined
        ? undefined
        : {
            item,
            entityType: field,
            ...match,
          };
    }),
    filter(isDefined.strict),
  );
