/* eslint-disable max-lines, @typescript-eslint/no-magic-numbers --
 * This file is defacto a JSON file, but we built it as a TS module so we get
 * type-checking and coupling at compile time instead of runtime.
 */

import type { BackendLineageState } from "../graph/type";
import type { Settings } from "../settings/types";

export const EXAMPLE_SNAPSHOT: Readonly<BackendLineageState> = {
  stateType: "SNAPSHOT",
  version: 1,
  tables: [
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "customers",
      },
      columns: [
        { col_name: "customer_id", type: "varchar" },
        { col_name: "first_name", type: "varchar" },
        { col_name: "first_order", type: "varchar" },
        { col_name: "last_name", type: "varchar" },
        { col_name: "most_recent_order", type: "varchar" },
        { col_name: "number_of_orders", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "stg_orders",
      },
      columns: [
        { col_name: "customer_id", type: "varchar" },
        { col_name: "order_date", type: "varchar" },
        { col_name: "order_id", type: "varchar" },
        { col_name: "status", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "stg_customers",
      },
      columns: [
        { col_name: "customer_id", type: "varchar" },
        { col_name: "first_name", type: "varchar" },
        { col_name: "last_name", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "orders",
      },
      columns: [
        { col_name: "amount", type: "varchar" },
        { col_name: "bank_transfer_amount", type: "varchar" },
        { col_name: "coupon_amount", type: "varchar" },
        { col_name: "credit_card_amount", type: "varchar" },
        { col_name: "customer_id", type: "varchar" },
        { col_name: "gift_card_amount", type: "varchar" },
        { col_name: "order_date", type: "varchar" },
        { col_name: "order_id", type: "varchar" },
        { col_name: "status", type: "varchar" },
        { col_name: "deprecated_order_id", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "stg_payments",
      },
      columns: [
        { col_name: "amount", type: "varchar" },
        { col_name: "order_id", type: "varchar" },
        { col_name: "payment_id", type: "varchar" },
        { col_name: "payment_method", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "raw_customers",
      },
      columns: [
        { col_name: "id", type: "varchar" },
        { col_name: "first_name", type: "varchar" },
        { col_name: "last_name", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "raw_payments",
      },
      columns: [
        { col_name: "amount", type: "varchar" },
        { col_name: "id", type: "varchar" },
        { col_name: "order_id", type: "varchar" },
        { col_name: "payment_method", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "demo_schema",
        db_name: "dbt_tests",
        table_name: "raw_orders",
      },
      columns: [
        { col_name: "id", type: "varchar" },
        { col_name: "order_date", type: "varchar" },
        { col_name: "status", type: "varchar" },
        { col_name: "user_id", type: "varchar" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "dashboards",
        db_name: "tableau",
        table_name: "monthly_active_users",
      },
      columns: [
        {
          col_name:
            "Tracking users who made purchases in the recent 30 days, over time",
          type: "",
        },
        { col_name: " ", type: "" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "dashboards",
        db_name: "tableau",
        table_name: "deprecated_monthly_active_users",
      },
      columns: [
        {
          col_name:
            "Tracking users who made purchases in the recent 30 days, over time",
          type: "",
        },
        { col_name: " ", type: "" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "dashboards",
        db_name: "tableau",
        table_name: "open_orders",
      },
      columns: [
        {
          col_name:
            "Tracking active orders that are not marked as 'delivered', over time",
          type: "",
        },
        { col_name: " ", type: "" },
      ],
    },
    {
      table_identifier: {
        identifier_type: "db",
        db_schema: "dashboards",
        db_name: "tableau",
        table_name: "orders_by_date",
      },
      columns: [
        {
          col_name: "Tracking number of orders and their amounts by date",
          type: "",
        },
        { col_name: " ", type: "" },
      ],
    },
  ],
  relations: [
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "customer_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "monthly_active_users",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "most_recent_order",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "monthly_active_users",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_date",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "monthly_active_users",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_date",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "open_orders",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "status",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "open_orders",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "open_orders",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "orders_by_date",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_date",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "orders_by_date",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "orders_by_date",
        },
        col_name: " ",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "order_date",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "most_recent_order",
      },
      source_reference: {
        snippet: "snippet for src col: order_date",
        snippet_flavor: "SQL",
        url: "https://bla-order_date",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "order_date",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "first_order",
      },
      source_reference: {
        snippet: "snippet for src col: order_date",
        snippet_flavor: "SQL",
        url: "https://bla-order_date",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "order_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "number_of_orders",
      },
      source_reference: {
        snippet: "snippet for src col: order_id",
        snippet_flavor: "SQL",
        url: "https://bla-order_id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        col_name: "customer_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "customer_id",
      },
      source_reference: {
        snippet: "snippet for src col: customer_id",
        snippet_flavor: "SQL",
        url: "https://bla-customer_id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        col_name: "last_name",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "last_name",
      },
      source_reference: {
        snippet: "snippet for src col: last_name",
        snippet_flavor: "SQL",
        url: "https://bla-last_name",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        col_name: "first_name",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        col_name: "first_name",
      },
      source_reference: {
        snippet: "snippet for src col: first_name",
        snippet_flavor: "SQL",
        url: "https://bla-first_name",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "gift_card_amount",
      },
      source_reference: {
        snippet: "snippet for src col: amount",
        snippet_flavor: "SQL",
        url: "https://bla-amount",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "bank_transfer_amount",
      },
      source_reference: {
        snippet: "snippet for src col: amount",
        snippet_flavor: "SQL",
        url: "https://bla-amount",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "amount",
      },
      source_reference: {
        snippet: "snippet for src col: amount",
        snippet_flavor: "SQL",
        url: "https://bla-amount",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "customer_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "customer_id",
      },
      source_reference: {
        snippet: "snippet for src col: customer_id",
        snippet_flavor: "SQL",
        url: "https://bla-customer_id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "order_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_id",
      },
      source_reference: {
        snippet: "snippet for src col: order_id",
        snippet_flavor: "SQL",
        url: "https://bla-order_id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "order_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_id",
      },
      source_reference: {
        snippet: "snippet for src col: order_id",
        snippet_flavor: "SQL",
        url: "https://bla-order_id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "status",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "status",
      },
      source_reference: {
        snippet: "snippet for src col: status",
        snippet_flavor: "SQL",
        url: "https://bla-status",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "order_date",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_date",
      },
      source_reference: {
        snippet: "snippet for src col: order_date",
        snippet_flavor: "SQL",
        url: "https://bla-order_date",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "coupon_amount",
      },
      source_reference: {
        snippet: "snippet for src col: amount",
        snippet_flavor: "SQL",
        url: "https://bla-amount",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "credit_card_amount",
      },
      source_reference: {
        snippet: "snippet for src col: amount",
        snippet_flavor: "SQL",
        url: "https://bla-amount",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_customers",
        },
        col_name: "last_name",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        col_name: "last_name",
      },
      source_reference: {
        snippet: "snippet for src col: last_name",
        snippet_flavor: "SQL",
        url: "https://bla-last_name",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_customers",
        },
        col_name: "first_name",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        col_name: "first_name",
      },
      source_reference: {
        snippet: "snippet for src col: first_name",
        snippet_flavor: "SQL",
        url: "https://bla-first_name",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_customers",
        },
        col_name: "id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        col_name: "customer_id",
      },
      source_reference: {
        snippet: "snippet for src col: id",
        snippet_flavor: "SQL",
        url: "https://bla-id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      source_reference: {
        snippet: "snippet for src col: amount",
        snippet_flavor: "SQL",
        url: "https://bla-amount",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_payments",
        },
        col_name: "payment_method",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "payment_method",
      },
      source_reference: {
        snippet: "snippet for src col: payment_method",
        snippet_flavor: "SQL",
        url: "https://bla-payment_method",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_payments",
        },
        col_name: "order_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "order_id",
      },
      source_reference: {
        snippet: "snippet for src col: order_id",
        snippet_flavor: "SQL",
        url: "https://bla-order_id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_payments",
        },
        col_name: "id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "payment_id",
      },
      source_reference: {
        snippet: "snippet for src col: id",
        snippet_flavor: "SQL",
        url: "https://bla-id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_orders",
        },
        col_name: "status",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "status",
      },
      source_reference: {
        snippet: "snippet for src col: status",
        snippet_flavor: "SQL",
        url: "https://bla-status",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_orders",
        },
        col_name: "order_date",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "order_date",
      },
      source_reference: {
        snippet: "snippet for src col: order_date",
        snippet_flavor: "SQL",
        url: "https://bla-order_date",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_orders",
        },
        col_name: "user_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "customer_id",
      },
      source_reference: {
        snippet: "snippet for src col: user_id",
        snippet_flavor: "SQL",
        url: "https://bla-user_id",
      },
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_orders",
        },
        col_name: "id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "order_id",
      },
      source_reference: {
        snippet: "snippet for src col: id",
        snippet_flavor: "SQL",
        url: "https://bla-id",
      },
    },
  ],
  dependencies: [
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "order_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "order_id",
      },
      dependencyType: "where",
      targetType: "table",
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "amount",
      },
      dependencyType: "order",
      targetType: "table",
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        col_name: "amount",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "gift_card_amount",
      },
      dependencyType: "case",
      targetType: "table",
    },
    {
      src_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        col_name: "customer_id",
      },
      dst_column: {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        col_name: "customer_id",
      },
      dependencyType: "join",
      targetType: "table",
    },
  ],
};

export const EXAMPLE_PRS: readonly BackendLineageState[] = [
  {
    stateType: "PR",
    version: 1,
    prId: 1,
    prText: "Initial implementation of sales tax (approximation).",
    tables: [
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "orders_by_date",
        },
        columns: [
          {
            col_name:
              "Tracking users who made purchases in the recent 30 days, over time",
            type: "",
          },
          { col_name: " ", type: "" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        columns: [
          {
            col_name: "order_id",
            type: "varchar",
          },
          { col_name: "customer_id", type: "varchar" },
          { col_name: "total_amount", type: "varchar" },
          { col_name: "bank_transfer_amount", type: "varchar" },
          { col_name: "deprecated_order_id", type: "varchar" },
          { col_name: "credit_card_amount", type: "varchar" },
          { col_name: "coupon_amount", type: "varchar" },
          { col_name: "gift_card_amount", type: "varchar" },
          { col_name: "order_date", type: "varchar" },
          { col_name: "status", type: "varchar" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        columns: [
          {
            col_name: "payment_id",
            type: "varchar",
          },
          {
            col_name: "order_id",
            type: "varchar",
            changeStatus: "removed",
          },
          { col_name: "amount", type: "varchar" },
          {
            col_name: "sales_tax",
            type: "varchar",
            changeStatus: "new",
          },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_payments",
        },
        columns: [
          { col_name: "id", type: "varchar" },
          { col_name: "order_id", type: "varchar" },
          { col_name: "amount", type: "varchar" },
        ],
      },
    ],
    relations: [
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "order_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "dashboards",
            db_name: "tableau",
            table_name: "orders_by_date",
          },
          col_name: " ",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "order_date",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "dashboards",
            db_name: "tableau",
            table_name: "orders_by_date",
          },
          col_name: " ",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "total_amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "dashboards",
            db_name: "tableau",
            table_name: "orders_by_date",
          },
          col_name: " ",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "order_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "order_id",
        },
        source_reference: {
          snippet: "snippet for src col: order_id",
          snippet_flavor: "SQL",
          url: "https://bla-order_id",
        },
        changeStatus: "removed",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "total_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "credit_card_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "bank_transfer_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "coupon_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "gift_card_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "status",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "status",
        },
        source_reference: {
          snippet: "snippet for src col: status",
          snippet_flavor: "SQL",
          url: "https://bla-status",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "order_date",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "order_date",
        },
        source_reference: {
          snippet: "snippet for src col: order_date",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "last_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "last_name",
        },
        source_reference: {
          snippet: "snippet for src col: last_name",
          snippet_flavor: "SQL",
          url: "https://bla-last_name",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "first_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "first_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-first_name",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: id",
          snippet_flavor: "SQL",
          url: "https://bla-id",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_payments",
          },
          col_name: "id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "payment_id",
        },
        source_reference: {
          snippet: "snippet for src col: order_id",
          snippet_flavor: "SQL",
          url: "https://bla-order_id",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_payments",
          },
          col_name: "order_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "order_id",
        },
        source_reference: {
          snippet: "snippet for src col: order_id",
          snippet_flavor: "SQL",
          url: "https://bla-order_id",
        },
        changeStatus: "removed",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "sales_tax",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-amount",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "sales_tax",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "gift_card_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-amount",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "sales_tax",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "bank_transfer_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-amount",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "sales_tax",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "credit_card_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-amount",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "sales_tax",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "coupon_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-amount",
        },
        changeStatus: "new",
      },
    ],
  },
  {
    stateType: "PR",
    version: 1,
    prId: 3,
    prText: "Merge first and last name to full-name",
    tables: [
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_customers",
        },
        columns: [
          { col_name: "first_name", type: "varchar" },
          { col_name: "id", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        columns: [
          { col_name: "customer_id", type: "varchar" },
          {
            col_name: "first_name",
            type: "varchar",
            changeStatus: "removed",
          },
          {
            col_name: "last_name",
            type: "varchar",
            changeStatus: "removed",
          },
          {
            col_name: "full_name",
            type: "varchar",
            changeStatus: "new",
          },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "customers",
        },
        columns: [
          { col_name: "customer_id", type: "varchar" },
          { col_name: "first_name", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
          { col_name: "first_order", type: "varchar" },
          { col_name: "most_recent_order", type: "varchar" },
          { col_name: "number_of_orders", type: "varchar" },
        ],
      },
    ],
    relations: [
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "first_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "first_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "removed",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "last_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "last_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "removed",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "first_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "full_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "last_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "full_name",
        },
        source_reference: {
          snippet: "snippet for src col: last_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "customer_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "customers",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: customer_id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "first_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "customers",
          },
          col_name: "first_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "last_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "customers",
          },
          col_name: "last_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
    ],
  },
  {
    stateType: "PR",
    version: 1,
    prId: 6,
    prText: "Add customer address and use it as shipping address for orders",
    tables: [
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_customers",
        },
        columns: [
          { col_name: "id", type: "varchar" },
          { col_name: "first_name", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
          { col_name: "address", type: "varchar" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        columns: [
          { col_name: "customer_id", type: "varchar" },
          { col_name: "first_name", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
          {
            col_name: "address",
            type: "varchar",
            changeStatus: "new",
          },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        columns: [
          { col_name: "order_id", type: "varchar" },
          { col_name: "customer_id", type: "varchar" },
          { col_name: "order_date", type: "varchar" },
          { col_name: "status", type: "varchar" },
          {
            col_name: "shipping_address",
            type: "varchar",
            changeStatus: "new",
          },
          { col_name: "total_amount", type: "varchar" },
          { col_name: "bank_transfer_amount", type: "varchar" },
          { col_name: "deprecated_order_id", type: "varchar" },
          { col_name: "coupon_amount", type: "varchar" },
          { col_name: "credit_card_amount", type: "varchar" },
          { col_name: "gift_card_amount", type: "varchar" },
        ],
      },
    ],
    relations: [
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "shipping_address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "first_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "first_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "last_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "last_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
    ],
  },
  {
    stateType: "PR",
    version: 1,
    prId: 5,
    prText: "Rename customer address to default_shipping_address",
    tables: [
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_customers",
        },
        columns: [
          { col_name: "id", type: "varchar" },
          { col_name: "first_name", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
          { col_name: "address", type: "varchar" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        columns: [
          { col_name: "customer_id", type: "varchar" },
          { col_name: "first_name", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
          {
            col_name: "default_shipping_address",
            type: "varchar",
            changeStatus: "new",
          },
          {
            col_name: "address",
            type: "varchar",
            changeStatus: "removed",
          },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        columns: [
          { col_name: "amount", type: "varchar" },
          { col_name: "bank_transfer_amount", type: "varchar" },
          { col_name: "coupon_amount", type: "varchar" },
          { col_name: "credit_card_amount", type: "varchar" },
          { col_name: "customer_id", type: "varchar" },
          { col_name: "gift_card_amount", type: "varchar" },
          { col_name: "order_date", type: "varchar" },
          { col_name: "order_id", type: "varchar" },
          { col_name: "deprecated_order_id", type: "varchar" },
          { col_name: "status", type: "varchar" },
          {
            col_name: "shipping_address",
            type: "varchar",
            changeStatus: "changed",
          },
        ],
      },
    ],
    relations: [
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "customer_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: customer_id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "shipping_address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "removed",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "default_shipping_address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "shipping_address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "first_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "first_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "last_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "last_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "removed",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "default_shipping_address",
        },
        source_reference: {
          snippet: "snippet for src col: shipping_address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
    ],
  },
  {
    stateType: "PR",
    version: 1,
    prId: 7,
    prText:
      "Use customer address to define billing and shipping address in stg tables.",
    tables: [
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "dashboards",
          db_name: "tableau",
          table_name: "orders_by_country",
        },
        columns: [
          {
            col_name:
              "Tracking new orders by country based on order shipping address, over time",
            type: "",
          },
          { col_name: " ", type: "" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "raw_customers",
        },
        columns: [
          { col_name: "id", type: "varchar" },
          { col_name: "first_name", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
          { col_name: "address", type: "varchar" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_customers",
        },
        columns: [
          { col_name: "customer_id", type: "varchar" },
          { col_name: "first_name", type: "varchar" },
          { col_name: "last_name", type: "varchar" },
          { col_name: "address", type: "varchar" },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_orders",
        },
        columns: [
          { col_name: "order_id", type: "varchar" },
          { col_name: "customer_id", type: "varchar" },
          { col_name: "order_date", type: "varchar" },
          { col_name: "status", type: "varchar" },
          {
            col_name: "shipping_address",
            type: "varchar",
            changeStatus: "new",
          },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "stg_payments",
        },
        columns: [
          { col_name: "amount", type: "varchar" },
          { col_name: "order_id", type: "varchar" },
          { col_name: "payment_id", type: "varchar" },
          { col_name: "payment_method", type: "varchar" },
          {
            col_name: "billing_address",
            type: "varchar",
            changeStatus: "new",
          },
        ],
      },
      {
        table_identifier: {
          identifier_type: "db",
          db_schema: "demo_schema",
          db_name: "dbt_tests",
          table_name: "orders",
        },
        columns: [
          { col_name: "order_id", type: "varchar" },
          { col_name: "customer_id", type: "varchar" },
          { col_name: "order_date", type: "varchar" },
          { col_name: "status", type: "varchar" },
          {
            col_name: "shipping_address",
            type: "varchar",
            changeStatus: "changed",
          },
          { col_name: "total_amount", type: "varchar" },
          { col_name: "bank_transfer_amount", type: "varchar" },
          { col_name: "coupon_amount", type: "varchar" },
          { col_name: "credit_card_amount", type: "varchar" },
          { col_name: "gift_card_amount", type: "varchar" },
          { col_name: "deprecated_order_id", type: "varchar" },
        ],
      },
    ],
    relations: [
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "order_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "dashboards",
            db_name: "tableau",
            table_name: "orders_by_country",
          },
          col_name: " ",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "shipping_address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "dashboards",
            db_name: "tableau",
            table_name: "orders_by_country",
          },
          col_name: " ",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "shipping_address",
        },
        source_reference: {
          snippet: "snippet for src col: shipping_payment",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "billing_address",
        },
        source_reference: {
          snippet: "snippet for src col: billing_payment",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "customer_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: customer_id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "order_date",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "order_date",
        },
        source_reference: {
          snippet: "snippet for src col: order_date",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "status",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "status",
        },
        source_reference: {
          snippet: "snippet for src col: status",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "order_id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "order_id",
        },
        source_reference: {
          snippet: "snippet for src col: order_id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "total_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "credit_card_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "bank_transfer_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "coupon_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_payments",
          },
          col_name: "amount",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "gift_card_amount",
        },
        source_reference: {
          snippet: "snippet for src col: amount",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_orders",
          },
          col_name: "shipping_address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "shipping_address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "new",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "orders",
          },
          col_name: "shipping_address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
        changeStatus: "removed",
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "id",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "customer_id",
        },
        source_reference: {
          snippet: "snippet for src col: id",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "first_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "first_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "last_name",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "last_name",
        },
        source_reference: {
          snippet: "snippet for src col: first_name",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
      {
        src_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "raw_customers",
          },
          col_name: "address",
        },
        dst_column: {
          table_identifier: {
            identifier_type: "db",
            db_schema: "demo_schema",
            db_name: "dbt_tests",
            table_name: "stg_customers",
          },
          col_name: "address",
        },
        source_reference: {
          snippet: "snippet for src col: address",
          snippet_flavor: "SQL",
          url: "https://bla-order_date",
        },
      },
    ],
  },
];

export const EXAMPLE_SETTINGS: Readonly<Settings> = {
  github: {
    installations: [
      {
        github_install_id: 31_254_534,
        account_login: "foundational-io",
        registrar_login: "merlindso",
        registrar_name: "Omri Ildis",
        enabled_repos: [
          "foundational-io/ex1-jaffle-shop",
          "foundational-io/ex3-spellbook",
          "foundational-io/repo_and_branch_tests",
        ],
      },
    ],
    username: "flow3d-test",
  },
  bigquery: [
    {
      source_identifier:
        "bigquery-foundational@focused-sound-364312.iam.gserviceaccount.com",
    },
  ],
  tableau: [{ source_identifier: "https://bi.foundational.io", username: "test" }],
  snowflake: [
    {
      source_identifier:
        "https://foundational.snowflakecomputing.com,default-warehouse",
      username: "test",
    },
  ],
  looker: [],
  powerbi: [],
};

export const EXAMPLE_SLACK_CHANNELS: Readonly<string[]> = [
  "random",
  "cfo-team",
  "deprecations-alerts",
  "data-team",
];
