export function S3Icon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      height="310px"
      preserveAspectRatio="xMidYMid"
      viewBox="-27 0 310 310"
      width="310px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.624 53.686L0 64v181.02l20.624 10.254.124-.149V53.828l-.124-.142"
        fill="#8C3123"
      />
      <path d="M131 229L20.624 255.274V53.686L131 79.387V229" fill="#E05243" />
      <path
        d="M81.178 187.866l46.818 5.96.294-.678.263-76.77-.557-.6-46.818 5.874v66.214"
        fill="#8C3123"
      />
      <path
        d="M127.996 229.295l107.371 26.035.169-.269-.003-201.195-.17-.18-107.367 25.996v149.613"
        fill="#8C3123"
      />
      <path
        d="M174.827 187.866l-46.831 5.96v-78.048l46.831 5.874v66.214"
        fill="#E05243"
      />
      <path
        d="M174.827 89.631l-46.831 8.535-46.818-8.535 46.759-12.256 46.89 12.256"
        fill="#5E1F18"
      />
      <path
        d="M174.827 219.801l-46.831-8.591-46.818 8.591 46.761 13.053 46.888-13.053"
        fill="#F2B0A9"
      />
      <path
        d="M81.178 89.631l46.818-11.586.379-.117V.313L127.996 0 81.178 23.413v66.218"
        fill="#8C3123"
      />
      <path d="M174.827 89.631l-46.831-11.586V0l46.831 23.413v66.218" fill="#E05243" />
      <path
        d="M127.996 309.428l-46.823-23.405v-66.217l46.823 11.582.689.783-.187 75.906-.502 1.351"
        fill="#8C3123"
      />
      <path
        d="M127.996 309.428l46.827-23.405v-66.217l-46.827 11.582v78.04M235.367 53.686L256 64v181.02l-20.633 10.31V53.686"
        fill="#E05243"
      />
    </svg>
  );
}
