import { Popover } from "@headlessui/react";
import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import { groupBy, pipe, prop, toPairs } from "remeda";
import type { LineageIssue, LineageIssueSeverity } from "../../../api";
import type { NonEmptyArray } from "../../../utils/NonEmptyArray";
import { IssuesCounter } from "./IssuesCounter";
import { IssuesDialog } from "./IssuesDialog";
import { issueColorsClassName } from "./issueColorsClassName";

export function IssuesChipsCloud({
  issues,
  fixed: isFixed = false,
}: {
  readonly issues: NonEmptyArray<LineageIssue>;
  readonly fixed?: boolean;
}): JSX.Element {
  const issuesByType = useMemo(
    () => pipe(issues, groupBy.strict(prop("issue_type")), toPairs.strict),
    [issues],
  );

  return (
    <ul className="flex flex-wrap gap-2">
      {issuesByType.map(([issueType, groupedIssues]) => (
        <li key={issueType}>
          <IssuesChip fixed={isFixed} issues={groupedIssues} />
        </li>
      ))}
    </ul>
  );
}

function IssuesChip({
  issues,
  fixed: isFixed,
}: {
  readonly issues: NonEmptyArray<LineageIssue>;
  readonly fixed: boolean;
}): JSX.Element {
  const [{ severity, issue_type }] = issues;

  return (
    <Popover>
      <IssuesChipButton count={issues.length} severity={isFixed ? "fixed" : severity}>
        {issue_type}
      </IssuesChipButton>
      <Popover.Overlay className="fixed inset-0 z-50 bg-black opacity-30" />
      <Popover.Panel
        unmount
        className="fixed left-1/2 top-1/2 z-50 -ml-[17rem] -mt-[12rem] flex h-[24rem] w-[34rem] flex-col rounded-md bg-white shadow-[0_0_20px_rgba(115,130,133,0.3)]"
      >
        <IssuesDialog fixed={isFixed} issues={issues} />
      </Popover.Panel>
    </Popover>
  );
}

function IssuesChipButton({
  severity,
  count,
  children,
}: PropsWithChildren<{
  readonly severity: LineageIssueSeverity | "fixed";
  readonly count: number;
}>): JSX.Element {
  return (
    <Popover.Button
      className={`flex items-center rounded pr-1 text-xs font-semibold ${issueColorsClassName(
        severity,
      )}`}
    >
      <IssuesCounter severity={severity}>{count}</IssuesCounter>
      {children}
    </Popover.Button>
  );
}
