import decodeJwt from "jwt-decode";
import type { SetRequired } from "type-fest";
import { QUERY_CLIENT } from "../../api";
import { clearDDUser } from "../../datadog";
import { HTTP_CODE, isClientErrorStatus } from "../HTTP_CODE";
import { BASE_API_URL } from "../env";

const STORAGE_KEY_TOKEN = "token";
const STORAGE_KEY_LOGIN_METADATA = "token_md";

const MS_IN_SEC = 1000;

type Token = {
  readonly permissions: string;
  readonly exp: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- Defined by the API
  readonly user_id: string;
};

type LoginMetadata = {
  permissions?: string;
  tokenExp?: number;
};

type ServerErrorResponse = {
  detail?: string;
};

type ServerResponse = {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- Defined by the API
  access_token?: string;
};

export function logout(): void {
  localStorage.removeItem(STORAGE_KEY_TOKEN);
  localStorage.removeItem(STORAGE_KEY_LOGIN_METADATA);

  QUERY_CLIENT.clear();
  clearDDUser();
}

export function getTokenFromStorage(): SetRequired<LoginMetadata, "tokenExp"> & {
  readonly authToken: string | null;
} {
  const loginMetadataJson = localStorage.getItem(STORAGE_KEY_LOGIN_METADATA);

  const loginMetadata = (
    loginMetadataJson === null ? {} : JSON.parse(loginMetadataJson)
  ) as LoginMetadata;

  return {
    authToken: localStorage.getItem(STORAGE_KEY_TOKEN),

    // Order is important here, `tokenExp` will get overridden if it's defined in
    // the login metadata, but otherwise would take the default value of 0
    tokenExp: 0,
    ...loginMetadata,
  };
}

export const isTokenStorageKey = (key: string): boolean =>
  key === STORAGE_KEY_TOKEN || key === STORAGE_KEY_LOGIN_METADATA;

export async function handleLoginLikeRequest(body: BodyInit): Promise<void> {
  const request = new Request(`${BASE_API_URL}/auth/jwt/login`, {
    method: "POST",
    body,
  });

  const response = await fetch(request);

  if (response.status === HTTP_CODE.INTERNAL_SERVER_ERROR) {
    throw new Error("Internal server error");
  }

  const data = (await response.json()) as unknown;

  if (isClientErrorStatus(response.status)) {
    const { detail } = data as ServerErrorResponse;
    throw detail ?? data;
  }

  const { access_token } = data as ServerResponse;
  if (access_token === undefined) {
    throw data;
  }

  const { exp, permissions } = decodeJwt<Token>(access_token);
  localStorage.setItem(STORAGE_KEY_TOKEN, access_token);
  localStorage.setItem(
    STORAGE_KEY_LOGIN_METADATA,
    JSON.stringify({
      permissions,
      tokenExp: exp * MS_IN_SEC,
    }),
  );
}
