export function MetricBox({
  label,
  value,
  change,
  color,
}: {
  readonly label: string;
  readonly value: string;
  readonly change: number;
  readonly color?: "green" | "red";
}): JSX.Element {
  return (
    <li className="flex flex-1 items-center justify-center gap-3 rounded-xl border p-4 shadow-sm">
      <span className="flex-1 text-center text-sm text-black/80">{label}</span>
      <div className="flex flex-1 flex-col items-center gap-2">
        <span className="text-xl font-bold tabular-nums">{value}</span>
        <span
          className={`rounded-md  p-2 text-center text-xs font-medium text-black/70 ${
            color === undefined
              ? "bg-gray-200"
              : color === "green"
              ? "bg-[rgba(101,202,132,0.4)]"
              : "bg-[rgba(255,134,108,0.4)]"
          }`}
        >
          {change === 0
            ? "No change"
            : `${change > 0 ? "+" : ""}${(
                change *
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This is just percent :-/
                100
              ).toFixed(1)}%`}{" "}
          since last week
        </span>
      </div>
    </li>
  );
}
