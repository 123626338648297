import { AddNewPolicyButton } from "./AddNewPolicyButton";

export function PoliciesScreenHeader({
  onCreatePolicy,
}: {
  readonly onCreatePolicy: () => void;
}): JSX.Element {
  return (
    <header className="flex items-end justify-between">
      <h1 className="text-lg font-semibold leading-7 text-gray-600">Policies</h1>
      <AddNewPolicyButton onClick={onCreatePolicy} />
    </header>
  );
}
