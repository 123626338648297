import { useCallback } from "react";
import { useRouteLineage } from "../../api";
import { logEventGraphSearchResultClicked } from "../../utils/analytics/eventLogging";
import { useGraphType } from "../../utils/graph/useGraphType";
import type { LineageSearchResult } from "../../utils/search/lineage";
import { useAddRootNodeIfNeededAndFocus } from "../useMoveToRootNode";
import { LineageSearch } from "./LineageSearch";

export function GraphSearch(): JSX.Element {
  const lineage = useRouteLineage();
  const addRootNodeIfNeededAndFocus = useAddRootNodeIfNeededAndFocus();
  const graphType = useGraphType();

  const handleResultClick = useCallback(
    ({ result, rootEntity }: LineageSearchResult) => {
      const isAddingNode = addRootNodeIfNeededAndFocus(rootEntity);
      logEventGraphSearchResultClicked(graphType, result, !isAddingNode);
    },
    [addRootNodeIfNeededAndFocus, graphType],
  );

  return (
    <LineageSearch
      resetOnResultClick
      className="w-80"
      lineage={lineage?.snapshot}
      searchBoxPlaceholder="Search or Add Elements..."
      onResultClick={handleResultClick}
    />
  );
}
