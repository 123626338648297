import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import type { SettingsOverviewProps } from "../../../utils/settings/types";

export type DemoConnectorProps = {
  readonly displayName: string;
  readonly iconLocation: string;
};

export const DEMO_CONNECTORS: Readonly<Record<string, DemoConnectorProps>> = {
  // IMPORTANT: The order the connectors are defined here will define their order
  // in the UX.

  gitlab: {
    displayName: "GitLab",
    iconLocation: "/img/connectors/gitlab-logo-500.svg",
  },

  fivetran: {
    displayName: "Fivetran",
    iconLocation: "/img/connectors/fivetran-icon.svg",
  },

  airbyte: {
    displayName: "Airbyte",
    iconLocation: "/img/connectors/airbyte.svg",
  },

  stitch: {
    displayName: "Stitch",
    iconLocation: "/img/connectors/stitch.svg",
  },

  metabase: {
    displayName: "Metabase",
    iconLocation: "/img/connectors/metabase.svg",
  },

  s3: {
    displayName: "S3",
    iconLocation: "/img/connectors/s3.svg",
  },

  postgresql: {
    displayName: "Postgres",
    iconLocation: "/img/connectors/postgresql.svg",
  },

  databricks: {
    displayName: "Databricks",
    iconLocation: "/img/connectors/databricks.svg",
  },

  redshift: {
    displayName: "Redshift",
    iconLocation: "/img/connectors/aws-redshift-logo.svg",
  },

  azureSql: {
    displayName: "Azure Sql",
    iconLocation: "/img/connectors/azure-sql.svg",
  },

  redash: {
    displayName: "Redash",
    iconLocation: "/img/connectors/redashio-icon.svg",
  },

  sisense: {
    displayName: "Sisense",
    iconLocation: "/img/connectors/sisense.svg",
  },

  census: {
    displayName: "Census",
    iconLocation: "/img/connectors/census.png",
  },

  matillion: {
    displayName: "Matillion",
    iconLocation: "/img/connectors/Matillion.svg",
  },
};

export const cardOverviewComponentFactory = (
  connector: DemoConnectorProps,
): FC<Pick<SettingsOverviewProps, "onConfigure">> =>
  // eslint-disable-next-line react/function-component-definition
  function OverviewComponent(
    props: Pick<SettingsOverviewProps, "onConfigure">,
  ): JSX.Element {
    return <CardConfigOverview {...connector} {...props} />;
  };

function CardConfigOverview({
  onConfigure,
  iconLocation,
  displayName,
}: DemoConnectorProps & SettingsOverviewProps): JSX.Element {
  return (
    <Card>
      <CardActionArea onClick={onConfigure}>
        <CardHeader
          avatar={<Avatar alt={displayName} src={iconLocation} variant="square" />}
          title={<Typography variant="h5">{displayName}</Typography>}
        />
        <CardContent>
          <Typography gutterBottom component="div" variant="h6">
            Not Configured!
          </Typography>
          <Typography color="text.secondary" variant="body2">
            Click to configure
            <br />
            &nbsp;
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button color="primary" size="small" onClick={onConfigure}>
          Configure
        </Button>
      </CardActions>
    </Card>
  );
}
