import { useCallback, useMemo } from "react";
import { useReactFlow } from "reactflow";
import type { EdgeState, LineageRootEntity } from "../../../api";
import { layoutGraph } from "../../../utils/graph/layout";
import { toLineageRootNode } from "../../graph/LineageRootNode";
import { toEdge } from "../../graph/edge";

const GO_TO_NODE_ANIMATION_DURATION_MS = 1000;

// We add padding to prevent graphs from being rendered under the top toolbar area.
// Unfortunately, react-flow don't offer an option to provide just a top-padding so we
// lose some space on the other sides.
// I'm also not sure what unit this number is in, it's not pixels! it might be screen
// ratio units (vh?, vw?). I picked this number via trial and error... ¯\_(ツ)_/¯
const PADDING = 0.33;

export function useGraphInitializer(
  entities: readonly LineageRootEntity[] | undefined = [],
  edges: readonly EdgeState[] | undefined = [],
): () => void {
  const { setNodes, setEdges, fitView } = useReactFlow();

  const initialNodes = useMemo(
    () => entities.map((entity) => toLineageRootNode(entity, 0)),
    [entities],
  );

  const initialEdges = useMemo(() => edges.map((rel) => toEdge(rel)), [edges]);

  const positionedNodes = useMemo(
    () => layoutGraph(initialNodes, initialEdges),
    [initialEdges, initialNodes],
  );

  return useCallback(() => {
    setNodes(positionedNodes);
    setEdges(initialEdges);

    if (positionedNodes.length > 0) {
      setTimeout(() => {
        fitView({
          nodes: positionedNodes,
          duration: GO_TO_NODE_ANIMATION_DURATION_MS,
          padding: PADDING,
        });
      });
    }
  }, [fitView, initialEdges, positionedNodes, setEdges, setNodes]);
}
