import type { BaseSyntheticEvent } from "react";

/**
 * Prevent parent nodes from being "notified" about the event, so that they don't get to
 * act too.
 *
 * We use this, for example, to prevent ReactFlow's default node onClick behaviour,
 * which makes the node "selected", from firing when buttons on the node are clicked.
 *
 * IMPORTANT: Dont use this if you are already implementing a full-blown event handler
 * anyway. This exists only to provide a global function that doesn't need to be
 * memoized via useCallback for simple cases where it's the only logic needed.
 */
export const stopPropagation = (e: BaseSyntheticEvent): void => {
  e.stopPropagation();
};
