import {
  useFloating,
  type Strategy,
  type UseFloatingOptions,
} from "@floating-ui/react-dom";
import type { PropsWithChildren, RefCallback } from "react";
import { useEffect, useMemo, useState } from "react";

type UseTooltipReturn<E extends Element = Element> = {
  readonly setReference: RefCallback<E>;
  readonly tooltip: JSX.Element | null;
};

// We don't want to show the tooltip immediatly because it's distracting and the
// user might not need the info.
const TOOLTIP_DELAY_MS = 500;

export function useTooltip<E extends Element = Element>(
  tooltip: string,
  options?: UseFloatingOptions,
): UseTooltipReturn<E> {
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
    elements: { reference },
  } = useFloating<E>(options);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (reference === null) {
      return;
    }

    // Track the state of the mouse until the timer sets off. This would prevent
    // us from showing the tooltip after the user already moved their mouse just
    // because they were quicker than the timeout.
    let stillRelevant = false;

    const mouseEnterHandler = () => {
      stillRelevant = true;
      setTimeout(() => {
        if (stillRelevant) {
          setIsVisible(true);
        }
      }, TOOLTIP_DELAY_MS);
    };
    const mouseLeaveHandler = () => {
      stillRelevant = false;
      setIsVisible(false);
    };

    reference.addEventListener("mouseenter", mouseEnterHandler);
    reference.addEventListener("mouseleave", mouseLeaveHandler);

    return () => {
      reference.removeEventListener("mouseenter", mouseEnterHandler);
      reference.removeEventListener("mouseleave", mouseLeaveHandler);
    };
  }, [reference]);

  return useMemo(
    () => ({
      setReference,
      tooltip: (
        <Tooltip
          setFloating={setFloating}
          strategy={strategy}
          visible={isVisible}
          x={x}
          y={y}
        >
          {tooltip}
        </Tooltip>
      ),
    }),
    [isVisible, setFloating, setReference, strategy, tooltip, x, y],
  );
}

function Tooltip({
  visible,
  setFloating,
  x,
  y,
  strategy,
  children,
}: PropsWithChildren<{
  readonly visible: boolean;
  readonly setFloating: (node: HTMLElement | null) => void;
  readonly x: number;
  readonly y: number;
  readonly strategy: Strategy;
}>): JSX.Element | null {
  if (!visible) {
    return null;
  }

  return (
    <div
      ref={setFloating}
      className="z-50 w-max rounded bg-slate-600 px-2 py-1 text-xs font-medium text-white shadow"
      style={{
        position: strategy,
        top: y,
        left: x,
      }}
    >
      {children}
    </div>
  );
}
