import type { PropsWithChildren } from "react";
import { POLICIES_TABLE_COLUMNS_FLEX_RATIOS } from "../utils";

export function PoliciesTableHeaderRow(): JSX.Element {
  return (
    <div className="flex flex-row justify-between gap-x-2 border-b border-[rgba(74,89,92,0.1)] py-4">
      <HeaderColumnTitle className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.policyName}>
        Policy name
      </HeaderColumnTitle>
      <HeaderColumnTitle className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.createdBy}>
        Created by
      </HeaderColumnTitle>
      <HeaderColumnTitle className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.modifiedBy}>
        Last modified by
      </HeaderColumnTitle>
      <HeaderColumnTitle className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.modifiedAt}>
        Last modified on
      </HeaderColumnTitle>
      {/* A column with no title as a placeholder for the edit and delete policy buttons */}
      <HeaderColumnTitle className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.actionButtons} />
      <HeaderColumnTitle
        className={`text-end ${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.status}`}
      >
        Status
      </HeaderColumnTitle>
    </div>
  );
}

function HeaderColumnTitle({
  children,
  className,
}: PropsWithChildren<{ readonly className?: string | undefined }>): JSX.Element {
  return (
    <span
      className={`text-start text-sm font-light text-[rgb(165,175,176)] ${
        className ?? ""
      }`}
    >
      {children}
    </span>
  );
}
