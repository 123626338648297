import { create } from "zustand";

type CollapsedModeStoreState = {
  readonly isEnabled: boolean;
  readonly actions: CollapsedModeStoreActions;
};

type CollapsedModeStoreActions = {
  readonly enable: (isEnabled?: boolean) => void;
};

const useCollapsedModeStore = create<CollapsedModeStoreState>((set) => ({
  isEnabled: true,

  actions: {
    enable: (isEnabled = true) => {
      set({ isEnabled });
    },
  },
}));

export const useIsCollapsedModeEnabled = (): boolean =>
  useCollapsedModeStore(({ isEnabled }) => isEnabled);

export const useCollapsedModeActions = (): CollapsedModeStoreActions =>
  useCollapsedModeStore(({ actions }) => actions);
