import { useCallback, useMemo } from "react";
import { filter, map, pipe, sort } from "remeda";
import { type ChangeStatus, useRouteLineage } from "../../../api";
import { logEventModifiedTabItemClicked } from "../../../utils/analytics/eventLogging";
import { fullSearchResultPathTitle } from "../../../utils/search/lineage";
import {
  type FilterType,
  searchLineageByType,
} from "../../../utils/search/lineageType";
import { FilterSearchResultsItem } from "./FilterSearchResultsItem";

export function FilteredEntitiesList({
  filterType,
  changeStatus,
}: {
  readonly filterType: FilterType;
  readonly changeStatus: ChangeStatus;
}): JSX.Element | null {
  const lineage = useRouteLineage();

  const filteredEntities = useMemo(
    () =>
      // We do a deep check here instead of just checking `lineage` so that we don't
      // need to depend on the whole object. This allows us to depend only on the parts
      // of the lineage object that we care about for this computation.
      lineage?.diff.edges === undefined
        ? []
        : pipe(
            searchLineageByType(filterType, lineage.diff.entities, lineage.diff.edges),
            filter(
              ({ result: { changeStatus: entityChangeStatus } }) =>
                entityChangeStatus === changeStatus,
            ),
            sort((entity1, entity2) =>
              fullSearchResultPathTitle(entity1).localeCompare(
                fullSearchResultPathTitle(entity2),
              ),
            ),
          ),
    [changeStatus, filterType, lineage?.diff.edges, lineage?.diff.entities],
  );

  const handleFilterItemClick = useCallback(() => {
    logEventModifiedTabItemClicked(filterType, changeStatus, filteredEntities.length);
  }, [changeStatus, filterType, filteredEntities.length]);

  if (lineage === undefined) {
    // TODO: return some loading element
    return null;
  }

  return (
    <ul className="flex-1 overflow-y-auto">
      {map(filteredEntities, (entity) => (
        <li key={entity.result.id} className="border-b last:border-0 hover:bg-slate-50">
          <FilterSearchResultsItem entity={entity} onClick={handleFilterItemClick} />
        </li>
      ))}
    </ul>
  );
}
