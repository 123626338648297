import type React from "react";
import { useCallback } from "react";
import { Position } from "reactflow";
import { sumBy } from "remeda";
import type { ChangeStatus, ColumnEntity, LineageChildEntity } from "../../api";
import { logEventGraphExpandButtonClicked } from "../../utils/analytics/eventLogging";
import { useGraphType } from "../../utils/graph/useGraphType";
import { useDiscoveryModeContext } from "../discovery/DiscoveryModeLineageRootWrapper";
import { EdgesConnectorWrapper } from "../discovery/EdgesConnectorWrapper";
import { columnContentHeight, ColumnMainContent } from "./ColumnMainContent";
import { InvisibleHandle } from "./InvisibleHandle";

const BORDER_HEIGHT = 1; // Class: border-b
const LEFT_COLUMN_PADDING = 40; // Class: pl-10
const LEFT_HANDLE_OFFSET = 12; // Class: -left-3

const columnHeight = (
  { id, childEntities }: LineageChildEntity,
  expandedEntities: Readonly<Record<string, boolean>>,
): number => {
  const isExpanded = expandedEntities[id] ?? false;
  return (
    columnContentHeight(childEntities.length > 0 /* hasChildren */) +
    BORDER_HEIGHT +
    sumChildEntitiesHeights(isExpanded, childEntities, expandedEntities)
  );
};

export const sumChildEntitiesHeights = (
  isExpanded: boolean,
  childEntities: readonly ColumnEntity[],
  expandedEntities: Readonly<Record<string, boolean>>,
): number =>
  isExpanded
    ? sumBy(childEntities, (entity) => columnHeight(entity, expandedEntities))
    : 0;

export function Column({
  column,
  expandedEntities,
  onColumnExpandChange,
  isSubColumn = false,
  leftHandleOffset = LEFT_HANDLE_OFFSET,
}: {
  readonly column: LineageChildEntity;
  readonly expandedEntities: Readonly<Record<string, boolean>>;
  readonly onColumnExpandChange: (newValue: boolean, entityId: string) => void;
  readonly isSubColumn?: boolean;
  readonly leftHandleOffset?: number;
}): JSX.Element {
  const isExpanded = expandedEntities[column.id] ?? false;

  const discoveryContext = useDiscoveryModeContext(column);
  const graphType = useGraphType();

  const handleEdgeConnectorClick = useCallback(
    (context: Parameters<typeof logEventGraphExpandButtonClicked>[2]) => {
      logEventGraphExpandButtonClicked(graphType, "column", context);
    },
    [graphType],
  );

  const handleColumnExpandChange = useCallback(
    (newIsExpanded: boolean) => {
      onColumnExpandChange(newIsExpanded, column.id);
    },
    [column.id, onColumnExpandChange],
  );

  let content = (
    <ColumnMainContent
      column={column}
      isExpanded={isExpanded}
      onColumnExpandChange={handleColumnExpandChange}
    />
  );

  if (discoveryContext !== undefined) {
    content = (
      <EdgesConnectorWrapper
        context={discoveryContext}
        leftStyle={{ left: `-${leftHandleOffset}px` }}
        onToggleClick={handleEdgeConnectorClick}
      >
        {content}
      </EdgesConnectorWrapper>
    );
  }

  return (
    <li
      className={`flex w-full flex-col items-center border-t border-neutral-200 ${backgroundAndTextForColumn(
        column.changeStatus,
        isExpanded,
      )} ${
        isSubColumn
          ? "relative before:absolute before:-bottom-1 before:-left-[18px] before:-top-6 before:w-px before:border-t before:bg-[rgb(205,215,215)] after:absolute after:-left-[20.5px] after:top-4 after:h-[6px] after:w-[6px] after:rounded-full after:bg-[rgb(217,217,217)] last:before:h-10"
          : "first:border-0"
      }`}
      data-col-name={column.entityName}
    >
      <div className={`relative w-full pl-4 ${hoverForColumn(column.changeStatus)}`}>
        <ColumnInvisibleHandles
          column={column}
          isExpanded={isExpanded}
          leftHandleStyle={{ left: `-${leftHandleOffset}px` }}
        />
        {content}
      </div>
      {!isExpanded || column.childEntities.length === 0 ? null : (
        <ul className="flex w-full flex-col pl-10">
          {column.childEntities.map((child) => (
            <Column
              key={child.id}
              isSubColumn
              column={child}
              expandedEntities={expandedEntities}
              leftHandleOffset={leftHandleOffset + LEFT_COLUMN_PADDING}
              onColumnExpandChange={onColumnExpandChange}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

// This is a disgusting hack used to create a "collapse" behavior for sub-column edges. When the column is collapsed,
// The sub-cols handles are still rendered so that their edges will still be rendered as going out from the column.
// This is a behavior we get for free on tables because handles and tables have parent-child relationships in react-flow,
// but we dont get this for handles because we cant define a child-parent relationship between them.
// Another possible solution is to add dummy edges when the column is collapsed.
function ColumnInvisibleHandles({
  column: { childEntities, entityName },
  leftHandleStyle,
  isExpanded,
}: {
  readonly column: LineageChildEntity;
  readonly leftHandleStyle: React.CSSProperties;
  readonly isExpanded: boolean;
}): JSX.Element {
  return (
    <>
      {childEntities.length === 0 || isExpanded ? null : (
        <>
          {childEntities.map((child) => (
            <ColumnInvisibleHandles
              key={child.entityName}
              column={child}
              isExpanded={false}
              leftHandleStyle={leftHandleStyle}
            />
          ))}
        </>
      )}
      <InvisibleHandle
        skipOffset
        id={entityName}
        position={Position.Left}
        style={leftHandleStyle}
        type="target"
      />
      <InvisibleHandle id={entityName} position={Position.Right} type="source" />
    </>
  );
}

function backgroundAndTextForColumn(
  changeStatus: ChangeStatus | undefined,
  isExpanded: boolean,
): string {
  if (changeStatus === undefined) {
    // We don't need styling for unchanged columns.
    return "";
  }

  switch (changeStatus) {
    case "new":
      return "bg-green-50";

    case "removed":
      return "bg-red-50";

    case "changed":
    case "renamed":
      return isExpanded ? "" : "bg-orange-50";
  }
}

function hoverForColumn(changeStatus: ChangeStatus | undefined): string {
  if (changeStatus === undefined) {
    return "hover:bg-gray-50";
  }

  switch (changeStatus) {
    case "new":
      return "hover:bg-green-100";

    case "removed":
      return "hover:bg-red-100";

    case "changed":
    case "renamed":
      return "hover:bg-orange-100";
  }
}
