import { useCallback } from "react";
import { Position } from "reactflow";
import type { ChangeStatus, LineageRootEntity } from "../../api";
import { logEventGraphExpandButtonClicked } from "../../utils/analytics/eventLogging";
import { useGraphType } from "../../utils/graph/useGraphType";
import { useDiscoveryModeContext } from "../discovery/DiscoveryModeLineageRootWrapper";
import { EdgesConnectorWrapper } from "../discovery/EdgesConnectorWrapper";
import { InvisibleHandle } from "./InvisibleHandle";
import { LineageRootDescriptor } from "./LineageRootDescriptor";
import { META_LINE_HEIGHT } from "./NodeDescriptor";

const HEADER_LINE_HEIGHT = 24; // Class: text-base
const MAX_CHARS_IN_LINE = 21; // Emperically measured by taking the glyph W which is usually the widest and taking a factor of 15%
const GAP = 8; // Class: gap-y-2
const PADDING = 16; // Class: p-4
const TOP_BORDER_HEIGTH = 4; // Class: border-t-4

export const headerHeight = (tableName: string): number =>
  Math.ceil(tableName.length / MAX_CHARS_IN_LINE) * HEADER_LINE_HEIGHT +
  META_LINE_HEIGHT +
  GAP +
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  PADDING * 2 +
  TOP_BORDER_HEIGTH;

export function LineageRootNodeHeader({
  entity,
}: {
  readonly entity: LineageRootEntity;
}): JSX.Element {
  const discoveryTableContext = useDiscoveryModeContext();
  const graphType = useGraphType();

  const handleEdgeConnectorClick = useCallback(
    (context: Parameters<typeof logEventGraphExpandButtonClicked>[2]) => {
      logEventGraphExpandButtonClicked(graphType, "table", context);
    },
    [graphType],
  );

  let content = <LineageRootDescriptor entity={entity} />;

  if (discoveryTableContext !== undefined) {
    content = (
      <EdgesConnectorWrapper
        context={discoveryTableContext}
        onToggleClick={handleEdgeConnectorClick}
      >
        {content}
      </EdgesConnectorWrapper>
    );
  }

  return (
    <header
      className={`relative z-50 flex border-t-4 p-4 shadow-[0_1px_0_rgba(0,0,0,0.04),0_1px_5px_rgba(0,0,0,0.14)] ${borderColorClassName(
        entity.changeStatus,
      )}`}
    >
      <InvisibleHandle id={entity.entityName} position={Position.Left} type="target" />
      {content}
      <InvisibleHandle id={entity.entityName} position={Position.Right} type="source" />
    </header>
  );
}

function borderColorClassName(changeStatus: ChangeStatus | undefined): string {
  switch (changeStatus) {
    case "new":
      return "border-[rgb(101,202,132)]";

    case "removed":
      return "border-[rgb(255,134,108)]";

    case "changed":
    case "renamed":
      return "border-orange-500";

    case undefined:
      return "border-[rgb(134,163,172)]";
  }
}
