import { offset } from "@floating-ui/react-dom";
import { Switch } from "@headlessui/react";
import { useCallback, useState } from "react";
import { ChevronLeftIcon } from "../icons/ChevronLeftIcon";
import { usePrNumberParam } from "../usePrNumberParam";
import { useTooltip } from "../useTooltip";
import { PrMetadata } from "./PrMetadata";
import { SidebarFiltersPanel } from "./filters/SidebarFiltersPanel";
import { Issues } from "./issues/Issues";
import { RepositorySelector } from "./selectors/RepositorySelector";
import { VersionSelector } from "./selectors/versions/VersionSelector";

export function PullRequestExplorerPanel(): JSX.Element | null {
  const prNumber = usePrNumberParam();

  const [isOpen, setIsOpen] = useState(true);

  const { tooltip, setReference } = useTooltip(
    `${isOpen ? "Hide" : "Show"} browsing panel`,
    {
      placement: "right",
      middleware: [
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        offset(4),
      ],
    },
  );

  return (
    <div
      className={`relative isolate z-40 flex h-full w-80 flex-none flex-col bg-white shadow-[0_0_1px_rgba(44,57,63,0.3),0_0_30px_rgba(44,57,63,0.15)] transition-all duration-75 ${
        isOpen ? "" : "-ml-[19.25rem]"
      }`}
    >
      <Switch
        ref={setReference}
        checked={isOpen}
        className="absolute -right-12 top-20 z-50 flex h-9 w-9 items-center justify-center rounded-full bg-white text-[rgb(165,173,176)] shadow-[0px_2px_6px_rgba(44,57,63,0.08),0px_0px_1px_rgba(44,57,63,0.4)] hover:bg-[rgb(247,250,249)]"
        onChange={setIsOpen}
      >
        <ChevronLeftIcon className="w-[7px] transition-transform duration-150 ui-not-checked:rotate-180" />
        {tooltip}
      </Switch>
      {isOpen ? (
        <>
          <section className="flex flex-none flex-col shadow-[0px_2px_8px_rgba(44,57,63,0.08)]">
            <RepositorySelector />
            <VersionSelector />
          </section>
          <PrMetadata prNumber={prNumber} />
          <BottomSections />
        </>
      ) : null}
    </div>
  );
}

/**
 * The bottom sections are split from the other sections because the rendering is
 * dependent on all of them being loaded. This is to prevent sections from jumping
 * around when a section above it loads with an empty state.
 */
function BottomSections(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  const handleIssuesLoaded = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <section
      className={`flex flex-1 flex-col overflow-hidden ${
        isLoading ? "invisible" : undefined
      }`}
    >
      <Issues onLoaded={handleIssuesLoaded} />
      <SidebarFiltersPanel />
    </section>
  );
}
