export function RepositoryIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 2.5C3.222 2.5 2.986 2.59733 2.792 2.792C2.59733 2.986 2.5 3.222 2.5 3.5C2.5 3.778 2.59733 4.014 2.792 4.208C2.986 4.40267 3.222 4.5 3.5 4.5C3.778 4.5 4.014 4.40267 4.208 4.208C4.40267 4.014 4.5 3.778 4.5 3.5C4.5 3.222 4.40267 2.986 4.208 2.792C4.014 2.59733 3.778 2.5 3.5 2.5ZM3.5 10.5C3.222 10.5 2.986 10.5973 2.792 10.792C2.59733 10.986 2.5 11.222 2.5 11.5C2.5 11.778 2.59733 12.014 2.792 12.208C2.986 12.4027 3.222 12.5 3.5 12.5C3.778 12.5 4.014 12.4027 4.208 12.208C4.40267 12.014 4.5 11.778 4.5 11.5C4.5 11.222 4.40267 10.986 4.208 10.792C4.014 10.5973 3.778 10.5 3.5 10.5ZM1 0H13C13.278 0 13.514 0.0973334 13.708 0.292C13.9027 0.486 14 0.722 14 1V6C14 6.278 13.9027 6.514 13.708 6.708C13.514 6.90267 13.278 7 13 7H1C0.722 7 0.486 6.90267 0.292 6.708C0.0973334 6.514 0 6.278 0 6V1C0 0.722 0.0973334 0.486 0.292 0.292C0.486 0.0973334 0.722 0 1 0ZM1.5 1.5V5.5H12.5V1.5H1.5ZM1 8H13C13.278 8 13.514 8.09733 13.708 8.292C13.9027 8.486 14 8.722 14 9V14C14 14.278 13.9027 14.514 13.708 14.708C13.514 14.9027 13.278 15 13 15H1C0.722 15 0.486 14.9027 0.292 14.708C0.0973334 14.514 0 14.278 0 14V9C0 8.722 0.0973334 8.486 0.292 8.292C0.486 8.09733 0.722 8 1 8ZM1.5 9.5V13.5H12.5V9.5H1.5ZM1.5 1.5V5.5V1.5ZM1.5 9.5V13.5V9.5Z" />
    </svg>
  );
}
