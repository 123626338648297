import { useCallback, useEffect } from "react";
import type { MultiLineage } from "../../../../api";
import { usePullRequest } from "../../../../api";
import { FullscreenMessage } from "../../../../components/FullscreenMessage";
import { PullRequestDiffError } from "../../../../components/PullRequestDiffError";
import { DiscoveryGraph } from "../../../../components/discovery/DiscoveryGraph";
import { useGraphInitializer } from "../../../../components/discovery/utils/useGraphInitializer";
import { PullRequestExplorerPanel } from "../../../../components/pr_explorer/PullRequestExplorerPanel";
import { useRequiredParam } from "../../../../components/useRequiredParam";
import { logEventChangeGraphImpression } from "../../../../utils/analytics/eventLogging";

export default function RepoPullRequestPage(): JSX.Element {
  const repoName = useRequiredParam("repoName");
  const prId = useRequiredParam("prId");

  const handleGraphImpression = useCallback(() => {
    logEventChangeGraphImpression(prId, repoName);
  }, [prId, repoName]);

  return (
    <DiscoveryGraph
      leftPanel={<PullRequestExplorerPanel />}
      toolbarShown="always"
      onInitialNodesRender={handleGraphImpression}
    >
      <PullRequestDiffLoader prId={prId} repoName={repoName} />
    </DiscoveryGraph>
  );
}

function PullRequestDiffLoader({
  repoName,
  prId,
}: {
  readonly repoName: string;
  readonly prId: string;
}): JSX.Element | null {
  const { data, isError, error, isLoading } = usePullRequest(repoName, prId);

  // The callback is updated every time the data changes, so this will cause the effect
  // to fire ONCE (and only once) on every change to data, which is great because we
  // want to update the graph once when we start with an empty state, and then again
  // once the data loads
  const initializeGraph = useGraphInitializer(data?.diff.entities, data?.diff.edges);
  useEffect(() => {
    initializeGraph();
  }, [initializeGraph]);

  if (isError) {
    return <PullRequestDiffError error={error} prId={prId} repoName={repoName} />;
  }

  if (isLoading) {
    return <FullscreenMessage loading>Loading data...</FullscreenMessage>;
  }

  if (data !== undefined && isEmptyDiff(data.diff)) {
    return <EmptyDiffMessage />;
  }

  // No interstitial/overlay to show, we let the graph show instead.
  return null;
}

function EmptyDiffMessage(): JSX.Element {
  return (
    <div className="m-auto flex h-full w-72 flex-col items-center justify-center gap-3">
      <h4 className="font-semibold text-neutral-600">No changes were made</h4>
      <p className="text-center text-xs text-neutral-500">
        You can start exploring the graph by searching for an entity as a starting
        point.
      </p>
    </div>
  );
}

// TODO: Move this to a utils file. It's here because I pulled it off
// main component and I just wanted to make sure I run the same logic.
export const isEmptyDiff = ({ entities, edges }: MultiLineage["diff"]) =>
  entities.length === 0 && edges.length === 0;
