import { type PropsWithChildren, useCallback, useState } from "react";
import invariant from "tiny-invariant";
import type { PolicyRule, PolicyRulesData } from "../../../api";
import { PlusSignIcon } from "../../icons/PlusSignIcon";
import { Button } from "../common/Button";
import { type PartialRuleData, SingleRuleConfigBox } from "./SingleRuleConfigBox";

type AddRuleButtonsType = "AND" | "OR";

export function EditableTriggerList({
  onRuleSetChange,
}: {
  readonly onRuleSetChange: (rulesData: PolicyRulesData | undefined) => void;
}): JSX.Element {
  const [isRuleSetConjunctive, setIsRuleSetConjunctive] = useState<
    boolean | undefined
  >();
  const [rules, setRules] = useState<readonly PartialRuleData[]>([{}]);

  const handleRuleChange = useCallback(
    (ruleId: number, ruleData?: PartialRuleData) => {
      setRules((current) => {
        invariant(ruleId < current.length, "Rule edited does not exist");
        const newRules = [...current];
        if (ruleData === undefined) {
          newRules.splice(ruleId, 1);
        } else {
          newRules[ruleId] = ruleData;
        }

        try {
          const rulesData = buildPolicyRulesData(newRules, isRuleSetConjunctive);
          onRuleSetChange(rulesData);
        } catch {
          onRuleSetChange(undefined);
        }

        if (newRules.length === 1) {
          setIsRuleSetConjunctive(undefined);
        }

        return newRules;
      });
    },
    [onRuleSetChange, isRuleSetConjunctive],
  );

  const handleAddRule = useCallback(
    (buttonClicked: AddRuleButtonsType) => {
      setIsRuleSetConjunctive(buttonClicked === "AND");
      setRules((current) => [...current, {}]);
    },
    [setRules, setIsRuleSetConjunctive],
  );

  return (
    <>
      <ul>
        {rules.map((rule, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx}>
            <SingleRuleConfigBox
              className="after:my-3 after:h-[1px] after:w-full after:bg-[rgb(222,228,228)]"
              hideDeleteButton={idx === 0}
              ruleData={rule}
              ruleId={idx}
              onRuleChange={handleRuleChange}
            />
          </li>
        ))}
      </ul>
      <AddRuleButtons
        isRuleSetConjunctive={isRuleSetConjunctive}
        onClick={handleAddRule}
      />
    </>
  );
}

function AddRuleButtons({
  isRuleSetConjunctive,
  onClick,
}: {
  readonly isRuleSetConjunctive: boolean | undefined;
  readonly onClick: (button: AddRuleButtonsType) => void;
}): JSX.Element {
  const handleAddRuleAnd = useCallback(() => {
    onClick("AND");
  }, [onClick]);
  const handleAddRuleOr = useCallback(() => {
    onClick("OR");
  }, [onClick]);

  return (
    <div className="flex gap-x-3">
      {isRuleSetConjunctive === undefined || isRuleSetConjunctive ? (
        <AddRuleButton onClick={handleAddRuleAnd}>And</AddRuleButton>
      ) : null}
      {isRuleSetConjunctive === undefined || !isRuleSetConjunctive ? (
        <AddRuleButton onClick={handleAddRuleOr}>Or</AddRuleButton>
      ) : null}
    </div>
  );
}

function AddRuleButton({
  onClick,
  children,
}: PropsWithChildren<{
  readonly onClick: () => void;
}>): JSX.Element {
  return (
    <Button
      className="flex items-center bg-[rgba(143,109,241,0.2)] py-2 pl-2 pr-3 font-semibold leading-4 text-[rgb(116,85,206)] hover:bg-[rgba(143,109,241,0.3)] active:bg-[rgba(143,109,241,0.35)]"
      onClick={onClick}
    >
      <PlusSignIcon className="h-5 w-5 text-[rgb(116,85,206)]" />
      {children}
    </Button>
  );
}

function buildPolicyRulesData(
  partialRules: readonly PartialRuleData[],
  isConjunctive: boolean | undefined,
): PolicyRulesData {
  const rules = buildPolicyRulesFromPartialRules(partialRules);

  return { conjunctiveEvaluation: isConjunctive ?? false, rules };
}

function buildPolicyRulesFromPartialRules(
  partialRules: readonly PartialRuleData[],
): readonly PolicyRule[] {
  return partialRules.map(({ changeType, entityType, entityId }) => {
    invariant(
      changeType !== undefined && entityType !== undefined && entityId !== undefined,
      "Rule is not fully defined",
    );
    return {
      rule_type: "single_lineage_entity_rule" as const,
      subject_entity_type: entityType,
      subject_entity_id: entityId,
      include_upstream_changes: changeType === "indirect",
    };
  });
}
