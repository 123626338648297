import AddIcon from "@mui/icons-material/Add";
import { IconButton, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from "react";

function ProjectChip({
  project,
  onDelete,
}: {
  readonly project: string;
  readonly onDelete: (projectName: string) => void;
}): JSX.Element {
  const handleDelete = useCallback(() => {
    onDelete(project);
  }, [onDelete, project]);

  return <Chip label={project} onDelete={handleDelete} />;
}

export function ProjectsChips({
  enabledProjects,
  onChange,
}: {
  readonly enabledProjects: readonly string[];
  readonly onChange: Dispatch<SetStateAction<readonly string[]>>;
}): JSX.Element {
  const [newProjectName, setNewProjectName] = useState("");

  const handleNewProjectChange = useCallback(
    ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
      setNewProjectName(value);
    },
    [],
  );

  const handleAdd = useCallback(() => {
    onChange((current) => [...current, newProjectName]);
    setNewProjectName("");
  }, [newProjectName, onChange]);

  const handleDelete = useCallback(
    (projectToDelete: string) => {
      onChange((current) =>
        current.filter((projectName) => projectName !== projectToDelete),
      );
    },
    [onChange],
  );

  return (
    <>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton
              disabled={
                newProjectName === "" || enabledProjects.includes(newProjectName)
              }
              onClick={handleAdd}
            >
              <AddIcon />
            </IconButton>
          ),
        }}
        autoComplete="off"
        label="Add projects"
        type="text"
        value={newProjectName}
        variant="standard"
        onChange={handleNewProjectChange}
      />
      <Typography variant="subtitle1">Enabled Projects:</Typography>
      <ul className="flex flex-wrap justify-center gap-1 rounded-md border-2 border-double p-0.5">
        {enabledProjects.map((project) => (
          <li key={project}>
            <ProjectChip project={project} onDelete={handleDelete} />
          </li>
        ))}
      </ul>
    </>
  );
}
