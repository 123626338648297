import { useCallback, useEffect, useState } from "react";
import { useRouteLineage } from "../../api";
import type { LineageSearchResult } from "../../utils/search/lineage";
import { searchLineage } from "../../utils/search/lineage";
import { SearchEmptyStateGraphic } from "../icons/SearchEmptyState";
import { NoResults } from "../search/NoResults";
import { useIncrementalSearch } from "../useIncrementalSearch";
import { useAddRootNodeIfNeededAndFocus } from "../useMoveToRootNode";
import { SearchResultsTabs } from "./SearchResultsTabs";

export function DiscoveryAddNodeDialog({
  onResultClick,
}: {
  readonly onResultClick: () => void;
}): JSX.Element {
  const lineage = useRouteLineage();
  const entities = lineage?.snapshot.entities;

  const [query, setQuery] = useState("");
  const [results, setResults] = useState<readonly LineageSearchResult[] | undefined>();

  useEffect(() => {
    setResults(
      query === ""
        ? undefined
        : entities === undefined
        ? []
        : searchLineage(entities, query),
    );
  }, [lineage, entities, query]);

  const addRootNodeIfNeededAndFocus = useAddRootNodeIfNeededAndFocus();
  const handleResultClick = useCallback(
    (result: LineageSearchResult) => {
      addRootNodeIfNeededAndFocus(result.rootEntity);
      onResultClick();
    },
    [addRootNodeIfNeededAndFocus, onResultClick],
  );

  const searchInputRef = useIncrementalSearch(setQuery);

  return (
    <>
      <input
        ref={searchInputRef}
        autoComplete="off"
        autoCorrect="off"
        className="my-4 w-[calc(100%-2rem)] flex-none rounded-lg border border-green-600 px-4 py-3 text-sm shadow-sm outline-none"
        minLength={1}
        name="q"
        placeholder="Start by selecting a table, a column, or a dashboard..."
        spellCheck={false}
        type="search"
      />
      {results === undefined ? (
        <InitialState />
      ) : lineage === undefined ? (
        <NoResults animateMagnifier className="flex-1" subtitle="Please wait">
          Loading data from server
        </NoResults>
      ) : results.length === 0 ? (
        <NoResults className="flex-1">No elements found</NoResults>
      ) : (
        <SearchResultsTabs
          className="w-full flex-1 overflow-hidden"
          results={results}
          onResultClick={handleResultClick}
        />
      )}
    </>
  );
}

function InitialState(): JSX.Element {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-y-6">
      <SearchEmptyStateGraphic className="mb-8 w-52" />
      <h6 className="text-center text-sm font-medium">
        Select a table, column, or dashboard
      </h6>
      <p className="px-14 text-center text-xs text-black/50">
        Start exploring your data by picking a table, a column, or a dashboard.
      </p>
    </div>
  );
}
