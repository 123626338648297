export function NumberIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.354 13.125L3.979 10.625H0.854004L1.292 8.875H4.417L5.229 5.625H1.854L2.292 3.875H5.667L6.417 0.875H8.146L7.396 3.875H10.167L10.917 0.875H12.646L11.896 3.875H15.146L14.708 5.625H11.458L10.646 8.875H14.146L13.708 10.625H10.208L9.583 13.125H7.854L8.479 10.625H5.708L5.083 13.125H3.354ZM6.146 8.875H8.917L9.729 5.625H6.958L6.146 8.875Z" />
    </svg>
  );
}
