import { Handle, Position } from "reactflow";

export function InvisibleHandle({
  position,
  className,
  skipOffset = false,
  ...props
}: Parameters<typeof Handle>[0] & { readonly skipOffset?: boolean }): JSX.Element {
  return (
    <Handle
      className={`!h-[1px] !min-h-0 !w-[1px] !min-w-0 !border-none opacity-0 ${
        className ?? ""
      } 
      ${skipOffset ? "" : handleOffset(position)}`}
      isConnectable={false}
      position={position}
      {...props}
    />
  );
}

/**
 * The tailwind class names to place the handle correctly for the given
 * position.
 *
 * IMPORTANT: Don't try to optimize how these strings are built; in order for
 * tailwind to detect and package these classes they need to remain in full
 * (including the important marker and the pixel count)
 */
function handleOffset(position: Position): string {
  switch (position) {
    case Position.Bottom:
      return "!-bottom-[1px]";

    case Position.Top:
      return "!-top-[1px]";

    case Position.Left:
      return "!-left-3.5";

    case Position.Right:
      return "!-right-[1px]";
  }
}
