/* eslint-disable import/no-deprecated */

import { useCallback, useMemo, useState } from "react";
import { filter, identity, pipe } from "remeda";
import { rootEntityFromTable, useRootEntityDependencies } from "../../api";
import type { LineageTable } from "../../utils/graph/type";
import {
  searchDependencies,
  type DependenciesSearchResult,
} from "../../utils/search/dependencies";
import type { LineageSearchResult } from "../../utils/search/lineage";
import { SimpleRadioGroup } from "../SimpleRadioGroup";
import { SearchResultsItem } from "../discovery/SearchResultsItem";
import { useAddRootNodeIfNeededAndFocus } from "../useMoveToRootNode";
import { DependencySearchInput } from "./DependencySearchInput";

/**
 * Define the filters we provide on the dependencies. The order here is important and
 * would affect the visual order of the filter buttons. The names are also important and
 * would be used directly as the labels themselves. The first value would the the
 * default value which would be selected on component load.
 */
const TYPE_FILTERS = ["all", "downstream", "upstream"] as const;

export function DependenciesPanel({
  table,
}: {
  readonly table: LineageTable;
}): JSX.Element | null {
  const rootEntity = useMemo(() => rootEntityFromTable(table), [table]);
  const { data: dependencies } = useRootEntityDependencies(rootEntity);

  const [typeFilter, setTypeFilter] = useState<(typeof TYPE_FILTERS)[number]>(
    TYPE_FILTERS[0],
  );
  const [query, setQuery] = useState("");

  const results = useMemo(
    () =>
      dependencies === undefined
        ? undefined
        : pipe(
            dependencies,
            typeFilter === "all" ? identity : filter(({ type }) => type === typeFilter),
            ($) => searchDependencies($, query),
          ),
    [dependencies, query, typeFilter],
  );

  if (results === undefined) {
    // Because the dependencies are computed and not fetched the loading times are fast
    // enough that a loading indicator actually looks worse because it just causes the
    // UI to momentarily flash.
    return null;
  }

  return (
    <section className="flex flex-1 flex-col items-stretch divide-y overflow-hidden">
      <header className="flex flex-none flex-col items-stretch gap-3 px-6 py-4">
        <DependencySearchInput
          disabled={query === "" && results.length === 0}
          value={query}
          onChange={setQuery}
        />
        <SimpleRadioGroup
          allValues={TYPE_FILTERS}
          value={typeFilter}
          onChange={setTypeFilter}
        />
      </header>
      <section className="flex flex-1 flex-col overflow-hidden">
        <h6 className="flex h-9 flex-none items-center border-b bg-neutral-50 px-6 text-xs text-neutral-400">
          {results.length} dependencies
        </h6>
        <ul className="flex flex-1 flex-col divide-y overflow-hidden overflow-y-auto px-6">
          {results.map((result) => (
            <li key={result.item.entity.id}>
              <DependencyResult result={result} />
            </li>
          ))}
        </ul>
      </section>
    </section>
  );
}

function DependencyResult({
  result: {
    item: { entity },
    ...matchDescriptor
  },
}: {
  readonly result: DependenciesSearchResult;
}): JSX.Element {
  const addRootNodeIfNeededAndFocus = useAddRootNodeIfNeededAndFocus();

  const handleClick = useCallback(
    ({ rootEntity }: LineageSearchResult) => {
      addRootNodeIfNeededAndFocus(rootEntity);
    },
    [addRootNodeIfNeededAndFocus],
  );

  // We are reusing a component that shows search results, but because we are performing
  // search on a different kind of entity we need to convert the result so it would
  // match the type used elsewhere...
  const convertedResult = useMemo<LineageSearchResult>(
    () => ({ ...matchDescriptor, rootEntity: entity, result: entity }),
    [entity, matchDescriptor],
  );

  return (
    <SearchResultsItem
      className="py-4 hover:bg-neutral-50"
      result={convertedResult}
      onClick={handleClick}
    />
  );
}
