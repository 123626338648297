/* eslint-disable import/no-deprecated */
import type { LineageTable } from "../../utils/graph/type";
import { Description } from "./Description";
import { MissingSectionContents } from "./MissingSectionContents";
import { Owners } from "./Owners";

const INFORMATION_PANEL_ENABLED = false;

export function InformationPanel({
  table,
}: {
  readonly table: LineageTable;
}): JSX.Element {
  return (
    <section className="flex flex-1 flex-col gap-6 p-6">
      {INFORMATION_PANEL_ENABLED ? (
        <>
          <Owners table={table} />
          <hr className="h-px w-full" />
          <Description table={table} />
        </>
      ) : (
        <MissingSectionContents />
      )}
    </section>
  );
}
