export function EnvelopeIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.41663 16.6666C2.93538 16.6666 2.5234 16.4952 2.18069 16.1525C1.83798 15.8098 1.66663 15.3978 1.66663 14.9166V5.08325C1.66663 4.602 1.83798 4.19002 2.18069 3.84731C2.5234 3.50461 2.93538 3.33325 3.41663 3.33325H16.5833C17.0645 3.33325 17.4765 3.50461 17.8192 3.84731C18.1619 4.19002 18.3333 4.602 18.3333 5.08325V14.9166C18.3333 15.3978 18.1619 15.8098 17.8192 16.1525C17.4765 16.4952 17.0645 16.6666 16.5833 16.6666H3.41663ZM16.5833 6.83325L10.4566 10.6471C10.3883 10.6879 10.3159 10.7221 10.2395 10.7499C10.1632 10.7777 10.0833 10.7916 9.99996 10.7916C9.91663 10.7916 9.83677 10.7794 9.76038 10.7551C9.68399 10.7308 9.61107 10.6944 9.54163 10.6458L3.41663 6.83325V14.9166H16.5833V6.83325ZM9.99996 9.20825L16.625 5.08325H3.39579L9.99996 9.20825ZM3.41663 6.83325V7.03842V5.77596V5.79159V5.7855V7.04159V6.83325Z" />
    </svg>
  );
}
