import { ChartAnalyzedPRs } from "./ChartAnalyzedPRs";
import { ChartCycleTime } from "./ChartCycleTime";

export function DashboardCharts(): JSX.Element {
  return (
    <section className="flex items-center justify-center gap-12">
      <div className="flex-1">
        <ChartAnalyzedPRs />
      </div>
      <div className="flex-1">
        <ChartCycleTime />
      </div>
    </section>
  );
}
