/* eslint-disable import/no-deprecated */
import type { LineageTableId, LineageUriId } from "./graph/type";

export function extractTableName(
  tableIdentifier: LineageTableId | LineageUriId,
): string {
  return tableIdentifier.identifier_type === "db"
    ? tableIdentifier.table_name
    : tableIdentifier.identifier ?? "";
}
