import { offset, shift, useFloating } from "@floating-ui/react-dom";
import { Menu } from "@headlessui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMe } from "../../api";
import { logEventLogout } from "../../utils/analytics/eventLogging";
import { Avatar } from "../Avatar";
import { LogoutIcon } from "../icons/LogoutIcon";
import { useLogout } from "../login/authProvider";
import { useNavStateContext } from "./Nav";

export function NavigationFooter(): JSX.Element {
  const { data } = useMe();
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: "top-start",
    middleware: [
      /* eslint-disable @typescript-eslint/no-magic-numbers */
      offset(4),
      shift({ padding: 6 }),
      /* eslint-enable @typescript-eslint/no-magic-numbers */
    ],
  });
  const { isOpen } = useNavStateContext();

  return (
    <Menu as="footer" className="relative flex-none">
      <Menu.Button
        ref={setReference}
        className="flex h-[4.5rem] w-full items-center gap-x-3 border-t border-white/10 px-3 hover:bg-[rgb(56,74,80)]"
      >
        <Avatar
          fullName={
            data === undefined
              ? undefined
              : { first: data.first_name, last: data.last_name }
          }
        />
        {isOpen ? (
          <div className="flex flex-col gap-y-1 overflow-hidden text-left text-xs">
            {data === undefined ? (
              <div className="animate-pulse text-white/60">Loading...</div>
            ) : (
              <>
                <span
                  className="overflow-hidden overflow-ellipsis font-medium text-white"
                  title={`${data.first_name} ${data.last_name}`}
                >
                  {data.first_name}&nbsp;{data.last_name}
                </span>
                <span
                  className="overflow-hidden overflow-ellipsis text-white/60"
                  title={data.email}
                >
                  {data.email}
                </span>
              </>
            )}
          </div>
        ) : null}
      </Menu.Button>
      <Menu.Items
        ref={setFloating}
        className="z-50 w-max min-w-[7.5rem] overflow-clip rounded bg-white text-xs font-medium text-gray-600 shadow-[0px_0px_0px_1px_rgba(44,57,63,0.1),0px_4px_10px_rgba(44,57,63,0.2)] [&>*]:flex [&>*]:h-10 [&>*]:w-full [&>*]:justify-between [&>*]:px-4 [&>*]:py-3 ui-active:[&>*]:bg-gray-50 ui-active:[&>*]:text-[rgb(67,178,118)]"
        style={{ position: strategy, top: y, left: x }}
      >
        <LogoutMenuItem />
      </Menu.Items>
    </Menu>
  );
}

function LogoutMenuItem(): JSX.Element {
  const logout = useLogout();
  const navigate = useNavigate();
  const logoutAndNavigateToRoot = useCallback(() => {
    logout();
    logEventLogout();
    navigate("/");
  }, [logout, navigate]);

  return (
    <Menu.Item as="button" type="submit" onClick={logoutAndNavigateToRoot}>
      {({ active }) => (
        <>
          Sign out
          <LogoutIcon className={`w-4 ${active ? "" : "opacity-60"}`} />
        </>
      )}
    </Menu.Item>
  );
}
