import type { PropsWithChildren } from "react";
import { BigMagnifyingGlass } from "../icons/BigMagnifyingGlass";

export function NoResults({
  className,
  animateMagnifier = false,
  subtitle,
  children,
}: PropsWithChildren<{
  readonly animateMagnifier?: boolean;
  readonly subtitle?: string | undefined;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-y-2 p-11 ${
        className ?? ""
      }`}
    >
      <BigMagnifyingGlass
        className={`mb-6 h-14 w-14 ${animateMagnifier ? "animate-bounce" : ""}`}
      />
      <h4 className="text-sm font-semibold text-[rgb(82,97,100)]">{children}</h4>
      <span className="text-xs text-[rgb(165,173,176)]">
        {subtitle ?? "You can try a different search text"}
      </span>
    </div>
  );
}
