import type { PropsWithChildren } from "react";
import { DeletePolicyIcon } from "../../../icons/DeletePolicyIcon";
import { EditPolicyIcon } from "../../../icons/EditPolicyIcon";

function PolicyActionButton({
  className,
  onClick,
  children,
}: PropsWithChildren<{
  readonly onClick: () => void;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <button
      className={`rounded-full hover:drop-shadow-md active:drop-shadow-sm ${
        className ?? ""
      }`}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function DeletePolicyButton({
  onClick,
}: {
  readonly onClick: () => void;
}): JSX.Element {
  return (
    <PolicyActionButton onClick={onClick}>
      <DeletePolicyIcon className="h-8 w-8 text-[rgb(165,175,176)]" />
    </PolicyActionButton>
  );
}

function EditPolicyButton({ onClick }: { readonly onClick: () => void }): JSX.Element {
  return (
    <PolicyActionButton onClick={onClick}>
      <EditPolicyIcon className="text-[rgb(165,175,176)]" />
    </PolicyActionButton>
  );
}

export function PolicyActionButtonsBox({
  className,
  onDelete,
  onEdit,
}: {
  readonly onDelete: () => void;
  readonly onEdit?: (() => void) | undefined;
  readonly className?: string | undefined;
}): JSX.Element {
  const isEditSupported = onEdit !== undefined;
  return (
    <div
      className={`flex max-h-5 flex-row items-center gap-x-1 ${
        isEditSupported ? "justify-center" : "justify-end px-2"
      } ${className ?? ""}`}
    >
      <DeletePolicyButton onClick={onDelete} />
      {isEditSupported ? (
        <>
          <hr className="h-5 w-px bg-[rgba(115,130,133,0.15)]" />
          <EditPolicyButton onClick={onEdit} />
        </>
      ) : null}
    </div>
  );
}
