import { type FocusEventHandler, useCallback, useId, useState } from "react";
import { queryApiCall } from "../../api/queryApiCall";
import { GreenButton } from "../../components/Buttons";
import { BulkUserForm } from "../../components/admin/BulkUserForm";
import type { UserDetails } from "../../components/admin/NewUserForm";
import { UserCreationResultBox } from "../../components/admin/UserCreationResultBox";

export default function CreateOrgAdminPage(): JSX.Element {
  const [users, setUsers] = useState<readonly UserDetails[]>([]);
  const [orgName, setOrgName] = useState("");
  const [orgDisplayName, setOrgDisplayName] = useState("");
  const [result, setResult] = useState<Record<string, string> | undefined>(undefined);
  const [error, setError] = useState<unknown>(undefined);
  const orgNameId = useId();
  const orgInternalNameId = useId();

  const createOrg = useCallback(() => {
    const createOrgAsync = async () => {
      setResult(undefined);
      setError(undefined);
      try {
        const res = await queryApiCall<Record<string, string>>("/admin/create-org", {
          body: {
            org_name: orgName,
            org_display_name: orgDisplayName,
            users: users.map((u) => ({
              first_name: u.firstName,
              last_name: u.lastName,
              email: u.email,
            })),
          },
        });
        setResult(res);
      } catch (error_) {
        setError(error_);
      }
    };

    // eslint-disable-next-line no-void
    void createOrgAsync();
  }, [setResult, setError, orgName, orgDisplayName, users]);

  return (
    <div className="m-3 w-1/2">
      <h1 className="mb-2 text-2xl">Create Org</h1>
      <div className="mb-4 flex flex-col">
        <div className="my-1 flex align-middle">
          <label className="mr-3 py-1" htmlFor={orgNameId}>
            Display Name
          </label>
          <input
            autoComplete="off"
            autoCorrect="off"
            className="flex-grow border-2 p-1 text-sm outline-1"
            id={orgNameId}
            placeholder="Cool corp."
            spellCheck={false}
            value={orgDisplayName}
            onChange={useCallback<FocusEventHandler<HTMLInputElement>>(
              (e) => {
                setOrgDisplayName(e.target.value);
              },
              [setOrgDisplayName],
            )}
          />
        </div>
        <div className="my-1 flex align-middle">
          <label className="mr-3 py-1" htmlFor={orgInternalNameId}>
            Internal Name
          </label>
          <input
            autoComplete="off"
            autoCorrect="off"
            className="flex-grow border-2 p-1 text-sm outline-1"
            id={orgInternalNameId}
            placeholder="cool-corp"
            spellCheck={false}
            value={orgName}
            onChange={useCallback<FocusEventHandler<HTMLInputElement>>(
              (e) => {
                setOrgName(e.target.value);
              },
              [setOrgName],
            )}
          />
        </div>
      </div>
      <BulkUserForm minUsers={0} updateUserList={setUsers} users={users} />
      <GreenButton className="mb-2 mt-5 block" onClick={createOrg}>
        Create Org
      </GreenButton>
      <UserCreationResultBox error={error} result={result} />
    </div>
  );
}
