/* eslint-disable import/no-deprecated */
/**
 * This file converts between deprecated models to the models in lineage/schemas.ts.
 * @see lineage/schemas.ts for more documentation.
 */
import { map, pipe, prop, uniq } from "remeda";
import {
  canonicalColumnId,
  canonicalRelationIds,
  canonicalTableIdentifier,
} from "../../utils/graph/canonical";
import type {
  EdgeState as DeprecatedEdgeState,
  LineageState as DeprecatedLineageState,
  LineageTable,
  LineageTableColumn,
  LineageTableId,
  LineageUriId,
  MultiLineage as DeprecatedMultiLineage,
} from "../../utils/graph/type";
import { extractTableName } from "../../utils/parseTableIdentifier";
import type {
  ChangeStatus,
  EdgeState,
  LineageChildEntity,
  LineageRootEntity,
  LineageState,
  MultiLineage,
  RelationType,
} from "./schemas";

export function multiLineageFromDeprecated(
  lineage: DeprecatedMultiLineage,
): MultiLineage {
  return {
    diff: lineageFromDeprecated(lineage.diff),
    snapshot: lineageFromDeprecated(lineage.snapshot),
  };
}

export function lineageFromDeprecated(lineage: DeprecatedLineageState): LineageState {
  return {
    entities: lineage.tables.map((table) => rootEntityFromTable(table)),
    edges: lineage.edges.map((edge) => edgeFromDeprecated(edge)),
  };
}

function edgeFromDeprecated({
  sourceColumn,
  destinationColumn,
  relationType,
  changeStatus,
}: DeprecatedEdgeState): EdgeState {
  const { id, source, target } = canonicalRelationIds({
    sourceColumn,
    destinationColumn,
    relationType,
  });
  const targetId = canonicalColumnId(destinationColumn);

  const realDestId = getEdgeDest(target.table, targetId, relationType);
  const realDest =
    realDestId === targetId
      ? target
      : {
          table: target.table,
          col: extractTableName(destinationColumn.table_identifier),
        };

  return {
    id,
    source: {
      id: canonicalColumnId(sourceColumn),
      entityName: source.col,
      rootId: source.table,
    },
    dest: {
      id: realDestId,
      entityName: realDest.col,
      rootId: realDest.table,
    },
    relationType,
    changeStatus,
  };
}

export function rootEntityFromTable({
  table_identifier: tableIdentifier,
  changeStatus: originalChangeStatus,
  columns,
}: LineageTable): LineageRootEntity {
  const changeStatus = tableComputedChangeStatus(originalChangeStatus, columns);
  const id = canonicalTableIdentifier(tableIdentifier);
  const childEntities = childEntitiesFromColumns(tableIdentifier, columns);
  switch (tableIdentifier.identifier_type) {
    case "db":
      return {
        id,
        entityName: tableIdentifier.table_name,
        changeStatus,
        childEntities,
        ...(tableIdentifier.db_name === "tableau"
          ? {
              type: "dashboard",
              platform: tableIdentifier.db_name,
              projectName: tableIdentifier.db_schema,
            }
          : {
              type: "table",
              dbName: tableIdentifier.db_name,
              dbSchema: tableIdentifier.db_schema,
              platform: "bigquery",
            }),
      };

    case "uri":
      return {
        id,
        entityName:
          tableIdentifier.extracted_file_name ?? tableIdentifier.identifier ?? "",
        type: "file",
        platform: platformFromUriOrType(
          tableIdentifier.uri,
          tableIdentifier.extracted_file_type,
        ),
        uri: tableIdentifier.uri?.[0] ?? "",
        rootFolder: tableIdentifier.extracted_root_folder,
        dirName: tableIdentifier.extracted_dir_name,
        fileName: tableIdentifier.extracted_file_name,
        fileType: tableIdentifier.extracted_file_type,
        changeStatus,
        childEntities,
      };
  }
}

function childEntitiesFromColumns(
  tableIdentifier: LineageTableId | LineageUriId,
  columns: readonly LineageTableColumn[],
): readonly LineageChildEntity[] {
  return columns.map(({ col_name, changeStatus, type }) => ({
    id: canonicalColumnId({
      col_name,
      table_identifier: tableIdentifier,
    }),
    entityName: col_name,
    type: "column",
    changeStatus,
    childEntities: [],
    colType: type !== "" && type !== "n/a" ? type : undefined,
  }));
}

// eslint-disable-next-line complexity
function getEdgeDest(
  destRootId: string,
  destId: string,
  relationType: RelationType,
): string {
  switch (relationType) {
    case "relation":
      return destId;

    case "case":
    case "group":
    case "having":
    case "join":
    case "order":
    case "qualify":
    case "select":
    case "unknown":
    case "where":
    case "window":
    case "<<MISSING>>":
      return destRootId;
  }
}

export function platformFromUriOrType(
  uri: readonly string[] | undefined,
  fileType: string | undefined,
): "hdfs" | "s3" {
  if (fileType === "s3") {
    return "s3";
  }
  if (fileType === "hdfs") {
    return "hdfs";
  }
  return uri?.[0]?.startsWith("s3") ?? false ? "s3" : "hdfs";
}
const tableComputedChangeStatus = (
  changeStatus: LineageTable["changeStatus"],
  columns: LineageTable["columns"],
): ChangeStatus | undefined =>
  // Use the table's provided changeStatus if it has one, only fallback to use the
  // columns when the status is missing
  changeStatus ??
  pipe(columns, map(prop("changeStatus")), uniq(), ($) =>
    // If all columns have a single changeStatus we use that, otherwise the status
    // would be 'changed'
    $.length <= 1 ? $[0] : "changed",
  );
