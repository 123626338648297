import {
  DialogActionButton,
  DialogCancelButton,
  DialogFooter,
  ModalDialog,
} from "./ModalDialog";

export function DeletePolicyDialog({
  policyName,
  isOpen,
  onApprove,
  onCancel,
}: {
  readonly policyName: string;
  readonly isOpen: boolean;
  readonly onApprove: () => void;
  readonly onCancel: () => void;
}): JSX.Element {
  return (
    <ModalDialog isOpen={isOpen} title="Delete policy" onClose={onCancel}>
      <DeletePolicyDialogBody policyName={policyName} />
      <DialogFooter
        actionButton={
          <DialogActionButton onClick={onApprove}>Delete</DialogActionButton>
        }
        cancelButton={
          <DialogCancelButton onClick={onCancel}>Cancel</DialogCancelButton>
        }
        className="justify-end"
      />
    </ModalDialog>
  );
}

function DeletePolicyDialogBody({
  policyName,
}: {
  readonly policyName: string;
}): JSX.Element {
  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-sm font-normal leading-5 text-[rgb(115,129,131)]">
        Are you sure you want to delete{" "}
        <strong className="font-semibold text-[rgb(74,89,92)]">
          &quot;{policyName}&quot;
        </strong>
        ?
      </p>
      {/* TODO: Add a "notice" line with time since last activation of the deleted policy */}
      <p className="text-sm font-normal leading-5 text-[rgb(115,129,131)]">
        Notice: This action cannot be undone.
      </p>
    </div>
  );
}
