import { map } from "remeda";
import type { ChangeStatus } from "../../../api";
import type { FilterType } from "../../../utils/search/lineageType";
import { FiltersPanelSearchResultsView } from "./FiltersPanelSearchResultsView";
import { useFiltersPanelContext } from "./SidebarFiltersPanel";
import { TypeFilters } from "./TypeFilters";

const FILTER_TYPE_ORDER: readonly FilterType[] = [
  "table",
  "dashboard",
  "column",
  "edge",
];

export function ModifiedTab({
  onClick,
}: {
  readonly onClick: (type: FilterType, changeStatus: ChangeStatus) => void;
}): JSX.Element {
  const { query } = useFiltersPanelContext();

  if (query !== "") {
    return <FiltersPanelSearchResultsView changeType="modified" />;
  }

  return (
    <>
      {map(FILTER_TYPE_ORDER, (type) => (
        <TypeFilters key={type} type={type} onClick={onClick} />
      ))}
    </>
  );
}
