import { createContext, type PropsWithChildren, useContext, useMemo } from "react";
import { useEdges } from "reactflow";
import { filter, map, pipe, prop } from "remeda";
import {
  type EdgeState,
  type LineageChildEntity,
  useLineageRootConnectedEdges,
} from "../../api";
import type { EdgeData } from "../graph/edge";

export type DiscoveryModeTableContext = {
  readonly nodeId: string;

  readonly existingEdgeIds: ReadonlySet<string>;

  readonly inbound: readonly EdgeState[];
  readonly outbound: readonly EdgeState[];
};

/* eslint-disable-next-line @typescript-eslint/no-redeclare --
 * The naming convention for React Contexts breaks regular naming conventions (it should
 * be UPPER_CASE because it's a const, but that would allow using it as a component in
 * JSX), and that sucks because the naming convention it uses is how we define types, so
 * we just ignore it here, typescript is smart enough to know when something is used as
 * a type vs when it's used as a const.
 */
const DiscoveryModeTableContext = createContext<DiscoveryModeTableContext | undefined>(
  undefined,
);

/**
 * Returns context data required for making discovery mode work. When this hook returns
 * undefined it means the component is not a child of a discovery mode graph.
 *
 * @param column By default the context is at the table level, but by providing a
 * column we refine `inbound` and `outbound` to only those edges that are directly
 * connected to this specific column.
 */
export function useDiscoveryModeContext(
  column?: LineageChildEntity,
): DiscoveryModeTableContext | undefined {
  const tableContext = useContext(DiscoveryModeTableContext);

  const columnHierarchy = useMemo(
    () =>
      column === undefined ? undefined : new Set(extractAllColumnTreeNames(column)),
    [column],
  );

  const columnInbound = useMemo(
    () =>
      tableContext?.inbound === undefined || columnHierarchy === undefined
        ? undefined
        : tableContext.inbound.filter(({ dest }) =>
            columnHierarchy.has(dest.entityName),
          ),
    [columnHierarchy, tableContext?.inbound],
  );

  const columnOutbound = useMemo(
    () =>
      tableContext?.outbound === undefined || columnHierarchy === undefined
        ? undefined
        : tableContext.outbound.filter(({ source }) =>
            columnHierarchy.has(source.entityName),
          ),
    [columnHierarchy, tableContext?.outbound],
  );

  return useMemo(
    // We deliberatly split the useMemo calls here so that we break the dependency
    // between the different elements of the context. Without doing this it would mean
    // we filter the edges *every time* `existingEdgeIds` changes (which could happen
    // often).
    () =>
      tableContext === undefined ||
      columnInbound === undefined ||
      columnOutbound === undefined
        ? tableContext
        : {
            // The order is important here! tableContext already has inbound and
            // outbound edges, but we want to override them, so we first spread the
            // original values.
            ...tableContext,
            inbound: columnInbound,
            outbound: columnOutbound,
          },
    [columnInbound, columnOutbound, tableContext],
  );
}

export function DiscoveryModeLineageRootWrapper({
  entityId,
  children,
}: PropsWithChildren<{
  readonly entityId: string;
}>): JSX.Element {
  const allGraphEdges = useEdges<EdgeData>();

  const { data: connectedEdges = { inbound: [], outbound: [] } } =
    useLineageRootConnectedEdges(entityId);

  const existingEdgeIds = useMemo(
    // We do a tradeoff here between memory usage and code complexity (mainly props
    // drilling); we need to convert the data-structure ReactFlow provides us for the
    // current visible edges on the graph to something that would make querying a
    // specific relation ID efficiently. If we do this at the button or column level we
    // will have dozens of sets that would need to be recomputed every time the graph is
    // updated, so we do this at the table level. This would also come into play when we
    // add the table-level discover expand buttons to the table header.
    () =>
      pipe(
        allGraphEdges,
        filter(
          ({ data }) =>
            data?.edgeState !== undefined &&
            (entityId === data.edgeState.source.rootId ||
              entityId === data.edgeState.dest.rootId),
        ),
        map(prop("id")),
        ($) => new Set($),
      ),
    [allGraphEdges, entityId],
  );

  const context = useMemo(
    () => ({ nodeId: entityId, existingEdgeIds, ...connectedEdges }),
    [connectedEdges, existingEdgeIds, entityId],
  );

  return (
    <DiscoveryModeTableContext.Provider value={context}>
      {children}
    </DiscoveryModeTableContext.Provider>
  );
}

function extractAllColumnTreeNames(column: LineageChildEntity): readonly string[] {
  return [
    column.entityName,
    ...column.childEntities.flatMap((child) => extractAllColumnTreeNames(child)),
  ];
}
