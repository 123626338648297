import { DEMO_MODE } from "../../utils/demo/demoMode";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";

export const EXAMPLE: readonly string[] = ["foundational-io/ex1-jaffle_shop"];

export const useRepoNames = () =>
  useQueryRetriesServerErrors({
    queryKey: ["repoList"],
    queryFn: getReopNames,
    staleTime: STALE_TIME_MS,
  });

const getReopNames = async (): Promise<readonly string[]> =>
  DEMO_MODE.isEnabled ? EXAMPLE : queryApiCall("lineage/repo_list");
