/**
 * Use this file to supply texts for issue types. Whenever a new issue type is added to
 * the backend it should also be added here.
 *
 * Because the backend and frontend aren't coupled, these methods return reasonable
 * fallbacks so that the frontend doesn't break if this file isn't updated.
 */

/**
 * The description is shown in the issues dialog, it should provide the user with
 * general context and maybe instructions on how to interpret the issues listed below.
 */
export function getIssueTypeDescription(issueType: string): string | undefined {
  switch (issueType) {
    case "DbtCompilationError":
      return "dbt project fails compilation. Please see details for the exact error reported by dbt.";

    default:
      // We need a default cause because issueType is returned as a string from the API.
      // This is also a good idea in case a new issueType is returned that we don't have
      // a description for it yet. In the meantime we just won't show anything for the
      // description, which should be OK because the issues themselves have descriptions
      // too.
      return undefined;
  }
}

/**
 * The issue label is shown both on the issues chip in the PR explorer, and the issues
 * dialog header. It should be as short as possible, because there isn't a lot of room
 * in the chips.
 */
export function getIssueTypeLabel(issueType: string): string {
  switch (issueType) {
    case "DbtCompilationError":
      return "dbt Compilation Error";

    default:
      // We need a default cause because issueType is returned as a string from the API.
      // This is also a good idea in case a new issueType is returned that we don't have
      // a label for it yet. In the meantime we will use the issueType itself, it should
      // be a self-descriptive label anyway.
      return issueType;
  }
}
