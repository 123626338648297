export function CircledExclamationMarkIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.9956 12C8.20687 12 8.38542 11.9285 8.53125 11.7856C8.67708 11.6427 8.75 11.4656 8.75 11.2544C8.75 11.0431 8.67855 10.8646 8.53565 10.7188C8.39273 10.5729 8.21565 10.5 8.0044 10.5C7.79313 10.5 7.61458 10.5715 7.46875 10.7144C7.32292 10.8573 7.25 11.0344 7.25 11.2456C7.25 11.4569 7.32145 11.6354 7.46435 11.7812C7.60727 11.9271 7.78435 12 7.9956 12ZM7.9956 9C8.20687 9 8.38542 8.92813 8.53125 8.78438C8.67708 8.64063 8.75 8.4625 8.75 8.25V4.75C8.75 4.5375 8.67855 4.35938 8.53565 4.21563C8.39273 4.07188 8.21565 4 8.0044 4C7.79313 4 7.61458 4.07188 7.46875 4.21563C7.32292 4.35938 7.25 4.5375 7.25 4.75V8.25C7.25 8.4625 7.32145 8.64063 7.46435 8.78438C7.60727 8.92813 7.78435 9 7.9956 9ZM8.00575 16C6.9047 16 5.86806 15.7917 4.89583 15.375C3.92361 14.9583 3.07292 14.3854 2.34375 13.6562C1.61458 12.9271 1.04167 12.0767 0.625 11.105C0.208333 10.1334 0 9.09519 0 7.99046C0 6.88571 0.208333 5.85069 0.625 4.88542C1.04167 3.92014 1.61458 3.07292 2.34375 2.34375C3.07292 1.61458 3.92332 1.04167 4.89496 0.625C5.86661 0.208333 6.90481 0 8.00954 0C9.11429 0 10.1493 0.208333 11.1146 0.625C12.0799 1.04167 12.9271 1.61458 13.6562 2.34375C14.3854 3.07292 14.9583 3.92169 15.375 4.89008C15.7917 5.85849 16 6.89321 16 7.99425C16 9.09531 15.7917 10.1319 15.375 11.1042C14.9583 12.0764 14.3854 12.9271 13.6562 13.6562C12.9271 14.3854 12.0783 14.9583 11.1099 15.375C10.1415 15.7917 9.10679 16 8.00575 16ZM8 14.5C9.80556 14.5 11.3403 13.8681 12.6042 12.6042C13.8681 11.3403 14.5 9.80556 14.5 8C14.5 6.19444 13.8681 4.65972 12.6042 3.39583C11.3403 2.13194 9.80556 1.5 8 1.5C6.19444 1.5 4.65972 2.13194 3.39583 3.39583C2.13194 4.65972 1.5 6.19444 1.5 8C1.5 9.80556 2.13194 11.3403 3.39583 12.6042C4.65972 13.8681 6.19444 14.5 8 14.5Z" />
    </svg>
  );
}
