import { DashboardCharts } from "../../components/dashboard/DashboardCharts";
import { DashboardLists } from "../../components/dashboard/DashboardLists";
import { DashboardMetrics } from "../../components/dashboard/DashboardMetrics";

export default function DashboardPage(): JSX.Element {
  return (
    <div className="flex h-full flex-col justify-evenly gap-16 p-8">
      <DashboardMetrics />
      <DashboardLists />
      <DashboardCharts />
    </div>
  );
}
