import { useMemo } from "react";
import { useDropzone, type DropzoneOptions } from "react-dropzone";

const BASE_STYLE = {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  flex: 1,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  /* eslint-enable @typescript-eslint/no-magic-numbers */
};

const FOCUSED_STYLE = {
  borderColor: "#2196f3",
};

const ACCEPT_STYLE = {
  borderColor: "#00e676",
};

const REJECT_STYLE = {
  borderColor: "#ff1744",
};

export function StyledFileUploader({
  text,
  ...options
}: DropzoneOptions & {
  readonly text?: string;
}): JSX.Element {
  const message = text ?? "Drag 'n' drop some files here, or click to select files";
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone(options);

  const style = useMemo(
    () => ({
      ...BASE_STYLE,
      ...(isFocused ? FOCUSED_STYLE : {}),
      ...(isDragAccept ? ACCEPT_STYLE : {}),
      ...(isDragReject ? REJECT_STYLE : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{message}</p>
      </div>
    </section>
  );
}
