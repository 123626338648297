import { Popover } from "@headlessui/react";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useRepoStats } from "../../../api";
import { logEventRepositorySelected } from "../../../utils/analytics/eventLogging";
import { parseRepositoryName } from "../../../utils/parseRepositoryName";
import { PrettyTimeSince } from "../../PrettyTimeSince";
import { VerticalTextDivider } from "../../VerticalTextDivider";
import { RepoIcon } from "../../icons/RepoIcon";
import { withWordBreakOpportunities } from "../../render_utils";
import { MetricsTable } from "./MetricsTable";

export function RepoItem({ repoName }: { readonly repoName: string }): JSX.Element {
  const { data } = useRepoStats(repoName);

  const { organizationName: orgName, repositoryName: displayRepoName } = useMemo(
    () => parseRepositoryName(repoName),
    [repoName],
  );

  const handleClick = useCallback(() => {
    logEventRepositorySelected(repoName);
  }, [repoName]);

  return (
    <Popover.Button
      as={Link}
      className="flex gap-4 border-b border-[rgb(230,236,234)] p-5 hover:bg-[rgb(247,250,249)]"
      relative="route"
      to={
        // RepoNames contain "/" characters which need to be escaped
        `../../${encodeURIComponent(repoName)}`
      }
      onClick={handleClick}
    >
      <div className="flex-none pt-1 text-[rgba(165,173,176,0.6)]">
        <RepoIcon />
      </div>
      <div className="flex flex-1 flex-col gap-y-2">
        <h6 className="text-sm font-semibold text-[rgb(82,100,96)]">
          {withWordBreakOpportunities(displayRepoName)}
        </h6>
        <span className="text-xs font-normal">
          <span className="flex gap-x-2">
            <span className="font-light text-black/50">{orgName}</span>
            <VerticalTextDivider />
            <LastModifiedMessage lastModified={data?.last_modified} />
          </span>
        </span>
        <MetricsTable metrics={data?.size_metrics} />
      </div>
    </Popover.Button>
  );
}

function LastModifiedMessage({
  lastModified,
}: {
  readonly lastModified: number | undefined;
}): JSX.Element {
  return (
    <>
      Last changed{" "}
      {lastModified === undefined ? (
        <span className="animate-pulse">...</span>
      ) : (
        <PrettyTimeSince className="font-medium" timestamp={lastModified} />
      )}
    </>
  );
}
