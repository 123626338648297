import { Dialog } from "@headlessui/react";
import type { PropsWithChildren } from "react";

export function SimpleDialog({
  isOpen,
  onClose,
  children,
}: PropsWithChildren<{
  readonly isOpen: boolean;
  readonly onClose: () => void;
}>): JSX.Element {
  return (
    <Dialog className="relative z-50" open={isOpen} onClose={onClose}>
      <div aria-hidden="true" className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-3/5 rounded border bg-white p-6 text-sm shadow-md">
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
