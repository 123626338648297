import { useEffect, useRef, type RefObject } from "react";

/**
 * A hook to simplify creating a search input element that performs incremental search
 * events (instead of watching the 'change' event). This improves the experience for the
 * user and provides better performance, especially if the handler performs async server
 * calls.
 *
 * NOTE: This only works on webkit browsers, it isn't supported in firefox.
 *
 * @param onSearch the handler for the 'search' event. The handler has to be stable!
 * @returns a ref that should be used on the input element
 */
export function useIncrementalSearch(
  onSearch: (query: string) => void,
): RefObject<HTMLInputElement> {
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { current } = searchInputRef;
    if (current === null) {
      return;
    }

    if (current.type !== "search") {
      throw new Error(
        `Unexpected input element type '${current.type}', expected 'search'`,
      );
    }

    const handler = () => {
      onSearch(current.value);
    };

    current.addEventListener("search", handler);
    current.toggleAttribute("incremental", true /* force */);

    return () => {
      current.toggleAttribute("incremental", false /* force */);
      current.removeEventListener("search", handler);
    };
  }, [onSearch]);

  return searchInputRef;
}
