import { useCallback } from "react";
import { useReactFlow } from "reactflow";
import { layoutGraph } from "../../utils/graph/layout";
import { ResetIcon } from "../icons/ResetIcon";

const GO_TO_NODE_ANIMATION_DURATION_MS = 1000;

export function RelayoutButton(): JSX.Element {
  const { setNodes, getNodes, getEdges, fitView } = useReactFlow();

  const handleClick = useCallback(() => {
    const layedOutNodes = layoutGraph(getNodes(), getEdges());
    setNodes(layedOutNodes);
    setTimeout(() => {
      fitView({ nodes: layedOutNodes, duration: GO_TO_NODE_ANIMATION_DURATION_MS });
    });
  }, [setNodes, getNodes, getEdges, fitView]);

  return (
    <button
      className="flex items-center justify-center gap-x-2 rounded-md bg-white px-4 py-3 text-sm font-medium text-[rgb(82,97,100)] shadow hover:bg-slate-50"
      type="reset"
      onClick={handleClick}
    >
      Layout graph
      <ResetIcon className="h-4 w-4 text-[rgb(165,173,176)]" />
    </button>
  );
}
