export function NavigationBackIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12L0 6L6 0L7.062 1.062L2.875 5.25H12V6.75H2.875L7.062 10.938L6 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
