import { useCallback } from "react";
import type { PolicyAction, PolicyActionsData } from "../../../api";
import { PolicyActionIcon } from "../../icons/PolicyActionIcon";
import { ActionEditor } from "./ActionEditor";
import { Section } from "./Section";

export function ActionsSection({
  onActionsChange,
  onError,
  className,
}: {
  readonly onActionsChange: (actionsData: PolicyActionsData | undefined) => void;
  readonly onError: (err: string) => void;
  readonly className?: string | undefined;
}): JSX.Element {
  const handleActionChanged = useCallback(
    (action: PolicyAction | undefined) => {
      if (action === undefined) {
        onActionsChange(undefined);
        return;
      }

      onActionsChange({
        actions: [action],
      });
    },
    [onActionsChange],
  );

  return (
    <Section
      className={className}
      headerIcon={<PolicyActionIcon className="h-6 w-6 text-[rgb(143,156,157)]" />}
      title="Actions"
    >
      <ActionEditor onActionChange={handleActionChanged} onError={onError} />
    </Section>
  );
}
