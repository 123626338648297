import { Disclosure } from "@headlessui/react";
import { useEffect, useMemo } from "react";
import { groupBy, prop } from "remeda";
import { usePrIssuesInfo, type LineageIssue } from "../../../api";
import { ChevronDown } from "../../icons/ChevronDown";
import { usePrNumberParam } from "../../usePrNumberParam";
import { useRequiredParam } from "../../useRequiredParam";
import { IssuesChipsCloud } from "./IssuesChipsCloud";
import { IssuesCounter } from "./IssuesCounter";

export function Issues({
  onLoaded,
}: {
  readonly onLoaded: () => void;
}): JSX.Element | null {
  const repoName = useRequiredParam("repoName");
  const prNumber = usePrNumberParam();

  const { data: issues, isError, isSuccess } = usePrIssuesInfo(repoName, prNumber);

  const { added, removed } = useMemo(
    () => (issues === undefined ? {} : groupBy.strict(issues, prop("change_status"))),
    [issues],
  );

  useEffect(() => {
    if (!isSuccess && !isError) {
      // Neither a success or an error means that we are still waiting for something...
      return;
    }

    onLoaded();
  }, [isError, isSuccess, onLoaded]);

  return (
    <>
      <NewIssues issues={added} />
      <FixedIssues issues={removed} />
    </>
  );
}

function NewIssues({
  issues,
}: {
  readonly issues: readonly [LineageIssue, ...LineageIssue[]] | undefined;
}): JSX.Element | null {
  if (issues === undefined || issues.length === 0) {
    // We only show the issues panel when there are issues to show.
    return null;
  }

  return (
    <section className="flex-none border-t border-[rgba(82,97,100,0.15)] px-5 py-4">
      <h4 className="mb-3 text-sm font-semibold text-[rgb(82,97,100)]">New issues</h4>
      <IssuesChipsCloud issues={issues} />
    </section>
  );
}

function FixedIssues({
  issues,
}: {
  readonly issues: readonly [LineageIssue, ...LineageIssue[]] | undefined;
}): JSX.Element | null {
  if (issues === undefined || issues.length === 0) {
    return null;
  }

  return (
    <Disclosure
      as="section"
      className="flex-none border-t border-[rgba(82,97,100,0.15)] px-5 py-4"
    >
      <Disclosure.Button className="flex w-full items-center gap-2 text-sm font-semibold text-[rgb(82,97,100)]">
        <span className="flex-1 text-left">Fixed issues</span>
        <IssuesCounter className="ui-open:invisible" severity="fixed">
          {issues.length}
        </IssuesCounter>
        <ChevronDown className="h-5 w-5 p-1 text-[rgb(143,153,157)] ui-open:rotate-180" />
      </Disclosure.Button>
      <Disclosure.Panel unmount className="mt-3">
        <IssuesChipsCloud fixed issues={issues} />
      </Disclosure.Panel>
    </Disclosure>
  );
}
