import { Listbox } from "@headlessui/react";
import type { PolicyActionType } from "../../../api";
import { ChevronDown } from "../../icons/ChevronDown";
import { EmailIcon } from "../../icons/EmailIcon";
import { PrReviewerIcon } from "../../icons/PrReviewerIcon";
import { SlackIcon } from "../../icons/SlackIcon";
import type { SupportedActionType } from "./ActionEditor";

export function ActionTypeSelector({
  actionTypes,
  selectedActionType,
  onActionTypeSelect,
}: {
  readonly actionTypes: readonly PolicyActionType[];
  readonly selectedActionType: PolicyActionType | undefined;
  readonly onActionTypeSelect: (actionType: SupportedActionType) => void;
}): JSX.Element {
  return (
    // We don't want to use undefined as a value for the ListBox because it marks the listbox as uncontrolled, otherwise
    // when the value is later changed (via prop) from undefined to any other value this causes a React warning.
    <Listbox value={selectedActionType ?? ("" as const)} onChange={onActionTypeSelect}>
      {({ open: isOpen, value: actionType }) => (
        <div
          className={`relative rounded-md ${
            isOpen ? "z-50 border border-[rgb(101,202,132)]" : ""
          }`}
        >
          <Listbox.Button className="flex w-full cursor-default items-center justify-between rounded-md bg-white pr-3 text-left shadow focus:outline-none">
            <ActionTypeOption actionType={actionType} />
            <ChevronDown
              className={`h-3 w-3 text-[rgb(165,173,176)] ${
                isOpen ? "rotate-180 transform" : ""
              }`}
            />
          </Listbox.Button>
          <ActionTypeSelectorOptions actionTypes={actionTypes} />
        </div>
      )}
    </Listbox>
  );
}

function ActionTypeSelectorOptions({
  actionTypes,
}: {
  readonly actionTypes: readonly PolicyActionType[];
}): JSX.Element {
  return (
    <Listbox.Options className="max-h-90 absolute mt-1 w-full overflow-auto rounded-md bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
      {actionTypes.map((actionType) => (
        <Listbox.Option
          key={actionType}
          className="cursor-default select-none"
          value={actionType}
        >
          <ActionTypeOption actionType={actionType} className="hover:bg-gray-50" />
        </Listbox.Option>
      ))}
    </Listbox.Options>
  );
}

function ActionTypeOption({
  actionType,
  className,
}: {
  readonly actionType: PolicyActionType | "";
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <div
      className={`flex flex-1 items-center gap-x-2 border-b border-b-[rgba(165,173,176,0.25)] p-3 ${
        className ?? ""
      }`}
    >
      {actionType === "" ? null : <ActionTypeIcon actionType={actionType} />}
      <span
        className={`text-sm leading-5 ${
          actionType === ""
            ? "font-normal text-[rgb(165,173,176)]"
            : "font-semibold text-[rgb(74,89,92)]"
        }`}
      >
        {titleForActionType(actionType)}
      </span>
    </div>
  );
}

function ActionTypeIcon({
  actionType,
}: {
  readonly actionType: PolicyActionType;
}): JSX.Element {
  switch (actionType) {
    case "send_email":
      return <EmailIcon className="h-5 w-5 text-[rgb(143,156,157)]" />;
    case "send_slack_message":
      return <SlackIcon className="h-5 w-5" />;
    case "add_pr_reviewer":
      return <PrReviewerIcon className="h-5 w-5" />;
  }
}

const titleForActionType = (actionType: PolicyActionType | ""): string => {
  switch (actionType) {
    case "":
      return "Select action";
    case "send_email":
      return "Send email";
    case "send_slack_message":
      return "Alert on Slack";
    case "add_pr_reviewer":
      return "Add PR reviewer";
  }
};
