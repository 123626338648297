import type { RepoStats } from "../../../api";
import { shortenNumber } from "../../../utils/numbersFormat";

export function MetricsTable({
  metrics,
}: {
  readonly metrics: RepoStats["size_metrics"] | undefined;
}): JSX.Element {
  return (
    <div className="grid grid-cols-3">
      <MetricValue val={metrics?.tables} />
      <MetricValue val={metrics?.columns} />
      <MetricValue val={metrics?.edges} />
      <MetricLabel label="Tables" />
      <MetricLabel label="Columns" />
      <MetricLabel label="Edges" />
    </div>
  );
}
function MetricValue({ val }: { readonly val: number | undefined }): JSX.Element {
  return (
    <span
      className={`text-base ${
        val === undefined
          ? "animate-pulse font-normal text-gray-400"
          : "font-semibold tabular-nums text-[rgb(82,100,96)] "
      }`}
    >
      {val === undefined ? "..." : shortenNumber(val)}
    </span>
  );
}
function MetricLabel({ label }: { readonly label: string }): JSX.Element {
  return <span className="text-xs font-normal text-[rgb(165,173,176)]">{label}</span>;
}
