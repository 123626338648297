import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { AdminNavigationItems } from "./AdminNavigationItems";
import { NavigationFooter } from "./NavigationFooter";
import { NavigationHeader } from "./NavigationHeader";
import { NavigationItems } from "./NavigationItems";

type NavState = {
  readonly isOpen: boolean;
};

const NavStateContext = createContext<NavState>({ isOpen: true });

/* eslint-disable-next-line @typescript-eslint/naming-convention --
 * React contexts are a bit unique when it comes to how the code and names look
 * like...
 */
export const useNavStateContext = (): NavState => useContext(NavStateContext);

export function Nav({ isAdmin }: { isAdmin?: boolean }): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  const contextValue = useMemo(() => ({ isOpen }), [isOpen]);

  const hamburgerClickHandler = useCallback(() => {
    setIsOpen((current) => !current);
  }, []);

  return (
    <nav
      className={`group/nav isolate z-50 flex h-full flex-none flex-col bg-[rgb(38,52,56)] transition-[width] duration-75 ease-out ${
        isOpen ? "w-52" : "w-16"
      }`}
    >
      <NavStateContext.Provider value={contextValue}>
        <NavigationHeader onHamburgerClick={hamburgerClickHandler} />
        {isAdmin ?? false ? <AdminNavigationItems /> : <NavigationItems />}
        <NavigationFooter />
      </NavStateContext.Provider>
    </nav>
  );
}
