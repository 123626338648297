import type { LineageIssueSeverity } from "../../../api";

export function issueColorsClassName(severity: LineageIssueSeverity | "fixed"): string {
  switch (severity) {
    case "critical":
    case "error":
      // red
      return "bg-[rgba(255,223,216,0.6)] text-[rgb(218,67,34)]";

    case "warning":
      // yellow
      return "bg-[rgba(255,235,216,0.6)] text-[rgb(206,122,61)]";

    case "info":
      // gray
      return "bg-[rgb(239,242,241)] text-[rgb(115,130,131)]";

    case "fixed":
      // green
      return "bg-[rgba(208,242,218,0.6)] text-[rgb(73,161,100)]";
  }
}
