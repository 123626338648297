import { find as remedaFind, pipe } from "remeda";
import { z } from "zod";
import { DEMO_MODE } from "../../utils/demo/demoMode";
import { EXAMPLE_PRS, EXAMPLE_SNAPSHOT } from "../../utils/demo/example";
import { EMPTY_BACKEND_STATE } from "../../utils/graph/type";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";
import { multiLineageFromDeprecated } from "./conversionsToNew";
import { convertAndEnrichBackendLineageState } from "./convertBackend";
import { fetchLineage } from "./fetchLineageState";
import type { MultiLineage } from "./schemas";

export function usePullRequest(repoName?: string, prId?: string) {
  const prNumber =
    prId === undefined ? undefined : z.coerce.number().positive().int().parse(prId);

  return useQueryRetriesServerErrors({
    queryKey: ["pullRequest", repoName, prNumber],
    queryFn: async () => getPullRequest(repoName, prNumber),
    staleTime: STALE_TIME_MS,
  });
}

const getPullRequest = async (
  repoName?: string,
  prNumber?: number,
): Promise<MultiLineage> => {
  const oldLineage = DEMO_MODE.isEnabled
    ? {
        diff: pipe(
          EXAMPLE_PRS,
          remedaFind(({ prId }) => prId === prNumber),
          ($) => convertAndEnrichBackendLineageState($ ?? EMPTY_BACKEND_STATE),
        ),
        snapshot: convertAndEnrichBackendLineageState(EXAMPLE_SNAPSHOT),
      }
    : await fetchLineage(repoName, prNumber);
  return multiLineageFromDeprecated(oldLineage);
};
