import { searchUsers } from "../../utils/search/users";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";
import type { TableOwner } from "./useTableOwners";

const FAKE_USERS: readonly TableOwner[] = [
  {
    firstName: "John",
    lastName: "Lennon",
    email: "john.lennon@beatles.com",
  },
  {
    firstName: "Ringo",
    lastName: "Starr",
    email: "ringo.starr@beatles.com",
  },
  {
    firstName: "Paul",
    lastName: "McCartney",
    email: "paul.mccartney@beatles.com",
  },
  {
    firstName: "George",
    lastName: "Harrison",
    email: "george.harrison@beatles.com",
  },
  {
    firstName: "Michael",
    lastName: "Jackson",
    email: "michael.jackson@neverland.com",
  },
];

export const useQueriedUsers = (query: string) =>
  useQueryRetriesServerErrors({
    queryKey: ["queriedUsers", query],
    queryFn: () => fakeImpl(query),
    staleTime: STALE_TIME_MS,
  });

// TODO: Implement the real backend API call. We can either send the query
// to the server and filter there, or we can fetch the whole list of available users and
// filter on the client. If we do client-side filtering remove the query parameter
// from the react-query, and implement a custom hook that uses that.
const fakeImpl = (query: string) => searchUsers(FAKE_USERS, query);
