import invariant from "tiny-invariant";
import { getTokenFromStorage, logout } from "../utils/auth/foundational";
import { apiRequest, type APIRequestOptions } from "./apiRequest";

export async function queryApiCall<T>(
  endpoint: APIRequestOptions["endpoint"],
  otherOptions?: Omit<APIRequestOptions, "authToken" | "endpoint" | "onUnauthorized">,
): Promise<T> {
  const { authToken } = getTokenFromStorage();
  invariant(authToken !== null, "Missing auth token for viewer");

  // It's not great that we are simply casting here, but as long as we don't enforce any
  // runtime validation on the server responses there's no point in making using this
  // harder by requiring everyone to cast stuff. If we ever add this validation we need
  // to remove this.
  return apiRequest({
    authToken,
    onUnauthorized: logout,
    endpoint,
    ...otherOptions,
  }) as Promise<T>;
}
