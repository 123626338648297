/* eslint-disable import/no-deprecated */
import { useCallback, useState } from "react";
import {
  type OnSelectionChangeFunc,
  useOnSelectionChange,
  useReactFlow,
} from "reactflow";
import { map, omit } from "remeda";
import { tableFromRootEntity } from "../../api";
import type { LineageTable } from "../../utils/graph/type";
import type { LineageRootData } from "../graph/LineageRootNode";

export function useSelectedTable(): LineageTable | undefined {
  const [selectedTable, setSelectedTable] = useState<LineageTable>();

  const onChange = useCallback<OnSelectionChangeFunc>(({ nodes: [firstNode] }) => {
    // TODO: Unfortunatly reactflow doesn't accept the node's data type as
    // a generic (like they do in other APIs, like `useReactFlow`) so we can't have
    // strong typing here and we have to cast :(. If they ever change this we should use
    // better typing here too.
    const entity = (firstNode?.data as LineageRootData | undefined)?.entity;
    setSelectedTable(entity === undefined ? undefined : tableFromRootEntity(entity));
  }, []);

  useOnSelectionChange({ onChange });

  return selectedTable;
}

export function useDeselectAllTables(): () => void {
  const { setNodes } = useReactFlow();

  return useCallback(() => {
    setNodes(map(omit(["selected"])));
  }, [setNodes]);
}
