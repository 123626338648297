import { Switch } from "@headlessui/react";
import type { ChangeStatus, LineageChildEntity } from "../../api";
import { ChevronDown } from "../icons/ChevronDown";
import { stopPropagation } from "../stopPropagation";
import { ColumnTypeIcon } from "./ColumnTypeIcon";

const TEXT_HEIGHT = 20; // Class: text-sm
const PADDING_HEIGHT = 12; // Class: py-3
const EXPAND_GAP_HEIGHT = 8; // Class: gap-2

export const columnContentHeight = (hasChildren: boolean): number =>
  TEXT_HEIGHT +
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  PADDING_HEIGHT * 2 +
  (hasChildren ? EXPAND_GAP_HEIGHT + TEXT_HEIGHT : 0);

export function ColumnMainContent({
  column: { colType, changeStatus, entityName, childEntities },
  isExpanded,
  onColumnExpandChange,
}: {
  readonly column: LineageChildEntity;
  readonly isExpanded: boolean;
  readonly onColumnExpandChange: (newValue: boolean) => void;
}): JSX.Element {
  return (
    <div className="flex w-full gap-x-4 py-3 pr-1 ">
      {colType !== undefined && (
        <ColumnTypeIcon
          className={`h-4 pt-1  ${iconClassNames(changeStatus)}`}
          type={colType}
        />
      )}
      <div
        className={`flex w-full flex-col gap-2 pr-3 ${
          colType === undefined && isExpanded ? "pl-8" : ""
        }`}
      >
        <span
          className={`truncate text-sm font-medium text-neutral-500  ${
            changeStatus === "removed" ? "line-through" : ""
          }`}
          title={entityName}
        >
          {entityName}
        </span>
        {childEntities.length <= 0 ? null : (
          <CollapseSubColumnsToggle
            columnsCount={childEntities.length}
            expanded={isExpanded}
            onChange={onColumnExpandChange}
          />
        )}
      </div>
    </div>
  );
}

function iconClassNames(changeStatus: ChangeStatus | undefined): string {
  if (changeStatus === undefined) {
    return "text-neutral-400";
  }

  switch (changeStatus) {
    case "new":
      return "text-green-400";

    case "removed":
      return "text-red-400";

    case "changed":
    case "renamed":
      return "text-orange-400";
  }
}

function CollapseSubColumnsToggle({
  expanded,
  columnsCount,
  onChange,
}: {
  readonly expanded: boolean;
  readonly columnsCount: number;
  readonly onChange: (newValue: boolean) => void;
}): JSX.Element {
  return (
    <Switch
      checked={expanded}
      className="flex w-full items-center ui-checked:rounded-b"
      onChange={onChange}
      onClick={stopPropagation}
    >
      <span className="flex flex-1 text-sm font-light text-neutral-400">
        {columnsCount} sub-column{columnsCount > 1 && "s"}
      </span>
      <ChevronDown className="flex w-[10px] flex-none text-neutral-400 ui-checked:rotate-180" />
    </Switch>
  );
}
