import { type LoaderFunction, redirect } from "react-router-dom";
import { DEMO_MODE } from "../utils/demo/demoMode";

const QUERY_PARAM_MOCK_SERVER_BACKDOOR = "__mockServer";

export const loader: LoaderFunction = ({ request }) => {
  const { searchParams } = new URL(request.url);

  const mockServer = searchParams.get(QUERY_PARAM_MOCK_SERVER_BACKDOOR);

  if (mockServer === "1") {
    DEMO_MODE.isEnabled = true;
  } else if (mockServer === "0" || mockServer === "") {
    DEMO_MODE.isEnabled = false;
  }

  return redirect("/");
};
