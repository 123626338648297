import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import { usePullRequest } from "../../api";
import { logEventGraphResetClicked } from "../../utils/analytics/eventLogging";
import { useGraphType } from "../../utils/graph/useGraphType";
import { ResetIcon } from "../icons/ResetIcon";
import { useGraphInitializer } from "./utils/useGraphInitializer";

export function ResetButton(): JSX.Element {
  const { repoName, prId } = useParams();

  return repoName !== undefined && prId !== undefined ? (
    <ResetToPullRequestDiffButton prId={prId} repoName={repoName} />
  ) : (
    <ResetToCleanSlateButton />
  );
}

function ResetToCleanSlateButton(): JSX.Element {
  const { setNodes, setEdges, setCenter } = useReactFlow();

  const handleClick = useCallback(() => {
    setNodes([]);
    setEdges([]);

    // This is not perfect because the actual "canvas" center is not at (0, 0), but
    // dependent on the screen dimensions (e.g. (50%, 50%), which is not supported by
    // `setCenter`) but that's just too complex for what it's worth, as we zoom to the
    // node after adding it anyway...
    setCenter(0, 0, { zoom: 1 });
  }, [setCenter, setEdges, setNodes]);

  return <ResetButtonInternal onClick={handleClick} />;
}

function ResetToPullRequestDiffButton({
  repoName,
  prId,
}: {
  readonly repoName: string;
  readonly prId: string;
}): JSX.Element {
  const { data } = usePullRequest(repoName, prId);

  const initializeGraph = useGraphInitializer(data?.diff.entities, data?.diff.edges);

  return (
    <ResetButtonInternal disabled={data === undefined} onClick={initializeGraph} />
  );
}

function ResetButtonInternal({
  onClick,
  disabled,
}: {
  readonly onClick: () => void;
  readonly disabled?: boolean;
}): JSX.Element {
  const graphType = useGraphType();

  const handleClick = useCallback(() => {
    onClick();
    logEventGraphResetClicked(graphType);
  }, [graphType, onClick]);

  return (
    <button
      className="flex items-center justify-center gap-x-2 rounded-md bg-white px-4 py-3 text-sm font-medium text-[rgb(82,97,100)] shadow hover:bg-slate-50"
      disabled={disabled}
      type="reset"
      onClick={handleClick}
    >
      Reset graph
      <ResetIcon className="h-4 w-4 text-[rgb(165,173,176)]" />
    </button>
  );
}
