import { Button } from "@mui/material";
import { ConfiguredAccounts, type ConfiguredAccountsProps } from "./ConfiguredAccounts";

export function MainConfigScreen({
  onAddSource,
  ...configuredAccountsProps
}: ConfiguredAccountsProps & {
  readonly onAddSource: () => void;
}): JSX.Element {
  return (
    <>
      <ConfiguredAccounts {...configuredAccountsProps} />
      <Button sx={{ mt: 1, mb: 1 }} variant="contained" onClick={onAddSource}>
        Add New Snowflake Account
      </Button>
    </>
  );
}
