import { useCallback } from "react";
import type { LineageSearchResult } from "../../utils/search/lineage";
import { SearchResultContent } from "../search/SearchResultContent";

export function SearchResultsItem({
  result,
  className,
  onClick,
}: {
  readonly result: LineageSearchResult;
  readonly className?: string | undefined;
  readonly onClick?: (searchResult: LineageSearchResult) => void;
}): JSX.Element {
  const handleClick = useCallback(() => {
    onClick?.(result);
  }, [result, onClick]);

  return (
    <button
      className={`flex w-full flex-col items-stretch gap-2 text-sm ${className ?? ""}`}
      type="button"
      onClick={onClick === undefined ? undefined : handleClick}
    >
      <SearchResultContent result={result} />
    </button>
  );
}
