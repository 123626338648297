export function TableIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2 18H17C17.55 18 18.021 17.8043 18.413 17.413C18.8043 17.021 19 16.55 19 16V2C19 1.45 18.8043 0.979 18.413 0.587C18.021 0.195667 17.55 0 17 0H2C1.45 0 0.979 0.195667 0.587 0.587C0.195667 0.979 0 1.45 0 2V16C0 16.55 0.195667 17.021 0.587 17.413C0.979 17.8043 1.45 18 2 18ZM17 5H2V2H17V5ZM2 7H5V16H2V7ZM17 7V16H7V7H17Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
