export function ChevronLeftIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 7 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 10L0 5L5 0L6.062 1.062L2.125 5L6.062 8.938L5 10Z" />
    </svg>
  );
}
