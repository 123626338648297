import Alert from "@mui/material/Alert";
import { Suspense, useEffect, useState } from "react";
import {
  Await,
  defer,
  type LoaderFunction,
  useAsyncError,
  useLoaderData,
} from "react-router-dom";
import { loaderApiCall } from "../../api/loaderApiCall";
import { FullscreenMessage } from "../../components/FullscreenMessage";
import { SettingsPage } from "../../components/settings/SettingsMainPanel";
import { SettingsProvider } from "../../components/settings/settingsProvider";
import { DEMO_MODE } from "../../utils/demo/demoMode";
import { EXAMPLE_SETTINGS } from "../../utils/demo/example";
import type { Settings } from "../../utils/settings/types";

type LoaderData = {
  readonly deferredSettings: Promise<Settings>;
};

async function getSettings() {
  if (DEMO_MODE.isEnabled) {
    return EXAMPLE_SETTINGS;
  }
  return (await loaderApiCall({
    endpoint: "/config/all/",
  })) as Settings;
}

export const loader: LoaderFunction = () =>
  defer({
    deferredSettings: getSettings(),
  });

export default function ConnectorsPageLoader(): JSX.Element {
  const { deferredSettings } = useLoaderData() as LoaderData;

  return (
    <Suspense fallback={<FullscreenMessage loading>Loading</FullscreenMessage>}>
      <Await errorElement={<SettingsLoadingError />} resolve={deferredSettings}>
        {(settings: Settings) => (
          <SettingsProvider settings={settings}>
            <SettingsPage />
          </SettingsProvider>
        )}
      </Await>
    </Suspense>
  );
}

function SettingsLoadingError(): JSX.Element {
  const error = useAsyncError();

  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (error instanceof Error) {
      // Handle errors thrown from frontend
      setMessage(error.message);
      return;
    }

    if (!(error instanceof Response)) {
      setMessage(String(error));
      return;
    }

    setMessage("Fetching error message...");
    const maybeFetch = async () => {
      const { detail } = (await error.json()) as {
        detail?: string;
      };
      setMessage(`${error.status}: ${detail ?? error.statusText}`);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    maybeFetch();
  }, [error]);

  return <Alert severity="error">{message ?? "Error"}</Alert>;
}
