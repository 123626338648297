import { datadogLogs } from "@datadog/browser-logs";
import { useCallback, useEffect, useState } from "react";
import type { PolicyAction, PolicyActionType } from "../../../api";
import { useSlackAuthRequest } from "../../../api";
import { useSlackAuth } from "../common/SlackAuth";
import { ActionTypeSelector } from "./ActionTypeSelector";
import { SendEmailActionEditor } from "./SendEmailActionEditor";
import { SlackAlertActionEditor } from "./SlackAlertActionEditor";

const ALL_SUPPORTED_ACTION_TYPES = [
  "send_email" as const,
  "send_slack_message" as const,
];
export type SupportedActionType = (typeof ALL_SUPPORTED_ACTION_TYPES)[number];

export function ActionEditor({
  onActionChange,
  onError,
}: {
  readonly onActionChange: (action: PolicyAction | undefined) => void;
  readonly onError: (err: string) => void;
}): JSX.Element {
  const [selectedActionType, setSelectedActionType] = useState<
    SupportedActionType | undefined
  >(undefined);

  const { data: slackAuthRequest, isLoading, isError } = useSlackAuthRequest();
  const actionTypes: readonly PolicyActionType[] =
    isLoading || isError
      ? ALL_SUPPORTED_ACTION_TYPES.filter((a) => a !== "send_slack_message")
      : ALL_SUPPORTED_ACTION_TYPES;

  const { authenticate, didCompleteAuth } = useSlackAuth(onError);

  useEffect(() => {
    if (isError) {
      datadogLogs.logger.error("Failed to load slack installation");
      onError("Server error - some actions might be unavailable");
    }
  }, [isError, onError]);

  const handleActionTypeSelect = useCallback(
    (actionType: SupportedActionType) => {
      if (
        actionType === "send_slack_message" &&
        slackAuthRequest?.installationUrl !== undefined &&
        !didCompleteAuth
      ) {
        authenticate(slackAuthRequest.installationUrl);
        return;
      }
      setSelectedActionType(actionType);
    },
    [slackAuthRequest?.installationUrl, didCompleteAuth, authenticate],
  );

  return (
    <div className="flex w-1/2 min-w-[200px] flex-col space-y-8">
      <div className="flex flex-col space-y-4">
        <span className="text-base font-semibold leading-4 text-[rgb(74,89,92)]">
          Take the following action
        </span>
        <ActionTypeSelector
          actionTypes={actionTypes}
          selectedActionType={selectedActionType}
          onActionTypeSelect={handleActionTypeSelect}
        />
      </div>
      {selectedActionType === undefined ? null : (
        <ActionEditorSelector
          actionType={selectedActionType}
          onActionChange={onActionChange}
          onError={onError}
        />
      )}
    </div>
  );
}

function ActionEditorSelector({
  actionType,
  onActionChange,
  onError,
}: {
  readonly actionType: SupportedActionType;
  readonly onActionChange: (action: PolicyAction | undefined) => void;
  readonly onError: (err: string) => void;
}): JSX.Element {
  switch (actionType) {
    case "send_email":
      return <SendEmailActionEditor onActionChange={onActionChange} />;
    case "send_slack_message":
      return (
        <SlackAlertActionEditor onActionChange={onActionChange} onError={onError} />
      );
  }
}
