import invariant from "tiny-invariant";
import type {
  PolicyRule,
  PolicyRulesData,
  PolicyRuleSubjectEntityType,
} from "../../../../api";
import { hasAtLeast } from "../../../../utils/array";
import { ColumnIcon } from "../../../icons/ColumnIcon";
import { PolicyTriggerIcon } from "../../../icons/PolicyTriggerIcon";
import { TableIcon } from "../../../icons/TableIcon";
import { TableauIcon } from "../../../icons/TableauIcon";

export function PolicyTriggersView({
  rulesData,
  className,
}: {
  readonly rulesData: PolicyRulesData;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <div
      className={`flex flex-col gap-y-2 rounded-md bg-gray-100 p-3 ${className ?? ""}`}
    >
      <PolicyTriggersHeadline />
      <PolicyTriggersBody rulesData={rulesData} />
    </div>
  );
}

function PolicyTriggersHeadline(): JSX.Element {
  return (
    <div className="flex items-center gap-x-2">
      <PolicyTriggerIcon className="h-5 w-5 text-[rgb(185,194,195)]" />
      <h1 className="text-sm font-semibold leading-5 text-[rgb(143,156,157)]">
        Triggers
      </h1>
    </div>
  );
}

function PolicyTriggersBody({
  rulesData,
}: {
  readonly rulesData: PolicyRulesData;
}): JSX.Element {
  const triggersOp = rulesData.conjunctiveEvaluation ? "And" : "Or";
  return (
    <ol className="flex flex-col gap-y-1 px-7">
      {rulesData.rules.map((rule, index) => (
        <li
          key={`${rule.rule_type}_${rule.subject_entity_id}`}
          className="flex flex-col gap-y-1"
        >
          <PolicyRuleView rule={rule} />
          {index === rulesData.rules.length - 1 ? null : (
            <span className="text-xs leading-5 text-[rgb(143,156,157)]">
              {triggersOp}
            </span>
          )}
        </li>
      ))}
    </ol>
  );
}

function PolicyRuleView({ rule }: { readonly rule: PolicyRule }): JSX.Element {
  return (
    <div className="flex items-center gap-x-1.5">
      <span className="text-sm font-medium leading-5 text-[rgb(74,89,92)]">
        {labelForRule(rule)}
      </span>
      <div className="flex items-center gap-x-1 rounded bg-white py-0.5 pl-0.5 pr-2 text-sm font-medium text-[rgb(82,97,100)] shadow">
        <PolicyRuleIcon entityType={rule.subject_entity_type} />
        {ruleSubjectName(rule)}
      </div>
    </div>
  );
}

function labelForRule(rule: PolicyRule): string {
  return rule.include_upstream_changes
    ? "On direct or indirect changes to"
    : "On direct changes to";
}

function PolicyRuleIcon({
  entityType,
}: {
  readonly entityType: PolicyRuleSubjectEntityType;
}): JSX.Element {
  switch (entityType) {
    case "column":
      return <ColumnIcon className="h-5 w-5 p-0.5" />;
    case "table":
      return <TableIcon className="h-5 w-5 p-0.5" />;
    case "dashboard":
      // TODO: Properly choose the icon for dashboard type, and extract choosing logic to one reusable place.
      return <TableauIcon className="h-5 w-5 p-0.5" />;
  }
}

function ruleSubjectName(rule: PolicyRule): string {
  const subjectEntityNameParts = rule.subject_entity_id.split(".").reverse();
  invariant(hasAtLeast(subjectEntityNameParts, 1), "Invalid subject entity id");

  if (
    rule.subject_entity_type === "dashboard" ||
    rule.subject_entity_type === "table"
  ) {
    const [entityName] = subjectEntityNameParts;
    return entityName.toUpperCase();
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  invariant(hasAtLeast(subjectEntityNameParts, 2), "Invalid subject entity id");
  const [columnName, tableName] = subjectEntityNameParts;
  return `${tableName.toUpperCase()}.${columnName}`;
}
