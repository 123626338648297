import { useCallback, useState } from "react";
import invariant from "tiny-invariant";
import type { UserInfo } from "../../../api";

const DELETED_USER_PLACEHOLDER = "Deleted User";

const DAYS_IN_WEEK = 7;

export const POLICIES_TABLE_COLUMNS_FLEX_RATIOS = {
  policyName: "flex-[5]",
  createdBy: "flex-[4]",
  modifiedBy: "flex-[4]",
  modifiedAt: "flex-[4]",
  actionButtons: "flex-[2]",
  status: "flex-[1]",
} as const;

// This function is used to convert a timestamp string to a nice display format. It will return "Today" if the timestamp
// is from today, "Yesterday" if the timestamp is from yesterday, the day of the week if the timestamp is from within
// the last 7 days, and the date (without time) otherwise.
export function timestampToDisplayFormat(timestampStr: string): string {
  const timestamp = new Date(timestampStr);
  const timestampMs = timestamp.getTime();
  invariant(
    !Number.isNaN(timestampMs),
    `Invalid timestamp string provided: ${timestampStr}`,
  );

  const now = new Date();

  if (timestampMs >= midnightTime(now, 1 /* offsetDays */)) {
    // Future date?!
    return timestamp.toDateString();
  }

  if (timestampMs >= midnightTime(now)) {
    // If the date is today, return "Today" instead of the date.
    return "Today";
  }

  if (timestampMs >= midnightTime(now, -1 /* offsetDays */)) {
    // If the date is yesterday, return "Yesterday" instead of the date.
    return "Yesterday";
  }

  if (timestampMs >= midnightTime(now, -DAYS_IN_WEEK /* offsetDays */)) {
    // If the date is within the last 7 days, return just the day of the week.
    return timestamp.toLocaleDateString("en-US", { weekday: "long" });
  }

  return timestamp.toDateString();
}

export function userNameForDisplay(user: UserInfo | undefined): string {
  if (user === undefined) {
    return DELETED_USER_PLACEHOLDER;
  }

  if (user.firstName !== undefined && user.lastName !== undefined) {
    return `${user.firstName} ${user.lastName}`;
  }

  return user.email;
}

const midnightTime = (now: Date, offsetDays = 0): number =>
  // These date arithmetics work, even if it means that the numbers don't make sense.
  new Date(now.getFullYear(), now.getMonth(), now.getDate() + offsetDays).getTime();

export function usePolicyActionRecipientsSelection(
  onChange: (recipients: readonly string[]) => void,
): {
  readonly value: readonly string[];
  readonly unselect: (recipient: string) => void;
  readonly select: (recipient: string) => void;
} {
  const [selectedRecipients, setSelectedRecipients] = useState<readonly string[]>([]);

  const handleRecipientUnselected = useCallback(
    (recipient: string) => {
      setSelectedRecipients((currentRecipients) => {
        invariant(
          currentRecipients.includes(recipient),
          "Recipient not listed in recipients",
        );

        const updatedRecipients = currentRecipients.filter((r) => r !== recipient);
        setSelectedRecipients(updatedRecipients);

        onChange(updatedRecipients);
        return updatedRecipients;
      });
    },
    [onChange],
  );

  const handleRecipientSelected = useCallback(
    (recipient: string) => {
      setSelectedRecipients((currentRecipients) => {
        const recipientLowerCase = recipient.toLowerCase();
        if (currentRecipients.includes(recipientLowerCase)) {
          return currentRecipients;
        }
        const updatedRecipients = [...currentRecipients, recipientLowerCase];
        setSelectedRecipients(updatedRecipients);
        onChange(updatedRecipients);
        return updatedRecipients;
      });
    },
    [onChange],
  );
  return {
    value: selectedRecipients,
    unselect: handleRecipientUnselected,
    select: handleRecipientSelected,
  };
}
