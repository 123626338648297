export function StructIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89583 7.16667C4.57639 7.16667 4.33333 7.02444 4.16667 6.74C4 6.455 4.00695 6.17361 4.1875 5.89583L7.29167 0.833333C7.45833 0.569445 7.69445 0.4375 8 0.4375C8.30556 0.4375 8.54167 0.569445 8.70833 0.833333L11.8125 5.89583C11.9931 6.17361 12 6.455 11.8333 6.74C11.6667 7.02444 11.4236 7.16667 11.1042 7.16667H4.89583ZM12.5833 16.3333C11.5417 16.3333 10.6561 15.9689 9.92667 15.24C9.19778 14.5106 8.83333 13.625 8.83333 12.5833C8.83333 11.5417 9.19778 10.6561 9.92667 9.92667C10.6561 9.19778 11.5417 8.83333 12.5833 8.83333C13.625 8.83333 14.5106 9.19778 15.24 9.92667C15.9689 10.6561 16.3333 11.5417 16.3333 12.5833C16.3333 13.625 15.9689 14.5106 15.24 15.24C14.5106 15.9689 13.625 16.3333 12.5833 16.3333ZM1.33333 15.9167C1.09722 15.9167 0.899445 15.8367 0.74 15.6767C0.58 15.5172 0.5 15.3194 0.5 15.0833V10.0833C0.5 9.84722 0.58 9.64917 0.74 9.48917C0.899445 9.32972 1.09722 9.25 1.33333 9.25H6.33333C6.56945 9.25 6.7675 9.32972 6.9275 9.48917C7.08695 9.64917 7.16667 9.84722 7.16667 10.0833V15.0833C7.16667 15.3194 7.08695 15.5172 6.9275 15.6767C6.7675 15.8367 6.56945 15.9167 6.33333 15.9167H1.33333ZM12.5833 14.6667C13.1667 14.6667 13.6597 14.4653 14.0625 14.0625C14.4653 13.6597 14.6667 13.1667 14.6667 12.5833C14.6667 12 14.4653 11.5069 14.0625 11.1042C13.6597 10.7014 13.1667 10.5 12.5833 10.5C12 10.5 11.5069 10.7014 11.1042 11.1042C10.7014 11.5069 10.5 12 10.5 12.5833C10.5 13.1667 10.7014 13.6597 11.1042 14.0625C11.5069 14.4653 12 14.6667 12.5833 14.6667ZM2.16667 14.25H5.5V10.9167H2.16667V14.25ZM6.375 5.5H9.625L8 2.875L6.375 5.5Z"
        fill="#A5ADB0"
        fillOpacity="0.8"
      />
    </svg>
  );
}
