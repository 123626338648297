/* eslint-disable import/no-deprecated */
import type { EdgeState, LineageColumnId, LineageTableId, LineageUriId } from "./type";

type RelationEndIdentifier = {
  readonly table: string;
  readonly col: string;
};

type RelationIdentifiers = {
  readonly id: string;
  readonly source: RelationEndIdentifier;
  readonly target: RelationEndIdentifier;
};

export function canonicalTableIdentifier(
  tableIdentifier: LineageTableId | LineageUriId,
): string {
  return (
    tableIdentifier.identifier ??
    (tableIdentifier.identifier_type === "db"
      ? canonicalTableId(tableIdentifier)
      : canonicalUriId(tableIdentifier))
  );
}

export function canonicalUriId({ uri, config }: LineageUriId): string {
  return uri?.[0] ?? config?.["url"] ?? "INVALID_URI";
}

export const canonicalTableId = ({
  db_name,
  db_schema,
  table_name,
}: LineageTableId): string => `${db_name}::${db_schema}/${table_name}`;

export const canonicalColumnId = ({
  col_name,
  table_identifier,
}: LineageColumnId): string =>
  `${canonicalTableIdentifier(table_identifier)}::${col_name}`;

export function canonicalRelationIds({
  sourceColumn,
  destinationColumn,
  relationType,
}: Pick<
  EdgeState,
  "destinationColumn" | "relationType" | "sourceColumn"
>): RelationIdentifiers {
  const source = canonicalTableIdentifier(sourceColumn.table_identifier);
  const { col_name: sourceCol } = sourceColumn;

  const target = canonicalTableIdentifier(destinationColumn.table_identifier);
  const { col_name: destinationCol } = destinationColumn;

  return {
    id: `${source}.${sourceCol}->${target}.${destinationCol}::${relationType}`,
    source: { table: source, col: sourceCol },
    target: { table: target, col: destinationCol },
  };
}
