import DOMPurify from "dompurify";
import { marked } from "marked";
import { useMemo } from "react";
import type { LineageIssue, LineageIssueSeverity } from "../../../api";
import type { NonEmptyArray } from "../../../utils/NonEmptyArray";
import { ErrorIcon } from "../../icons/ErrorIcon";
import { InfoIcon } from "../../icons/InfoIcon";
import { WarningIcon } from "../../icons/WarningIcon";
import { issueColorsClassName } from "./issueColorsClassName";
import { getIssueTypeDescription } from "./issue_types";

const MARKED_OPTIONS: marked.MarkedOptions = {
  // These are turned on by default but are deprecated (?!) so they need to be turned
  // off explicitly.
  // @see https://github.com/markedjs/marked/releases/tag/v5.0.0
  mangle: false,
  headerIds: false,
};

const SEVERITY_LABELS = {
  critical: "Critical",
  error: "Error",
  warning: "Warning",
  info: "Info",
} as const;

export function IssuesDialogBody({
  issues,
  fixed: isFixed,
}: {
  readonly issues: NonEmptyArray<LineageIssue>;
  readonly fixed: boolean;
}): JSX.Element {
  const [{ severity, issue_type }] = issues;

  return (
    <section className="flex flex-col overflow-hidden p-6 pt-5">
      <p className="mb-6 text-xs text-[rgb(143,153,157)] empty:invisible">
        {getIssueTypeDescription(issue_type)}
      </p>
      <ul className="flex flex-col overflow-hidden overflow-y-auto border-t border-[rgb(230,236,234)]">
        {issues.map(({ id, description }) => (
          <IssueItem
            key={id}
            description={
              // TODO: The server currently sends full stack traces for these errors so
              // we wrap them with a multi-line code block so they render as intended.
              // We should migrate the errors so that the server sends markdown capable
              // descriptions instead and then we can remove this.
              issue_type === "DbtCompilationError"
                ? `\`\`\`\n${description}\n\`\`\``
                : description
            }
            icon={<IssueIcon fixed={isFixed} severity={severity} />}
            label={isFixed ? "Fixed" : SEVERITY_LABELS[severity]}
            severity={severity}
          />
        ))}
      </ul>
    </section>
  );
}

function IssueItem({
  severity,
  description,
  icon,
  label,
}: {
  readonly severity: LineageIssueSeverity;
  readonly description: string;
  readonly icon: JSX.Element;
  readonly label: string;
}): JSX.Element {
  const html = useMemo(
    () => DOMPurify.sanitize(marked.parse(description, MARKED_OPTIONS)),
    [description],
  );

  return (
    <li className="flex gap-4 border-b border-[rgb(230,236,234)] py-5">
      {icon}
      <div className="flex flex-1 flex-col gap-2 overflow-hidden">
        <div
          className={`text-xs font-medium ${issueColorsClassName(severity)} bg-white`}
        >
          {label}
        </div>
        <div
          // eslint-disable-next-line react/no-danger -- This html is generated from markdown controlled by the server and then sanitized.
          dangerouslySetInnerHTML={{ __html: html }}
          // TODO: We override the default colors to make it look better for our current
          // where the whole description is wrapped as a code block. In the future when
          // the server sends us markdown descriptions that are fine tuned for the
          // specific issue we should revisit this to make sure it's what we actually
          // want. We can also continue overriding more properties if we want more
          // control over the output; but also go over the tailwind typography plugin
          // docs too, as it gives us a lot for free if we accept it's defaults...
          // @see https://tailwindcss.com/docs/typography-plugin
          className="prose prose-sm prose-neutral prose-code:text-neutral-500 prose-pre:bg-neutral-100"
        />
      </div>
    </li>
  );
}

function IssueIcon({
  severity,
  fixed: isFixed,
}: {
  readonly severity: LineageIssueSeverity;
  readonly fixed: boolean;
}): JSX.Element {
  return (
    <div
      className={`flex h-10 w-10 flex-none items-center justify-center rounded-full ${issueColorsClassName(
        isFixed ? "fixed" : severity,
      )}`}
    >
      <SeverityIcon severity={severity} />
    </div>
  );
}

function SeverityIcon({
  severity,
}: {
  readonly severity: LineageIssueSeverity;
}): JSX.Element {
  switch (severity) {
    case "critical":
    case "error":
      return <ErrorIcon className="h-5 w-5" />;

    case "warning":
      return <WarningIcon className="h-5 w-5" />;

    case "info":
      return <InfoIcon className="h-5 w-5" />;
  }
}
