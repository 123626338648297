import type { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "./authProvider";

type LocationState = {
  readonly from?: URL;
};

type CurrentLocation = {
  readonly state: LocationState | null;
};

function useOriginFromLocation() {
  const { state } = useLocation() as CurrentLocation;
  const { from } = state ?? {};
  return from;
}

export function OnlyNonLoggedIn({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const from = useOriginFromLocation();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return (
      <Navigate
        to={from === undefined ? "/" : `${from.pathname}${from.search}${from.hash}`}
      />
    );
  }

  return <>{children}</>;
}
