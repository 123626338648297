import type { ChangeStatus } from "../../api";
import type { FilterType } from "../../utils/search/lineageType";

export function FilterIcon({
  className,
  changeStatus,
  type,
}: {
  readonly changeStatus: ChangeStatus;
  readonly type: FilterType;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 30 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d={iconPath(type)}
        fill="rgb(165,173,176)"
        fillRule="evenodd"
      />
      <circle cx="21" cy="20" fill="white" r="9" />
      <rect
        fill={changeStatusSecondaryColor(changeStatus)}
        fillOpacity="0.6"
        height="14"
        rx="7"
        width="14"
        x="14"
        y="13"
      />
      <path
        clipRule="evenodd"
        d={changeStatusIconPath(changeStatus)}
        fill={changeStatusPrimaryColor(changeStatus)}
        fillRule="evenodd"
      />
    </svg>
  );
}

function iconPath(type: FilterType): string {
  switch (type) {
    case "column":
      return "M19 15V17H5V15H19ZM19 7V9H5V7H19ZM3.587 18.413C3.979 18.8043 4.45 19 5 19H19C19.55 19 20.021 18.8043 20.413 18.413C20.8043 18.021 21 17.55 21 17V7C21 6.45 20.8043 5.97933 20.413 5.588C20.021 5.196 19.55 5 19 5H5C4.45 5 3.979 5.196 3.587 5.588C3.19567 5.97933 3 6.45 3 7V17C3 17.55 3.19567 18.021 3.587 18.413ZM5 13V11H19V13H5Z";

    case "dashboard":
      return "M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM4 18H20V8H4V18Z";

    case "edge":
      return "M2.99995 17C2.99995 16.7167 3.09595 16.4793 3.28795 16.288C3.47928 16.096 3.71662 16 3.99995 16L8.99995 16L8.99995 8C8.99995 7.45 9.19595 6.97934 9.58795 6.588C9.97929 6.196 10.45 6 11 6L17.2 6L16.3 5.1C16.1166 4.91667 16.025 4.68334 16.025 4.4C16.025 4.11667 16.1166 3.88334 16.3 3.7C16.4833 3.51667 16.7166 3.425 17 3.425C17.2833 3.425 17.5166 3.51667 17.7 3.7L20.3 6.3C20.4 6.4 20.471 6.50834 20.513 6.625C20.5543 6.74167 20.575 6.86667 20.575 7C20.575 7.13334 20.5543 7.25834 20.513 7.375C20.471 7.49167 20.4 7.6 20.3 7.7L17.7 10.3C17.5166 10.4833 17.2833 10.575 17 10.575C16.7166 10.575 16.4833 10.4833 16.3 10.3C16.1166 10.1167 16.025 9.88334 16.025 9.6C16.025 9.31667 16.1166 9.08334 16.3 8.9L17.2 8L11 8L11 16C11 16.55 10.8043 17.021 10.413 17.413C10.021 17.8043 9.54995 18 8.99995 18L3.99995 18C3.71662 18 3.47928 17.904 3.28795 17.712C3.09595 17.5207 2.99995 17.2833 2.99995 17Z";

    case "table":
      return "M5 21H20C20.55 21 21.021 20.8043 21.413 20.413C21.8043 20.021 22 19.55 22 19V5C22 4.45 21.8043 3.979 21.413 3.587C21.021 3.19567 20.55 3 20 3H5C4.45 3 3.979 3.19567 3.587 3.587C3.19567 3.979 3 4.45 3 5V19C3 19.55 3.19567 20.021 3.587 20.413C3.979 20.8043 4.45 21 5 21ZM20 8H5V5H20V8ZM5 10H8V19H5V10ZM20 10V19H10V10H20Z";
  }
}

function changeStatusIconPath(changeStatus: ChangeStatus): string {
  switch (changeStatus) {
    case "new":
      // A plus (+) icon
      return "M21 16C20.4477 16 20 16.4477 20 17V19H18C17.4477 19 17 19.4477 17 20C17 20.5523 17.4477 21 18 21H20V23C20 23.5523 20.4477 24 21 24C21.5523 24 22 23.5523 22 23V21H24C24.5523 21 25 20.5523 25 20C25 19.4477 24.5523 19 24 19H22V17C22 16.4477 21.5523 16 21 16Z";

    case "removed":
      // A cross (x) icon
      return "M18.1716 17.1716C17.781 17.5621 17.781 18.1953 18.1716 18.5858L19.5858 20L18.1716 21.4142C17.781 21.8047 17.781 22.4379 18.1716 22.8284C18.5621 23.219 19.1953 23.219 19.5858 22.8284L21 21.4142L22.4142 22.8284C22.8047 23.219 23.4379 23.219 23.8284 22.8284C24.2189 22.4379 24.2189 21.8047 23.8284 21.4142L22.4142 20L23.8284 18.5858C24.2189 18.1953 24.2189 17.5621 23.8284 17.1716C23.4379 16.781 22.8047 16.781 22.4142 17.1716L21 18.5858L19.5858 17.1716C19.1953 16.781 18.5621 16.781 18.1716 17.1716Z";

    case "changed":
      // A pencil icon
      return "M23.735 18.929L22.071 17.265L22.6066 16.7295C22.7596 16.5765 22.9444 16.5 23.1612 16.5C23.378 16.5 23.5628 16.5765 23.7158 16.7295L24.2705 17.2842C24.4235 17.4372 24.5 17.622 24.5 17.8388C24.5 18.0556 24.4235 18.2404 24.2705 18.3934L23.735 18.929ZM17.5 23.5V21.8361L21.5355 17.8005L23.1995 19.4645L19.1639 23.5H17.5Z";

    case "renamed":
      // An underscored letter (A) icon
      return "M20.2347 16.554L20.2347 16.554L20.2343 16.5551L18.4845 21.2421C18.4844 21.2422 18.4844 21.2423 18.4844 21.2424C18.4106 21.4343 18.4194 21.6071 18.5215 21.7524C18.6234 21.8974 18.7831 21.9663 18.9895 21.9663C19.0984 21.9663 19.1977 21.931 19.2859 21.862C19.3717 21.7948 19.4347 21.7153 19.4731 21.6231L19.4732 21.6231L19.474 21.6207L19.8684 20.5184H22.1313L22.5257 21.6207L22.5257 21.6207L22.5266 21.6231C22.5651 21.7153 22.6281 21.7948 22.7139 21.862C22.8014 21.9305 22.8971 21.9663 22.9999 21.9663C23.2124 21.9663 23.376 21.8979 23.4783 21.7524C23.5804 21.6071 23.5891 21.4343 23.5154 21.2424C23.5154 21.2423 23.5153 21.2422 23.5153 21.2421L21.7655 16.5551L21.7655 16.5551L21.7651 16.554C21.7055 16.4051 21.6033 16.2813 21.4606 16.1825C21.318 16.0838 21.1676 16.033 21.0103 16.033C20.8466 16.033 20.6911 16.0834 20.5448 16.1822C20.3987 16.2808 20.2944 16.4047 20.2347 16.554ZM21.7832 19.533H20.2166L20.9999 17.3299L21.7832 19.533Z M17.2388 24.1135C17.398 24.2598 17.5904 24.333 17.8159 24.333H24.1841C24.4096 24.333 24.602 24.2598 24.7612 24.1135C24.9204 23.9672 25 23.7903 25 23.583C25 23.3757 24.9204 23.1989 24.7612 23.0525C24.602 22.9062 24.4096 22.833 24.1841 22.833H17.8159C17.5904 22.833 17.398 22.9062 17.2388 23.0525C17.0796 23.1989 17 23.3757 17 23.583C17 23.7903 17.0796 23.9672 17.2388 24.1135Z";
  }
}

function changeStatusPrimaryColor(changeStatus: ChangeStatus): string {
  switch (changeStatus) {
    case "new":
      // A kind of green
      return "rgb(101,202,132)";

    case "removed":
      // A kind of red
      return "rgb(246,111,81)";

    case "changed":
    case "renamed":
      // A kind of yellow
      return "rgb(251,185,60)";
  }
}

/**
 * Secondary color should generally be a brighter, more washed out, variation on the
 * primary color.
 */
function changeStatusSecondaryColor(changeStatus: ChangeStatus): string {
  switch (changeStatus) {
    case "new":
      return "rgb(208,242,218)";

    case "removed":
      return "rgb(255,223,216)";

    case "changed":
    case "renamed":
      return "rgb(255,235,216)";
  }
}
