import { DEMO_MODE } from "../../utils/demo/demoMode";
import { EXAMPLE_SNAPSHOT } from "../../utils/demo/example";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";
import { lineageFromDeprecated } from "./conversionsToNew";
import { convertAndEnrichBackendLineageState } from "./convertBackend";
import { fetchLineageSnapshot } from "./fetchLineageState";
import type { LineageState } from "./schemas";

export function useSnapshot() {
  return useQueryRetriesServerErrors({
    queryKey: ["currentSnapshot"],
    queryFn: getCurrentSnapshot,
    staleTime: STALE_TIME_MS,
  });
}

const getCurrentSnapshot = async (): Promise<LineageState> => {
  const oldSnapshot = DEMO_MODE.isEnabled
    ? convertAndEnrichBackendLineageState(EXAMPLE_SNAPSHOT)
    : await fetchLineageSnapshot();
  return lineageFromDeprecated(oldSnapshot);
};
