import { useCallback } from "react";
import type { ExpandedPrStatus } from "./LoadedVersionsPanel";

export function SearchResultsNavigationTab({
  prStatus,
  visibleSection,
  itemsCount,
  onClick,
}: {
  readonly prStatus: ExpandedPrStatus;
  readonly visibleSection: ExpandedPrStatus | undefined;
  readonly itemsCount: number;
  readonly onClick: (clickedStatus: ExpandedPrStatus) => void;
}): JSX.Element {
  const handleClick = useCallback(() => {
    onClick(prStatus);
  }, [onClick, prStatus]);

  return (
    <button
      className={`flex flex-1 justify-center gap-2 border-b-4 py-3 text-sm capitalize tabular-nums text-[rgb(143,153,157)] transition-all duration-100 hover:bg-[rgb(247,250,249)] ${
        prStatus === visibleSection
          ? "border-[rgb(101,202,132)]"
          : "border-[rgb(205,215,215)]"
      }`}
      type="button"
      onClick={handleClick}
    >
      <span
        className={
          prStatus === visibleSection
            ? "font-semibold text-[rgb(82,97,100)]"
            : "font-medium"
        }
      >
        {prStatus}
      </span>
      {itemsCount}
    </button>
  );
}
