/* eslint-disable import/no-deprecated */
import type { LineageTable } from "../../utils/graph/type";
import type { QueryData } from "../QueryData";
import { queryApiCall } from "../queryApiCall";
import { useOptimisticMutation } from "../useOptimisticMutation";
import type {
  SetTableDescriptionRating,
  TableDescriptionInfo,
  ViewerRating,
} from "./schemas";
import {
  tableDescriptionQueryKey,
  type useTableDescription,
} from "./useTableDescription";

export const useTableDescriptionViewerRatingMutation = (
  table: LineageTable,
  description: TableDescriptionInfo | undefined,
) =>
  useOptimisticMutation({
    context: { description, table },

    queryKeyFn: tableDescriptionQueryKey,

    mutationFn: putTableDescriptionRating,

    optimisticMutationFn: (
      { viewer_rating: _current, ...rest }: QueryData<typeof useTableDescription>,
      { viewerRating },
    ) => ({ ...rest, viewerRating }),
  });

async function putTableDescriptionRating(
  context: { readonly description: TableDescriptionInfo | undefined },
  variables: { readonly viewerRating: ViewerRating },
): Promise<void> {
  const { viewerRating } = variables;
  const descriptionId = context.description?.description_id;
  return queryApiCall("inspect/description/viewer-rating", {
    requestInit: { method: "PUT" },
    body: {
      description_id: descriptionId,
      viewer_rating: viewerRating,
    } as SetTableDescriptionRating,
  });
}
