import { DEMO_MODE } from "../../utils/demo/demoMode";
import { EXAMPLE_PRS } from "../../utils/demo/example";
import { EXAMPLE_PR_ITEM } from "../../utils/demo/prItem";
import type { BackendLineageState } from "../../utils/graph/type";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";
import type { PrItem } from "./usePrInfo";

/**
 * Get all pull requests for a given repo, including their metadata.
 * @see [repo_pr_list](../../../backend/backend/app/api/lineage_v1.py)
 */
export const useRepoPrList = (repoName: string) =>
  useQueryRetriesServerErrors({
    queryKey: ["prList", repoName],
    queryFn: async () => repoPrList(repoName),
    staleTime: STALE_TIME_MS,
  });

const repoPrList = async (repoName: string): Promise<readonly PrItem[]> =>
  DEMO_MODE.isEnabled
    ? EXAMPLE_PRS.map(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ({ prId: pr_number, prText: pr_text }: BackendLineageState): PrItem => ({
          ...EXAMPLE_PR_ITEM,
          // Copy these fields from the backend lineage state if they exist there...
          ...(pr_number !== undefined && { pr_number }),
          ...(pr_text !== undefined && { pr_text }),
        }),
      )
    : queryApiCall(`lineage/${repoName}/pulls`);
