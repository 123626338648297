/* eslint-disable @typescript-eslint/no-magic-numbers -- This is a numbers utility! */

export function shortenNumber(val: number): string {
  if (val < 1000) {
    return `${val}`;
  }

  const k =
    val >= 10_000
      ? Math.floor(val / 1000)
      : // *2 before floor and /2 after to round to the nearest 0.5K
        Math.floor((val / 1000) * 2) / 2;

  return `${k}K`;
}
