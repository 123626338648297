export function InformationIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 14.167C10.236 14.167 10.441 14.08 10.615 13.906C10.7883 13.7327 10.875 13.528 10.875 13.292V10.021C10.875 9.78499 10.7883 9.58366 10.615 9.41699C10.441 9.25033 10.236 9.16699 10 9.16699C9.764 9.16699 9.559 9.25366 9.385 9.42699C9.21167 9.60099 9.125 9.80599 9.125 10.042V13.312C9.125 13.5487 9.21167 13.7503 9.385 13.917C9.559 14.0837 9.764 14.167 10 14.167ZM10 7.47899C10.236 7.47899 10.441 7.39233 10.615 7.21899C10.7883 7.04499 10.875 6.83999 10.875 6.60399C10.875 6.36799 10.7883 6.16333 10.615 5.98999C10.441 5.81599 10.236 5.72899 10 5.72899C9.764 5.72899 9.559 5.81599 9.385 5.98999C9.21167 6.16333 9.125 6.36799 9.125 6.60399C9.125 6.83999 9.21167 7.04499 9.385 7.21899C9.559 7.39233 9.764 7.47899 10 7.47899ZM10 18.333C8.84733 18.333 7.764 18.1143 6.75 17.677C5.736 17.2397 4.854 16.646 4.104 15.896C3.354 15.146 2.76033 14.264 2.323 13.25C1.88567 12.236 1.667 11.1527 1.667 9.99999C1.667 8.84733 1.88567 7.76399 2.323 6.74999C2.76033 5.73599 3.354 4.85399 4.104 4.10399C4.854 3.35399 5.736 2.76033 6.75 2.32299C7.764 1.88566 8.84733 1.66699 10 1.66699C11.1527 1.66699 12.236 1.88566 13.25 2.32299C14.264 2.76033 15.146 3.35399 15.896 4.10399C16.646 4.85399 17.2397 5.73599 17.677 6.74999C18.1143 7.76399 18.333 8.84733 18.333 9.99999C18.333 11.1527 18.1143 12.236 17.677 13.25C17.2397 14.264 16.646 15.146 15.896 15.896C15.146 16.646 14.264 17.2397 13.25 17.677C12.236 18.1143 11.1527 18.333 10 18.333ZM10 16.583C11.8193 16.583 13.3713 15.9407 14.656 14.656C15.9407 13.3713 16.583 11.8193 16.583 9.99999C16.583 8.18066 15.9407 6.62866 14.656 5.34399C13.3713 4.05933 11.8193 3.41699 10 3.41699C8.18067 3.41699 6.62867 4.05933 5.344 5.34399C4.05933 6.62866 3.417 8.18066 3.417 9.99999C3.417 11.8193 4.05933 13.3713 5.344 14.656C6.62867 15.9407 8.18067 16.583 10 16.583Z" />
    </svg>
  );
}
