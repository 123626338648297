import { useCallback, useMemo, useRef } from "react";
import invariant from "tiny-invariant";
import type { PullRequestsSearchResult } from "../../../../utils/search/pullRequests";
import { NoResults } from "../../../search/NoResults";
import { useVisibleSection } from "../../../useVisibleSection";
import { isExpandedPrStatus, type ExpandedPrStatus } from "./LoadedVersionsPanel";
import { ResultsSection } from "./ResultsSection";
import { SearchResultsNavigationTab } from "./SearchResultsNavigationTab";

// IMPORTANT: This name HAS to be all lowercase, these are HTML rules!
export const DATA_ATTRIBUTE_NAME_PR_STATUS = "prstatus";

export function VersionsPanelBody({
  searchResults,
}: {
  readonly searchResults: readonly (readonly [
    prStatus: ExpandedPrStatus,
    items: readonly PullRequestsSearchResult[],
  ])[];
}): JSX.Element {
  const resultsRef = useRef<HTMLElement>(null);

  const renderedSectionIds = useMemo(
    () => searchResults.map(([prStatus]) => prStatus),
    [searchResults],
  );

  const visibleSection = useVisibleSection({
    containerRef: resultsRef,
    trackedSectionIds: renderedSectionIds,
    dataAttributeName: DATA_ATTRIBUTE_NAME_PR_STATUS,
    isSectionId: isExpandedPrStatus,
  });

  const handleNavClick = useCallback((prStatus: ExpandedPrStatus) => {
    const { current: resultsSectionElement } = resultsRef;
    invariant(resultsSectionElement !== null, "No results container");

    const activedElement = resultsSectionElement.querySelector(
      `[data-${DATA_ATTRIBUTE_NAME_PR_STATUS}="${prStatus}"]`,
    );

    invariant(activedElement !== null, `No element for '${prStatus}'`);
    invariant(
      activedElement instanceof HTMLElement,
      `Element for ${prStatus} is not an HTML element?!`,
    );

    const { offsetTop } = activedElement;
    resultsSectionElement.scrollTop = offsetTop;
  }, []);

  if (searchResults.length === 0) {
    return <NoResults>No pull requests found</NoResults>;
  }

  return (
    <>
      <nav className="relative z-50 flex shadow-[0_3px_5px_rgba(115,130,133,0.1)]">
        {searchResults.map(([prStatus, items]) => (
          <SearchResultsNavigationTab
            key={prStatus}
            itemsCount={items.length}
            prStatus={prStatus}
            visibleSection={visibleSection}
            onClick={handleNavClick}
          />
        ))}
      </nav>
      <section
        ref={resultsRef}
        className="relative flex-1 overflow-hidden overflow-y-auto scroll-smooth"
      >
        {searchResults.map(([prStatus, items]) => (
          <ResultsSection key={prStatus} items={items} prStatus={prStatus} />
        ))}
      </section>
    </>
  );
}
