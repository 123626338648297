/* eslint-disable @typescript-eslint/no-magic-numbers -- this is dummy data */

import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  type ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const OPTIONS: ChartOptions<"line"> = {
  plugins: {
    title: { display: true, text: "Weekly Average Cycle Time" },
    legend: { display: false },
  },
  scales: { y: { beginAtZero: true } },
  responsive: true,
};

const LABELS = [
  "4-12-2022",
  "11-12-2022",
  "18-12-2022",
  "25-12-2022",
  "1-1-2023",
  "1-8-2023",
  "1-15-2023",
  "1-22-2023",
];

export function ChartCycleTime(): JSX.Element {
  return (
    <Line
      data={{
        labels: LABELS,
        datasets: [
          {
            label: "Changes",
            data: [3.9, 3.8, 4.1, 2.8, 2.3, 2.9, 1.8, 1.1],
            borderColor: "rgb(255,134,108)",
            backgroundColor: "rgba(255,134,108,0.5)",
          },
        ],
      }}
      options={OPTIONS}
    />
  );
}
