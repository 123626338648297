import type { PrItem } from "../../api";

export const EXAMPLE_PR_ITEM: PrItem = {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  pr_number: 123,
  pr_text: "Fixing a bug",
  pr_status: "open",
  head_hash: "85168d0",
  base_hash: "942a293",
  is_draft: false,
  created_at: "2023-06-10T00:00:00Z",
  updated_at: "2023-06-20T00:00:00Z",
  created_by: { login: "dataeng", name: "Data Engineer" },
};
