/* eslint-disable @typescript-eslint/naming-convention -- Defined by the API */

import type { ChangeStatus, DependencyType, RelationType } from "../../api";

const ALL_SNIPPET_FLAVORS = ["SQL"] as const;
type SnippetFlavor = (typeof ALL_SNIPPET_FLAVORS)[number];

const ALL_STATE_TYPES = ["PR", "SNAPSHOT"] as const;
type StateType = (typeof ALL_STATE_TYPES)[number];

const ALL_LEGACY_RELATION_TYPE = ["create_view"] as const;
type LegacyRelationType = (typeof ALL_LEGACY_RELATION_TYPE)[number];

const ALL_LINEAGE_ENTITY_TYPES = ["column", "table"] as const;
/**
 * @deprecated this is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
type LineageEntityType = (typeof ALL_LINEAGE_ENTITY_TYPES)[number];

const ALL_EFFECT_TYPES = ["N/A"] as const;
type EffectType = (typeof ALL_EFFECT_TYPES)[number];

const ALL_LINEAGE_SOURCES = ["N/A"] as const;
type LineageSource = (typeof ALL_LINEAGE_SOURCES)[number];

const ALL_COLUMN_TYPES = [
  "",
  "date",
  "enum",
  "integer",
  "n/a",
  "varchar",
  "struct",
] as const;
/**
 * @deprecated this is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
type ColumnType = (typeof ALL_COLUMN_TYPES)[number];

export type LineageLocation = {
  readonly location: string;
};

export const EMPTY_BACKEND_STATE: BackendLineageState = {
  tables: [],
  relations: [],
  version: 1,
  stateType: "SNAPSHOT",
};

export type BackendLineageState = {
  readonly tables: readonly LineageTable[];
  readonly relations: readonly LineageRelationState[];

  readonly version: number;

  // TODO: We shouldn't use these anymore. it's separated to
  // different API calls.
  readonly stateType: StateType;

  readonly dependencies?: readonly DependencyRelationState[];
  readonly prId?: number;
  readonly prText?: string;
  readonly commitHash?: string;
};

/**
 * @deprecated LineageState is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type LineageState = {
  readonly tables: readonly LineageTable[];
  readonly edges: readonly EdgeState[];
};

/**
 * @deprecated LineageTableId is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type LineageTableId = {
  readonly db_name: string;
  readonly db_schema: string;
  readonly table_name: string;
  readonly identifier_type: "db";
  readonly identifier?: string;
};

/**
 * @deprecated LineageUriId is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type LineageUriId = {
  readonly identifier_type: "uri";
  readonly identifier?: string;
  readonly uri?: readonly string[];
  readonly type?: string;
  readonly config?: Readonly<Record<string, string>>;
  readonly extracted_root_folder?: string;
  readonly extracted_dir_name?: string;
  readonly extracted_file_name?: string;
  readonly extracted_file_type?: string;
};

/**
 * @deprecated LineageTable is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type LineageTable = HasChangeStatus & {
  readonly table_identifier: LineageTableId | LineageUriId;
  readonly columns: readonly LineageTableColumn[];
};

/**
 * @deprecated LineageTableColumn is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type LineageTableColumn = HasChangeStatus & {
  readonly col_name: string;
  readonly type: ColumnType;
};

type HasChangeStatus = {
  readonly changeStatus?: ChangeStatus | undefined;
};

/**
 * @deprecated EdgeState is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type EdgeState = {
  readonly sourceColumn: LineageColumn;
  readonly destinationColumn: LineageColumn;
  readonly relationType: RelationType;
  readonly changeStatus: ChangeStatus | undefined;
};

type DependencyRelationState = {
  readonly src_column: LineageColumn;
  readonly dst_column: LineageColumn;

  readonly dependencyType?: DependencyType;
  readonly targetType?: LineageEntityType;
  readonly changeStatus?: ChangeStatus;
};

type LineageRelationState = {
  // TODO: Why do we have a type prop on relations? isn't the type
  // defined at the table level?
  readonly src_column: LineageColumn;
  readonly dst_column: LineageColumn;

  readonly source_reference?: LineageSourceReference;
  readonly relation_type?: LegacyRelationType;
  readonly effect_type?: EffectType;
  readonly lineage_source?: LineageSource;

  /**
   * @example direct
   * @example substr(x, 3)
   * @example count(x)
   */
  readonly transformation?: string;

  readonly changeStatus?: ChangeStatus;
};

/**
 * @deprecated LineageColumnId is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type LineageColumnId = {
  readonly table_identifier: LineageTableId | LineageUriId;
  readonly col_name: string;
};

/**
 * @deprecated this is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type LineageColumn = LineageColumnId & { readonly type?: ColumnType };

type LineageSourceReference = {
  readonly snippet: string;
  readonly snippet_flavor: SnippetFlavor;
  readonly url: string;
};

export type LineageLocationError = {
  readonly error: string;
};

export type MultiLineageLocations = {
  readonly results: Readonly<Record<string, LineageLocation | LineageLocationError>>;
  readonly diff: LineageLocation | LineageLocationError;
};

/**
 * @deprecated MultiLineage is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
export type MultiLineage = {
  readonly snapshot: LineageState;
  readonly diff: LineageState;
};
