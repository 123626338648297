/* eslint-disable import/no-deprecated */
/**
 * This file converts between the models in lineage/schemas.ts to deprecated models for convenience. Please refrain from
 * using it in new code.
 * @see lineage/schemas.ts for more documentation.
 */

import type { LineageTable, LineageTableColumn } from "../../utils/graph/type";
import type { LineageChildEntity, LineageRootEntity } from "./schemas";

/**
 * @deprecated LineageTable is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see schemas.ts documentation.
 */
export function tableFromRootEntity({
  id,
  entityName,
  childEntities,
  ...entity
}: LineageRootEntity): LineageTable {
  switch (entity.type) {
    case "table":
      return {
        table_identifier: {
          identifier: id,
          identifier_type: "db",
          db_name: entity.dbName,
          db_schema: entity.dbSchema,
          table_name: entityName,
        },
        columns: columnsFromEntities(childEntities),
      };
    case "dashboard":
      return {
        table_identifier: {
          identifier: id,
          identifier_type: "db",
          db_name: entity.platform,
          db_schema: entity.projectName,
          table_name: entityName,
        },
        columns: columnsFromEntities(childEntities),
      };
    case "file":
      return {
        table_identifier: {
          identifier: id,
          identifier_type: "uri",
          uri: [entity.uri],
        },
        columns: columnsFromEntities(childEntities),
      };
  }
}

/**
 * @deprecated LineageTableColumn is an old model that should no longer be used. Please use models from lineage/schemas.ts
 * instead. For more see lineage/schemas.ts documentation.
 */
function columnsFromEntities(
  entities: readonly LineageChildEntity[],
): readonly LineageTableColumn[] {
  return entities.map(({ entityName, colType, changeStatus }) => ({
    col_name: entityName,
    type: colType === undefined ? "n/a" : colType,
    changeStatus,
  }));
}
