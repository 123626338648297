import { UserIcon } from "./icons/UserIcon";

export type FullName = {
  readonly first: string;
  readonly last: string;
};

export function Avatar({
  fullName,
}: {
  readonly fullName: FullName | undefined;
}): JSX.Element {
  return (
    <div className="flex h-10 w-10 flex-none items-center justify-center rounded-full bg-[rgb(73,88,95)] text-white/30">
      {fullName === undefined ? <UserIcon /> : initials(fullName)}
    </div>
  );
}

const initials = ({ first, last }: FullName): string =>
  first === "" && last === "" ? "?" : `${first[0] ?? ""}${last[0] ?? ""}`.toUpperCase();
