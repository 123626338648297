/* eslint-disable import/no-deprecated */
import type { LineageTable } from "../../utils/graph/type";
import type { QueryData } from "../QueryData";
import { queryApiCall } from "../queryApiCall";
import { useOptimisticMutation } from "../useOptimisticMutation";
import type { SetTableDescription, TableDescriptionInfo } from "./schemas";
import {
  tableDescriptionQueryKey,
  type useTableDescription,
} from "./useTableDescription";

export const useSetTableDescriptionMutation = (table: LineageTable) =>
  useOptimisticMutation({
    context: { table },

    queryKeyFn: tableDescriptionQueryKey,

    mutationFn: putTableDescription,

    optimisticMutationFn: (
      _current: QueryData<typeof useTableDescription>,
      { description },
    ) => ({
      // By ignoring the current value we make sure that we reset the viewer rating too
      description,
    }),
  });

async function putTableDescription(
  context: { readonly table: LineageTable },
  variables: { readonly description: string },
): Promise<TableDescriptionInfo> {
  const {
    table: {
      table_identifier: { identifier: tableIdentifier },
    },
  } = context;
  return queryApiCall("inspect/description", {
    requestInit: { method: "PUT" },
    body: {
      entity_id: tableIdentifier,
      description: variables.description,
    } as SetTableDescription,
  });
}
