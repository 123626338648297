/* eslint-disable max-lines */

import { Box, Button, CircularProgress, List, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useCallback, useState } from "react";
import type { FileRejection } from "react-dropzone";
import { ErrorCode } from "react-dropzone";
import { exceptionToErrorMessage } from "../../../utils/error";
import { useApiRequest } from "../useApiRequest";
import { StyledFileUploader } from "./StyledFileUploader";
import { BASE_URL_PREFIX } from "./consts";

type BQKeyAttributes = {
  /* eslint-disable @typescript-eslint/naming-convention -- Defined by the API */
  readonly auth_provider_x509_cert_url: string;
  readonly auth_uri: string;
  readonly client_email: string;
  readonly client_id: string;
  readonly client_x509_cert_url: string;
  readonly private_key: string;
  readonly private_key_id: string;
  readonly project_id: string;
  readonly token_uri: string;
  readonly type: string;
  /* eslint-enable @typescript-eslint/naming-convention */
};

function validKey(content: BQKeyAttributes) {
  const allProps: (string | null | undefined)[] = [
    content.client_email,
    content.project_id,
    content.private_key,
    content.private_key_id,
    content.client_id,
    content.auth_uri,
    content.token_uri,
    content.auth_provider_x509_cert_url,
    content.client_x509_cert_url,
    content.type,
  ];
  return ![undefined, "", null]
    .map((x) => allProps.indexOf(x) > 0)
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((previous, current) => previous || current, false);
}

// eslint-disable-next-line max-lines-per-function
export function KeyUploadScreen({
  onDone,
}: {
  readonly onDone: (canceled: boolean) => void;
}): JSX.Element {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState<string>();
  const [acceptedKeys, setAcceptedKeys] = useState<readonly BQKeyAttributes[]>([]);
  const [rejectedFileItems, setRejectedFileItems] = useState<readonly FileRejection[]>(
    [],
  );
  const api = useApiRequest();

  const saveKeys = useCallback(() => {
    setError(undefined);
    setInProgress(true);

    const toServer = async () => {
      try {
        await api(
          `${BASE_URL_PREFIX}/keys/`,
          acceptedKeys.map((key) => ({
            source_identifier: key.project_id,
            username: key.client_email,
            credentials: key,
          })),
          "PUT",
        );
        onDone(false);
      } catch (
        // eslint-disable-next-line unicorn/catch-error-name -- error is already used
        err
      ) {
        setError(await exceptionToErrorMessage(err));
      } finally {
        setInProgress(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    toServer();
  }, [acceptedKeys, onDone, api]);

  const handleDrop = async (acceptedFiles: readonly File[]) => {
    const parsed = await Promise.all(
      acceptedFiles.map(
        async (f) => [JSON.parse(await f.text()) as BQKeyAttributes, f] as const,
      ),
    );

    setAcceptedKeys(
      parsed.filter(([content]) => validKey(content)).map(([content]) => content),
    );

    setRejectedFileItems(
      parsed
        .filter(([content]) => !validKey(content))
        .map(([, f]) => ({
          errors: [
            {
              message: "Unexpected key file format",
              code: ErrorCode.FileInvalidType,
            },
          ],
          file: f,
        })),
    );
  };

  const dropHandler = useCallback((acceptedFiles: readonly File[]) => {
    // NOTICE: The original onDrop method is not async which means it
    // assumes we can accept and reject files without reading them.
    // Because we await the file's text before we make the decision, we
    // need to wrap the method and handle the rejected files externally in
    // our component
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleDrop(acceptedFiles);
  }, []);

  const resetClickHandler = useCallback(() => {
    onDone(true);
  }, [onDone]);

  return (
    <Box
      sx={{
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
        mt: 2,
      }}
    >
      <StyledFileUploader
        accept={{ "application/json": [] }}
        text="Drop your key .json file here, or click to select"
        onDrop={dropHandler}
      />
      {/* <aside> */}
      {acceptedKeys.length > 0 && (
        <Box mt={1}>
          <Typography variant="h6">Accepted keys</Typography>
          <List
            sx={{
              listStyleType: "disc",
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
              ml: 2,
            }}
          >
            {acceptedKeys.map((keyObject: BQKeyAttributes) => (
              <li key={keyObject.client_id}>{keyObject.client_email}</li>
            ))}
          </List>
        </Box>
      )}
      {rejectedFileItems.length > 0 && (
        <Box mt={1}>
          <Typography variant="h6">Rejected files</Typography>
          <ul>
            {rejectedFileItems.map(({ file, errors }) => (
              <li key={file.name}>
                {file.name} - {file.size} bytes
                <ul>
                  {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Box>
      )}
      {acceptedKeys.length === 0 &&
        rejectedFileItems.length === 0 &&
        "Please select at least one key file to continue"}
      {/* </aside> */}
      <Typography component="div" variant="h6">
        Instructions
      </Typography>
      You&apos;ll need to create a service account.
      <br />
      <List
        sx={{
          listStylePosition: "outside",
          listStyleType: "disc",
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
          ml: 2,
        }}
      >
        <li>
          Go into the{" "}
          <a
            href="https://console.cloud.google.com/iam-admin/serviceaccounts"
            rel="noreferrer"
            target="_blank"
          >
            IAM & Admin page
          </a>{" "}
          of your GCS account.
        </li>
        <li>
          Create a new service account. Choose a distinct name, account ID &
          description, and click &quot;CREATE AND CONTINUE&quot;
          {/* <Box sx={{ ml: -1.8 }}> */}
          {/*   <img src="/img/inst/bq_inst1.png" alt="instructions" /> */}
          {/* </Box> */}
        </li>
        <li>
          On the next step - assign &quot;BigQuery Metadata Viewer&quot; role to the
          account
        </li>
        <li>Finish the account creation</li>
        <li>
          In the account&apos;s &quot;KEYS&quot; tab, add a new JSON key. Save the file
          and upload it here.
        </li>
      </List>
      <Box sx={{ mt: 1 }}>
        <Button type="reset" variant="outlined" onClick={resetClickHandler}>
          Cancel
        </Button>
        <Button
          disabled={acceptedKeys.length === 0 || inProgress}
          sx={{ ml: 1 }}
          type="submit"
          variant="contained"
          onClick={saveKeys}
        >
          {inProgress ? (
            <CircularProgress
              color="inherit"
              size={24}
              sx={{
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
                marginX: 0.8,
              }}
            />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
      {error !== undefined && <Alert severity="error">{error}</Alert>}
    </Box>
  );
}
