import { Dialog } from "@headlessui/react";
import { SimpleDialog } from "../SimpleDialog";

export function FakeTableauInstructionsDialog({
  isOpen,
  onClose,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}): JSX.Element {
  return (
    <SimpleDialog isOpen={isOpen} onClose={onClose}>
      <Dialog.Title className="mb-4 flex text-lg font-medium">
        <img
          alt="Tableau's colorful circle made of plus signs icon"
          className="mr-2 h-8 w-8"
          src="/img/connectors/tableau.svg"
        />
        Instructions
      </Dialog.Title>
      <Dialog.Description>
        Perform the following steps in{" "}
        <a
          className="hover:text-blue-600 hover:underline"
          href="https://www.tableau.com"
          rel="noreferrer"
          target="_blank"
        >
          Tableau
        </a>
      </Dialog.Description>
      <ol className="m-8 flex list-disc flex-col leading-6">
        <li>
          Open dashboard{" "}
          <a
            className="rounded bg-slate-100 px-2 py-1 font-[monospace] text-xs font-bold hover:underline"
            href="https://www.tableau.com"
          >
            internal/data-pipeline-debug/Staging Customers
          </a>
          .
        </li>
        <li>Open the source query.</li>
        <li>
          Replace the query with:
          <br />
          <pre className="mt-2 rounded bg-slate-100 p-3 text-xs font-semibold leading-5">
            SELECT customer_id, first_name, final_name
            <br />
            FROM stg_customers;
          </pre>
        </li>
      </ol>
    </SimpleDialog>
  );
}
