import { EnvelopeIcon } from "../icons/EnvelopeIcon";

export function User({
  email,
  firstName,
  lastName,
}: {
  readonly email: string;
  readonly firstName?: string;
  readonly lastName?: string;
}): JSX.Element {
  // No need to memoize this, it's trivial...
  const fullName = `${firstName ?? ""} ${lastName ?? ""}`;

  return (
    <div className="flex flex-1 items-center justify-center gap-3 overflow-hidden">
      <Avatar
        fallback={<EnvelopeIcon className="h-4 w-4" />}
        firstName={firstName}
        lastName={lastName}
      />
      <div className="flex flex-1 flex-col overflow-hidden text-sm">
        <strong
          className="truncate font-semibold text-[rgb(82,97,100)]"
          title={fullName}
        >
          {fullName}
        </strong>
        <span className="truncate text-[rgb(143,153,157)]" title={email}>
          {email}
        </span>
      </div>
    </div>
  );
}

function Avatar({
  firstName,
  lastName,
  fallback,
}: {
  readonly firstName?: string | undefined;
  readonly lastName?: string | undefined;
  readonly fallback?: JSX.Element;
}): JSX.Element {
  return (
    <div
      className="flex h-10 w-10 flex-none items-center justify-center rounded-full bg-[rgba(117,108,220,0.2)] font-medium text-[rgba(117,108,220,0.8)]"
      title={`${firstName ?? ""}${lastName === undefined ? "" : ` ${lastName}`}`}
    >
      {firstName === undefined && lastName === undefined
        ? fallback
        : nameInitials(firstName, lastName)}
    </div>
  );
}

const nameInitials = (
  firstName: string | undefined,
  lastName: string | undefined,
): string => `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`;
