import type { LineageSearchResult } from "../../utils/search/lineage";
import { fullSearchResultPathTitle } from "../../utils/search/lineage";
import { BigQueryIcon } from "../icons/BigQueryIcon";
import { SearchIcon } from "../icons/SearchIcon";
import { TableauIcon } from "../icons/TableauIcon";

export function CompactLineageSearchResult({
  searchResult,
  onClick,
  className,
}: {
  readonly searchResult: LineageSearchResult;
  readonly onClick: () => void;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <button
      className={`flex items-center justify-between gap-x-4 truncate rounded-md border border-[rgba(82,97,100,0.2)] bg-white px-4 py-3 shadow ${
        className ?? ""
      }`}
      type="button"
      onClick={onClick}
    >
      <div className="flex items-center gap-x-1 truncate">
        <LineageSearchResultIcon searchResult={searchResult} />
        <span className="flex-1 truncate text-sm font-medium leading-5">
          {fullSearchResultPathTitle(searchResult)}
        </span>
      </div>
      <SearchIcon className="w-[14px] flex-none text-[rgb(185,194,195)]" />
    </button>
  );
}

function LineageSearchResultIcon({
  searchResult: {
    result: { type },
  },
}: {
  readonly searchResult: LineageSearchResult;
}): JSX.Element {
  // TODO: Implement a more complete logic for selecting the entity icon from type.
  return type === "dashboard" ? (
    <TableauIcon className="h-5 w-5 p-0.5" />
  ) : (
    <BigQueryIcon className="h-5 w-5 p-0.5" />
  );
}
