export function EditPolicyIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_174_2424)">
        <rect fill="white" height="32" rx="16" width="32" x="4" y="2" />
        <path d="M14.25 23.75H15.479L22.479 16.75L21.25 15.521L14.25 22.521V23.75ZM26.188 15.542L22.458 11.812L23.479 10.792C23.8263 10.4447 24.2397 10.271 24.719 10.271C25.1983 10.271 25.6113 10.4447 25.958 10.792L27.208 12.042C27.5413 12.3753 27.708 12.7883 27.708 13.281C27.708 13.7743 27.5413 14.1877 27.208 14.521L26.188 15.542ZM24.958 16.771L16.229 25.5H12.5V21.771L21.229 13.042L24.958 16.771ZM21.875 16.146L21.25 15.521L22.479 16.75L21.875 16.146Z" />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="40"
          id="filter0_dd_174_2424"
          width="40"
          x="0"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.45098 0 0 0 0 0.509804 0 0 0 0 0.521569 0 0 0 0.15 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_174_2424"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="effect2_dropShadow_174_2424"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.45098 0 0 0 0 0.509804 0 0 0 0 0.521569 0 0 0 0.15 0"
          />
          <feBlend
            in2="effect1_dropShadow_174_2424"
            mode="normal"
            result="effect2_dropShadow_174_2424"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_174_2424"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
