import type { PropsWithChildren } from "react";
import { useCallback } from "react";

const DISABLED_BUTTON_CLASS_NAME =
  "bg-[rgb(245,247,247)] text-[rgb(185,194,195)] font-medium shadow-sm";

export function Button({
  onClick,
  isDisabled,
  className,
  children,
}: PropsWithChildren<{
  readonly onClick: () => void;
  readonly isDisabled?: boolean | undefined;
  readonly className?: string | undefined;
}>): JSX.Element {
  const handleClick = useCallback(() => {
    if (isDisabled !== true) {
      onClick();
    }
  }, [isDisabled, onClick]);
  return (
    <button
      className={`flex items-center justify-center rounded-md px-3 py-2 text-sm leading-5 ${
        isDisabled === true ? DISABLED_BUTTON_CLASS_NAME : className ?? ""
      }`}
      type="button"
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

export function ActionButton({
  onClick,
  className,
  isDisabled,
  children,
}: PropsWithChildren<{
  readonly onClick: () => void;
  readonly isDisabled?: boolean | undefined;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <Button
      className={`bg-[rgb(116,85,206)] font-semibold text-white shadow hover:bg-[rgb(128,94,226)] hover:shadow-md active:bg-[rgb(107,76,188)] active:shadow-sm ${
        className ?? ""
      }`}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export function CancelButton({
  onClick,
  className,
  isDisabled,
  children,
}: PropsWithChildren<{
  readonly onClick: () => void;
  readonly isDisabled?: boolean | undefined;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <Button
      className={`bg-[rgb(222,228,228)] font-medium text-[rgb(74,89,92)] shadow-sm hover:bg-[rgb(228,233,233)] hover:shadow-md active:bg-[rgb(200,208,208)] active:shadow-sm ${
        className ?? ""
      }`}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
