/* eslint-disable max-lines, max-lines-per-function --
 * This is just an SVG file practically, it doesn't really matter...
 */

export function SearchEmptyStateGraphic({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 216 170"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#EFF2F1" height="170" rx="6" width="160" x="20" />
      <g clipPath="url(#clip0_5282_910)">
        <g filter="url(#filter0_f_5282_910)" opacity="0.2">
          <circle cx="167.5" cy="105.5" fill="#EFF2F1" r="29.5" />
        </g>
      </g>
      <g filter="url(#filter1_d_5282_910)">
        <rect fill="white" height="46" rx="4" width="144" x="28" y="116" />
      </g>
      <path
        d="M40 128L100 128"
        stroke="#C9CED0"
        strokeLinecap="round"
        strokeWidth="4"
      />
      <path d="M40 140H54" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M64 140H124" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M40 150H44" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M54 150H86" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <g filter="url(#filter2_d_5282_910)">
        <rect fill="white" height="46" rx="4" width="144" x="28" y="62" />
      </g>
      <path d="M40 74L100 74" stroke="#C9CED0" strokeLinecap="round" strokeWidth="4" />
      <path d="M40 86H54" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M64 86H124" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M40 96H44" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M54 96H86" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <g filter="url(#filter3_d_5282_910)">
        <rect fill="white" height="46" rx="4" width="144" x="28" y="8" />
      </g>
      <path d="M40 20L100 20" stroke="#C9CED0" strokeLinecap="round" strokeWidth="4" />
      <path d="M40 32H54" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M64 32H124" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M40 42H44" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <path d="M54 42H86" stroke="#E8E9ED" strokeLinecap="round" strokeWidth="4" />
      <g clipPath="url(#clip1_5282_910)">
        <rect fill="#EFF2F1" height="59" rx="29.5" width="59" x="138" y="81" />
        <g filter="url(#filter4_d_5282_910)">
          <rect fill="white" height="47" rx="8" width="61" x="117" y="58" />
        </g>
        <g filter="url(#filter5_d_5282_910)">
          <rect fill="white" height="47" rx="8" width="61" x="117" y="117" />
        </g>
      </g>
      <path
        d="M186 129L203 146"
        stroke="#A5ADB0"
        strokeLinecap="round"
        strokeWidth="4"
      />
      <circle cx="167.5" cy="110.5" r="25.5" stroke="#C9CED0" strokeWidth="8" />
      <path
        d="M195 138L212 155"
        stroke="#C9CED0"
        strokeLinecap="round"
        strokeWidth="8"
      />
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="87"
          id="filter0_f_5282_910"
          width="87"
          x="124"
          y="62"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            mode="normal"
            result="shape"
          />
          <feGaussianBlur result="effect1_foregroundBlur_5282_910" stdDeviation="7" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="50"
          id="filter1_d_5282_910"
          width="148"
          x="26"
          y="116"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="erode"
            radius="1"
            result="effect1_dropShadow_5282_910"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_5282_910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5282_910"
            mode="normal"
            result="shape"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="50"
          id="filter2_d_5282_910"
          width="148"
          x="26"
          y="62"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="erode"
            radius="1"
            result="effect1_dropShadow_5282_910"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_5282_910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5282_910"
            mode="normal"
            result="shape"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="50"
          id="filter3_d_5282_910"
          width="148"
          x="26"
          y="8"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="erode"
            radius="1"
            result="effect1_dropShadow_5282_910"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_5282_910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5282_910"
            mode="normal"
            result="shape"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="51"
          id="filter4_d_5282_910"
          width="65"
          x="115"
          y="58"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="erode"
            radius="1"
            result="effect1_dropShadow_5282_910"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_5282_910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5282_910"
            mode="normal"
            result="shape"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="51"
          id="filter5_d_5282_910"
          width="65"
          x="115"
          y="117"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="erode"
            radius="1"
            result="effect1_dropShadow_5282_910"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_5282_910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5282_910"
            mode="normal"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_5282_910">
          <rect fill="white" height="168" transform="translate(20 2)" width="160" />
        </clipPath>
        <clipPath id="clip1_5282_910">
          <rect fill="white" height="59" rx="29.5" width="59" x="138" y="81" />
        </clipPath>
      </defs>
    </svg>
  );
}
