/* eslint-disable @typescript-eslint/naming-convention -- */
/* eslint-disable @typescript-eslint/no-magic-numbers -- */

import { DEMO_MODE } from "../../utils/demo/demoMode";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";

type Metric = "columns" | "edges" | "tables";

export type RepoStats = {
  readonly size_metrics: Readonly<Record<Metric, number>>;
  readonly last_modified: number;
};

const EXAMPLE: RepoStats = {
  size_metrics: { columns: 37, edges: 36, tables: 8 },
  last_modified: Date.now() - 1_670_510_201,
};

export const useRepoStats = (repoName: string) =>
  useQueryRetriesServerErrors({
    queryKey: ["repoStats", repoName],
    queryFn: async () => getRepoStats(repoName),
    staleTime: STALE_TIME_MS,
  });

const getRepoStats = async (repoName: string): Promise<RepoStats> =>
  DEMO_MODE.isEnabled ? EXAMPLE : queryApiCall(`lineage/${repoName}/stats`);
