// eslint-disable-next-line import/no-unassigned-import, import/order -- Required for reactflow to work!
import "reactflow/dist/style.css";

import { type PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Background,
  BackgroundVariant,
  Controls,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { LINEAGE_NODE_TYPE, LineageRootNode } from "../graph/LineageRootNode";
import { InspectorPanel } from "../inspector/InspectorPanel";
import { AddNodeDialogController } from "./AddNodeDialogController";
import { TopGradient } from "./TopGradient";
import { TopToolbar } from "./TopToolbar";

// I don't know what unit this number is in, so it was picked via trial and error by
// checking how "far back" I can go with the zoom. I picked a spot where after it nodes
// would be too small to read.
const MIN_ZOOM = 0.0001;

const NODE_TYPES = {
  [LINEAGE_NODE_TYPE]: LineageRootNode,
};

export function DiscoveryGraph({
  leftPanel,
  onInitialNodesRender,
  toolbarShown = "nonempty",
  children,
}: PropsWithChildren<{
  readonly onInitialNodesRender: () => void;
  readonly toolbarShown?: "always" | "nonempty";
  readonly leftPanel?: JSX.Element;
}>): JSX.Element {
  return (
    <ReactFlowProvider>
      <div className="flex h-full flex-1">
        {leftPanel}
        <DiscoveryGraphInternal
          toolbarShown={toolbarShown}
          onInitialNodesRender={onInitialNodesRender}
        >
          {children}
        </DiscoveryGraphInternal>
        <InspectorPanel />
      </div>
    </ReactFlowProvider>
  );
}

function DiscoveryGraphInternal({
  onInitialNodesRender,
  toolbarShown,
  children,
}: PropsWithChildren<{
  readonly toolbarShown: "always" | "nonempty";
  readonly onInitialNodesRender: () => void;
}>): JSX.Element {
  const [nodes, , onNodesChange] = useNodesState([]);
  const [edges, , onEdgesChange] = useEdgesState([]);

  const { prId } = useParams();

  const isEmptyNodes = nodes.length === 0;

  useEffect(() => {
    if (isEmptyNodes) {
      return;
    }

    onInitialNodesRender();
  }, [isEmptyNodes, onInitialNodesRender]);

  return (
    <ReactFlow
      fitView
      className="flex h-full w-full flex-row bg-[rgb(229,233,231)]"
      edges={edges}
      minZoom={MIN_ZOOM}
      nodeTypes={NODE_TYPES}
      nodes={nodes}
      nodesDraggable={false}
      proOptions={{ hideAttribution: true }}
      onEdgesChange={onEdgesChange}
      onNodesChange={onNodesChange}
    >
      {nodes.length > 0 && (
        <>
          <Background
            color="rgba(0,0,0,0.1)"
            gap={20}
            size={3}
            variant={BackgroundVariant.Dots}
          />
          <Controls position="bottom-right" showInteractive={false} />
        </>
      )}
      {(toolbarShown === "always" || nodes.length > 0) && (
        <>
          <TopGradient />
          <TopToolbar />
        </>
      )}
      <AddNodeDialogController forceOpen={prId === undefined && isEmptyNodes} />
      {children}
    </ReactFlow>
  );
}
