import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { APIQueryClientProvider } from "./api";
import { AuthProvider } from "./components/login/authProvider";
import { THEME } from "./theme";

export function Root(): JSX.Element {
  return (
    <ThemeProvider theme={THEME}>
      <APIQueryClientProvider>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </APIQueryClientProvider>
    </ThemeProvider>
  );
}
