/* eslint-disable import/no-deprecated */
import { addProp, concat, map, pipe } from "remeda";
import { canonicalTableId, canonicalUriId } from "../../utils/graph/canonical";
import type {
  BackendLineageState,
  EdgeState,
  LineageColumn,
  LineageState,
  LineageTable,
  LineageTableId,
  LineageUriId,
} from "../../utils/graph/type";
import type { BackendLineageStateV0 } from "../../utils/graph/typeV0";
import type { RelationType } from "./schemas";

export const convertAndEnrichBackendLineageState = (
  lineage: BackendLineageState | BackendLineageStateV0,
): LineageState =>
  filterEmptyTablesHack(enrichLineage(convertFromBackendLineageState(lineage)));

const convertFromBackendLineageState = (
  lineage: BackendLineageState | BackendLineageStateV0,
): LineageState =>
  isBackendLineageStateV1(lineage)
    ? {
        tables: lineage.tables,
        edges: mergeToEdges(lineage.relations, lineage.dependencies),
      }
    : convertFromVersion0ToVersion1(lineage);

const isBackendLineageStateV1 = (
  lineage: BackendLineageState | BackendLineageStateV0,
): lineage is BackendLineageState => "version" in lineage;

const enrichLineage = (state: LineageState): LineageState => ({
  tables: enrichTablesIdentifier(state.tables),
  edges: enrichEdgesIdentifier(state.edges),
});

function enrichTablesIdentifier(tables: readonly LineageTable[]): LineageTable[] {
  return tables.map(({ table_identifier, ...rest }) => ({
    table_identifier: addTableIdentifier(table_identifier),
    ...rest,
  }));
}

function enrichEdgesIdentifier(edges: readonly EdgeState[]): EdgeState[] {
  return edges.map(({ sourceColumn, destinationColumn, ...rest }) => ({
    sourceColumn: enrichColumn(sourceColumn),
    destinationColumn: enrichColumn(destinationColumn),
    ...rest,
  }));
}

const enrichColumn = ({ table_identifier, ...col }: LineageColumn): LineageColumn => ({
  ...col,
  table_identifier: addTableIdentifier(table_identifier),
});

function addTableIdentifier(
  item: LineageTableId | LineageUriId,
): LineageTableId | LineageUriId {
  return {
    ...item,
    identifier:
      item.identifier_type === "db" ? canonicalTableId(item) : canonicalUriId(item),
  };
}

const filterEmptyTablesHack = (state: LineageState): LineageState => ({
  tables: state.tables.filter((table) => table.table_identifier.identifier !== "::/"),
  edges: state.edges,
});

function convertFromVersion0ToVersion1({
  tables,
  relations,
  dependencies,
}: BackendLineageStateV0): LineageState {
  const newTables = tables.map(({ db_schema, db_name, table_name, ...rest }) => ({
    ...rest,
    table_identifier: {
      db_name,
      db_schema,
      table_name,
      identifier_type: "db" as const,
    },
  }));
  const newRelations = relations.map(
    ({
      src_column: {
        db_name: srcDb,
        db_schema: srcSchema,
        table_name: srcTable,
        ...srcRest
      },
      dst_column: {
        db_name: dstDb,
        db_schema: dstSchema,
        table_name: dstTable,
        ...dstRest
      },
      ...rest
    }) => ({
      ...rest,
      src_column: {
        ...srcRest,
        table_identifier: {
          db_name: srcDb,
          db_schema: srcSchema,
          table_name: srcTable,
          identifier_type: "db" as const,
        },
      },
      dst_column: {
        ...dstRest,
        table_identifier: {
          db_name: dstDb,
          db_schema: dstSchema,
          table_name: dstTable,
          identifier_type: "db" as const,
        },
      },
    }),
  );
  const newDependencies = dependencies?.map(
    ({
      src_column: {
        db_name: srcDb,
        db_schema: srcSchema,
        table_name: srcTable,
        ...srcRest
      },
      dst_column: {
        db_name: dstDb,
        db_schema: dstSchema,
        table_name: dstTable,
        ...dstRest
      },
      ...rest
    }) => ({
      ...rest,
      src_column: {
        ...srcRest,
        table_identifier: {
          db_name: srcDb,
          db_schema: srcSchema,
          table_name: srcTable,
          identifier_type: "db" as const,
        },
      },
      dst_column: {
        ...dstRest,
        table_identifier: {
          db_name: dstDb,
          db_schema: dstSchema,
          table_name: dstTable,
          identifier_type: "db" as const,
        },
      },
    }),
  );
  return { tables: newTables, edges: mergeToEdges(newRelations, newDependencies) };
}

const mergeToEdges = (
  relations: BackendLineageState["relations"],
  dependencies: BackendLineageState["dependencies"] = [],
): LineageState["edges"] =>
  pipe(
    relations,
    map(
      addProp(
        // We will later rename this prop to "relationType", but to make the pipe easier
        // to read we fake the value we want to pass through as a "dependencyType"
        // first...
        "dependencyType",
        "relation" as RelationType,
      ),
    ),
    concat(dependencies),
    map(
      // Remove unneeded props + rename (snake_case -> camelCase) + coalesce missing
      // values
      ({
        src_column: sourceColumn,
        dst_column: destinationColumn,
        dependencyType: relationType = "<<MISSING>>" as RelationType,
        changeStatus,
      }) => ({ sourceColumn, destinationColumn, relationType, changeStatus }),
    ),
  );
