export function LogoutIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.625 11.667C10.4583 11.5003 10.375 11.2953 10.375 11.052C10.375 10.8093 10.4583 10.6047 10.625 10.438L12.188 8.875H6.396C6.16 8.875 5.955 8.78833 5.781 8.615C5.60767 8.441 5.521 8.236 5.521 8C5.521 7.764 5.60767 7.559 5.781 7.385C5.955 7.21167 6.16 7.125 6.396 7.125H12.125L10.604 5.583C10.4513 5.41633 10.375 5.215 10.375 4.979C10.375 4.743 10.4583 4.54167 10.625 4.375C10.7917 4.20833 10.9967 4.125 11.24 4.125C11.4827 4.125 11.6873 4.20833 11.854 4.375L14.875 7.396C14.9723 7.49333 15.0417 7.594 15.083 7.698C15.125 7.802 15.146 7.90967 15.146 8.021C15.146 8.13233 15.125 8.24 15.083 8.344C15.0417 8.448 14.9723 8.54867 14.875 8.646L11.833 11.688C11.6663 11.8547 11.4687 11.938 11.24 11.938C11.0107 11.938 10.8057 11.8477 10.625 11.667ZM2.25 15.5C1.764 15.5 1.35067 15.33 1.01 14.99C0.67 14.6493 0.5 14.236 0.5 13.75V2.25C0.5 1.764 0.67 1.35067 1.01 1.01C1.35067 0.67 1.764 0.5 2.25 0.5H7.104C7.34 0.5 7.545 0.586667 7.719 0.76C7.89233 0.934 7.979 1.139 7.979 1.375C7.979 1.611 7.89233 1.816 7.719 1.99C7.545 2.16333 7.34 2.25 7.104 2.25H2.25V13.75H7.104C7.34 13.75 7.545 13.8367 7.719 14.01C7.89233 14.184 7.979 14.389 7.979 14.625C7.979 14.861 7.89233 15.066 7.719 15.24C7.545 15.4133 7.34 15.5 7.104 15.5H2.25Z" />
    </svg>
  );
}
