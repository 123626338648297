export function CodeIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 16 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.958 9.375C11.7913 9.54167 11.59 9.62167 11.354 9.615C11.118 9.60767 10.9097 9.52067 10.729 9.354C10.5623 9.17333 10.479 8.965 10.479 8.729C10.479 8.493 10.5623 8.29167 10.729 8.125L13.854 5L10.708 1.833C10.5553 1.65233 10.479 1.44767 10.479 1.219C10.479 0.989667 10.5623 0.791667 10.729 0.625C10.8957 0.458333 11.1007 0.375 11.344 0.375C11.5867 0.375 11.7913 0.458333 11.958 0.625L15.708 4.375C15.8747 4.54167 15.958 4.75 15.958 5C15.958 5.25 15.8747 5.45833 15.708 5.625L11.958 9.375ZM4.042 9.375L0.312 5.625C0.145334 5.45833 0.0620003 5.25 0.0620003 5C0.0620003 4.75 0.145334 4.54167 0.312 4.375L4.062 0.625C4.22867 0.458333 4.43367 0.375 4.677 0.375C4.92033 0.375 5.12533 0.458333 5.292 0.625C5.45867 0.791667 5.542 0.996666 5.542 1.24C5.542 1.48267 5.45867 1.68733 5.292 1.854L2.146 5L5.312 8.146C5.47867 8.31267 5.55867 8.51733 5.552 8.76C5.54533 9.00333 5.45867 9.20833 5.292 9.375C5.11133 9.55567 4.903 9.646 4.667 9.646C4.43033 9.646 4.222 9.55567 4.042 9.375Z" />
    </svg>
  );
}
