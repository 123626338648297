export function BooleanIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 18 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 10C3.61133 10 2.43067 9.514 1.458 8.542C0.486 7.56933 0 6.38867 0 5C0 3.61133 0.486 2.43067 1.458 1.458C2.43067 0.486 3.61133 0 5 0H13C14.3887 0 15.5693 0.486 16.542 1.458C17.514 2.43067 18 3.61133 18 5C18 6.38867 17.514 7.56933 16.542 8.542C15.5693 9.514 14.3887 10 13 10H5ZM5 8.5H13C13.972 8.5 14.7983 8.15967 15.479 7.479C16.1597 6.79833 16.5 5.972 16.5 5C16.5 4.028 16.1597 3.20167 15.479 2.521C14.7983 1.84033 13.972 1.5 13 1.5H5C4.028 1.5 3.20167 1.84033 2.521 2.521C1.84033 3.20167 1.5 4.028 1.5 5C1.5 5.972 1.84033 6.79833 2.521 7.479C3.20167 8.15967 4.028 8.5 5 8.5ZM13 7.5C13.6947 7.5 14.285 7.257 14.771 6.771C15.257 6.285 15.5 5.69467 15.5 5C15.5 4.30533 15.257 3.715 14.771 3.229C14.285 2.743 13.6947 2.5 13 2.5C12.3053 2.5 11.715 2.743 11.229 3.229C10.743 3.715 10.5 4.30533 10.5 5C10.5 5.69467 10.743 6.285 11.229 6.771C11.715 7.257 12.3053 7.5 13 7.5Z" />
    </svg>
  );
}
