import { createRoot } from "react-dom/client";
import { App } from "./App";

const DOM_ROOT_ELEMENT_ID = "root";

export function initReact(): void {
  /* eslint-disable-next-line unicorn/prefer-query-selector --
   * I dunno, this is how Create React App does it
   */
  const rootElement = document.getElementById(DOM_ROOT_ELEMENT_ID);
  if (rootElement === null) {
    throw new Error(`Root element with id: '${DOM_ROOT_ELEMENT_ID}' not found on page`);
  }

  const root = createRoot(rootElement);
  root.render(<App />);
}
