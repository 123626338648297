/* eslint-disable max-lines */
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  type SelectChangeEvent,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useCallback, useState, type ChangeEvent } from "react";
import { prop } from "remeda";
import { exceptionToErrorMessage } from "../../../utils/error";
import type { SourceInstance } from "../../../utils/settings/types";
import { CallToActions } from "../common/CallToActions";
import { GITHUB_NAME } from "../github";
import { useSubSettings } from "../settingsProvider";
import { useApiRequest } from "../useApiRequest";
import { Instructions } from "./Instructions";
import { BASE_URL_PREFIX } from "./consts";

type LookerNewSource = {
  /* eslint-disable @typescript-eslint/naming-convention -- defined by the API */
  readonly source_identifier: string;
  readonly username: string;
  readonly credentials: {
    readonly server_address: string;
    readonly client_id: string;
    readonly client_secret: string;
  };
  readonly looker_lookml_repos: readonly string[];
  /* eslint-enable @typescript-eslint/naming-convention */
};

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function NewSourceScreen({
  onDone,
  initialSourceData,
}: {
  readonly onDone: (isCanceled: boolean) => void;
  readonly initialSourceData?: SourceInstance | undefined;
}): JSX.Element {
  const isEditMode = initialSourceData !== undefined;

  const [githubAccounts] = useSubSettings(GITHUB_NAME);
  const [enabledRepos, setEnabledRepos] = useState(
    () => initialSourceData?.options?.looker_options?.lookml_repos ?? [],
  );

  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState<string>();
  const [isServerEdited, setIsServerEdited] = useState(false);
  const [isTokenNameEdited, setIsTokenNameEdited] = useState(false);
  const [isTokenSecretEdited, setIsTokenSecretEdited] = useState(false);
  const [serverAddress, setServerAddress] = useState(
    () => initialSourceData?.source_identifier ?? "",
  );
  const [tokenName, setTokenName] = useState(initialSourceData?.username ?? "");
  const [tokenSecret, setTokenSecret] = useState("");

  const api = useApiRequest();

  const handleSave = useCallback(() => {
    setError(undefined);
    setIsInProgress(true);

    const newSource: LookerNewSource = {
      source_identifier: `${serverAddress}`,
      username: tokenName,
      credentials: {
        server_address: serverAddress,
        client_id: tokenName,
        client_secret: tokenSecret,
      },
      looker_lookml_repos: enabledRepos,
    };

    const toServer = async (): Promise<void> => {
      try {
        await api(`${BASE_URL_PREFIX}/keys/`, [newSource], "PUT");
        onDone(false);
      } catch (serverError) {
        const message = await exceptionToErrorMessage(serverError);
        setError(message);
      } finally {
        setIsInProgress(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    toServer();
  }, [onDone, api, serverAddress, tokenName, tokenSecret, enabledRepos]);

  const handleServerAddressBlur = useCallback(() => {
    setIsServerEdited(true);
  }, []);

  const handleServerAddressChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setServerAddress(currentTarget.value);
    },
    [],
  );

  const handleTokenNameBlur = useCallback(() => {
    setIsTokenNameEdited(true);
  }, []);

  const handleTokenNameChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setTokenName(currentTarget.value);
    },
    [],
  );

  const handleTokenSecretBlur = useCallback(() => {
    setIsTokenSecretEdited(true);
  }, []);

  const handleTokenSecretChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setTokenSecret(currentTarget.value);
    },
    [],
  );

  const handleChangeRepos = useCallback(
    (currentEvent: SelectChangeEvent<typeof enabledRepos>) => {
      const {
        target: { value },
      } = currentEvent;
      setEnabledRepos(typeof value === "string" ? value.split(",") : value);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    onDone(true);
  }, [onDone]);

  return (
    <Box
      sx={{
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
        mt: 2,
      }}
    >
      <form>
        <TextField
          fullWidth
          required
          autoFocus={!isEditMode}
          disabled={isEditMode}
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render
            isServerEdited && serverAddress === ""
          }
          label="Server Address"
          placeholder="https://bi.server.net"
          type="url"
          value={serverAddress}
          variant="standard"
          onBlur={handleServerAddressBlur}
          onChange={handleServerAddressChange}
        />
        <TextField
          fullWidth
          required
          autoComplete="off"
          autoFocus={isEditMode}
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render
            isTokenNameEdited && tokenName === ""
          }
          label="Client ID"
          type="text"
          value={tokenName}
          variant="standard"
          onBlur={handleTokenNameBlur}
          onChange={handleTokenNameChange}
        />
        <TextField
          fullWidth
          autoComplete="off"
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render
            isTokenSecretEdited && tokenSecret === "" && !isEditMode
          }
          label={
            isEditMode
              ? "New Client Secret (Leave empty to keep old value)"
              : "Client Secret"
          }
          required={!isEditMode}
          type="password"
          value={tokenSecret}
          variant="standard"
          onBlur={handleTokenSecretBlur}
          onChange={handleTokenSecretChange}
        />
        <FormControl fullWidth className="flex">
          <InputLabel className="my-3">LookML Repository</InputLabel>
          <Select
            fullWidth
            multiple
            className="my-3"
            label="LookML Repository"
            value={enabledRepos}
            onChange={handleChangeRepos}
          >
            {githubAccounts?.installations
              .flatMap(prop("enabled_repos"))
              .map((repo) => (
                <MenuItem key={repo} value={repo}>
                  {repo}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Instructions />
        <CallToActions
          disabled={
            serverAddress === "" ||
            tokenName === "" ||
            (!isEditMode && tokenSecret === "")
          }
          inProgress={isInProgress}
          onCancel={handleCancel}
          onSave={handleSave}
        />
        {error !== undefined && <Alert severity="error">{error}</Alert>}
      </form>
    </Box>
  );
}
