import { useCallback, useEffect, useMemo } from "react";
import { filter, pipe } from "remeda";
import { useRouteLineage } from "../../../api";
import {
  logEventDiffBoxSearched,
  logEventDiffBoxSearchResultClicked,
} from "../../../utils/analytics/eventLogging";
import { searchLineage, type LineageSearchResult } from "../../../utils/search/lineage";
import { SearchResultsItem } from "../../discovery/SearchResultsItem";
import { useMoveToRootNode } from "../../useMoveToRootNode";
import { useFiltersPanelContext } from "./SidebarFiltersPanel";

export type ChangeType = "affected" | "modified";

export function FiltersPanelSearchResultsView({
  changeType,
}: {
  readonly changeType: ChangeType;
}): JSX.Element {
  const lineage = useRouteLineage({ assertIsLoaded: true });
  const entities = lineage?.diff.entities;

  const { query } = useFiltersPanelContext();

  const searchResults = useMemo(
    () =>
      entities === undefined
        ? []
        : pipe(
            entities,
            ($) => searchLineage($, query),
            filter(
              ({ result: { changeStatus } }) =>
                (changeType === "modified" && changeStatus !== undefined) ||
                (changeType === "affected" && changeStatus === undefined),
            ),
          ),
    [changeType, query, entities],
  );

  useEffect(() => {
    if (query === "") {
      return;
    }
    logEventDiffBoxSearched(query, changeType, searchResults.length);
  }, [changeType, searchResults, query]);

  const handleFilterItemClick = useCallback(
    ({ result: { type } }: LineageSearchResult) => {
      logEventDiffBoxSearchResultClicked(type, changeType, searchResults.length);
    },
    [changeType, searchResults.length],
  );

  return (
    <ul>
      {searchResults.map((result) => (
        <li key={result.result.id} className="border-b last:border-0 hover:bg-slate-50">
          <FiltersPanelSearchResultsItem
            result={result}
            onClick={handleFilterItemClick}
          />
        </li>
      ))}
    </ul>
  );
}

function FiltersPanelSearchResultsItem({
  result,
  onClick,
}: {
  readonly result: LineageSearchResult;
  readonly onClick: (searchResult: LineageSearchResult) => void;
}): JSX.Element {
  const moveToTableNode = useMoveToRootNode(result.rootEntity.id);

  const handleFilterItemClick = useCallback(() => {
    moveToTableNode();
    onClick(result);
  }, [moveToTableNode, onClick, result]);

  return (
    <SearchResultsItem
      key={result.result.id}
      className="p-3"
      result={result}
      onClick={handleFilterItemClick}
    />
  );
}
