export function ErrorIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.075 18C5.80833 18 5.554 17.95 5.312 17.85C5.07067 17.75 4.85833 17.6083 4.675 17.425L0.575 13.325C0.391667 13.1417 0.25 12.9293 0.15 12.688C0.0500001 12.446 0 12.1917 0 11.925V6.075C0 5.80833 0.0500001 5.554 0.15 5.312C0.25 5.07067 0.391667 4.85833 0.575 4.675L4.675 0.575C4.85833 0.391667 5.07067 0.25 5.312 0.15C5.554 0.0500001 5.80833 0 6.075 0H11.925C12.1917 0 12.446 0.0500001 12.688 0.15C12.9293 0.25 13.1417 0.391667 13.325 0.575L17.425 4.675C17.6083 4.85833 17.75 5.07067 17.85 5.312C17.95 5.554 18 5.80833 18 6.075V11.925C18 12.1917 17.95 12.446 17.85 12.688C17.75 12.9293 17.6083 13.1417 17.425 13.325L13.325 17.425C13.1417 17.6083 12.9293 17.75 12.688 17.85C12.446 17.95 12.1917 18 11.925 18H6.075ZM9 14C9.28333 14 9.521 13.904 9.713 13.712C9.90433 13.5207 10 13.2833 10 13C10 12.7167 9.90433 12.479 9.713 12.287C9.521 12.0957 9.28333 12 9 12C8.71667 12 8.47933 12.0957 8.288 12.287C8.096 12.479 8 12.7167 8 13C8 13.2833 8.096 13.5207 8.288 13.712C8.47933 13.904 8.71667 14 9 14ZM9 10C9.28333 10 9.521 9.904 9.713 9.712C9.90433 9.52067 10 9.28333 10 9V5C10 4.71667 9.90433 4.479 9.713 4.287C9.521 4.09567 9.28333 4 9 4C8.71667 4 8.47933 4.09567 8.288 4.287C8.096 4.479 8 4.71667 8 5V9C8 9.28333 8.096 9.52067 8.288 9.712C8.47933 9.904 8.71667 10 9 10ZM6.1 16H11.9L16 11.9V6.1L11.9 2H6.1L2 6.1V11.9L6.1 16Z" />
    </svg>
  );
}
