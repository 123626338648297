/* eslint-disable import/no-deprecated */
import { rootEntityFromTable } from "../../api";
import type { LineageTable } from "../../utils/graph/type";
import { LineageRootDescriptor } from "../graph/LineageRootDescriptor";
import { XIcon } from "../icons/XIcon";
import { InspectorSections } from "./InspectorSections";
import { useDeselectAllTables, useSelectedTable } from "./useSelectedTable";

export function InspectorPanel(): JSX.Element | null {
  const selectedTable = useSelectedTable();

  return (
    <aside
      className={`z-40 flex flex-none flex-col overflow-hidden transition-all duration-75 ${
        selectedTable === undefined
          ? "w-0 shadow-none"
          : "w-[26rem] shadow-[0_0_1px_rgba(44,57,63,0.3),0_0_30px_rgba(44,57,63,0.15)]"
      }`}
    >
      {selectedTable === undefined ? null : (
        <InspectorPanelContents table={selectedTable} />
      )}
    </aside>
  );
}

function InspectorPanelContents({
  table,
}: {
  readonly table: LineageTable;
}): JSX.Element {
  const deselectAll = useDeselectAllTables();
  return (
    <>
      <header className="z-50 flex gap-2 border-b border-[rgba(82,97,100,0.15)] p-5">
        <LineageRootDescriptor className="flex-1" entity={rootEntityFromTable(table)} />
        <button
          className="h-6 w-6 flex-none p-1.5 text-[rgb(165,173,176)] hover:text-[rgb(135,143,146)]"
          type="button"
          onClick={deselectAll}
        >
          <XIcon variant="light" />
        </button>
      </header>
      <InspectorSections table={table} />
    </>
  );
}
