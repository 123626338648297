import { useEffect, useMemo, useState, type RefObject } from "react";
import { filter, first, flatMapToObj, fromPairs, map, pipe } from "remeda";
import invariant from "tiny-invariant";

type UseVisibleSectionOptions<T extends string> = {
  /**
   * The root element under which defines the tracking intersection boundary and under
   * which all tracked section IDs need to be found in.
   */
  readonly containerRef: RefObject<HTMLElement>;
  /**
   * The IDs of the sections you want to track. All IDs need to have a corresponding
   * element. A missing element would throw! The IDs are defined as the value of the
   * data attribute defined in `dataAttributeName`.
   */
  readonly trackedSectionIds: readonly T[];
  /**
   * The data attribute name for storing and retrieving the section ID. It is on the
   * hook consumers to make sure this is defined properly on the sections.
   */
  readonly dataAttributeName: string;
  /**
   * A type narrowing predicate that is used to make sure we don't observe unexpected
   * sections. If this is causing issues either refactor the code, or send in a function
   * that always returns true.
   */
  readonly isSectionId: (raw: string) => raw is T;
};

/**
 * Tracks the visibility (intersection events) of sections under the root element
 * and returns the first visible section. Tracking is done via an IntersectionObserver
 * that is synced to the ids of the sections that we want to track so that it works
 * even with sections which are added or removed dynamically.
 * Sections are identified via a data attribute so that they don't impact the HTML or
 * CSS behaviour of the elements.
 */
export function useVisibleSection<T extends string>({
  containerRef,
  trackedSectionIds,
  dataAttributeName,
  isSectionId,
}: UseVisibleSectionOptions<T>): T | undefined {
  const [visibleSections, setVisibleSections] = useState(() =>
    flatMapToObj(trackedSectionIds, (key) => [[key, false]]),
  );

  useEffect(() => {
    const { current: root } = containerRef;
    if (root === null) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const updates = pipe(
          entries,
          map(({ target, isIntersecting }) => {
            invariant(target instanceof HTMLElement, "Target is not an HTML element");
            const {
              dataset: { [dataAttributeName]: sectionId },
            } = target;
            invariant(
              sectionId !== undefined,
              "Missing section id data attribute in observed element!",
            );
            invariant(
              isSectionId(sectionId),
              `Element with unknown section id '${sectionId}' observed!`,
            );
            return [sectionId, isIntersecting] as const;
          }),
          // TODO: fromPairs.strict can't infer the type correctly because
          // of the generic T so we do an explicit cast here. We should check from time
          // to time if typing got better in Remeda or there's a workaround...
          ($) => fromPairs.strict($) as Partial<Record<T, boolean>>,
        );
        setVisibleSections((current) => ({ ...current, ...updates }));
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- we use a tiny threshold to allow the observer to ignore the pixel border that is still "visible" when clicking the navigation buttons
        threshold: 0.001,
      },
    );

    // IMPORTANT: We use an external sectionIds array although it could be computed
    // locally via the data attribute intentionally so that the hook is dependent on it
    // and react will re-run the effect every time it changes. This allows us to track
    // changes when a section is added or removed from the container. Without this we
    // will only run once, on initial render, and might miss the changes.
    for (const section of trackedSectionIds) {
      const sectionElement = root.querySelector(
        `[data-${dataAttributeName}="${section}"]`,
      );
      invariant(
        sectionElement !== null,
        `Failed to observe section '${section}' because it wasn't found under the root`,
      );
      observer.observe(sectionElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [containerRef, dataAttributeName, isSectionId, trackedSectionIds]);

  const visibleSection = useMemo(
    () =>
      pipe(
        trackedSectionIds,
        filter((prStatus) => visibleSections[prStatus]),
        first(),
      ),
    [trackedSectionIds, visibleSections],
  );

  return visibleSection;
}
