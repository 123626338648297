const UNICODE_RIGHT_ARROW = "\u2192";

const FAKE_PR = "foundational-io/lineage-learner/pull/109";

export function GithubFakePR({
  tableName,
  columnName,
  renameTo,
}: {
  readonly tableName: string | undefined;
  readonly columnName: string | undefined;
  readonly renameTo: string | undefined;
}): JSX.Element {
  return (
    <a
      className="flex items-center hover:text-blue-600 hover:underline"
      href={`https://github.com/${FAKE_PR}`}
      rel="noreferrer"
      target="_blank"
    >
      <img
        alt="Github's cat icon"
        className="mr-2 h-4 w-4"
        src="/img/connectors/github64px.png"
      />
      {`#1337: Rename ${tableName ?? ""}.${columnName ?? ""} ${UNICODE_RIGHT_ARROW} ${
        renameTo ?? ""
      }`}
    </a>
  );
}
