import { DEMO_MODE } from "../../utils/demo/demoMode";
import { exampleOrgPoliciesList } from "../../utils/demo/policies";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import type { OrganizationPolicyList } from "./schemas";

export const ORG_POLICY_LIST_QUERY_KEY = ["getOrgPolicyList"] as const;

export const useOrgPolicyList = () =>
  useQueryRetriesServerErrors({
    queryKey: ORG_POLICY_LIST_QUERY_KEY,
    queryFn: getOrgPolicyList,
  });

const getOrgPolicyList = async (): Promise<OrganizationPolicyList> =>
  DEMO_MODE.isEnabled ? exampleOrgPoliciesList() : queryApiCall("policies/policies");
