import { useMemo } from "react";
import { groupBy, map, pipe } from "remeda";
import { type ChangeStatus, EMPTY_STATE, useRouteLineage } from "../../../api";
import {
  type FilterType,
  searchLineageByType,
} from "../../../utils/search/lineageType";
import { Filter } from "./Filter";

const STATUS_ORDER: readonly ChangeStatus[] = ["new", "removed", "changed", "renamed"];

export function TypeFilters({
  type,
  onClick,
}: {
  readonly type: FilterType;
  readonly onClick: (filterType: FilterType, changeStatus: ChangeStatus) => void;
}): JSX.Element {
  const { diff } = useRouteLineage({ assertIsLoaded: true }) ?? {
    diff: EMPTY_STATE,
  };

  const grouped = useMemo(
    () =>
      pipe(
        searchLineageByType(type, diff.entities, diff.edges),
        groupBy.strict(({ result: { changeStatus } }) => changeStatus ?? "unchanged"),
      ),
    [diff.edges, diff.entities, type],
  );

  return (
    <>
      {map(STATUS_ORDER, (changeStatus) => {
        const { [changeStatus]: values } = grouped;
        return values === undefined ? null : (
          <Filter
            key={changeStatus}
            changeStatus={changeStatus}
            count={values.length}
            type={type}
            onClick={onClick}
          />
        );
      })}
    </>
  );
}
