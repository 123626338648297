/* eslint-disable import/no-deprecated */
import type { LineageTable } from "../../utils/graph/type";
import { sleep } from "../../utils/sleep";
import type { QueryData } from "../QueryData";
import { useOptimisticMutation } from "../useOptimisticMutation";
import {
  type TableOwner,
  tableOwnersQueryKey,
  type useTableOwners,
} from "./useTableOwners";

// Just something so we can see the transitions
const FAKE_MUTATION_RUN_TIME_MS = 3000;

// Without this we won't see our optimistic update because once we invalidate the cache
// we will see that in fact this mutation does nothing :(
const FAKE_STALE_INVALIDATION_DELAY_MS = 10_000;

export const useAddTableOwnerMutation = (table: LineageTable) =>
  useOptimisticMutation({
    context: { table },

    queryKeyFn: tableOwnersQueryKey,

    mutationFn: fakeAddImpl,

    optimisticMutationFn: (current: QueryData<typeof useTableOwners>, { owner }) => [
      ...current,
      owner,
    ],

    invalidationDelayMs: FAKE_STALE_INVALIDATION_DELAY_MS,
  });

// TODO: Implement a real backend API endpoint for this
const fakeAddImpl = async (
  _context: { readonly table: LineageTable },
  _variables: { readonly owner: TableOwner },
) => {
  await sleep(FAKE_MUTATION_RUN_TIME_MS);
};
