import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import type {
  SettingsOverviewProps,
  SourceInstance,
} from "../../../utils/settings/types";
import { BaseConfigCard } from "../BaseConfigCard";
import { BaseConfigDrawer } from "../BaseConfigDrawer";
import { useSubSettings } from "../settingsProvider";
import { useApiRequest } from "../useApiRequest";
import { MainConfigScreen } from "./MainConfigScreen";
import { NewSourceScreen } from "./NewSourceScreen";
import { BASE_URL_PREFIX, LOOKER_DISPLAY_NAME, LOOKER_NAME } from "./consts";

const SUFFIX_TRUNCATE = ".cloud.looker.com";

export function LookerConfigOverview({
  onConfigure,
}: SettingsOverviewProps): JSX.Element {
  const [rawAccounts] = useSubSettings(LOOKER_NAME);
  const accounts = rawAccounts ?? [];
  const [firstAccount] = accounts;

  return (
    <BaseConfigCard
      avatarPath="/img/connectors/looker.svg"
      configured={accounts.length > 0}
      loading={rawAccounts === undefined}
      sourceName={LOOKER_DISPLAY_NAME}
      onConfigure={onConfigure}
    >
      <Typography gutterBottom component="div" variant="h6">
        Configured Accounts
      </Typography>
      <Typography color="text.secondary" variant="body2">
        {firstAccount !== undefined && (
          <>
            {`${firstAccount.source_identifier.replace(SUFFIX_TRUNCATE, "")}${
              firstAccount.username === undefined ? "" : ` (${firstAccount.username})`
            }`}
            <br />
            {accounts.length > 1
              ? `... (${accounts.length - 1} more configured)`
              : "1 account configured"}
          </>
        )}
      </Typography>
    </BaseConfigCard>
  );
}

export function LookerSettings(): JSX.Element {
  const [accounts, setAccounts] = useSubSettings(LOOKER_NAME);
  const [isAddAccount, setIsAddAccount] = useState(false);
  const [currentInstance, setCurrentInstance] = useState<SourceInstance>();
  const api = useApiRequest();

  const updateAccounts = useCallback(async () => {
    const response = await api(`${BASE_URL_PREFIX}/keys/`);
    setAccounts(response as [SourceInstance]);
  }, [api, setAccounts]);

  const handleDone = useCallback(
    (canceled: boolean) => {
      setCurrentInstance(undefined);
      setIsAddAccount(false);

      if (canceled) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateAccounts();
    },
    [updateAccounts],
  );

  const handleAddSource = useCallback(() => {
    setIsAddAccount(true);
  }, []);

  const handleEditSource = useCallback((identifier: SourceInstance) => {
    setCurrentInstance(identifier);
    setIsAddAccount(true);
  }, []);

  const handleDelete = useCallback(
    (identifier: SourceInstance) => {
      const deleteAccount = async () => {
        await api(`${BASE_URL_PREFIX}/keys/`, identifier, "DELETE");
        await updateAccounts();
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      deleteAccount();
    },
    [api, updateAccounts],
  );

  return (
    <BaseConfigDrawer sourceName={LOOKER_DISPLAY_NAME}>
      {!isAddAccount && (
        <MainConfigScreen
          accounts={accounts ?? []}
          onAddSource={handleAddSource}
          onDelete={handleDelete}
          onEdit={handleEditSource}
        />
      )}
      {isAddAccount ? (
        <NewSourceScreen initialSourceData={currentInstance} onDone={handleDone} />
      ) : null}
    </BaseConfigDrawer>
  );
}
