import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import type { PropsWithChildren } from "react";
import type { SettingsOverviewProps } from "../../utils/settings/types";

export function BaseConfigCard({
  onConfigure,
  sourceName,
  avatarPath,
  children,
  loading = false,
  error,
  configured = false,
}: PropsWithChildren<
  Omit<SettingsOverviewProps, "cacheKey"> & {
    readonly sourceName: string;
    readonly avatarPath: string;
    readonly loading?: boolean;
    readonly error?: string;
    readonly configured?: boolean;
  }
>): JSX.Element {
  const emptyState = (
    <>
      <Typography gutterBottom component="div" variant="h6">
        Not Configured!
      </Typography>
      <Typography color="text.secondary" variant="body2">
        Click to configure
        <br />
        &nbsp;
      </Typography>
    </>
  );

  return (
    <Card>
      <CardActionArea onClick={onConfigure}>
        <CardHeader
          avatar={<Avatar alt={sourceName} src={avatarPath} variant="square" />}
          title={<Typography variant="h5">{sourceName}</Typography>}
        />
        <CardContent>
          {loading ? <CircularProgress /> : null}
          {!loading && error !== undefined && <Alert severity="error">{error}</Alert>}
          {!loading && !configured && emptyState}
          {!loading && configured ? children : null}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button color="primary" disabled={loading} size="small" onClick={onConfigure}>
          Configure
        </Button>
      </CardActions>
    </Card>
  );
}
