import { pipe, sortBy } from "remeda";
import type { Dependency } from "../../api";
import type { MatchDescriptor, SearcherFunction } from "./SEARCHERS";
import { entitySearch } from "./entitiesSearch";

export type DependenciesSearchResult = DependenciesSearchEntityDescriptor &
  MatchDescriptor;

type DependenciesSearchEntityDescriptor = {
  readonly item: Dependency;
};

const MATCH_TYPE_ORDER: readonly MatchDescriptor["matchType"][] = ["exact", "contains"];

export const searchDependencies = (
  dependencies: readonly Dependency[],
  query: string,
): readonly DependenciesSearchResult[] =>
  pipe(
    dependencies,
    search(query),
    sortBy(
      // Our very crude "ranking algorithm" for search results
      ({ matchType }) => MATCH_TYPE_ORDER.indexOf(matchType),
      ({ isMatchCase }) => isMatchCase,
      // Prefer close
      ({ item: { distance } }) => distance,
      // Prefer to keep them grouped
      ({ item: { type } }) => type,
      // Make it logical
      ({
        item: {
          entity: { entityName },
        },
      }) => entityName,
    ),
  );

const search = (query: string) => (dependencies: readonly Dependency[]) =>
  entitySearch(dependencies, query, searchDependency);

function searchDependency(
  item: Dependency,
  searcher: SearcherFunction,
): readonly DependenciesSearchResult[] {
  const {
    entity: { entityName },
  } = item;
  const match = searcher(entityName);
  return match === undefined ? [] : [{ item, ...match }];
}
