import { map, pipe } from "remeda";
import type {
  CreatePolicyRequestModel,
  OrganizationPolicyList,
  Policy,
  PolicyBasicMetadata,
  PolicyNameCheckResponseModel,
} from "../../api";

// note- EXAMPLE_POLICIES changes dynamically based on user actions, to allow demo feel functional.
const EXAMPLE_POLICIES: Policy[] = [
  {
    id: "2",
    name: "Monitor Raw Customers",
    orgName: "foundational",
    isEnabled: true,
    rulesData: {
      conjunctiveEvaluation: false,
      rules: [
        {
          rule_type: "single_lineage_entity_rule",
          subject_entity_type: "table",
          subject_entity_id: "dbt_tests.demo_schema.raw_customers",
          include_upstream_changes: false,
        },
      ],
    },
    actionsData: {
      actions: [{ action_type: "send_email", recipients: ["demo@foundational.io"] }],
    },
    createdAt: "2023-06-08T08:21:41.228633",
    createdBy: {
      id: "a75b9d38-8682-42cf-8c05-72a13e82de44",
      orgName: "foundational",
      email: "omri-demo@foundational.io",
      firstName: "Omri",
      lastName: "Ildis",
    },
  },
  {
    id: "4",
    name: "Deprecated Entities",
    orgName: "foundational",
    isEnabled: true,
    rulesData: {
      conjunctiveEvaluation: false,
      rules: [
        {
          rule_type: "single_lineage_entity_rule",
          subject_entity_type: "column",
          subject_entity_id: "dbt_tests.demo_schema.deprecated_order_id",
          include_upstream_changes: true,
        },
        {
          rule_type: "single_lineage_entity_rule",
          subject_entity_type: "dashboard",
          subject_entity_id: "dashboards.tableau.deprecated_monthly_active_users",
          include_upstream_changes: true,
        },
      ],
    },
    actionsData: {
      actions: [
        { action_type: "send_slack_message", channels: ["deprecations-alerts"] },
      ],
    },
    createdAt: "2023-01-08T08:21:41.228633",
    createdBy: {
      id: "a75b9d38-8682-42cf-8c05-72a13e82de45",
      orgName: "foundational",
      email: "demo@foundational.io",
      firstName: "Alon",
      lastName: "Nafta",
    },
  },
  {
    id: "5",
    name: "Critical Alerts",
    orgName: "foundational",
    isEnabled: true,
    rulesData: {
      conjunctiveEvaluation: false,
      rules: [
        {
          rule_type: "single_lineage_entity_rule",
          subject_entity_type: "dashboard",
          subject_entity_id: "dashboards.tableau.monthly_active_users",
          include_upstream_changes: true,
        },
      ],
    },
    actionsData: {
      actions: [{ action_type: "send_slack_message", channels: ["cfo-team"] }],
    },
    createdAt: "2023-02-08T08:21:41.228633",
    createdBy: {
      id: "a75b9d38-8682-42cf-8c05-72a13e82de46",
      orgName: "foundational",
      email: "memo@foundational.io",
      firstName: "Omri",
      lastName: "Ildis",
    },
  },
];

export function exampleOrgPoliciesList(): Readonly<OrganizationPolicyList> {
  return {
    orgName: "foundational",
    policies: pipe(
      EXAMPLE_POLICIES,
      map((p): PolicyBasicMetadata => ({ id: p.id, name: p.name })),
    ),
  };
}

export function examplePolicyNameAvailability(
  policyName: string,
): Readonly<PolicyNameCheckResponseModel> {
  return {
    orgName: "example",
    policyName,
    isValid: true,
    isAvailable: !EXAMPLE_POLICIES.some((p) => p.name === policyName),
  };
}

export function exampleCreatePolicy(
  policy: CreatePolicyRequestModel,
): Readonly<Policy> {
  const newPolicy: Policy = {
    ...policy,
    id: policy.name + new Date().toLocaleString(),
    orgName: "foundational",
    createdAt: "2023-06-08T08:21:41.228633",
    createdBy: {
      id: "a75b9d38-8682-42cf-8c05-72a13e82de46",
      orgName: "foundational",
      email: "memo@foundational.io",
      firstName: "Alon",
      lastName: "Nafta",
    },
  };
  EXAMPLE_POLICIES.push(newPolicy);
  return newPolicy;
}

export function exampleGetPolicy(policyId: string): Readonly<Policy> | undefined {
  return EXAMPLE_POLICIES.find((p) => p.id === policyId);
}

export function exampleDeletePolicy(policyId: string): Readonly<Policy> | undefined {
  const policy = EXAMPLE_POLICIES.find((p) => p.id === policyId);
  if (policy === undefined) {
    return undefined;
  }
  EXAMPLE_POLICIES.splice(EXAMPLE_POLICIES.indexOf(policy), 1);
  return policy;
}
