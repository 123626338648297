import type { ReactElement } from "react";
import { useEffect, useState } from "react";
import { exceptionToErrorMessage } from "../../utils/error";

export function UserCreationResultBox({
  result,
  error,
}: {
  result?: Record<string, string> | undefined;
  error?: unknown;
}): ReactElement | null {
  const [renderedError, setRenderedError] = useState<string | undefined>(undefined);
  useEffect(() => {
    const renderErrorAsync = async () => {
      if (error === undefined) {
        setRenderedError(undefined);
      } else {
        setRenderedError(await exceptionToErrorMessage(error));
      }
    };

    // eslint-disable-next-line no-void
    void renderErrorAsync();
  }, [error, setRenderedError]);

  if (error !== undefined) {
    return (
      <div className="whitespace-pre-line rounded bg-red-200 px-2 py-1">
        {renderedError}
      </div>
    );
  }

  if (result !== undefined) {
    return (
      <div className="whitespace-pre-line rounded bg-green-200 px-2 py-1">
        Organization created successfully.
        <div className="mt-3">
          {Object.entries(result).map(([key, value]) => (
            <div key={`res-${key}`}>{`${key}: ${value}`}</div>
          ))}
        </div>
      </div>
    );
  }
  return null;
}
