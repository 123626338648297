import { Tab } from "@headlessui/react";
import { useMemo, type PropsWithChildren } from "react";
import { groupBy } from "remeda";
import type { LineageSearchResult } from "../../utils/search/lineage";
import { SearchResultsItem } from "./SearchResultsItem";

export function SearchResultsTabs({
  results,
  onResultClick,
  className,
}: {
  readonly results: readonly LineageSearchResult[];
  readonly onResultClick: (result: LineageSearchResult) => void;
  readonly className?: string | undefined;
}): JSX.Element {
  const {
    columns = [],
    dashboards = [],
    tables = [],
  } = useMemo(
    () =>
      groupBy.strict(results, ({ result: { type } }) =>
        type === "column" || type === "dashboard" ? `${type}s` : "tables",
      ),
    [results],
  );

  return (
    <Tab.Group
      as="div"
      className={`flex flex-col ${className ?? ""}`}
      defaultIndex={
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        tables.length > 0 ? 0 : dashboards.length > 0 ? 1 : 2
      }
    >
      <Tab.List className="flex flex-none text-sm">
        <ResultsListTab count={tables.length}>Tables</ResultsListTab>
        <ResultsListTab count={dashboards.length}>Dashboards</ResultsListTab>
        <ResultsListTab count={columns.length}>Columns</ResultsListTab>
      </Tab.List>
      <Tab.Panels as="ol" className="flex-1 overflow-hidden overflow-y-auto">
        <ResultsListTabPanel results={tables} onResultClick={onResultClick} />
        <ResultsListTabPanel results={dashboards} onResultClick={onResultClick} />
        <ResultsListTabPanel results={columns} onResultClick={onResultClick} />
      </Tab.Panels>
    </Tab.Group>
  );
}

function ResultsListTab({
  count,
  children,
}: PropsWithChildren<{
  readonly count: number;
}>): JSX.Element | null {
  if (count <= 0) {
    return null;
  }

  return (
    <Tab className="flex grow items-center justify-center gap-x-1 overflow-hidden whitespace-nowrap border-b-4 border-[rgb(205,215,215)] px-1 py-3 font-medium text-[rgb(143,153,157)] outline-none ui-selected:border-[rgb(101,202,132)] ui-selected:font-semibold ui-selected:text-[rgb(82,97,100)]">
      {children}
      {count > 0 && (
        <span className="font-normal tabular-nums text-[rgb(130,132,132)]">
          {count}
        </span>
      )}
    </Tab>
  );
}

function ResultsListTabPanel({
  results,
  onResultClick,
}: {
  readonly results: readonly LineageSearchResult[];
  readonly onResultClick: (result: LineageSearchResult) => void;
}): JSX.Element | null {
  if (results.length <= 0) {
    return null;
  }

  return (
    <Tab.Panel>
      {results.map((result) => (
        <li key={result.result.id} className="border-b last:border-0 hover:bg-slate-50">
          <SearchResultsItem className="p-3" result={result} onClick={onResultClick} />
        </li>
      ))}
    </Tab.Panel>
  );
}
