import { purry } from "remeda";
import type { ArrayN } from "./types/ArrayN";

/**
 * Checks that the array has EXACTLY N items. The result is typed so that all items in
 * the array are strictly typed to T (without the `undefined`), and accessing indexes
 * larger than N would cause a type error.
 *
 * @see hasAtLeast to define a lower bound on array length
 * @see hasAtMost to define an upper bound on array length
 */
export function isLength<T, N extends number>(
  data: readonly T[],
  n: N,
): data is ArrayN<T, N>;

export function isLength<T, N extends number>(
  n: N,
): (data: readonly T[]) => data is ArrayN<T, N>;

export function isLength(...args: readonly unknown[]): unknown {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return purry(isLengthImplementation, args);
}

export const isLengthImplementation = <T, N extends number>(
  data: readonly T[],
  n: N,
): data is ArrayN<T, N> => data.length === n;
