/* eslint-disable import/no-deprecated */
import { canonicalTableIdentifier } from "../../utils/graph/canonical";
import type { LineageTable } from "../../utils/graph/type";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";
import type { TableDescriptionInfo } from "./schemas";

export const tableDescriptionQueryKey = ({
  table: { table_identifier },
}: {
  readonly table: LineageTable;
}) => ["tableDescription", canonicalTableIdentifier(table_identifier)] as const;

export const useTableDescription = (table: LineageTable) =>
  useQueryRetriesServerErrors({
    queryKey: tableDescriptionQueryKey({ table }),
    queryFn: async () => queryTableDescription(table),
    staleTime: STALE_TIME_MS,
  });

async function queryTableDescription(
  table: LineageTable,
): Promise<TableDescriptionInfo> {
  const {
    table_identifier: { identifier: tableIdentifier },
  } = table;
  return tableIdentifier === undefined
    ? { description: "" }
    : queryApiCall(
        `inspect/description?${new URLSearchParams({
          entity_id: tableIdentifier,
        }).toString()}`,
      );
}
