import { offset } from "@floating-ui/react-dom";
import { NavLink, type To } from "react-router-dom";
import { useTooltip } from "../useTooltip";
import { useNavStateContext } from "./Nav";

export function NavItem({
  to,
  icon,
  children,
}: {
  readonly to: To;
  readonly icon: JSX.Element;
  readonly children: string;
}): JSX.Element {
  const { isOpen } = useNavStateContext();

  const { setReference, tooltip } = useTooltip(children, {
    placement: "right",
    middleware: [
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      offset(6),
    ],
  });

  return (
    <li ref={setReference}>
      <NavLink
        // eslint-disable-next-line react/jsx-no-bind -- This is how NavLink is used in the Router docs
        className={({ isActive }) =>
          `flex h-14 items-center text-sm font-semibold ${
            isActive
              ? "border-l-4 border-[rgb(101,202,132)] bg-[rgb(50,66,71)]"
              : "group hover:bg-[rgb(56,74,80)]"
          }`
        }
        to={to}
      >
        {({ isActive }) => (
          <>
            <span
              className={`mr-2 flex w-6 flex-none items-center justify-center ${
                isActive
                  ? "ml-4 text-[rgb(101,202,132)]"
                  : `ml-5 text-white opacity-60 ${
                      isOpen ? "" : "group-hover:opacity-100"
                    }`
              }`}
            >
              {icon}
            </span>
            <span
              className={`flex-1 overflow-hidden text-white ${
                isActive ? "" : "opacity-60 group-hover:opacity-100"
              }`}
            >
              {isOpen ? children : null}
            </span>
          </>
        )}
      </NavLink>
      {!isOpen && tooltip}
    </li>
  );
}
