import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getTokenFromStorage,
  handleLoginLikeRequest,
  isTokenStorageKey,
  logout as globalLogout,
} from "../../utils/auth/foundational";

const ALL_PERMISSION = ["user", "admin"] as const;
type Permission = (typeof ALL_PERMISSION)[number];

type AuthTokenContextType =
  | {
      readonly authToken: string | null;
      readonly reloadToken: () => void;

      readonly permissions?: string;
      readonly username?: string;
      readonly name?: string;
    }
  | undefined;

const AuthTokenContext = createContext<AuthTokenContextType>(undefined);

function useInitializedContext(): NonNullable<AuthTokenContextType> {
  const context = useContext(AuthTokenContext);
  if (context === undefined) {
    throw new Error("Using logout outside of the auth context!");
  }
  return context;
}

export function useAuthToken(): string | null {
  const { authToken } = useInitializedContext();
  return authToken;
}

export function useIsAuthenticated(): boolean {
  const { permissions } = useInitializedContext();
  return permissions === undefined ? false : isAuthPermission(permissions);
}

export function useIsAdmin(): boolean {
  const { permissions } = useInitializedContext();
  return permissions === "admin";
}

export function useLogout(): () => void {
  const { reloadToken } = useInitializedContext();
  return useCallback(() => {
    globalLogout();
    reloadToken();
  }, [reloadToken]);
}

export function useLogin(): (body: FormData) => Promise<void> {
  const { reloadToken } = useInitializedContext();
  return useCallback(
    async (body: FormData) => {
      await handleLoginLikeRequest(body);
      reloadToken();
    },
    [reloadToken],
  );
}

export function AuthProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const [storedToken, setStoredToken] = useState(getTokenFromStorage);
  const { tokenExp } = storedToken;

  const reloadToken = useCallback(() => {
    setStoredToken((current) => {
      const newStoredToken = getTokenFromStorage();
      // Only update the token state if the token actually updated, otherwise we
      // will cause excessive re-renders
      return current.authToken === newStoredToken.authToken &&
        current.permissions === newStoredToken.permissions &&
        current.tokenExp === newStoredToken.tokenExp
        ? current
        : newStoredToken;
    });
  }, []);

  // Setup timeout timer to sign out the user
  useEffect(() => {
    if (tokenExp === 0) {
      // Token doesn't expire
      return;
    }

    const expiresIn = tokenExp - Date.now();

    const logoutAndReload = () => {
      globalLogout();
      reloadToken();
    };

    if (expiresIn < 0) {
      logoutAndReload();
      return;
    }

    const timeoutId = window.setTimeout(logoutAndReload, expiresIn);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [reloadToken, tokenExp]);

  // Setup event listener to get storage events from other tabs signing us out.
  useEffect(() => {
    const storageEH = ({ key }: StorageEvent) => {
      if (key !== null && isTokenStorageKey(key)) {
        reloadToken();
      }
    };

    window.addEventListener("storage", storageEH);
    return () => {
      window.removeEventListener("storage", storageEH);
    };
  }, [reloadToken]);

  const context = useMemo(
    () => ({
      reloadToken,
      ...storedToken,
    }),
    [storedToken, reloadToken],
  );

  return (
    <AuthTokenContext.Provider value={context}>{children}</AuthTokenContext.Provider>
  );
}

const isAuthPermission = (x: string): x is Permission =>
  (ALL_PERMISSION as readonly string[]).includes(x);
