import { useCallback } from "react";
import { XIcon } from "../../icons/XIcon";

export function SelectedRecipientsSection({
  recipients,
  onDelete,
}: {
  readonly recipients: readonly string[];
  readonly onDelete: (recipient: string) => void;
}): JSX.Element {
  return (
    <ul className="flex flex-wrap gap-2">
      {recipients.map((recipient) => (
        <li key={recipient}>
          <RecipientItem recipient={recipient} onDelete={onDelete} />
        </li>
      ))}
    </ul>
  );
}

function RecipientItem({
  recipient,
  onDelete,
}: {
  readonly recipient: string;
  readonly onDelete: (address: string) => void;
}): JSX.Element {
  const handleDeleteClicked = useCallback(() => {
    onDelete(recipient);
  }, [recipient, onDelete]);

  return (
    <div className="flex items-center gap-x-2 rounded-md bg-white py-1 pl-2 shadow">
      <span className="text-sm text-[rgb(74,89,92)]">{recipient}</span>
      <button
        className="border-l border-[rgb(222,228,228)]"
        type="button"
        onClick={handleDeleteClicked}
      >
        <XIcon className="h-5 w-5 p-1.5 text-[rgb(185,194,195)]" />
      </button>
    </div>
  );
}
