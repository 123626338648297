import { datadogLogs } from "@datadog/browser-logs";
import { useCallback, useState } from "react";
import { useCompleteSlackAuthCallback } from "../../../api";
import { useOAuthCallback } from "../../../utils/auth/oauth";

export function useSlackAuth(onError: (err: string) => void): {
  readonly authenticate: (link: string) => void;
  readonly didCompleteAuth: boolean;
} {
  const [didCompleteAuth, setDidCompleteAuth] = useState(false);
  const handleCompleteAuth = useCompleteSlackAuthCallback();

  const handleAuthDone = useCallback(
    (authParams: URLSearchParams | undefined) => {
      if (authParams === undefined) {
        return;
      }

      const state = authParams.get("state");
      const code = authParams.get("code");
      if (state === null || code === null) {
        datadogLogs.logger.error(
          "Failed to authenticate slack- got invalid response.",
          { state, code },
        );
        onError("Failed to authenticate slack");
        return;
      }

      const completeAuth = async () => {
        const error = await handleCompleteAuth({ state, code });
        if (error !== undefined) {
          datadogLogs.logger.error("Failed to complete Slack auth.", { error });
          onError("Failed to authenticate slack");
          return;
        }
        setDidCompleteAuth(true);
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      completeAuth();
    },
    [handleCompleteAuth, onError],
  );

  const authenticate = useOAuthCallback(handleAuthDone);
  return { authenticate, didCompleteAuth };
}
