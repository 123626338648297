export function ArrowTopRightOnSquareIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className ?? "h-4 w-4"}
      fill="currentColor"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.25 15.5C1.764 15.5 1.35067 15.33 1.01 14.99C0.67 14.6493 0.5 14.236 0.5 13.75V2.25C0.5 1.764 0.67 1.35067 1.01 1.01C1.35067 0.67 1.764 0.5 2.25 0.5H7.208C7.444 0.5 7.649 0.586667 7.823 0.76C7.99633 0.934 8.083 1.139 8.083 1.375C8.083 1.611 7.99633 1.816 7.823 1.99C7.649 2.16333 7.444 2.25 7.208 2.25H2.25V13.75H13.75V8.792C13.75 8.556 13.8367 8.351 14.01 8.177C14.184 8.00367 14.389 7.917 14.625 7.917C14.861 7.917 15.066 8.00367 15.24 8.177C15.4133 8.351 15.5 8.556 15.5 8.792V13.75C15.5 14.236 15.33 14.6493 14.99 14.99C14.6493 15.33 14.236 15.5 13.75 15.5H2.25ZM5.5 10.5C5.33333 10.3333 5.25 10.1283 5.25 9.885C5.25 9.64233 5.33333 9.43767 5.5 9.271L12.521 2.25H10.708C10.472 2.25 10.2673 2.16333 10.094 1.99C9.92 1.816 9.833 1.611 9.833 1.375C9.833 1.139 9.92 0.934 10.094 0.76C10.2673 0.586667 10.472 0.5 10.708 0.5H14.625C14.861 0.5 15.066 0.586667 15.24 0.76C15.4133 0.934 15.5 1.139 15.5 1.375V5.292C15.5 5.528 15.4133 5.73267 15.24 5.906C15.066 6.08 14.861 6.167 14.625 6.167C14.389 6.167 14.184 6.08 14.01 5.906C13.8367 5.73267 13.75 5.528 13.75 5.292V3.479L6.708 10.521C6.54133 10.6877 6.34367 10.771 6.115 10.771C5.88567 10.771 5.68067 10.6807 5.5 10.5Z" />
    </svg>
  );
}
