import { useCallback, type PropsWithChildren } from "react";
import { ChevronDown } from "../icons/ChevronDown";

export function InspectorSection({
  sectionId,
  activeSectionId,
  title,
  icon,
  onClick,
  children,
}: PropsWithChildren<{
  readonly icon: JSX.Element;
  readonly sectionId: string;
  readonly activeSectionId: string | undefined;
  readonly title: string;
  readonly onClick: (clickedSectionId: string) => void;
}>): JSX.Element {
  const handleClick = useCallback(() => {
    onClick(sectionId);
  }, [onClick, sectionId]);

  const isActive = activeSectionId === sectionId;

  return (
    <section
      // We use the `data-active` attribute to make some nice css/tailwind magic tricks:
      // 1. `data-[active]:flex-1` allows us to expand the section to take up the full
      // height available to it when it's active, and use the minimal height it needs
      // when it's inactive. This is how it accordions!
      // 2. `[&[data-active]+*]:` selects the elements where the previous element is
      // active (`&[data-active]` means select *this* component only when it has the
      // data-active attribute, `+` means select the direct sibling of it, and `*` means
      // that any element that matches this description could be selected; Together they
      // mean that we select any direct sibling of this component, but only when it has
      // the data-active attribute). We do this to add the "top shadow" on top of the
      // active panel. We might be able to add this shadow directly to the active panel,
      // instead of to it's sibling, using an inline shadow instead of a box shadow.
      className="flex flex-none flex-col overflow-hidden border-b border-[rgba(82,97,100,0.15)] last:border-none data-[active]:flex-1 [&[data-active]+*]:border-none [&[data-active]+*]:shadow-[0_1px_0_rgba(0,0,0,0.04),0_-1px_5px_rgba(0,0,0,0.14)]"
      data-active={isActive ? "true" : undefined}
    >
      <button
        className={`z-50 flex flex-none items-center gap-3 px-7 py-4 text-sm font-medium text-[rgb(82,97,100)] hover:bg-[rgb(247,250,249)] ${
          isActive ? "shadow-md" : ""
        }`}
        type="button"
        onClick={handleClick}
      >
        <div className="flex h-4 w-4 flex-none items-center justify-center text-[rgb(165,173,176)]">
          {icon}
        </div>
        <span className="flex-1 text-left">{title}</span>
        <ChevronDown
          className={`h-3 w-3 text-[rgb(143,153,157)] transition duration-75 ${
            isActive ? "rotate-180" : ""
          }`}
        />
      </button>
      {isActive ? children : null}
    </section>
  );
}
