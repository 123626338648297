export function PullRequestIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 16 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.354 12.625L3.979 10.125H0.854004L1.292 8.375H4.417L5.229 5.125H1.854L2.292 3.375H5.667L6.417 0.375H8.146L7.396 3.375H10.167L10.917 0.375H12.646L11.896 3.375H15.146L14.708 5.125H11.458L10.646 8.375H14.146L13.708 10.125H10.208L9.583 12.625H7.854L8.479 10.125H5.708L5.083 12.625H3.354ZM6.146 8.375H8.917L9.729 5.125H6.958L6.146 8.375Z" />
    </svg>
  );
}
