import invariant from "tiny-invariant";
import { isLength } from "./array";

const GLUE = "/";

// We only assume 1 delimiter, so we want to prevent ourselves from over-eagerly parsing
// more than we planned to.
const PARTS = 2;

type RepositoryName = {
  readonly organizationName: string;
  readonly repositoryName: string;
};

/**
 * Repo names on GitHub are actually an organization ID and the actual repo name, glued
 * with a backslash. For most cases we don't actually need the organization ID as it's
 * redundant within the org.
 */
export function parseRepositoryName(fullRepositoryName: string): RepositoryName {
  const parsed = fullRepositoryName.split(GLUE, PARTS);
  invariant(
    isLength(parsed, PARTS),
    `Failed parsing ${fullRepositoryName} as a full repo name`,
  );
  const [organizationName, repositoryName] = parsed;
  return { organizationName, repositoryName };
}
