import { useEffect } from "react";
import { LoginDialog } from "../../components/login/LoginDialog";
import { logEventLoginPageImpression } from "../../utils/analytics/eventLogging";

export function LoginPage(): JSX.Element {
  useEffect(() => {
    logEventLoginPageImpression();
  }, []);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-zinc-800">
      <div className="rounded bg-white p-4 text-black shadow-xl">
        <LoginDialog />
      </div>
    </div>
  );
}
