import type { LineageRootEntity } from "../lineage";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";

export type CodePointer = {
  readonly url: string;
  readonly label?: string;
};

const FAKE_CODE_POINTERS: readonly CodePointer[] = [
  {
    label: "Query 1",
    url: "https://github.com/foundational-io/data-stack/blob/main/dbt_transformations/models/stg_query_reports.sql",
  },
  {
    label: "Query 2",
    url: "https://github.com/foundational-io/data-stack/blob/main/dbt_transformations/models/failed_queries.sql",
  },
  {
    url: "https://en.wikipedia.org/wiki/Data_build_tool",
  },
  {
    label:
      "Very long label, probably too long, they should have kept it shorter, at least add a TL;DR or something...",
    url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
];

const rootEntityCodePointersQueryKey = ({
  rootEntity: { id },
}: {
  readonly rootEntity: LineageRootEntity;
}) => ["rootEntityCodePointers", id] as const;

export const useRootEntityCodePointers = (rootEntity: LineageRootEntity) =>
  useQueryRetriesServerErrors({
    queryKey: rootEntityCodePointersQueryKey({ rootEntity }),
    queryFn: () => fakeImpl(rootEntity),
    staleTime: STALE_TIME_MS,
  });

// TODO: Implement the real backend API call.
const fakeImpl = (_table: LineageRootEntity) => FAKE_CODE_POINTERS;
