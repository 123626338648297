import { useCallback, useMemo, type Dispatch } from "react";
import { mapToObj } from "remeda";
import { SimpleComboBox } from "../SimpleComboBox";
import { SimpleTextInput } from "../SimpleTextInput";
import type { Action } from "./reducer";

const FAKE_COLUMNS = [
  "amount",
  "bank_transfer_amount",
  "coupon_amount",
  "credit_card_amount",
  "customer_id",
  "gift_card_amount",
  "order_date",
  "order_id",
  "status",
];

const FAKE_TABLES = [
  "RAW_CUSTOMERS",
  "STG_CUSTOMERS",
  "CUSTOMERS",
  "RAW_ORDERS",
  "STG_ORDERS",
  "ORDERS",
  "RAW_PAYMENTS",
  "STG_PAYMENTS",
];

export function StepTwo({
  dispatch,
  disabled,
  className,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly disabled: boolean;
  readonly className?: string | undefined;
}): JSX.Element {
  const tableNameChangeHandler = useCallback(
    (tableName: string) => {
      dispatch({ type: "tableSelected", data: { tableName } });
    },
    [dispatch],
  );

  const columnNameChangeHandler = useCallback(
    (columnName: string) => {
      dispatch({ type: "columnSelected", data: { columnName } });
    },
    [dispatch],
  );

  const fakeColumns = useMemo(() => mapToObj(FAKE_COLUMNS, (key) => [key, key]), []);

  const fakeTables = useMemo(() => mapToObj(FAKE_TABLES, (key) => [key, key]), []);

  return (
    <section
      className={`flex flex-col items-start justify-center gap-3 transition duration-300 ${
        disabled ? "opacity-20" : ""
      } ${className ?? ""}`}
    >
      <h5 className="text-2xl font-medium">Step 2: Select column to rename</h5>
      <form className="flex w-96 flex-col flex-wrap gap-2">
        <FakeInput label="Repository:" value="foundational-io/ex1-jaffle_shop" />
        <FakeInput label="Database:" value="dbt_tests" />
        <FakeInput label="Schema:" value="temp_for_dbt" />
        <label className="flex items-center justify-between">
          Table:
          <SimpleComboBox
            className="z-50 w-60"
            disabled={disabled}
            optionsWithLabels={fakeTables}
            onChange={tableNameChangeHandler}
          />
        </label>
        <label className="flex items-center justify-between">
          Column:
          <SimpleComboBox
            className="z-40 w-60"
            disabled={disabled}
            optionsWithLabels={fakeColumns}
            onChange={columnNameChangeHandler}
          />
        </label>
      </form>
    </section>
  );
}

function FakeInput({
  label,
  value,
}: {
  readonly value: string;
  readonly label: string;
}): JSX.Element {
  return (
    <label className="flex items-center justify-between">
      {label}
      <SimpleTextInput disabled className="w-60" value={value} />
    </label>
  );
}
