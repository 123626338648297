export function BigMagnifyingGlass({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 58 57"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5 33L50.5 50"
        stroke="#A5ADB0"
        strokeLinecap="round"
        strokeWidth="4"
      />
      <circle cx="21" cy="20" r="18" stroke="#DEE4E4" strokeWidth="4" />
      <path
        d="M41.5 41L53.5 53"
        stroke="#DEE4E4"
        strokeLinecap="round"
        strokeWidth="8"
      />
    </svg>
  );
}
