import { useMutation, useQueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";

type UseOptimisticMutationOptions<Context, CachedData, MutationVariables> = {
  /**
   * The context for the query is the data needed to *fetch* the data we are mutating.
   * This needs to stay in sync with that hook!
   */
  readonly context: Context;

  /**
   * Creates the key that the *fetch* hook uses for the data we are mutating. This is
   * the cache key we will update optimistically.
   */
  readonly queryKeyFn: (context: Context) => readonly string[];

  /**
   * The server call that does the *actual* mutation of the data.
   */
  readonly mutationFn: (
    context: Context,
    variables: MutationVariables,
  ) => Promise<unknown>;

  /**
   * The optimistic update function that takes the value currently in the cache and the
   * variables of the mutation and returns a new value for the cache. The object
   * returned should be as similar as possible to the value that the fetcher hook would
   * return when refetching from the server after the mutation succeeds.
   * @param current The current value in the cache. It is highly recommeneded that you
   * use the helper type `QueryData` to type this param (e.g.
   * `QueryData<typeof useMyQuery>`) so that the two are tightly coupled. There are no
   * other ways to ensure that the typings match without it!
   * easily type this, and to couple it with the fetcher method so that
   * @param variables The variables of the mutation.
   * @returns The new value for the cache.
   */
  readonly optimisticMutationFn: (
    current: CachedData,
    variables: MutationVariables,
  ) => CachedData;

  /**
   * TODO: We don't need this in production use-cases, it's only useful
   * while building UX features in order to test loading states (e.g. slow connections).
   */
  readonly invalidationDelayMs?: number;
};

/**
 * Perform simple optimistic mutations to cached data. This basically follows the
 * example in the react-query docs for optimistic updates. There's just a lot of bolier-
 * plate code that complicates the basic hooks so it's better to abstract it away to a
 * helper hook.
 *
 * This hook only makes sense if you also have a query that fetches the data (a "GET"
 * method) and you are rendering it to the screen during the mutation (so you'd want to
 * show the user that the mutation happened). If the mutation happens "off screen" then
 * just use `useMutation`.
 *
 * @returns The same result as `useMutation` from react-query.
 */
export function useOptimisticMutation<Context, CachedData, MutationVariables>({
  context,
  queryKeyFn,
  mutationFn,
  optimisticMutationFn,
  invalidationDelayMs,
}: UseOptimisticMutationOptions<Context, CachedData, MutationVariables>) {
  const queryClient = useQueryClient();

  const queryKey = queryKeyFn(context);

  return useMutation({
    mutationFn: async (variables: MutationVariables) => mutationFn(context, variables),

    onMutate: async (variables) => {
      // Stop any inflight queries that might override our work here...
      await queryClient.cancelQueries({ queryKey });

      // Store the previous value so we can roll back on errors
      const previousValue = queryClient.getQueryData<CachedData>(queryKey);

      // Actually do the optimistic update
      queryClient.setQueryData<CachedData>(queryKey, (current) => {
        invariant(
          current !== undefined,
          "Trying to update cache data before it was fetched!",
        );

        return optimisticMutationFn(current, variables);
      });

      return { previousValue };
    },

    onError: (_error, _param, mutationContext) => {
      // Roll back optimistic updates
      queryClient.setQueryData<CachedData>(queryKey, mutationContext?.previousValue);
    },

    onSettled: () => {
      // TODO: We wrap the invalidation with a timeout so that we can see
      // the result while the mutation is fake. Remove this once we have a real mutation
      // implemented, as the invalidation should fetch the mutated data back properly.
      setTimeout(() => {
        // After the mutation is done, either successfully or unsuccessfully we want to
        // refetch the data from the server to make sure we are showing the most
        // accurate data.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries(queryKey);
      }, invalidationDelayMs);
    },
  });
}
