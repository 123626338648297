export function WarningIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 22 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.72505 18C1.34172 18 1.05838 17.8333 0.875049 17.5C0.691715 17.1667 0.691715 16.8333 0.875049 16.5L10.125 0.5C10.3084 0.166667 10.6 0 11 0C11.4 0 11.6917 0.166667 11.875 0.5L21.125 16.5C21.3084 16.8333 21.3084 17.1667 21.125 17.5C20.9417 17.8333 20.6584 18 20.275 18H1.72505ZM11 7C10.7167 7 10.4794 7.09567 10.288 7.287C10.096 7.479 10 7.71667 10 8V11C10 11.2833 10.096 11.5207 10.288 11.712C10.4794 11.904 10.7167 12 11 12C11.2834 12 11.521 11.904 11.713 11.712C11.9044 11.5207 12 11.2833 12 11V8C12 7.71667 11.9044 7.479 11.713 7.287C11.521 7.09567 11.2834 7 11 7ZM11 15C11.2834 15 11.521 14.904 11.713 14.712C11.9044 14.5207 12 14.2833 12 14C12 13.7167 11.9044 13.4793 11.713 13.288C11.521 13.096 11.2834 13 11 13C10.7167 13 10.4794 13.096 10.288 13.288C10.096 13.4793 10 13.7167 10 14C10 14.2833 10.096 14.5207 10.288 14.712C10.4794 14.904 10.7167 15 11 15ZM3.45005 16H18.55L11 3L3.45005 16Z" />
    </svg>
  );
}
