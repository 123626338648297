import { useCallback, useEffect } from "react";
import type { Dimensions, Node as ReactFlowNode, NodeProps } from "reactflow";
import { useReactFlow } from "reactflow";
import { createPipe, map } from "remeda";
import type { LineageRootEntity } from "../../api";
import { repositionElementsInGridLayout } from "../../utils/discovery/repositionElementsInGridLayout";
import { DiscoveryModeLineageRootWrapper } from "../discovery/DiscoveryModeLineageRootWrapper";
import { useIsCollapsedModeEnabled } from "../discovery/utils/collapsedModeStore";
import { Column, sumChildEntitiesHeights } from "./Column";
import {
  EXPAND_COLUMN_TOGGLE_HEIGHT,
  ExpandColumnsToggle,
} from "./ExpandColumnsToggle";
import { headerHeight, LineageRootNodeHeader } from "./LineageRootNodeHeader";

export const LINEAGE_ROOT_NODE_WIDTH = 320; // Class: w-80

export type LineageRootData = {
  readonly expandedEntities: Readonly<Record<string, boolean>>;
  readonly entity: LineageRootEntity;
  readonly layoutPosition?: Readonly<Record<"index" | "tier", number>>;
};

export const LINEAGE_NODE_TYPE = "lineage_root_node" as const;

/**
 * Convert an entity (coming from the backend API) to a node that could be positioned on
 * a graph.
 *
 * This does not add it to the chart!
 *
 * @param entity The entity to create the node for
 * @param tier The simple X position in the graph - whole integers
 */
export const toLineageRootNode = (
  entity: LineageRootEntity,
  tier?: number,
): ReactFlowNode<LineageRootData> => ({
  data: {
    entity,
    ...layoutData(tier),
    expandedEntities: {},
  },
  id: entity.id,
  type: LINEAGE_NODE_TYPE,

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  zIndex: 60,
  position: {
    x: 0,
    y: 0,
  },
  ...nodeLayoutDimensions(entity, {}),
});

export function LineageRootNode({
  data: { entity, expandedEntities },
  selected,
}: Readonly<NodeProps<LineageRootData>>): JSX.Element {
  const isGlobalCollapsedModeEnabled = useIsCollapsedModeEnabled();

  const isExpanded = expandedEntities[entity.id] ?? false;
  const { setNodes } = useReactFlow<LineageRootData>();

  const { id: entityId, childEntities } = entity;

  const handleColumnExpandChange = useCallback(
    (newIsExpanded: boolean, id: string) => {
      setNodes(
        createPipe(
          map((node) => {
            if (node.id !== entityId) {
              return node;
            }
            // eslint-disable-next-line no-param-reassign
            node.data = {
              ...node.data,
              expandedEntities: {
                ...node.data.expandedEntities,
                [id]: newIsExpanded,
              },
            };
            return node;
          }),
          repositionElementsInGridLayout,
        ),
      );
    },
    [entityId, setNodes],
  );

  const handleRootExpandChange = useCallback(
    (newIsExpanded: boolean) => {
      handleColumnExpandChange(newIsExpanded, entityId);
    },
    [entityId, handleColumnExpandChange],
  );

  useEffect(() => {
    // Synchronize the global collapsed mode state with the local one. This allows us to
    // collapse all tables when we enter collapsed mode, and then expand them when we
    // leave it. Notice that this means that we don't "remember" the collapsed state of
    // each individual node once a user leaves the collapsed mode and then re-enables
    // it. To do that you'd need to remove this effect entirely and make the columns
    // render based on the global collapsed state in addition to the local one.
    handleRootExpandChange(!isGlobalCollapsedModeEnabled);
  }, [handleRootExpandChange, isGlobalCollapsedModeEnabled]);

  return (
    <DiscoveryModeLineageRootWrapper entityId={entityId}>
      <article
        className={`isolate w-80 rounded-b shadow-[0px_1px_0px_rgba(0,0,0,0.04),0px_1px_5px_rgba(0,0,0,0.14)] transition-transform duration-75 ${
          // TODO: Push a PR to reactflow to add a `data-` attribute for
          // selected state, similar to how it's done in HeadlessUI, so that we can
          // target styling via tailwind without needing JS. Once that is available we
          // can rever the change that added the "article" node and put the className
          // directly in the containing `div` created by react-flow automatically;
          // reducing the memory footprint our rendered graph.
          selected
            ? "shadow-xl ring-2 ring-[rgb(116,85,206)] transition-shadow duration-300"
            : ""
        } ${entity.type === "dashboard" ? "bg-[rgba(222,255,254,0.75)]" : "bg-white"}`}
      >
        <LineageRootNodeHeader entity={entity} />
        <ExpandColumnsToggle
          columnsCount={childEntities.length}
          expanded={isExpanded}
          onChange={handleRootExpandChange}
        />
        {isExpanded ? (
          <ul>
            {childEntities.map((child) => (
              <Column
                key={child.entityName}
                column={child}
                expandedEntities={expandedEntities}
                onColumnExpandChange={handleColumnExpandChange}
              />
            ))}
          </ul>
        ) : null}
      </article>
    </DiscoveryModeLineageRootWrapper>
  );
}

/**
 * We use fixed dimensions so that our layout engine can lay out the graph on
 * the first render, without needing to render the nodes first to the DOM just
 * to get their sizes. These numbers are computed manually from understanding
 * how `TableNode` and `Column` would render.
 */
// TODO: Add a test that would render a node and make sure these numbers are correct.
export const nodeLayoutDimensions = (
  { id, entityName, childEntities }: LineageRootEntity,
  expandedEntities: Readonly<Record<string, boolean>>,
): Dimensions => {
  const isExpanded = expandedEntities[id] ?? false;
  return {
    width: LINEAGE_ROOT_NODE_WIDTH,
    height:
      headerHeight(entityName) +
      EXPAND_COLUMN_TOGGLE_HEIGHT +
      sumChildEntitiesHeights(isExpanded, childEntities, expandedEntities),
  };
};

export const layoutData = (
  tier: number | undefined,
): Pick<LineageRootData, "layoutPosition"> | undefined =>
  tier === undefined
    ? undefined
    : {
        layoutPosition: {
          tier,
          // Put any new node at the end of the sort order for the tier. The
          // repositioning algorithm would then set it a concrete index instead.
          index: Number.POSITIVE_INFINITY,
        },
      };
