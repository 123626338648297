import { useCallback, type ChangeEvent, type Dispatch } from "react";
import { SimpleTextInput } from "../SimpleTextInput";
import type { Action } from "./reducer";

export function StepThree({
  dispatch,
  disabled,
  className,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly disabled: boolean;
  readonly className?: string | undefined;
}): JSX.Element {
  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "renameToProvided",
        data: { renameTo: e.target.value },
      });
    },
    [dispatch],
  );

  return (
    <section
      className={`flex flex-col items-start justify-center gap-3 transition duration-300 ${
        disabled ? "opacity-20" : ""
      } ${className ?? ""}`}
    >
      <h5 className="text-2xl font-medium">Step 3: Rename to</h5>
      <form className="flex w-96 flex-col flex-wrap gap-2">
        <label className="flex items-center justify-between">
          New name:
          <SimpleTextInput
            autoComplete="off"
            className="w-60 outline-none"
            disabled={disabled}
            name="renameTo"
            onChange={changeHandler}
          />
        </label>
      </form>
    </section>
  );
}
