/* eslint-disable import/no-deprecated */
import { useMemo } from "react";
import {
  rootEntityFromTable,
  useRootEntityCodePointers,
  type CodePointer,
  type LineageRootEntity,
} from "../../api";
import type { LineageTable } from "../../utils/graph/type";
import { ArrowTopRightOnSquareIcon } from "../icons/ArrowTopRightOnSquareIcon";
import { MissingSectionContents } from "./MissingSectionContents";

const CODE_POINTERS_PANEL_ENABLED = false;

export function CodePointersPanel({
  table,
}: {
  readonly table: LineageTable;
}): JSX.Element {
  const rootEntity = useMemo(() => rootEntityFromTable(table), [table]);

  return (
    <section className="flex flex-1 flex-col gap-6 p-6">
      {CODE_POINTERS_PANEL_ENABLED ? (
        <CodePointersInternal rootEntity={rootEntity} />
      ) : (
        <MissingSectionContents />
      )}
    </section>
  );
}

function CodePointersInternal({
  rootEntity,
}: {
  readonly rootEntity: LineageRootEntity;
}): JSX.Element {
  const { data: codePointers } = useRootEntityCodePointers(rootEntity);

  if (codePointers === undefined) {
    return (
      <div className="animate-pulse text-xs font-medium">Fetching code pointers...</div>
    );
  }

  return (
    <ol className="flex list-disc flex-col gap-1 px-4 py-2 marker:text-neutral-400">
      {codePointers.map((pointer) => (
        <li key={pointer.url}>
          <CodePointerLink codePointer={pointer} />
        </li>
      ))}
    </ol>
  );
}

function CodePointerLink({
  codePointer: { label, url },
}: {
  readonly codePointer: CodePointer;
}): JSX.Element {
  const asUrl = new URL(url);
  return (
    <a
      className="group block items-center rounded p-2 text-violet-800 hover:bg-neutral-50 group-hover:underline"
      href={url}
      rel="noreferrer"
      target="_blank"
      title={url}
    >
      <span className="me-2 text-sm font-medium group-hover:underline">
        {label ?? asUrl.pathname}
      </span>
      <ArrowTopRightOnSquareIcon className="me-2 inline h-3 w-3" />
      <span className="text-xs font-normal text-neutral-300">[{asUrl.hostname}]</span>
    </a>
  );
}
