import { memo, type DetailedHTMLProps, type HTMLAttributes } from "react";
import { equals, map, pipe } from "remeda";
import type { MatchDescriptor } from "../../utils/search/SEARCHERS";
import { prepareInputForRendering } from "../../utils/search/prepareInputForRendering";
import { withWordBreakOpportunities } from "../render_utils";

export const HighlightedText = memo(
  function HighlightedText({
    text,
    segments,
    className,
    ...spanProps
  }: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
    readonly text: string;
    readonly segments?: MatchDescriptor["segments"] | undefined;
  }): JSX.Element {
    return (
      <span className={`break-words ${className ?? ""}`} {...spanProps}>
        {pipe(
          text,
          ($) =>
            segments === undefined
              ? [{ text, isHighlighted: false }]
              : prepareInputForRendering($, segments),
          map(({ text: substring, isHighlighted }) => {
            const segment = withWordBreakOpportunities(substring);

            return isHighlighted ? (
              <mark key={segment} className="inline-block text-inherit">
                {segment}
              </mark>
            ) : (
              segment
            );
          }),
        )}
      </span>
    );
  },
  // We want to compare the segments arrays by value (deeply), not by reference.
  equals,
);
