import type { Node as ReactFlowNode } from "reactflow";
import { flatMap, groupBy, map, pipe, sortBy, toPairs } from "remeda";
import invariant from "tiny-invariant";
import type { SetRequired } from "type-fest";
import {
  LINEAGE_ROOT_NODE_WIDTH,
  type LineageRootData,
  nodeLayoutDimensions,
} from "../../components/graph/LineageRootNode";

const PADDING_Y = 24;
const PADDING_X = 128;

export const repositionElementsInGridLayout = (
  allNodes: readonly ReactFlowNode<LineageRootData>[],
): ReactFlowNode<LineageRootData>[] =>
  pipe(
    allNodes,
    map((node) => {
      invariant(
        hasLayoutPosition(node),
        `Node ${node.id} is missing a layout position`,
      );
      return node;
    }),
    groupBy.strict(
      ({
        data: {
          layoutPosition: { tier },
        },
      }) => tier,
    ),
    toPairs.strict,
    // actual layout - go over each column position according to y values.
    flatMap(([tierAsString, nodes]) => {
      // During the groupBy the key gets coalecsed from a number to string (that's
      // the only real supported key type for an object), after flattening the
      // object into an array of tuples we can convert it back.
      const tier = Number.parseInt(tierAsString);
      const posX = tier * (LINEAGE_ROOT_NODE_WIDTH + PADDING_X);

      let nextPosY = 0;
      return pipe(
        nodes,
        sortBy(
          ({
            data: {
              layoutPosition: { index },
            },
          }) => index,
        ),
        map.indexed(
          (
            {
              data: {
                entity,
                expandedEntities,
                // Remove previous layout position
                layoutPosition: _,
                // We copy all remaining data elements as-is, although currently
                // (2023-03-21) this is trivially empty
                ...data
              },
              ...node
            },
            index,
          ) => {
            const updatedNode: ReactFlowNode<LineageRootData> = {
              ...node,
              data: {
                ...data,
                entity,
                expandedEntities,
                layoutPosition: { tier, index },
              },
              position: { x: posX, y: nextPosY },
            };
            nextPosY +=
              nodeLayoutDimensions(entity, expandedEntities).height + PADDING_Y;
            return updatedNode;
          },
        ),
      );
    }),
  );

export const hasLayoutPosition = (
  x: ReactFlowNode<LineageRootData>,
): x is ReactFlowNode<SetRequired<LineageRootData, "layoutPosition">> =>
  x.data.layoutPosition !== undefined;
