import {
  createContext,
  useCallback,
  useContext,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from "react";
import type { Settings } from "../../utils/settings/types";

type SubSettingsType<T extends keyof Settings> = readonly [
  subSettings: Settings[T] | undefined,
  setSubSettings: (newSettings: Settings[T]) => void,
];

export function useSubSettings<T extends keyof Settings>(
  settingName: T,
): SubSettingsType<T> {
  const [settings, setSettings] = useContext(SettingsContext);

  const setSubSettings = useCallback(
    (newSetting: Settings[T]) => {
      setSettings((current) => ({ ...current, [settingName]: newSetting }));
    },
    [setSettings, settingName],
  );

  return [settings?.[settingName], setSubSettings];
}

// This is a copy of the return value of React.useState with S defined as
// `Settings | undefined`. Unfortunately they didn't define a type for it
// explicitly

type SettingsContextType = readonly [
  settings: Settings | undefined,
  setSettings: Dispatch<SetStateAction<Settings>>,
];

const SettingsContext = createContext<SettingsContextType>([
  undefined,
  (_newSettings): never => {
    throw new Error("Trying to mutate the context before outside its provider");
  },
]);

export function SettingsProvider({
  settings,
  children,
}: PropsWithChildren<{ readonly settings: Settings }>): JSX.Element {
  // eslint-disable-next-line react/hook-use-state -- We hook the state directly to the context
  const state = useState(settings);

  return <SettingsContext.Provider value={state}>{children}</SettingsContext.Provider>;
}
