import { useCallback } from "react";
import { useApiRequest } from "../../components/settings/useApiRequest";
import { DEMO_MODE } from "../../utils/demo/demoMode";
import { EXAMPLE_SLACK_CHANNELS } from "../../utils/demo/example";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import type {
  SlackAuthenticationRequest,
  SlackAuthenticationResponse,
} from "./schemas";

export const useSlackAuthRequest = () =>
  useQueryRetriesServerErrors({
    queryKey: ["getSlackAuthRequest"],
    queryFn: getSlackAuthRequest,
  });

const getSlackAuthRequest = async (): Promise<SlackAuthenticationRequest> =>
  DEMO_MODE.isEnabled ? {} : queryApiCall("/config/slack/check_installation");

export function useCompleteSlackAuthCallback() {
  const api = useApiRequest();
  return useCallback(
    async (body: SlackAuthenticationResponse) => {
      try {
        await api("/config/slack/oauth", body, "POST");
      } catch (serverError) {
        return serverError;
      }
    },
    [api],
  );
}

export const useSlackRecipients = () =>
  useQueryRetriesServerErrors({
    queryKey: ["getSlackRecipients"],
    queryFn: getSlackRecipients,
  });

const getSlackRecipients = async (): Promise<readonly string[]> =>
  DEMO_MODE.isEnabled
    ? EXAMPLE_SLACK_CHANNELS
    : queryApiCall("/config/slack/list_recipients");
