import { filter, first, flatMap, isDefined, map, pipe } from "remeda";
import type {
  LineageChildEntity,
  LineageEntityType,
  LineageRootEntity,
  LineageState,
} from "../../api";
import type { LineageSearchEntityDescriptor } from "./lineage";
import { searchById } from "./lineage";

export type FilterType = Exclude<LineageEntityType, "file"> | "edge";

/**
 * We need to make sure we run the same exact logic for both the FiltersTabs (where we
 * show the count of entities for each type) and the actual filtered view (where we show
 * entity itself). This method centralizes the logic so that it always works the same.
 */
export function searchLineageByType(
  targetType: FilterType,
  entities: LineageState["entities"],
  edges: LineageState["edges"],
): LineageSearchEntityDescriptor[] {
  switch (targetType) {
    case "column":
      return flatMap(entities, (entity) =>
        flatMap(entity.childEntities, (child) => searchColumnType(entity, child)),
      );

    case "dashboard":
    case "table":
      return pipe(
        entities,
        filter(
          ({ type: entityType }) =>
            entityType === targetType ||
            (targetType === "table" && entityType === "file"),
        ),
        map((entity) => ({
          rootEntity: entity,
          result: entity,
          resultNameRelativeToRoot: "",
        })),
      );

    case "edge":
      return searchEdgeType(entities, edges);
  }
}

function searchColumnType(
  rootEntity: LineageRootEntity,
  curEntity: LineageChildEntity,
  prevRelativePath = "",
): LineageSearchEntityDescriptor[] {
  const curRelativePath = `${prevRelativePath}.${curEntity.entityName}`;
  return [
    {
      rootEntity,
      result: curEntity,
      resultNameRelativeToRoot: curRelativePath,
    },
    ...flatMap(curEntity.childEntities, (child) =>
      searchColumnType(rootEntity, child, curRelativePath),
    ),
  ];
}

// TODO: We currently don't support edges/edges in search results
// so we do this hack to support them. We should add native support for this!
function searchEdgeType(
  entities: LineageState["entities"],
  edges: LineageState["edges"],
): LineageSearchEntityDescriptor[] {
  const idToRootEntityMap: Record<string, LineageRootEntity> = {};
  for (const entity of entities) {
    idToRootEntityMap[entity.id] = entity;
  }
  return pipe(
    edges,
    map(({ source: { rootId, id }, changeStatus: edgeChangeStatus }) => {
      const { [rootId]: rootEntity } = idToRootEntityMap;
      if (rootEntity === undefined) {
        return;
      }

      const edgeSourceResult = first(pipe([rootEntity], searchById(id)));
      if (edgeSourceResult === undefined) {
        return;
      }

      return {
        rootEntity,
        result: { ...edgeSourceResult.result, changeStatus: edgeChangeStatus },
        resultNameRelativeToRoot: edgeSourceResult.resultNameRelativeToRoot,
      };
    }),
    filter(isDefined),
  );
}
