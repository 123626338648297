import { MetricBox } from "./MetricBox";

export function DashboardMetrics(): JSX.Element {
  return (
    <ul className="flex flex-wrap gap-8">
      <MetricBox
        change={-0.2345}
        color="green"
        label="PR Cycle Time"
        value="1.3 days"
      />
      <MetricBox
        change={-0.42}
        color="red"
        label="Changes in the last week"
        value="22"
      />
      <MetricBox change={0} label="Open Issues" value="11" />
    </ul>
  );
}
