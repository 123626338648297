import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import type {
  SettingsOverviewProps,
  SourceInstance,
} from "../../../utils/settings/types";
import { BaseConfigCard } from "../BaseConfigCard";
import { BaseConfigDrawer } from "../BaseConfigDrawer";
import { useSubSettings } from "../settingsProvider";
import { useApiRequest } from "../useApiRequest";
import { MainConfigScreen } from "./MainConfigScreen";
import { NewSourceScreen } from "./NewSourceScreen";
import { BASE_URL_PREFIX, TABLEAU_DISPLAY_NAME, TABLEAU_NAME } from "./consts";

const SUFFIX_TRUNCATE = ".iam.gserviceaccount.com";

export function TableauConfigOverview({
  onConfigure,
}: SettingsOverviewProps): JSX.Element {
  const [rawAccounts] = useSubSettings(TABLEAU_NAME);
  const accounts = rawAccounts ?? [];
  const [firstAccount] = accounts;

  const usernameIfExists =
    firstAccount?.username === undefined ? "" : ` (${firstAccount.username})`;
  return (
    <BaseConfigCard
      avatarPath="/img/connectors/tableau.svg"
      configured={accounts.length > 0}
      loading={rawAccounts === undefined}
      sourceName={TABLEAU_DISPLAY_NAME}
      onConfigure={onConfigure}
    >
      <>
        <Typography gutterBottom component="div" variant="h6">
          Configured Accounts
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {firstAccount !== undefined && (
            <>
              {`${firstAccount.source_identifier.replace(
                SUFFIX_TRUNCATE,
                "",
              )}${usernameIfExists}`}
              <br />
              {accounts.length > 1
                ? `... (${accounts.length - 1} more configured)`
                : "1 account configured"}
            </>
          )}
        </Typography>
      </>
    </BaseConfigCard>
  );
}

export function TableauSettings(): JSX.Element {
  const [accounts, setAccounts] = useSubSettings(TABLEAU_NAME);
  const [isAddAccount, setIsAddAccount] = useState(false);
  const [currentInstance, setCurrentInstance] = useState<SourceInstance>();
  const api = useApiRequest();

  const updateAccounts = useCallback(async () => {
    const response = await api(`${BASE_URL_PREFIX}/keys/`);
    setAccounts(response as [SourceInstance]);
  }, [api, setAccounts]);

  const deleteAccount = useCallback(
    async (account: SourceInstance) => {
      await api(`${BASE_URL_PREFIX}/keys/`, account, "DELETE");
      await updateAccounts();
    },
    [api, updateAccounts],
  );

  const doneHandler = useCallback(
    (canceled: boolean) => {
      setCurrentInstance(undefined);
      setIsAddAccount(false);

      if (canceled) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateAccounts();
    },
    [updateAccounts],
  );

  const addSourceHandler = useCallback(() => {
    setIsAddAccount(true);
  }, []);

  const editSourceHandler = useCallback((identifier: SourceInstance) => {
    setCurrentInstance(identifier);
    setIsAddAccount(true);
  }, []);

  const deleteHandler = useCallback(
    (identifier: SourceInstance) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      deleteAccount(identifier);
    },
    [deleteAccount],
  );

  return (
    <BaseConfigDrawer sourceName={TABLEAU_DISPLAY_NAME}>
      {!isAddAccount && (
        <MainConfigScreen
          accounts={accounts ?? []}
          onAddSource={addSourceHandler}
          onDelete={deleteHandler}
          onEdit={editSourceHandler}
        />
      )}
      {isAddAccount ? (
        <NewSourceScreen initialSourceData={currentInstance} onDone={doneHandler} />
      ) : null}
    </BaseConfigDrawer>
  );
}
