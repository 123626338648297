import { useCallback, useId, type ChangeEventHandler, type ReactElement } from "react";
import type { StringKeyOf } from "type-fest";

export type UserDetails = {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
};
export const EMPTY_USER: UserDetails = {
  firstName: "",
  lastName: "",
  email: "",
};

export function NewUserForm({
  index,
  onUpdateDetails,
}: {
  readonly index: number;
  readonly onUpdateDetails: (
    idx: number,
    key: StringKeyOf<UserDetails>,
    value: string,
  ) => void;
}): ReactElement {
  const firstNameId = useId();
  const lastNameId = useId();
  const emailId = useId();

  return (
    <>
      <div className="my-1 flex">
        <label className="mr-3 py-1" htmlFor={firstNameId}>
          First Name
        </label>
        <input
          autoComplete="off"
          autoCorrect="off"
          className="flex-grow border-2 p-1 text-sm  outline-1"
          id={firstNameId}
          placeholder="John"
          spellCheck={false}
          onChange={useCallback<ChangeEventHandler<HTMLInputElement>>(
            (e) => {
              onUpdateDetails(index, "firstName", e.target.value);
            },
            [onUpdateDetails, index],
          )}
        />
      </div>
      <div className="my-1 flex flex-row">
        <label className="mr-3 py-1" htmlFor={lastNameId}>
          Last Name
        </label>
        <input
          autoComplete="off"
          autoCorrect="off"
          className="flex-grow border-2 p-1 text-sm outline-1"
          id={lastNameId}
          placeholder="Doe"
          spellCheck={false}
          onChange={useCallback<ChangeEventHandler<HTMLInputElement>>(
            (e) => {
              onUpdateDetails(index, "lastName", e.target.value);
            },
            [onUpdateDetails, index],
          )}
        />
      </div>
      <div className="my-1 flex flex-row">
        <label className="mr-3 py-1" htmlFor={emailId}>
          Email
        </label>
        <input
          autoComplete="off"
          autoCorrect="off"
          className="flex-grow border-2 p-1 text-sm outline-1"
          id={emailId}
          placeholder="here@be.dragons"
          spellCheck={false}
          onChange={useCallback<ChangeEventHandler<HTMLInputElement>>(
            (e) => {
              onUpdateDetails(index, "email", e.target.value);
            },
            [onUpdateDetails, index],
          )}
        />
      </div>
    </>
  );
}
