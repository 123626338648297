import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
import type { CreatePolicyRequestModel } from "../../../api";
import { useCreatePolicyMutation } from "../../../api";
import { CircledExclamationMarkIcon } from "../../icons/CircledExclamationMarkIcon";
import { CreatePolicyScreenBody } from "./CreatePolicyScreenBody";
import { CreatePolicyScreenHeader } from "./CreatePolicyScreenHeader";

const DEFAULT_ERROR_MESSAGE = "Unexpected error occurred while creating policy";

export function CreatePolicyScreen({
  policiesScreenPath,
}: {
  readonly policiesScreenPath: string;
}): JSX.Element {
  const navigate = useNavigate();
  const [policy, setPolicy] = useState<CreatePolicyRequestModel>();
  const createPolicyMutation = useCreatePolicyMutation();
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleCancel = useCallback(() => {
    navigate(policiesScreenPath);
  }, [policiesScreenPath, navigate]);

  const handleSave = useCallback(() => {
    invariant(policy !== undefined, "Policy must be defined on creation");
    createPolicyMutation.mutate(policy, {
      onSuccess: () => {
        navigate(policiesScreenPath);
      },
      onError: (error) => {
        if (!(error instanceof Response)) {
          setErrorMessage(DEFAULT_ERROR_MESSAGE);
          return;
        }

        const fetchError = async () => {
          const { detail } = await (error.json() as Promise<{
            readonly detail?: string;
          }>);
          setErrorMessage(detail ?? DEFAULT_ERROR_MESSAGE);
        };

        /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
        fetchError();
      },
    });
  }, [createPolicyMutation, policy, navigate, policiesScreenPath]);

  const handlePolicyChange = useCallback(
    (newPolicy: CreatePolicyRequestModel | undefined) => {
      setPolicy(newPolicy);
    },
    [],
  );

  return (
    <div className="flex h-full w-full min-w-[768px] flex-col overflow-auto bg-[rgb(240,242,242)]">
      {errorMessage === undefined ? null : <ErrorBanner errorMessage={errorMessage} />}
      <div className="flex flex-1 flex-col gap-y-5 px-20 pb-5 pt-4">
        <CreatePolicyScreenHeader
          isSaveButtonEnabled={policy !== undefined}
          onCancel={handleCancel}
          onSave={handleSave}
        />
        <div className="h-px w-full bg-gray-300" />
        <CreatePolicyScreenBody
          onError={setErrorMessage}
          onPolicyChange={handlePolicyChange}
        />
      </div>
    </div>
  );
}

function ErrorBanner({ errorMessage }: { readonly errorMessage: string }): JSX.Element {
  return (
    <div className="flex items-center gap-x-2 bg-[rgba(246,111,81,0.15)] px-20 py-3">
      <CircledExclamationMarkIcon className="h-5 w-5 text-[rgb(218,67,34)]" />
      <span className="text-sm font-normal leading-5 text-[rgb(218,67,34)]">
        {errorMessage}
      </span>
    </div>
  );
}
