import { useCallback, useState } from "react";
import type {
  CreatePolicyRequestModel,
  PolicyActionsData,
  PolicyRulesData,
} from "../../../api";
import { ActionsSection } from "./ActionsSection";
import { NewPolicyNameInput } from "./NewPolicyNameInput";
import { TriggersSection } from "./TriggersSection";

export function CreatePolicyScreenBody({
  onPolicyChange,
  onError,
  className,
}: {
  readonly onPolicyChange: (policy: CreatePolicyRequestModel | undefined) => void;
  readonly onError: (err: string) => void;
  readonly className?: string | undefined;
}): JSX.Element {
  const [policyName, setPolicyName] = useState<string | undefined>(undefined);
  const [rulesData, setRulesData] = useState<PolicyRulesData | undefined>(undefined);
  const [actionsData, setActionsData] = useState<PolicyActionsData | undefined>(
    undefined,
  );

  const handlePolicyNameChange = useCallback(
    (newName: string | undefined) => {
      setPolicyName(newName);
      onPolicyChange(asCreatePolicyRequestModel(newName, rulesData, actionsData));
    },
    [onPolicyChange, rulesData, actionsData],
  );

  const handleTriggersChange = useCallback(
    (newRulesData: PolicyRulesData | undefined) => {
      setRulesData(newRulesData);
      onPolicyChange(asCreatePolicyRequestModel(policyName, newRulesData, actionsData));
    },
    [onPolicyChange, policyName, actionsData],
  );

  const handleActionsDataChange = useCallback(
    (newActionsData: PolicyActionsData | undefined) => {
      setActionsData(newActionsData);
      onPolicyChange(asCreatePolicyRequestModel(policyName, rulesData, newActionsData));
    },
    [onPolicyChange, policyName, rulesData],
  );

  return (
    <div className={`flex h-full w-full flex-col gap-y-5 ${className ?? ""}`}>
      <NewPolicyNameInput onPolicyNameChange={handlePolicyNameChange} />
      <div className="flex flex-1 items-stretch gap-x-4">
        <TriggersSection className="flex-1" onTriggersChange={handleTriggersChange} />
        <ActionsSection
          className="flex-1"
          onActionsChange={handleActionsDataChange}
          onError={onError}
        />
      </div>
    </div>
  );
}

function asCreatePolicyRequestModel(
  policyName: string | undefined,
  rulesData: PolicyRulesData | undefined,
  actionsData: PolicyActionsData | undefined,
): CreatePolicyRequestModel | undefined {
  if (
    policyName === undefined ||
    rulesData === undefined ||
    actionsData === undefined
  ) {
    return undefined;
  }

  return {
    name: policyName,
    rulesData,
    actionsData,
    isEnabled: true,
  };
}
