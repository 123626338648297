import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import type { MultiLineage } from "./schemas";
import { usePullRequest } from "./usePullRequest";

type UseRouteLineageOptions = {
  /**
   * If this is true and the lineage is either missing or simply still fetching the
   * component will through, use only if a parent component will prevent rendering this
   * component when the lineage isn't loaded.
   */
  readonly assertIsLoaded: boolean;
};

export function useRouteLineage(config?: {
  assertIsLoaded: true;
}): MultiLineage | undefined;
export function useRouteLineage(
  config?: UseRouteLineageOptions,
): MultiLineage | undefined {
  const { repoName, prId } = useParams();

  const { data: prData } = usePullRequest(repoName, prId);

  invariant(
    config === undefined || !config.assertIsLoaded || prData !== undefined,
    "Route lineage must be loaded for this component to render!",
  );

  return prData;
}
