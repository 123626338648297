import Typography from "@mui/material/Typography";

export function Instructions(): JSX.Element {
  return (
    <>
      <Typography component="div" mt={1} variant="h6">
        Instructions
      </Typography>
      <Typography fontWeight="bold" variant="subtitle1">
        Server Name
      </Typography>
      The URL of your tableau server
      <Typography fontWeight="bold" mt={1} variant="subtitle1">
        Site Name
      </Typography>
      For Tableau Server the default is <i>Default</i> (if you changed it, use the new
      name)
      <br />
      For Tableau Online, it&apos;s the name of your site (search for #/site/
      <b>site_name</b> in the URL)
      <Typography fontWeight="bold" mt={1} variant="subtitle1">
        Token Name & Secret
      </Typography>
      Go to <i>My Account Settings</i> at the top right corner.
      <br />
      Under <i>Personal Access Tokens</i> section, name your new token and click{" "}
      <i>Create new token</i>. Copy the name and secret over here.
      <br />
      <b>Important note:</b> When done, don&apos;t click the <i>Discard</i> button,
      it&apos;ll revoke your newly created key
      <Typography fontWeight="bold" mt={1} variant="subtitle1">
        Enabled Projects
      </Typography>
      The projects which you want us to extract from Tableau (allows to filter tests and
      example projects)
    </>
  );
}
