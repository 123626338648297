import { GithubFakePR } from "./GithubFakePR";
import { TableauFakeInstructions } from "./TableauFakeInstructions";
import type { RefactorState } from "./reducer";

export function RefactorArtifacts({
  state,
}: {
  readonly state: RefactorState;
}): JSX.Element {
  return (
    <section>
      <h5 className="text-2xl font-medium">Artifacts:</h5>
      <ul className="m-5 flex flex-col gap-y-4">
        <li>
          <GithubFakePR
            columnName={state.columnName}
            renameTo={state.renameTo}
            tableName={state.tableName}
          />
        </li>
        <li>
          <TableauFakeInstructions />
        </li>
      </ul>
    </section>
  );
}
