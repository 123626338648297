import { usePrInfo, type PrItem } from "../../api";
import { CommitIcon } from "../icons/CommitIcon";
import { GithubIcon } from "../icons/GithubIcon";
import { PullRequestIcon } from "../icons/PullRequestIcon";
import { useRequiredParam } from "../useRequiredParam";
import { PullRequestStatus } from "./PullRequestStatus";

const COMMIT_HASH_SIGNIFICANT_CHARS = 7;

export function PrMetadata({ prNumber }: { readonly prNumber: number }): JSX.Element {
  const repoName = useRequiredParam("repoName");
  const { data: prItem } = usePrInfo(repoName, prNumber);

  return (
    <section className="flex flex-none flex-col gap-3 overflow-hidden px-5 py-4 text-sm text-[rgb(143,153,157)]">
      <a
        className="flex items-center gap-2 font-semibold"
        href={asGithubUrl(repoName, prNumber)}
        rel="noreferrer"
        target="_blank"
        title="View on GitHub"
      >
        <PullRequestIcon className="w-3 text-[rgb(165,173,176)]" />
        {prNumber}
        {prItem?.head_hash !== undefined && (
          <>
            <div className="h-4 w-px bg-[rgb(205,215,215)]" />
            <CommitIcon className="w-4 text-[rgb(165,173,176)]" />
            <span className="flex-1" title={prItem.head_hash}>
              {prItem.head_hash.slice(0, COMMIT_HASH_SIGNIFICANT_CHARS)}
            </span>
          </>
        )}
        <GithubIcon className="h-5 text-[rgb(165,173,176)] hover:text-[rgb(143,153,157)]" />
      </a>
      {prItem === undefined ? (
        <div className="animate-pulse">Loading...</div>
      ) : (
        <PullRequestStatus iconClassName="w-[14px]" item={prItem}>
          <PrMetadataCreator createdBy={prItem.created_by} />
        </PullRequestStatus>
      )}
    </section>
  );
}

function PrMetadataCreator({
  createdBy: { name: creatorName, login },
}: {
  readonly createdBy: PrItem["created_by"];
}): JSX.Element {
  return (
    <strong
      className="font-semibold text-[rgb(82,97,100)]"
      title={creatorName === undefined ? login : `${creatorName} (${login})`}
    >
      {creatorName ?? login}
    </strong>
  );
}

// TODO: The pull request response from Github contains a pre-built URL for
// the PR (named `url`), that might be different from this. We should consider fetching
// it and providing it here instead.
// @see https://docs.github.com/en/rest/pulls/pulls?apiVersion=2022-11-28#get-a-pull-request
const asGithubUrl = (repoName: string, prNumber: number) =>
  `https://github.com/${repoName}/pull/${prNumber}`;
