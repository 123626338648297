import type {
  PolicyAction,
  PolicyActionsData,
  PolicyActionType,
} from "../../../../api";
import { EnvelopeIcon } from "../../../icons/EnvelopeIcon";
import { PolicyActionIcon } from "../../../icons/PolicyActionIcon";
import { PrReviewerIcon } from "../../../icons/PrReviewerIcon";
import { SlackIcon } from "../../../icons/SlackIcon";

export function PolicyActionsView({
  actionsData,
  className,
}: {
  readonly actionsData: PolicyActionsData;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <section
      className={`flex flex-col gap-y-2 rounded-md bg-gray-100 p-3 ${className ?? ""}`}
    >
      <PolicyActionsHeadline />
      <PolicyActionsBody actionsData={actionsData} />
    </section>
  );
}

function PolicyActionsHeadline(): JSX.Element {
  return (
    <div className="flex items-center gap-x-2">
      <PolicyActionIcon className="h-5 w-5 text-[rgb(185,194,195)]" />
      <h1 className="text-sm font-semibold leading-5 text-[rgb(143,156,157)]">
        Actions
      </h1>
    </div>
  );
}

function PolicyActionsBody({
  actionsData,
}: {
  readonly actionsData: PolicyActionsData;
}): JSX.Element {
  return (
    <ol className="flex flex-col gap-y-1 px-7">
      {actionsData.actions.map((action, index) => (
        <li key={keyForAction(action, index)} className="flex flex-col gap-y-1">
          <PolicyActionView action={action} />
          {index === actionsData.actions.length - 1 ? null : (
            <span className="text-xs leading-5 text-[rgb(143,156,157)]">And</span>
          )}
        </li>
      ))}
    </ol>
  );
}

const keyForAction = (action: PolicyAction, index: number): string =>
  `${action.action_type}_${index}`;

function PolicyActionView({ action }: { readonly action: PolicyAction }): JSX.Element {
  return (
    <div className="flex items-center gap-x-1.5">
      <PolicyActionLabel action={action} />
      <PolicyActionSubjectsView action={action} />
    </div>
  );
}

function PolicyActionLabel({ action }: { readonly action: PolicyAction }): JSX.Element {
  return (
    <span className="text-sm font-medium leading-5 text-[rgb(74,89,92)]">
      {labelForAction(action)}
    </span>
  );
}

function labelForAction({ action_type }: PolicyAction): string {
  switch (action_type) {
    case "send_email":
      return "Send an email to";
    case "send_slack_message":
      return "Alert on";
    case "add_pr_reviewer":
      return "Add PR reviewer";
  }
}

function PolicyActionSubjectsView({
  action,
}: {
  readonly action: PolicyAction;
}): JSX.Element {
  return (
    <div className="flex flex-wrap items-center gap-1 leading-7">
      {subjectsForAction(action).map((subject) => (
        <ActionSubjectBox
          key={subject}
          actionType={action.action_type}
          subject={subject}
        />
      ))}
    </div>
  );
}

function subjectsForAction(action: PolicyAction): readonly string[] {
  switch (action.action_type) {
    case "send_email":
      return action.recipients;
    case "send_slack_message":
      return action.channels;
    case "add_pr_reviewer":
      return [action.reviewer_user_name];
  }
}

function ActionSubjectBox({
  actionType,
  subject,
}: {
  readonly actionType: PolicyActionType;
  readonly subject: string;
}): JSX.Element {
  return (
    <div className="flex items-center gap-x-1 rounded bg-white py-0.5 pl-0.5 pr-2 text-sm font-medium text-[rgb(82,97,100)] shadow">
      <ActionSubjectIcon actionType={actionType} />
      {subject}
    </div>
  );
}

function ActionSubjectIcon({
  actionType,
}: {
  readonly actionType: PolicyActionType;
}): JSX.Element {
  switch (actionType) {
    case "send_email":
      return <EnvelopeIcon className="h-5 w-5 text-[rgb(185,194,195)]" />;
    case "send_slack_message":
      return <SlackIcon className="h-5 w-5 p-0.5" />;
    case "add_pr_reviewer":
      return <PrReviewerIcon className="h-5 w-5 text-[rgb(185,194,195)]" />;
  }
}
