import { SwipeableDrawer } from "@mui/material";
import {
  useCallback,
  type KeyboardEvent as ReactKeyboardEvent,
  type MouseEvent as ReactMouseEvent,
} from "react";
import { useSearchParams } from "react-router-dom";
import { SETTINGS_CONFIG } from "./SETTINGS_CONFIG";
import { BIGQUERY_NAME } from "./bigquery/consts";
import { DEMO_CONNECTORS } from "./demo/demoConnectors";
import { GITHUB_NAME } from "./github";
import { LOOKER_NAME } from "./looker/consts";
import { POWERBI_NAME } from "./powerbi/consts";
import { SNOWFLAKE_NAME } from "./snowflake/consts";
import { TABLEAU_NAME } from "./tableau/consts";

const QUERY_PARAM_SOURCE = "source";

const ORDER = [
  GITHUB_NAME,
  BIGQUERY_NAME,
  LOOKER_NAME,
  SNOWFLAKE_NAME,
  TABLEAU_NAME,
  POWERBI_NAME,
  ...Object.keys(DEMO_CONNECTORS),
];

export function SettingsPage(): JSX.Element {
  return (
    <div className="w-full p-6">
      <h2 className="mb-5 text-6xl font-light">Connectors</h2>
      <div className="mr-6 grid grow grid-cols-[repeat(auto-fill,minmax(16rem,_1fr))] gap-4">
        {ORDER.map((source) => (
          <SettingsCard key={source} src={source} />
        ))}
      </div>
      <SettingsDrawer />
    </div>
  );
}

function SettingsCard({ src }: { readonly src: string }): JSX.Element | null {
  const [, setSearchParams] = useSearchParams();

  const OverviewComponent = SETTINGS_CONFIG[src]?.overview;

  const configureHandler = useCallback(() => {
    setSearchParams({ [QUERY_PARAM_SOURCE]: src });
  }, [setSearchParams, src]);

  if (OverviewComponent === undefined) {
    return null;
  }

  return <OverviewComponent key={src} onConfigure={configureHandler} />;
}

function SettingsDrawer(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const source = searchParams.get(QUERY_PARAM_SOURCE);

  const closeHandler = useCallback(
    (e: ReactKeyboardEvent | ReactMouseEvent) => {
      if (e.type === "keydown") {
        const { key } = e as ReactKeyboardEvent;
        if (key === "Tab" || key === "Shift") {
          return;
        }
      }

      setSearchParams(
        (currentParams) => {
          currentParams.delete(QUERY_PARAM_SOURCE);
          return currentParams;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  return (
    <SwipeableDrawer
      anchor="right"
      open={source !== null}
      onClose={closeHandler}
      onOpen={doNothing}
    >
      <ConfigurationDrawer />
    </SwipeableDrawer>
  );
}

function ConfigurationDrawer(): JSX.Element | null {
  const [searchParams] = useSearchParams();

  const source = searchParams.get(QUERY_PARAM_SOURCE);

  const currentSettings = source === null ? undefined : SETTINGS_CONFIG[source];
  if (currentSettings === undefined) {
    return null;
  }

  const { settings: ConfigComponent } = currentSettings;
  return <ConfigComponent />;
}

const doNothing = () => {};
