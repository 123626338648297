export function PlusSignIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.99999 14.833C9.76399 14.833 9.55899 14.7463 9.38499 14.573C9.21166 14.399 9.12499 14.194 9.12499 13.958V10.875H6.04199C5.80599 10.875 5.60099 10.7883 5.42699 10.615C5.25366 10.441 5.16699 10.236 5.16699 10C5.16699 9.764 5.25366 9.559 5.42699 9.385C5.60099 9.21167 5.80599 9.125 6.04199 9.125H9.12499V6C9.12499 5.764 9.21166 5.559 9.38499 5.385C9.55899 5.21167 9.76399 5.125 9.99999 5.125C10.236 5.125 10.441 5.21167 10.615 5.385C10.7883 5.559 10.875 5.764 10.875 6V9.125H13.958C14.194 9.125 14.399 9.21167 14.573 9.385C14.7463 9.559 14.833 9.764 14.833 10C14.833 10.236 14.7463 10.441 14.573 10.615C14.399 10.7883 14.194 10.875 13.958 10.875H10.875V13.958C10.875 14.194 10.7883 14.399 10.615 14.573C10.441 14.7463 10.236 14.833 9.99999 14.833Z" />
    </svg>
  );
}
