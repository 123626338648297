import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import type {
  SettingsOverviewProps,
  SourceInstance,
} from "../../../utils/settings/types";
import { BaseConfigCard } from "../BaseConfigCard";
import { BaseConfigDrawer } from "../BaseConfigDrawer";
import { useSubSettings } from "../settingsProvider";
import { useApiRequest } from "../useApiRequest";
import { KeyUploadScreen } from "./KeyUploadScreen";
import { MainConfigScreen } from "./MainConfigScreen";
import { BASE_URL_PREFIX, BIGQUERY_NAME, BIG_QUERY_DISPLAY_NAME } from "./consts";

export function BigQueryConfigOverview({
  onConfigure,
}: SettingsOverviewProps): JSX.Element {
  const [rawAccounts] = useSubSettings(BIGQUERY_NAME);
  const accounts = rawAccounts ?? [];
  const [firstAccount] = accounts;

  return (
    <BaseConfigCard
      avatarPath="/img/connectors/bigquery.svg"
      configured={accounts.length > 0}
      loading={rawAccounts === undefined}
      sourceName={BIG_QUERY_DISPLAY_NAME}
      onConfigure={onConfigure}
    >
      <>
        <Typography gutterBottom component="div" variant="h6">
          Configured Accounts
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {firstAccount !== undefined && (
            <>
              {`${firstAccount.source_identifier} (${
                firstAccount.username?.split("@")[0] ?? ""
              }`}
              )
              <br />
              {accounts.length > 1
                ? `... (${accounts.length - 1} more configured)`
                : "1 account configured"}
            </>
          )}
        </Typography>
      </>
    </BaseConfigCard>
  );
}

export function BigQuerySettings(): JSX.Element {
  const [bigqueryAccounts, setBigqueryAccounts] = useSubSettings(BIGQUERY_NAME);
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const api = useApiRequest();

  const updateAccounts = useCallback(async () => {
    const accounts = (await api(`${BASE_URL_PREFIX}/keys/`)) as [SourceInstance];
    setBigqueryAccounts(accounts);
  }, [api, setBigqueryAccounts]);

  const handleDelete = useCallback(
    (account: SourceInstance) => {
      const onServer = async () => {
        await api(`${BASE_URL_PREFIX}/keys/`, account, "DELETE");
        await updateAccounts();
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onServer();
    },
    [api, updateAccounts],
  );

  const uploadFileHandler = useCallback(() => {
    setUploadFile(true);
  }, []);

  const doneHandler = useCallback(
    (isCancled: boolean) => {
      setUploadFile(false);

      if (isCancled) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateAccounts();
    },
    [updateAccounts],
  );

  return (
    <BaseConfigDrawer sourceName={BIG_QUERY_DISPLAY_NAME}>
      {!uploadFile && (
        <MainConfigScreen
          accounts={bigqueryAccounts ?? []}
          onDelete={handleDelete}
          onUploadFile={uploadFileHandler}
        />
      )}
      {uploadFile ? <KeyUploadScreen onDone={doneHandler} /> : null}
    </BaseConfigDrawer>
  );
}
