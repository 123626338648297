import type { PolicyBasicMetadata } from "../../../../api";
import { PoliciesTableHeaderRow } from "./PoliciesTableHeaderRow";
import { PoliciesTablePolicyRow } from "./PoliciesTablePolicyRow";

export function PoliciesTable({
  policies,
}: {
  readonly policies: readonly PolicyBasicMetadata[];
}): JSX.Element {
  return (
    <div className="flex flex-col rounded-lg bg-white pl-3 pr-4 shadow-[0_3px_5px_rgba(115,130,133,0.2)]">
      <PoliciesTableHeaderRow />
      <ul>
        {policies.map((policy) => (
          <li key={policy.id}>
            <PoliciesTablePolicyRow policyId={policy.id} policyName={policy.name} />
          </li>
        ))}
      </ul>
    </div>
  );
}
