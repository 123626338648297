import type { PrItem } from "../../../../api";

export function DiffSizeIndicator({
  diffSize,
}: {
  readonly diffSize: NonNullable<PrItem["diff_size_hint"]>;
}): JSX.Element {
  return (
    <div
      className={`flex items-center rounded px-2 py-1 text-xs font-semibold ${sizeIndicatorColorClassName(
        diffSize,
      )}`}
    >
      {sizeIndicatorLabel(diffSize)}
    </div>
  );
}

function sizeIndicatorLabel(diffSize: NonNullable<PrItem["diff_size_hint"]>): string {
  switch (diffSize) {
    case "small":
      return "Small change";

    case "large":
      return "Large change";

    case "empty":
      return "No changes";
  }
}

function sizeIndicatorColorClassName(
  diffSize: NonNullable<PrItem["diff_size_hint"]>,
): string {
  switch (diffSize) {
    case "small":
      // green
      return "bg-[rgba(208,242,218,0.6)] text-[rgb(73,161,100)]";

    case "empty":
      // gray
      return "bg-[rgb(239,242,241)] text-[rgb(115,130,131)]";

    case "large":
      // orange/yellow
      return "bg-[rgba(255,235,216,0.6)] text-[rgb(206,122,61)]";
  }
}
