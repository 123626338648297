export function XIcon({
  variant = "strong",
  className,
}: {
  // TODO: We have 3 different looks for our X icon. We need to see if this
  // makes sense from a design perspective in order to maybe reduce the variance between
  // surfaces...
  readonly variant?: "light" | "medium" | "strong";
  readonly className?: string | undefined;
}): JSX.Element {
  switch (variant) {
    case "strong":
      return (
        <svg
          className={className}
          fill="currentColor"
          viewBox="0 0 8 8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4.00001 5.22917L1.97918 7.25C1.81251 7.41667 1.61112 7.49653 1.37501 7.48958C1.1389 7.48264 0.93751 7.39583 0.770843 7.22917C0.604177 7.0625 0.520843 6.85764 0.520843 6.61458C0.520843 6.37153 0.604177 6.16667 0.770843 6L2.77084 4L0.75001 1.97917C0.583343 1.8125 0.503482 1.60764 0.510426 1.36458C0.517371 1.12153 0.604177 0.916667 0.770843 0.75C0.93751 0.583333 1.14237 0.5 1.38543 0.5C1.62848 0.5 1.83334 0.583333 2.00001 0.75L4.00001 2.77083L6.02084 0.75C6.18751 0.583333 6.39237 0.5 6.63543 0.5C6.87848 0.5 7.08334 0.583333 7.25001 0.75C7.41668 0.916667 7.50001 1.12153 7.50001 1.36458C7.50001 1.60764 7.41668 1.8125 7.25001 1.97917L5.22918 4L7.25001 6.02083C7.41668 6.1875 7.50001 6.38889 7.50001 6.625C7.50001 6.86111 7.41668 7.0625 7.25001 7.22917C7.08334 7.39583 6.87848 7.47917 6.63543 7.47917C6.39237 7.47917 6.18751 7.39583 6.02084 7.22917L4.00001 5.22917Z" />
        </svg>
      );

    case "medium":
      return (
        <svg
          className={className}
          fill="currentColor"
          viewBox="0 0 12 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 7.40005L2.1 11.3C1.91667 11.4834 1.68334 11.575 1.4 11.575C1.11667 11.575 0.883336 11.4834 0.700003 11.3C0.516669 11.1167 0.425003 10.8834 0.425003 10.6C0.425003 10.3167 0.516669 10.0834 0.700003 9.90005L4.6 6.00005L0.700003 2.10005C0.516669 1.91672 0.425003 1.68338 0.425003 1.40005C0.425003 1.11672 0.516669 0.883382 0.700003 0.700048C0.883336 0.516715 1.11667 0.425049 1.4 0.425049C1.68334 0.425049 1.91667 0.516715 2.1 0.700048L6 4.60005L9.9 0.700048C10.0833 0.516715 10.3167 0.425049 10.6 0.425049C10.8833 0.425049 11.1167 0.516715 11.3 0.700048C11.4833 0.883382 11.575 1.11672 11.575 1.40005C11.575 1.68338 11.4833 1.91672 11.3 2.10005L7.4 6.00005L11.3 9.90005C11.4833 10.0834 11.575 10.3167 11.575 10.6C11.575 10.8834 11.4833 11.1167 11.3 11.3C11.1167 11.4834 10.8833 11.575 10.6 11.575C10.3167 11.575 10.0833 11.4834 9.9 11.3L6 7.40005Z" />
        </svg>
      );

    case "light":
      return (
        <svg
          className={className}
          fill="currentColor"
          viewBox="0 0 10 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.00022 5.98281L1.78342 9.19961C1.65008 9.33294 1.48902 9.39694 1.30022 9.39161C1.11142 9.38574 0.950351 9.31614 0.817018 9.18281C0.683684 9.04948 0.617018 8.88574 0.617018 8.69161C0.617018 8.49694 0.683684 8.33294 0.817018 8.19961L4.01702 4.99961L0.800218 1.78281C0.666884 1.64948 0.602885 1.48574 0.608218 1.29161C0.614085 1.09694 0.683684 0.932943 0.817018 0.799609C0.950351 0.666276 1.11408 0.599609 1.30822 0.599609C1.50288 0.599609 1.66688 0.666276 1.80022 0.799609L5.00022 4.01641L8.21702 0.799609C8.35035 0.666276 8.51408 0.599609 8.70822 0.599609C8.90288 0.599609 9.06688 0.666276 9.20022 0.799609C9.33355 0.932943 9.40022 1.09694 9.40022 1.29161C9.40022 1.48574 9.33355 1.64948 9.20022 1.78281L5.98342 4.99961L9.20022 8.21641C9.33355 8.34974 9.40022 8.51081 9.40022 8.69961C9.40022 8.88841 9.33355 9.04948 9.20022 9.18281C9.06688 9.31614 8.90288 9.38281 8.70822 9.38281C8.51408 9.38281 8.35035 9.31614 8.21702 9.18281L5.00022 5.98281Z" />
        </svg>
      );
  }
}
