import type { PropsWithChildren } from "react";

export function FullscreenMessage({
  children,
  className = "",
  loading = false,
}: PropsWithChildren<{
  readonly className?: string;
  readonly loading?: boolean;
}>): JSX.Element {
  return (
    <div
      className={`z-30 flex h-full w-full items-center justify-center text-5xl ${
        loading ? "animate-pulse" : ""
      } ${className}`}
    >
      {children}
    </div>
  );
}
