import { DEMO_MODE } from "../../utils/demo/demoMode";
import { DashboardIcon } from "../icons/DashboardIcon";
import { LineageIcon } from "../icons/LineageIcon";
import { PoliciesIcon } from "../icons/PoliciesIcon";
import { PullRequestIcon } from "../icons/PullRequestIcon";
import { SearchIcon } from "../icons/SearchIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { VersionsIcon } from "../icons/VersionsIcon";
import { NavItem } from "./NavItem";

export function NavigationItems(): JSX.Element {
  const { isEnabled: isInDemoMode } = DEMO_MODE;
  return (
    <ol className="flex-1">
      {isInDemoMode ? (
        <NavItem icon={<DashboardIcon className="w-4" />} to="/dashboard">
          Dashboard
        </NavItem>
      ) : null}
      <NavItem icon={<VersionsIcon className="w-[18px]" />} to="/pr-analysis">
        PR Analysis
      </NavItem>
      <NavItem icon={<LineageIcon className="w-[18px]" />} to="/lineage">
        Lineage
      </NavItem>
      <NavItem icon={<PoliciesIcon className="w-4" />} to="/policies">
        Policies
      </NavItem>
      {isInDemoMode ? (
        <>
          <NavItem icon={<PullRequestIcon className="w-4" />} to="/refactor">
            Refactor
          </NavItem>
          <NavItem icon={<SearchIcon className="w-[18px]" />} to="/query-explorer">
            Query Explorer
          </NavItem>
        </>
      ) : null}
      <NavItem icon={<SettingsIcon className="w-[22px]" />} to="/connectors">
        Connectors
      </NavItem>
    </ol>
  );
}
