export function DownloadIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 -960 960 960"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M480-340q-8 0-15.5-3T450-353L304-499q-13-13-13-30t13-30q13-13 30-13t30 13l74 74v-273q0-17 12.5-29.5T480-800q17 0 29.5 12.5T522-758v273l74-74q13-13 30-13t30 13q13 13 13 30t-13 30L510-353q-7 7-14.5 10t-15.5 3ZM244-160q-35 0-59.5-24.5T160-244v-74q0-17 12.5-29.5T202-360q17 0 29.5 12.5T244-318v74h472v-74q0-17 12.5-29.5T758-360q17 0 29.5 12.5T800-318v74q0 35-24.5 59.5T716-160H244Z" />
    </svg>
  );
}
