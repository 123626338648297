import { Switch } from "@headlessui/react";

export function ToggleSwitch({
  isChecked,
  onToggle,
}: {
  readonly isChecked: boolean;
  readonly onToggle?: ((checked: boolean) => void) | undefined;
}): JSX.Element {
  return (
    <Switch
      checked={isChecked}
      className="inline-flex h-4 w-8 items-center rounded-full ui-checked:bg-[rgb(101,202,132)] ui-not-checked:bg-[rgb(205,215,215)]"
      onChange={onToggle}
    >
      <span
        aria-hidden="true"
        className="inline-block h-3 w-3 transform rounded-full bg-white transition ui-checked:translate-x-[1.125rem] ui-not-checked:translate-x-0.5"
      />
    </Switch>
  );
}
