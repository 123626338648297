export function TextIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 14V12.5H14V14H0Z" />
      <path
        clipRule="evenodd"
        d="M2 10L6.05068 0H7.97747L12 10H10.1282L9.17887 7.4475H4.84929L3.87182 10H2ZM8.61974 5.99H5.38026L6.97184 1.875H7.05631L8.61974 5.99Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
