import Face from "@mui/icons-material/Face";
import Fingerprint from "@mui/icons-material/Fingerprint";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { type FormEvent, useCallback, useState } from "react";
import { logEventLoginSuccess } from "../../utils/analytics/eventLogging";
import { useLogin } from "./authProvider";

// These are defined by the server endpoint, they should stay in sync
const FORM_KEY_USERNAME = "username";
const FORM_KEY_PASSWORD = "password";

export function LoginDialog(): JSX.Element {
  const [inAction, setInAction] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const login = useLogin();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      // We don't want this form to act like a real form...
      e.preventDefault();

      const { currentTarget } = e;
      const formData = new FormData(currentTarget);

      // Reset error state
      setLoginErrorMessage(undefined);

      const onServer = async () => {
        try {
          setInAction(true);
          await login(formData);
          logEventLoginSuccess();
        } catch (error) {
          setLoginErrorMessage(
            error instanceof Error
              ? // Handle errors thrown from frontend
                error.message
              : // Handle errors thrown from backend
                String(error),
          );
        } finally {
          setInAction(false);
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onServer();
    },
    [login],
  );

  return (
    <form
      className="flex w-80 flex-col items-stretch gap-y-4 p-4"
      onSubmit={handleSubmit}
    >
      <label className="flex items-center gap-x-4">
        <Face />
        <TextField
          autoFocus
          fullWidth
          required
          disabled={inAction}
          label="Email"
          name={FORM_KEY_USERNAME}
          type="email"
          variant="standard"
        />
      </label>
      <label className="flex items-center gap-x-4">
        <Fingerprint />
        <TextField
          fullWidth
          required
          disabled={inAction}
          label="Password"
          name={FORM_KEY_PASSWORD}
          type="password"
          variant="standard"
        />
      </label>
      {loginErrorMessage !== undefined && (
        <Alert severity="error">{loginErrorMessage}</Alert>
      )}
      <button
        className="!mt-12 flex items-center justify-center self-end rounded border border-[rgba(85,108,214,0.5)] bg-transparent px-4 py-2 text-sm font-medium text-[rgb(85,108,214)] outline-none transition-colors duration-200 ease-in-out hover:border-[rgb(85,108,214)] hover:bg-[rgba(85,108,214,0.04)]"
        disabled={inAction}
        type="submit"
      >
        Login
      </button>
    </form>
  );
}
