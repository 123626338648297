import { HTTP_CODE } from "../utils/HTTP_CODE";
import { BASE_API_URL } from "../utils/env";

const API_PREFIX = "/api/v1/";

const DEFAULT_HEADERS = { Accept: "application/json" };

export type APIRequestOptions = {
  readonly authToken: string;
  readonly onUnauthorized: () => void;
  readonly endpoint: string;
  readonly body?: unknown;
  readonly requestInit?: Omit<RequestInit, "body"> | undefined;
};

export async function apiRequest({
  authToken,
  onUnauthorized,
  endpoint,
  body,
  requestInit: { headers, method, ...requestInit } = {},
}: APIRequestOptions): Promise<unknown> {
  const actualHeaders = new Headers(headers ?? DEFAULT_HEADERS);

  actualHeaders.set("Authorization", `Bearer ${authToken}`);

  if (body !== null && body !== undefined) {
    actualHeaders.set("Content-type", "application/json");
  }

  const response = await fetch(
    `${BASE_API_URL}${API_PREFIX}${endpoint.replace(/^\/*/u, "")}`,
    {
      headers: actualHeaders,
      ...(body !== undefined && {
        body: JSON.stringify(body),
      }),
      ...((body !== undefined || method !== undefined) && {
        method: method ?? "POST",
      }),
      ...requestInit,
    },
  );

  if (!response.ok) {
    if (response.status === HTTP_CODE.UNAUTHORIZED) {
      onUnauthorized();
    }
    throw response;
  }

  return response.json();
}
