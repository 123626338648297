export function GithubIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M10.1779 0C4.54977 0 0 4.58331 0 10.2535C0 14.786 2.9152 18.6226 6.95935 19.9805C7.46497 20.0826 7.65018 19.7599 7.65018 19.4885C7.65018 19.2508 7.63351 18.436 7.63351 17.587C4.80227 18.1983 4.21269 16.3647 4.21269 16.3647C3.75769 15.1764 3.08353 14.871 3.08353 14.871C2.15687 14.2429 3.15103 14.2429 3.15103 14.2429C4.17894 14.3108 4.71831 15.2954 4.71831 15.2954C5.6281 16.857 7.09414 16.4158 7.68393 16.1441C7.76809 15.482 8.03788 15.0237 8.32434 14.7691C6.06623 14.5314 3.6904 13.6487 3.6904 9.71017C3.6904 8.58976 4.09457 7.67309 4.73498 6.96018C4.63394 6.7056 4.27998 5.65289 4.83623 4.24394C4.83623 4.24394 5.6956 3.97228 7.6333 5.29644C8.4629 5.07199 9.31845 4.95782 10.1779 4.95685C11.0372 4.95685 11.9133 5.07581 12.7222 5.29644C14.6601 3.97228 15.5195 4.24394 15.5195 4.24394C16.0758 5.65289 15.7216 6.7056 15.6206 6.96018C16.2778 7.67309 16.6653 8.58976 16.6653 9.71017C16.6653 13.6487 14.2895 14.5143 12.0145 14.7691C12.3854 15.0916 12.7054 15.7026 12.7054 16.6703C12.7054 18.0453 12.6887 19.1489 12.6887 19.4883C12.6887 19.7599 12.8741 20.0826 13.3795 19.9808C17.4237 18.6224 20.3389 14.786 20.3389 10.2535C20.3555 4.58331 15.7891 0 10.1779 0Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
