import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { PropsWithChildren } from "react";

export const QUERY_CLIENT = new QueryClient();

export function APIQueryClientProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      {children}
      <ReactQueryDevtools toggleButtonProps={{ className: "!mb-20" }} />
    </QueryClientProvider>
  );
}
