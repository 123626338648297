import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRepoNames } from "../api";
import { FullscreenMessage } from "./FullscreenMessage";

export function RepoLoader(): JSX.Element | null {
  const navigate = useNavigate();

  const { data: repoNames, isSuccess, isFetching } = useRepoNames();

  const firstRepo = isSuccess ? repoNames[0] : undefined;

  useEffect(() => {
    if (firstRepo !== undefined) {
      navigate(
        `./${
          // RepoNames contain "/" which needs to be escaped
          encodeURIComponent(firstRepo)
        }`,
        { replace: true },
      );
    }
  }, [firstRepo, navigate]);

  if (isFetching) {
    return (
      <FullscreenMessage loading>Finding viewer repositories...</FullscreenMessage>
    );
  }

  if (!isSuccess) {
    return <FullscreenMessage>Error while fetching viewer repos</FullscreenMessage>;
  }

  if (firstRepo === undefined) {
    return (
      <FullscreenMessage>
        Error: Viewer has no configured repositories
      </FullscreenMessage>
    );
  }

  // We reach here only if the API returned a success response with at least 1  PR ID,
  // so wait just for the navigation to happen, we don't need to show anything to the
  // user...
  return null;
}
