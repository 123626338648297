export function OpenPullRequestIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.17677 3.07322L8.57322 0.676753C8.73071 0.51926 8.99999 0.630802 8.99999 0.853529V5.64642C8.99999 5.86915 8.73071 5.98069 8.57322 5.8232L6.17677 3.42677C6.07914 3.32914 6.07914 3.17085 6.17677 3.07322ZM2.25 2.5C1.83579 2.5 1.5 2.83579 1.5 3.25C1.5 3.66421 1.83579 4 2.25 4C2.66421 4 3 3.66421 3 3.25C3 2.83579 2.66421 2.5 2.25 2.5ZM0 3.25C0 2.00736 1.00736 1 2.25 1C3.49264 1 4.5 2.00736 4.5 3.25C4.5 4.22966 3.87389 5.06309 3 5.37197V10.628C3.87389 10.9369 4.5 11.7703 4.5 12.75C4.5 13.9926 3.49264 15 2.25 15C1.00736 15 0 13.9926 0 12.75C0 11.7703 0.62611 10.9369 1.5 10.628V5.37197C0.62611 5.06309 0 4.22966 0 3.25ZM10 2.5H9V4H10C10.5523 4 11 4.44772 11 5V10.628C10.1261 10.9369 9.5 11.7703 9.5 12.75C9.5 13.9926 10.5074 15 11.75 15C12.9926 15 14 13.9926 14 12.75C14 11.7703 13.3739 10.9369 12.5 10.628V5C12.5 3.61929 11.3807 2.5 10 2.5ZM1.5 12.75C1.5 12.3358 1.83579 12 2.25 12C2.66421 12 3 12.3358 3 12.75C3 13.1642 2.66421 13.5 2.25 13.5C1.83579 13.5 1.5 13.1642 1.5 12.75ZM11 12.75C11 12.3358 11.3358 12 11.75 12C12.1642 12 12.5 12.3358 12.5 12.75C12.5 13.1642 12.1642 13.5 11.75 13.5C11.3358 13.5 11 13.1642 11 12.75Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
