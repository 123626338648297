import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useMe } from "../api";
import { useIsAuthenticated } from "../components/login/authProvider";
import { Nav } from "../components/nav/Nav";
import { setDDUser } from "../datadog";
import { setMixpanelUser } from "../utils/analytics/mixpanel";

export function LoggedInLayout(): JSX.Element {
  const loggedIn = useIsAuthenticated();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const location = useLocation();

  const { data } = useMe();

  useEffect(() => {
    if (data !== undefined) {
      const {
        id: userId,
        org_name: orgName,
        first_name,
        last_name,
        email,
        is_superuser: isSuperuser,
      } = data;
      const fullName = `${first_name} ${last_name}`;
      setDDUser(email, fullName, isSuperuser);
      setMixpanelUser({ userId, email, orgName, fullName, isSuperuser });
    }
  }, [data]);

  if (!loggedIn) {
    return <Navigate state={{ from: location }} to="/login" />;
  }

  return (
    <div className="flex h-screen w-screen items-stretch">
      <Nav />
      <main className="flex-1 overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}
