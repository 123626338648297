import { Box, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useCallback, useState, type ChangeEvent } from "react";
import { exceptionToErrorMessage } from "../../../utils/error";
import type { SourceInstance } from "../../../utils/settings/types";
import { CallToActions } from "../common/CallToActions";
import { useApiRequest } from "../useApiRequest";
import { Instructions } from "./Instructions";
import { ProjectsChips } from "./ProjectsChips";
import { BASE_URL_PREFIX, TABLEAU_DEFAULT_PROJECT } from "./consts";

type TableauNewSource = {
  /* eslint-disable @typescript-eslint/naming-convention -- defined by the API */
  readonly source_identifier: string;
  readonly username: string;
  readonly credentials: {
    readonly server_address: string;
    readonly site_name: string;
    readonly token_name: string;
    readonly token_secret: string;
  };
  readonly tableau_enabled_projects: readonly string[];
  /* eslint-enable @typescript-eslint/naming-convention */
};

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function NewSourceScreen({
  onDone,
  initialSourceData,
}: {
  readonly onDone: (isCanceled: boolean) => void;
  readonly initialSourceData?: SourceInstance | undefined;
}): JSX.Element {
  const isEditMode = initialSourceData !== undefined;

  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState<string>();
  const [isServerEdited, setIsServerEdited] = useState(false);
  const [isTokenNameEdited, setIsTokenNameEdited] = useState(false);
  const [isTokenSecretEdited, setIsTokenSecretEdited] = useState(false);
  const [serverAddress, setServerAddress] = useState(
    () => initialSourceData?.source_identifier.split(",")[0] ?? "",
  );
  const [siteName, setSiteName] = useState(
    () => initialSourceData?.source_identifier.split(",")[1] ?? "",
  );
  const [tokenName, setTokenName] = useState(initialSourceData?.username ?? "");
  const [tokenSecret, setTokenSecret] = useState("");
  const [enabledProjects, setEnabledProjects] = useState(() =>
    isEditMode
      ? initialSourceData.options?.tableau_options?.enabled_projects ?? []
      : [TABLEAU_DEFAULT_PROJECT],
  );

  const api = useApiRequest();

  const handleSave = useCallback(() => {
    setError(undefined);
    setIsInProgress(true);

    const newSource: TableauNewSource = {
      source_identifier: `${serverAddress},${siteName}`,
      username: tokenName,
      credentials: {
        server_address: serverAddress,
        site_name: siteName,
        token_name: tokenName,
        token_secret: tokenSecret,
      },
      tableau_enabled_projects: enabledProjects,
    };

    const toServer = async (): Promise<void> => {
      try {
        await api(`${BASE_URL_PREFIX}/keys/`, [newSource], "PUT");
        onDone(false);
      } catch (serverError) {
        const message = await exceptionToErrorMessage(serverError);
        setError(message);
      } finally {
        setIsInProgress(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    toServer();
  }, [onDone, api, serverAddress, siteName, tokenName, tokenSecret, enabledProjects]);

  const handleServerAddressBlur = useCallback(() => {
    setIsServerEdited(true);
  }, []);

  const handleServerAddressChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setServerAddress(currentTarget.value);
    },
    [],
  );

  const handleSiteNameChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setSiteName(currentTarget.value);
    },
    [],
  );

  const handleTokenNameBlur = useCallback(() => {
    setIsTokenNameEdited(true);
  }, []);

  const handleTokenNameChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setTokenName(currentTarget.value);
    },
    [],
  );

  const handleTokenSecretBlur = useCallback(() => {
    setIsTokenSecretEdited(true);
  }, []);

  const handleTokenSecretChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setTokenSecret(currentTarget.value);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    onDone(true);
  }, [onDone]);

  return (
    <Box
      sx={{
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
        mt: 2,
      }}
    >
      <form>
        <TextField
          fullWidth
          required
          autoFocus={!isEditMode}
          disabled={isEditMode}
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- what's the problem here :(
            isServerEdited && serverAddress === ""
          }
          label="Server Address"
          placeholder="https://bi.server.net"
          type="url"
          value={serverAddress}
          variant="standard"
          onBlur={handleServerAddressBlur}
          onChange={handleServerAddressChange}
        />
        <TextField
          fullWidth
          disabled={isEditMode}
          label="Site Name"
          placeholder="Default"
          type="text"
          value={siteName}
          variant="standard"
          onChange={handleSiteNameChange}
        />
        <TextField
          fullWidth
          required
          autoFocus={isEditMode}
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- i dunno what's the problem here :(
            isTokenNameEdited && tokenName === ""
          }
          label="Token Name"
          type="text"
          value={tokenName}
          variant="standard"
          onBlur={handleTokenNameBlur}
          onChange={handleTokenNameChange}
        />
        <TextField
          fullWidth
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- i dunno what's the problem here :(
            isTokenSecretEdited && tokenSecret === "" && !isEditMode
          }
          label={
            isEditMode
              ? "New Token Secret (Leave empty to keep old value)"
              : "Token Secret"
          }
          required={!isEditMode}
          type="password"
          value={tokenSecret}
          variant="standard"
          onBlur={handleTokenSecretBlur}
          onChange={handleTokenSecretChange}
        />
        <ProjectsChips
          enabledProjects={enabledProjects}
          onChange={setEnabledProjects}
        />
        <Instructions />
        <CallToActions
          disabled={
            serverAddress === "" ||
            tokenName === "" ||
            (!isEditMode && tokenSecret === "")
          }
          inProgress={isInProgress}
          onCancel={handleCancel}
          onSave={handleSave}
        />
        {error !== undefined && <Alert severity="error">{error}</Alert>}
      </form>
    </Box>
  );
}
