/* eslint-disable import/no-deprecated */
import { type ChangeEvent, type FormEvent, useCallback, useState } from "react";
import { useSetTableDescriptionMutation, useTableDescription } from "../../api";
import type { LineageTable } from "../../utils/graph/type";
import { InspectorButton } from "./InspectorButton";
import { SectionHeader } from "./SectionHeader";
import { ViewerRatingToggle } from "./ViewerRatingToggle";

const FORM_KEY_DESCRIPTION = "description";

export function Description({ table }: { readonly table: LineageTable }): JSX.Element {
  return (
    <section className="flex flex-col items-start gap-3">
      <SectionHeader>Description</SectionHeader>
      <DescriptionForm table={table} />
    </section>
  );
}

function DescriptionForm({ table }: { readonly table: LineageTable }): JSX.Element {
  const { data: storedDescription, isLoading } = useTableDescription(table);
  const { mutate, isLoading: isLoadingMutation } =
    useSetTableDescriptionMutation(table);
  const [isDirty, setIsDirty] = useState(false);

  const handleChange = useCallback(
    ({ currentTarget: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
      setIsDirty(value !== storedDescription?.description);
    },
    [storedDescription],
  );

  const handleReset = useCallback(() => {
    setIsDirty(false);
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const data = new FormData(e.currentTarget);
      const value = data.get(FORM_KEY_DESCRIPTION);
      if (typeof value !== "string") {
        return;
      }

      mutate({ description: value });
      setIsDirty(false);
    },
    [mutate],
  );

  return (
    <>
      <form
        className="flex w-full flex-col items-end gap-3"
        onReset={handleReset}
        onSubmit={handleSubmit}
      >
        <textarea
          key={storedDescription?.description}
          className={`h-24 w-full resize-none rounded-md px-4 py-3 text-sm font-medium  text-[rgb(82,97,100)] outline-none transition-all hover:shadow-[0_2px_4px_rgba(115,130,133,0.15)] ${
            isDirty
              ? "border border-[rgba(82,97,100,0.1)] shadow-[0_2px_4px_rgba(115,130,133,0.15)]"
              : "bg-[rgb(247,249,248)]"
          } ${isLoading || isLoadingMutation ? "scale-95 animate-pulse" : ""}`}
          defaultValue={storedDescription?.description}
          disabled={isLoading || isLoadingMutation}
          name={FORM_KEY_DESCRIPTION}
          placeholder={isLoading ? "Loading..." : ""}
          onChange={handleChange}
        />
        {isDirty ? (
          <div className="flex gap-2 text-sm">
            <InspectorButton type="reset">Cancel</InspectorButton>
            <InspectorButton filled type="submit">
              Save changes
            </InspectorButton>
          </div>
        ) : null}
      </form>
      {isDirty ? null : (
        <ViewerRatingToggle description={storedDescription} table={table} />
      )}
    </>
  );
}
