import type React from "react";
import { useCallback } from "react";
import { useReactFlow } from "reactflow";
import { DownloadIcon } from "../icons/DownloadIcon";
import { getGraphAsCsvFileData, useGraphCsvFileName } from "./utils/graphCsv";

export function DownloadButton(): JSX.Element {
  const { getEdges, getNodes } = useReactFlow();
  const fileName = useGraphCsvFileName();

  const handleClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLAnchorElement>) => {
      const blob = new Blob(getGraphAsCsvFileData(getEdges(), getNodes()), {
        type: "text/csv",
      });
      const url = URL.createObjectURL(blob);

      // eslint-disable-next-line no-param-reassign
      currentTarget.href = url;

      setTimeout(() => {
        // Clean up the temporary URL object when the browser finishes download.
        URL.revokeObjectURL(url);
        // eslint-disable-next-line no-param-reassign
        currentTarget.href = ".";
      });
    },
    [getEdges, getNodes],
  );

  return (
    <a
      className="flex items-center rounded-md bg-white p-2 shadow hover:bg-slate-50"
      download={fileName}
      href="."
      onClick={handleClick}
    >
      <DownloadIcon className="h-6 w-6 text-[rgb(165,173,176)]" />
    </a>
  );
}
