import { Popover } from "@headlessui/react";
import type { PropsWithChildren } from "react";
import { DiscoveryAddNodeDialog } from "./DiscoveryAddNodeDialog";

/**
 * Control the UX for showing and hiding the add node dialog.
 *
 * IMPORTANT: To control re-opening the dialog provide a child node to this element
 * which contains a `Popover.Button` element
 */
export function AddNodeDialogController({
  forceOpen,
  children,
}: PropsWithChildren<{
  readonly forceOpen: boolean;
}>): JSX.Element {
  return (
    <Popover>
      <Popover.Panel
        focus
        as="dialog"
        className="absolute inset-0 z-50 m-auto flex h-1/2 min-h-[30rem] w-2/5 min-w-[20rem] flex-col items-center overflow-hidden rounded-lg border bg-white shadow-md"
        static={forceOpen}
      >
        {
          // eslint-disable-next-line @typescript-eslint/no-shadow -- This is the name HeadlessUI chose :(
          ({ close }) => <DiscoveryAddNodeDialog onResultClick={close} />
        }
      </Popover.Panel>
      {children}
    </Popover>
  );
}
