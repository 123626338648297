import { useMutation, useQueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { DEMO_MODE } from "../../utils/demo/demoMode";
import {
  exampleCreatePolicy,
  exampleDeletePolicy,
  exampleGetPolicy,
  examplePolicyNameAvailability,
} from "../../utils/demo/policies";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import type {
  CreatePolicyRequestModel,
  Policy,
  PolicyNameCheckResponseModel,
  UpdatePolicyRequestModel,
} from "./schemas";
import { ORG_POLICY_LIST_QUERY_KEY } from "./useOrgPolicyList";

const getPolicyCacheKey = (policyId: string) => ["getPolicy", policyId];

export const usePolicy = (policyId: string) =>
  useQueryRetriesServerErrors({
    queryKey: getPolicyCacheKey(policyId),
    queryFn: async () => getPolicy(policyId),
  });

const getPolicy = async (policyId: string): Promise<Policy> => {
  if (DEMO_MODE.isEnabled) {
    const policy = exampleGetPolicy(policyId);
    if (policy !== undefined) {
      return policy;
    }
  }
  return queryApiCall(`policies/policies/${policyId}`);
};

// This hook uses the useMutation hook to update a policy. It uses the useMutation hook's onMutate, onSuccess, and
// onError callbacks to optimistically update the cache. The implementation is inspired by the react-query
// documentation: https://tanstack.com/query/latest/docs/react/guides/optimistic-updates
export function useUpdatePolicyMutation(policyId: string) {
  const queryClient = useQueryClient();
  const getPolicyKey = getPolicyCacheKey(policyId);
  return useMutation({
    mutationKey: ["updatePolicy", policyId],
    mutationFn: async (updates: UpdatePolicyRequestModel) =>
      updatePolicy(policyId, updates),
    onMutate: async (updates: UpdatePolicyRequestModel) => {
      // Cancel any outgoing fetches (so they don't overwrite our optimistic update).
      await queryClient.cancelQueries(getPolicyKey);

      // Snapshot the previous value.
      const preUpdatePolicy = queryClient.getQueryData<Policy>(getPolicyKey);
      invariant(
        preUpdatePolicy !== undefined,
        "Policy is not expected to be undefined during update",
      );

      // Optimistically update to the new value.
      queryClient.setQueryData(getPolicyKey, (old: Policy | undefined) => {
        invariant(
          old !== undefined,
          "Policy is not expected to be undefined during update",
        );
        return { ...old, ...updates };
      });

      // Return the pre-update value, so it can be used in onError or onSettled.
      return { preUpdatePolicy };
    },
    onError: (_error, _updates, context) => {
      const preUpdatePolicy = context?.preUpdatePolicy;
      invariant(
        preUpdatePolicy !== undefined,
        "Policy mutation failed, pre-mutation copy of the Policy is missing from mutation context",
      );

      // Restore the pre-update value if available.
      queryClient.setQueryData(getPolicyKey, preUpdatePolicy);
    },
    onSettled: async () => {
      // On error / success invalidate policy to trigger a re-fetch.
      await queryClient.invalidateQueries(getPolicyKey);
    },
  });
}

const updatePolicy = async (
  policyId: string,
  updateModel: UpdatePolicyRequestModel,
): Promise<Policy> =>
  queryApiCall(`/policies/policies/${policyId}`, {
    requestInit: { method: "PATCH" },
    body: updateModel,
  });

export function useDeletePolicyMutation(policyId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => deletePolicy(policyId),
    onSettled: async () => {
      // On error / success invalidate the organization policy-list.
      await queryClient.invalidateQueries(ORG_POLICY_LIST_QUERY_KEY);
    },
  });
}

const deletePolicy = async (policyId: string): Promise<Policy> => {
  if (DEMO_MODE.isEnabled) {
    const policy = exampleDeletePolicy(policyId);
    if (policy !== undefined) {
      return policy;
    }
  }
  return queryApiCall(`/policies/policies/${policyId}`, {
    requestInit: { method: "DELETE" },
  });
};

export function usePolicyNameCheck(policyName: string) {
  return useQueryRetriesServerErrors({
    queryKey: ["checkPolicyNameAvailability", policyName],
    queryFn: async () => checkPolicyNameAvailability(policyName),
  });
}

async function checkPolicyNameAvailability(
  policyName: string,
): Promise<PolicyNameCheckResponseModel> {
  if (DEMO_MODE.isEnabled) {
    return examplePolicyNameAvailability(policyName);
  }
  const queryParams = new URLSearchParams({ name: policyName });
  return queryApiCall(`/policies/policies/name_check?${queryParams.toString()}`);
}

export function useCreatePolicyMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPolicy,
    onSettled: async () => {
      // On error / success invalidate the organization policy-list.
      await queryClient.invalidateQueries(ORG_POLICY_LIST_QUERY_KEY);
    },
  });
}

const createPolicy = async (policy: CreatePolicyRequestModel): Promise<Policy> => {
  if (DEMO_MODE.isEnabled) {
    return exampleCreatePolicy(policy);
  }
  return queryApiCall("/policies/policies", {
    requestInit: { method: "POST" },
    body: policy,
  });
};
