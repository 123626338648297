import { concat, dropLast, filter, flatten, map, pipe, reduce } from "remeda";
import type { MatchDescriptor } from "./SEARCHERS";

type SearchResultSegment = MatchDescriptor["segments"][number];

type TextSegment = {
  readonly text: string;
  readonly isHighlighted: boolean;
};

export const prepareInputForRendering = (
  input: string,
  highlighted: MatchDescriptor["segments"],
): readonly TextSegment[] =>
  pipe(
    highlighted,

    // Add a dummy segment at the end so that our algorithm covers the whole input text
    concat([{ start: input.length, len: 0 }]),

    // We only have highlighted segments from our search engine, we want to inject an
    // unhighlighted segment between every 2 highlighted ones so we have a segment for
    // every part of the input text.
    map.indexed((segment, index) =>
      injectUnhighlighedSegment(
        // We should only get an undefined value for the first segment, we can fake the
        // previous segment as a segment of length 0
        highlighted[index - 1] ?? { start: 0, len: 0 },
        segment,
      ),
    ),

    flatten(),

    // Remove any empty segments, they will just create empty HTML elements...
    filter((segment) => segment.len > 0),

    // Merge consecutive segments that are both highlighed (or not) so that we need less
    // components to render.
    reduce((mergedSegments, segment) => {
      const previousSegment = mergedSegments.at(-1);
      if (previousSegment === undefined) {
        // This is the first segment!
        return [segment];
      }

      if (previousSegment.isHighlighted !== segment.isHighlighted) {
        // The previous segment is not of the same type
        return [...mergedSegments, segment];
      }

      const mergedSegment = {
        start: previousSegment.start,
        len: previousSegment.len + segment.len,
        isHighlighted: previousSegment.isHighlighted,
      };
      return [
        // Remove the previous segment and add the merged segment in it's place
        ...dropLast(mergedSegments, 1),
        mergedSegment,
      ];
    }, [] as readonly ReturnType<typeof injectUnhighlighedSegment>[number][]),

    // Project our segments on the text
    map(
      ({ len, start, ...rest }) =>
        ({
          text: input.slice(start, start + len),
          ...rest,
        } as const),
    ),
  );

function injectUnhighlighedSegment(
  previous: SearchResultSegment,
  current: SearchResultSegment,
) {
  const previousEndPosition = previous.start + previous.len;

  const unhighlighted = {
    // Add an unhighlighted segment from the end of the previous highlighted segment
    // until the start of the current highlighted segment (it could be empty!)
    start: previousEndPosition,
    len: current.start - previousEndPosition,
    isHighlighted: false,
  };

  return [unhighlighted, { ...current, isHighlighted: true }];
}
