import { Switch } from "@headlessui/react";
import { useCallback } from "react";
import { logEventGraphToggleClicked } from "../../utils/analytics/eventLogging";
import { useGraphType } from "../../utils/graph/useGraphType";
import {
  useCollapsedModeActions,
  useIsCollapsedModeEnabled,
} from "./utils/collapsedModeStore";

export function CollapsedToggle(): JSX.Element {
  const { enable } = useCollapsedModeActions();
  const isEnabled = useIsCollapsedModeEnabled();
  const graphType = useGraphType();

  const handleToggleChange = useCallback(
    (isChecked: boolean) => {
      enable(isChecked);

      logEventGraphToggleClicked(graphType, isChecked);
    },
    [enable, graphType],
  );

  return (
    <Switch
      checked={isEnabled}
      className="flex items-center justify-center gap-x-4 rounded-md bg-white px-4 py-3 text-sm font-medium text-[rgb(115,130,131)] shadow hover:bg-slate-50"
      onChange={handleToggleChange}
    >
      Collapse all
      <span className="inline-flex h-4 w-8 items-center rounded-full ui-checked:bg-[rgb(101,202,132)] ui-not-checked:bg-[rgb(205,215,215)]">
        <span className="inline-block h-3 w-3 transform rounded-full bg-white transition ui-checked:translate-x-[1.125rem] ui-not-checked:translate-x-0.5" />
      </span>
    </Switch>
  );
}
