import { useMemo } from "react";
import { useMatches } from "react-router-dom";
import { filter, first, isDefined, map, pipe, prop } from "remeda";
import { z } from "zod";

export type GraphType = z.infer<typeof GRAPH_TYPE_SCHEMA>;
export type GraphTypeRouteHandle = z.infer<typeof GRAPH_TYPE_HANDLE_SCHEMA>;

const GRAPH_TYPE_SCHEMA = z.enum(["diff", "lineage"]);
const GRAPH_TYPE_HANDLE_SCHEMA = z.object({ graphType: GRAPH_TYPE_SCHEMA });

export function useGraphType(): GraphType | undefined {
  const matches = useMatches();
  return useMemo(
    () =>
      pipe(
        matches,
        map(prop("handle")),
        map(extractGraphTypeFromHandle),
        filter(isDefined),
        first(),
      ),
    [matches],
  );
}

function extractGraphTypeFromHandle(handle: unknown): GraphType | undefined {
  const parsed = GRAPH_TYPE_HANDLE_SCHEMA.safeParse(handle);
  return parsed.success ? parsed.data.graphType : undefined;
}
