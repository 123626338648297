/* eslint-disable import/no-deprecated */
import { RadioGroup } from "@headlessui/react";
import { type PropsWithChildren, useCallback } from "react";
import { useTableDescriptionViewerRatingMutation } from "../../api";
import type { TableDescriptionInfo } from "../../api/inspector/schemas";
import type { LineageTable } from "../../utils/graph/type";
import { ThumbIcon } from "../icons/ThumbIcon";

export function ViewerRatingToggle({
  table,
  description,
}: {
  readonly table: LineageTable;
  readonly description: TableDescriptionInfo | undefined;
}): JSX.Element {
  const { mutate, isLoading: isMutationLoading } =
    useTableDescriptionViewerRatingMutation(table, description);

  const handleChange = useCallback(
    (viewerRating: TableDescriptionInfo["viewer_rating"]) => {
      mutate({ viewerRating });
    },
    [mutate],
  );

  return (
    <RadioGroup
      className={`flex w-full items-center gap-1 ${
        isMutationLoading ? "animate-pulse" : ""
      }`}
      disabled={
        description === undefined ||
        description.description_id === null ||
        description.description === ""
      }
      value={description?.viewer_rating}
      onChange={handleChange}
    >
      <RadioGroup.Label className="text-xs text-[rgb(165,173,176)]">
        Rate description quality
      </RadioGroup.Label>
      <RatingButton value="ThumbsUp">
        <ThumbIcon className="h-4 w-4" />
      </RatingButton>
      <RatingButton value="ThumbsDown">
        <ThumbIcon className="h-4 w-4 rotate-180" />
      </RatingButton>
    </RadioGroup>
  );
}

function RatingButton<T>({
  value,
  children,
}: PropsWithChildren<{
  readonly value: T;
}>): JSX.Element {
  return (
    <RadioGroup.Option
      className="flex cursor-pointer rounded p-1 text-[rgb(165,173,176)] hover:bg-[rgb(239,242,241)] hover:text-[rgb(115,130,131)] ui-checked:text-[rgb(117,108,220)] ui-checked:hover:bg-[rgb(225,220,255)]"
      value={value}
    >
      {children}
    </RadioGroup.Option>
  );
}
