import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import type {
  SettingsOverviewProps,
  SourceInstance,
} from "../../../utils/settings/types";
import { BaseConfigCard } from "../BaseConfigCard";
import { BaseConfigDrawer } from "../BaseConfigDrawer";
import { useSubSettings } from "../settingsProvider";
import { useApiRequest } from "../useApiRequest";
import { MainConfigScreen } from "./MainConfigScreen";
import { NewSourceScreen } from "./NewSourceScreen";
import { BASE_URL_PREFIX, SNOWFLAKE_DISPLAY_NAME, SNOWFLAKE_NAME } from "./consts";

export function SnowflakeConfigOverview({
  onConfigure,
}: SettingsOverviewProps): JSX.Element {
  const [rawSnowflakeAccounts] = useSubSettings(SNOWFLAKE_NAME);
  const accounts = rawSnowflakeAccounts ?? [];
  const [firstAccount] = accounts;
  const [firstAccountUri, firstAccountWarehouse] =
    firstAccount?.source_identifier.split(",") ?? [];

  return (
    <BaseConfigCard
      avatarPath="/img/connectors/snowflake.svg"
      configured={accounts.length > 0}
      loading={rawSnowflakeAccounts === undefined}
      sourceName={SNOWFLAKE_DISPLAY_NAME}
      onConfigure={onConfigure}
    >
      <>
        <Typography gutterBottom component="div" variant="h6">
          Configured Accounts
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {firstAccount !== undefined && (
            <>
              {`${firstAccountUri ?? ""}`}
              <br />
              {`(${firstAccountWarehouse ?? ""})`}
              <br />
              {accounts.length > 1
                ? `... (${accounts.length - 1} more configured)`
                : "1 account configured"}
            </>
          )}
        </Typography>
      </>
    </BaseConfigCard>
  );
}

export function SnowflakeConfigSettings(): JSX.Element {
  const [snowflakeAccounts, setSnowflakeAccounts] = useSubSettings(SNOWFLAKE_NAME);
  const [isAddAccount, setIsAddAccount] = useState(false);

  const api = useApiRequest();

  const updateAccounts = useCallback(async () => {
    const accounts = (await api(`${BASE_URL_PREFIX}/keys/`)) as [SourceInstance];
    setSnowflakeAccounts(accounts);
  }, [api, setSnowflakeAccounts]);

  const handleDelete = useCallback(
    (account: SourceInstance) => {
      const onServer = async () => {
        await api(`${BASE_URL_PREFIX}/keys/`, account, "DELETE");
        await updateAccounts();
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onServer();
    },
    [api, updateAccounts],
  );

  const doneHandler = useCallback(
    (canceled: boolean) => {
      setIsAddAccount(false);

      if (canceled) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateAccounts();
    },
    [updateAccounts],
  );

  const addSourceHandler = useCallback(() => {
    setIsAddAccount(true);
  }, []);

  const deleteHandler = useCallback(
    (identifier: SourceInstance) => {
      handleDelete(identifier);
    },
    [handleDelete],
  );

  return (
    <BaseConfigDrawer sourceName={SNOWFLAKE_DISPLAY_NAME}>
      {!isAddAccount && (
        <MainConfigScreen
          accounts={snowflakeAccounts ?? []}
          onAddSource={addSourceHandler}
          onDelete={deleteHandler}
        />
      )}
      {isAddAccount ? <NewSourceScreen onDone={doneHandler} /> : null}
    </BaseConfigDrawer>
  );
}
