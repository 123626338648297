import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { first, find as remedaFind } from "remeda";
import { useRepoPrList } from "../../../../api";
import { FullscreenMessage } from "../../../../components/FullscreenMessage";
import { useRequiredParam } from "../../../../components/useRequiredParam";

export default function RepoIndexPage(): JSX.Element | null {
  const navigate = useNavigate();

  const repoName = useRequiredParam("repoName");

  const { data: pulls, isSuccess, isFetching } = useRepoPrList(repoName);

  // Focus on the first opened PR
  const firstPull = isSuccess
    ? remedaFind(pulls, ({ pr_status }) => pr_status === "open") ?? first(pulls)
    : undefined;

  useEffect(() => {
    if (firstPull !== undefined) {
      navigate(`./pull/${firstPull.pr_number}`, { replace: true });
    }
  }, [firstPull, navigate]);

  if (isFetching) {
    return <FullscreenMessage loading>Finding repository PRs...</FullscreenMessage>;
  }

  if (!isSuccess) {
    return <FullscreenMessage>Error while fetching PRs for repo</FullscreenMessage>;
  }

  if (firstPull === undefined) {
    return <FullscreenMessage>No PRs found for repo</FullscreenMessage>;
  }

  // We reach here only if the API returned a success response with at least 1  PR ID,
  // so wait just for the navigation to happen, we don't need to show anything to the
  // user...
  return null;
}
