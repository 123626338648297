import { useCallback, useId, useMemo, useState } from "react";
import { useOrgList } from "../../api";
import { queryApiCall } from "../../api/queryApiCall";
import { GreenButton } from "../../components/Buttons";
import { SimpleComboBox } from "../../components/SimpleComboBox";
import { BulkUserForm } from "../../components/admin/BulkUserForm";
import { EMPTY_USER, type UserDetails } from "../../components/admin/NewUserForm";
import { UserCreationResultBox } from "../../components/admin/UserCreationResultBox";

const MIN_USER_COUNT = 1;

export default function InviteUsersAdminPage(): JSX.Element {
  const [users, setUsers] = useState<readonly UserDetails[]>(
    Array.from({ length: MIN_USER_COUNT }).map(() => ({ ...EMPTY_USER })),
  );
  const [selectedOrgName, setSelectedOrgName] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<Record<string, string> | undefined>(undefined);
  const [error, setError] = useState<unknown>(undefined);
  const { data: orgList } = useOrgList();
  const orgListId = useId();
  const orgOptionsWithLabels = useMemo(
    () =>
      Object.fromEntries(
        (orgList ?? []).map((org) => [org.name, `${org.name} (${org.display_name})`]),
      ),
    [orgList],
  );

  const inviteUsers = useCallback(() => {
    const inviteUsersAsync = async () => {
      setResult(undefined);
      setError(undefined);
      try {
        const res = await queryApiCall<Record<string, string>>("/admin/invite-users", {
          body: {
            org_name: selectedOrgName,
            users: users.map((u) => ({
              first_name: u.firstName,
              last_name: u.lastName,
              email: u.email,
            })),
          },
        });
        setResult(res);
      } catch (error_) {
        setError(error_);
      }
    };

    // eslint-disable-next-line no-void
    void inviteUsersAsync();
  }, [setResult, setError, selectedOrgName, users]);

  return (
    <div className="m-3 w-1/2">
      <h1 className="mb-2 text-2xl">Invite Users</h1>
      <div className="mb-4 flex flex-col">
        <div className="my-1 flex align-middle">
          <label className="mr-3 py-1" htmlFor={orgListId}>
            Select customer
          </label>
          <SimpleComboBox
            className="w-1/3"
            id={orgListId}
            optionsWithLabels={orgOptionsWithLabels}
            onChange={useCallback(
              (orgName: string) => {
                setSelectedOrgName(orgName);
              },
              [setSelectedOrgName],
            )}
          />
        </div>
      </div>
      <BulkUserForm minUsers={MIN_USER_COUNT} updateUserList={setUsers} users={users} />
      <GreenButton className="mb-2 mt-5 block" onClick={inviteUsers}>
        Invite Users
      </GreenButton>
      <UserCreationResultBox error={error} result={result} />
    </div>
  );
}
