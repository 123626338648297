import { useMemo } from "react";
import { groupBy } from "remeda";
import { type EdgeState, EMPTY_STATE, useRouteLineage } from "../lineage";

type UseTableConnectedEdgesResult = {
  readonly data?: Readonly<Record<"inbound" | "outbound", readonly EdgeState[]>>;
};

// TODO: It's extremely ineffective to scan the whole array of snapshot
// relations just to get the relations that are connected to a specific table. This
// should be done on the server.
export function useLineageRootConnectedEdges(
  entityId: string,
): UseTableConnectedEdgesResult {
  const { snapshot } = useRouteLineage() ?? {
    snapshot: EMPTY_STATE,
  };

  const { inbound = [], outbound = [] } = useMemo(
    () =>
      groupBy.strict(
        snapshot.edges,
        ({ source: { rootId: srcRootId }, dest: { rootId: dstRootId } }) =>
          entityId === srcRootId
            ? "outbound"
            : entityId === dstRootId
            ? "inbound"
            : undefined,
      ),
    [snapshot.edges, entityId],
  );

  return useMemo(() => ({ data: { inbound, outbound } }), [inbound, outbound]);
}
