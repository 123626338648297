import { useCallback, useEffect, useState } from "react";

declare global {
  // This is intentional, we are explicitly merging this into the Window
  // interface
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    processOAuthDone: ((searchParams: string) => void) | undefined;
  }
}

export function useOAuthCallback(
  onAuthDone: (authParams: URLSearchParams | undefined) => void,
): (url: string) => void {
  const [popup, setPopup] = useState<Window>();

  useEffect(() => {
    window.processOAuthDone = (queryString: string) => {
      const searchParams = new URLSearchParams(queryString);
      onAuthDone(searchParams);
      window.focus();
      popup?.close();
    };
    return () => {
      window.processOAuthDone = undefined;
    };
  }, [onAuthDone, popup]);

  return useCallback((url: string) => {
    setPopup(window.open(url) ?? undefined);
  }, []);
}
