export function Instructions(): JSX.Element {
  return (
    <section className="flex flex-col gap-4">
      <section>
        <h6 className="mt-3">Instructions</h6>
        <h5 className="font-bold">Server Name</h5>
        <p>
          The URL of your looker server (i.e. https://foundational.cloud.looker.com)
        </p>
      </section>
      <section>
        <h5 className="font-bold">API Key ID &amp; Secret</h5>
        <p>
          Go to <em className="italics">Admin Panel</em>, and there go to{" "}
          <em className="italics">Users</em> tab. Select or create new user with admin
          access. Click on the user (enter &quot;Edit User&quot; page), go to{" "}
          <em className="italics">API Keys</em> and click &quot;Edit Keys&quot; button.
          Click &quot;New API Key&quot; and copy here the Client ID and Client Secret.
        </p>
      </section>
      <section>
        <h5 className="font-bold">LookML repository</h5>
        Select the repository which holds your LookML files. (should be added first in
        Github connector). Multiple repositories can be selected.
      </section>
    </section>
  );
}
