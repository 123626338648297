import { type GraphLabel, graphlib, layout as dagreLayout } from "dagre";
import type { Dimensions, Edge, Node as ReactFlowNode, XYPosition } from "reactflow";
import {
  filter,
  forEach,
  groupBy,
  isDefined,
  map,
  pipe,
  prop,
  sort,
  toPairs,
  uniq,
} from "remeda";
import invariant from "tiny-invariant";
import type { EdgeState } from "../../api";
import type { LineageRootData } from "../../components/graph/LineageRootNode";
import { layoutData } from "../../components/graph/LineageRootNode";
import type { EdgeData } from "../../components/graph/edge";
import { repositionElementsInGridLayout } from "../discovery/repositionElementsInGridLayout";

// We give more weight to tables that have mutliple edges going between them so
// that dagre would put them closer together. We use an exponential curve with
// this factor as the exponent.
const EDGES_NUM_WEIGHT_FACTOR = 2;

/**
 * There is an error in typing for dagre's Graph class; the node method that
 * returns a node in the graph can return undefined for IDs that are not in the
 * graph. We can force a new type definition that would fix it using declaration
 * merging. Yey typescript!
 * @see https://www.typescriptlang.org/docs/handbook/declaration-merging.html
 * @see https://github.com/dagrejs/graphlib/blob/bfc78ebc7e2f56ce70f28f34f396a2a93e43f571/lib/graph.ts#L235-L241
 */
declare module "dagre" {
  // This is how it's done in dagre, not our choice
  // eslint-disable-next-line @typescript-eslint/no-namespace, @typescript-eslint/no-shadow
  namespace graphlib {
    // We are explicitly working with an interface here
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Graph {
      // This is copy-pasted from the original definition for the node method;
      // the only change is adding undefined to the return type.
      // eslint-disable-next-line @typescript-eslint/method-signature-style
      node(id: Label | string): Node | undefined;
    }
  }
}

/**
 * These settings define a wider graph layout, more suitable when showing large
 * amounts of nodes at a lower zoom level,
 * The setting kicks in depending on the number of nodes in the chart, where
 * low node counts would result in the dagre default instead.
 */
const WIDE_SPREAD_NODE_COUNT_THRESHOLD = 30;

const WIDE_NODE_SEPARATION = 150;
const WIDE_RANK_SEPARATION = 350;

export function layoutGraph(
  initialNodes: ReactFlowNode<LineageRootData>[],
  edges: Edge<EdgeData>[],
): ReactFlowNode<LineageRootData>[] {
  const relations = pipe(
    edges,
    map(prop("data")),
    filter(isDefined),
    map(({ edgeState }) => edgeState),
    filter(isDefined),
  );

  const layedoutGraph = layout(initialNodes, relations);

  const layedOutNodes = initialNodes.map(({ position: _, ...positionlessNode }) => {
    const masterNode = layedoutGraph.node(positionlessNode.id);
    invariant(
      masterNode !== undefined,
      `Node ${positionlessNode.id} was missing from the master layout while initializing it's position!`,
    );

    return {
      ...positionlessNode,
      position: centerToTopLeft(masterNode),
    };
  });

  const dagreXPositions = pipe(
    layedOutNodes,
    map(({ position: { x } }) => x),
    uniq(),
    sort((a, b) => a - b),
  );

  for (const [tier, dagreXPosition] of dagreXPositions.entries()) {
    for (const node of layedOutNodes) {
      if (node.position.x === dagreXPosition) {
        node.data = { ...node.data, ...layoutData(tier) };
      }
    }
  }

  return repositionElementsInGridLayout(layedOutNodes);
}

function layout(
  nodes: readonly ReactFlowNode[],
  relations: readonly EdgeState[],
): graphlib.Graph {
  const graph = new graphlib.Graph();
  graph.setDefaultEdgeLabel(() => ({}));
  graph.setGraph(configureDagre(nodes.length));

  // Add our table nodes to the graph
  for (const { id, height, width } of nodes) {
    graph.setNode(id, {
      height,
      width,
    });
  }

  // For dagre we create a single edge between any two tables that have an edge
  // between them in the final graph. This is because we are using a simple mode
  // of dagre. We can check out more complex algorithms in the future if this
  // layout doesn't work well enough for us in certian cases
  pipe(
    relations,
    groupBy.strict(({ source: { rootId } }) => rootId),
    toPairs.strict,
    forEach(([source, sourceRelations]) =>
      pipe(
        sourceRelations,
        groupBy.strict(({ dest: { rootId } }) => rootId),
        toPairs.strict,
        forEach(([target, groupedEdges]) => {
          graph.setEdge(source, target, {
            weight: groupedEdges.length ** EDGES_NUM_WEIGHT_FACTOR,
          });
        }),
      ),
    ),
  );

  dagreLayout(graph);

  return graph;
}

/**
 * Dagre layouts return the position of each node with it's coordinate in the
 * middle, but ReactFlow uses the top-left corner for positioning so we need to
 * shift the coords
 */
const centerToTopLeft = ({
  width,
  height,
  ...pos
}: Readonly<Dimensions & XYPosition>): Readonly<XYPosition> =>
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This is just math...
  offsetPos(pos, { x: -width / 2, y: -height / 2 });

const offsetPos = (
  { x, y }: Readonly<XYPosition>,
  { x: offsetX, y: offsetY }: Readonly<XYPosition>,
): Readonly<XYPosition> => ({ x: x + offsetX, y: y + offsetY });

/**
 * @see https://github.com/dagrejs/dagre/wiki#configuring-the-layout
 */
const configureDagre = (nodesNum: number): GraphLabel => ({
  // The general direction of the chart: Left to right
  rankdir: "LR",
  ...(nodesNum > WIDE_SPREAD_NODE_COUNT_THRESHOLD && {
    nodesep: WIDE_NODE_SEPARATION,
    ranksep: WIDE_RANK_SEPARATION,
  }),
});
