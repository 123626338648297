import humanizeDuration from "humanize-duration";
import type { RequireExactlyOne } from "type-fest";

const HUMANIZE_OPTIONS: humanizeDuration.Options = {
  largest: 1,
  round: true,
  units: [
    // Months
    "mo",
    // Weeks
    "w",
    // Days
    "d",
    // Hours
    "h",
    // Minutes
    "m",
  ],
};

/**
 * Takes an ISO 8601 date string and returns a <time> element with a humanized
 * "... ago" string.
 */
export function PrettyTimeSince({
  dateTime,
  timestamp,
  className,
}: RequireExactlyOne<{
  readonly dateTime: string;
  readonly timestamp: number;
}> & { readonly className?: string | undefined }): JSX.Element {
  const date = new Date(dateTime ?? timestamp);

  return (
    <time
      className={className}
      dateTime={date.toISOString()}
      title={date.toLocaleString()}
    >
      {prettySince(date.getTime())} ago
    </time>
  );
}

const prettySince = (pointInTimeMs: number): string =>
  humanizeDuration(since(pointInTimeMs), HUMANIZE_OPTIONS);

const since = (pointInTimeMs: number): number => Date.now() - pointInTimeMs;
