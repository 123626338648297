export function TableauInstructionsButton({
  onClick,
}: {
  readonly onClick: () => void;
}): JSX.Element {
  return (
    <button
      className="flex items-center hover:text-blue-600 hover:underline"
      type="button"
      onClick={onClick}
    >
      <img
        alt="Tableau's colorful circle made of plus signs icon"
        className="mr-2 h-4 w-4"
        src="/img/connectors/tableau.svg"
      />
      Instructions
    </button>
  );
}
