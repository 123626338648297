import type { LineageSearchResult } from "../../utils/search/lineage";
import { ColumnIcon } from "../icons/ColumnIcon";
import { TableIcon } from "../icons/TableIcon";
import { HighlightedText } from "./HighlightedText";

export function SearchResultItemHeader({
  result: { segments, result },
}: {
  readonly result: LineageSearchResult;
}): JSX.Element {
  return (
    <div className="flex w-full gap-x-2">
      <figure className="mt-0.5 flex h-4 w-4 items-start justify-start">
        <CategoryIcon
          category={result.type === "column" ? "column" : "table"}
          className="text-black/20"
        />
      </figure>
      <HighlightedText
        className={`flex-1 text-left font-bold text-[rgb(82,100,96)] [&>mark]:bg-[rgba(101,202,132,0.2)] ${
          result.type === "column" ? "" : "uppercase"
        }`}
        segments={segments}
        text={result.entityName}
      />
    </div>
  );
}

function CategoryIcon({
  category,
  className,
}: {
  readonly category: "column" | "table";
  readonly className?: string | undefined;
}): JSX.Element {
  switch (category) {
    case "column":
      return <ColumnIcon className={className} />;

    case "table":
      return <TableIcon className={className} />;
  }
}
