import type { PolicyRulesData } from "../../../api";
import { PolicyTriggerIcon } from "../../icons/PolicyTriggerIcon";
import { EditableTriggerList } from "./EditableTriggerList";
import { Section } from "./Section";

export function TriggersSection({
  className,
  onTriggersChange,
}: {
  readonly onTriggersChange: (policyRules: PolicyRulesData | undefined) => void;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <Section
      className={className}
      headerIcon={<PolicyTriggerIcon className="h-6 w-6 text-[rgb(143,156,157)]" />}
      title="Triggers"
    >
      <EditableTriggerList onRuleSetChange={onTriggersChange} />
    </Section>
  );
}
