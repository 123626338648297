import { FiltersPanelSearchResultsView } from "./FiltersPanelSearchResultsView";
import { useFiltersPanelContext } from "./SidebarFiltersPanel";

export function AffectedTab(): JSX.Element {
  const { query } = useFiltersPanelContext();

  if (query !== "") {
    return <FiltersPanelSearchResultsView changeType="affected" />;
  }

  return (
    <li className="p-4 text-sm text-black/50">
      (Use search to filter affected entities...)
    </li>
  );
}
