import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { SearchIcon } from "./icons/SearchIcon";

export type SimpleSearchBoxElement = {
  readonly reset: () => void;
  readonly focus: () => void;
};

export const SimpleSearchBox = forwardRef<
  SimpleSearchBoxElement,
  {
    readonly onSearch: (query: string) => void;
    readonly onFocus?: () => void;
    readonly placeholder?: string;
    readonly disabled?: boolean | undefined;
    readonly autoFocus?: boolean;
  }
>(function SimpleSearchBox(
  { placeholder, onSearch, onFocus, disabled, autoFocus },
  ref,
): JSX.Element | null {
  const searchInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      reset: () => {
        const { current: searchElement } = searchInputRef;
        if (searchElement === null) {
          return;
        }

        searchElement.value = "";
        searchElement.blur();
      },
      focus: () => {
        const { current: searchElement } = searchInputRef;
        if (searchElement === null) {
          return;
        }

        searchElement.focus();
      },
    }),
    [],
  );

  useEffect(() => {
    const { current: searchElement } = searchInputRef;
    if (searchElement === null) {
      return;
    }

    const handleSearch = ({ currentTarget }: Event) => {
      if (currentTarget === null) {
        return;
      }

      if (!(currentTarget instanceof HTMLInputElement)) {
        return;
      }

      const { value } = currentTarget;

      onSearch(value);
    };

    searchElement.addEventListener("search", handleSearch);
    searchElement.toggleAttribute("incremental", true /* force */);

    return () => {
      searchElement.toggleAttribute("incremental", false /* force */);
      searchElement.removeEventListener("search", handleSearch);
    };
  }, [onSearch]);

  return (
    <div className="relative">
      <input
        ref={searchInputRef}
        required
        autoComplete="off"
        autoCorrect="off"
        autoFocus={autoFocus}
        className="peer w-full rounded-md border px-5 py-3 text-sm font-semibold text-[rgb(82,100,96)] shadow-[0px_0px_1px_rgba(44,57,63,0.8),0px_2px_6px_rgba(44,57,63,0.08)] outline-none placeholder:font-normal placeholder:text-[rgb(165,173,176)] focus:ring-1 focus:ring-[rgb(101,202,132)]"
        disabled={disabled}
        name="q"
        placeholder={placeholder}
        spellCheck={false}
        // We use 'title' here to disable the browser's default behaviour for 'required'
        // fields to show a tooltip "please fill out this field". I don't know why this
        // works, i just found it on stack overflow
        title=""
        type="search"
        onFocus={onFocus}
      />
      <SearchIcon className="invisible absolute right-4 top-4 w-[14px] text-[rgb(185,194,195)] peer-invalid:visible" />
    </div>
  );
});
