export function ColumnIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 18 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M16 10V12H2V10H16ZM16 2V4H2V2H16ZM0.587 13.413C0.979 13.8043 1.45 14 2 14H16C16.55 14 17.021 13.8043 17.413 13.413C17.8043 13.021 18 12.55 18 12V2C18 1.45 17.8043 0.979333 17.413 0.588C17.021 0.196 16.55 0 16 0H2C1.45 0 0.979 0.196 0.587 0.588C0.195667 0.979333 0 1.45 0 2V12C0 12.55 0.195667 13.021 0.587 13.413ZM2 8V6H16V8H2Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
