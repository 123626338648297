import type { ChangeStatus } from "../../../api";
import { filterLabel } from "../../../utils/filters/filterLabel";
import type { FilterType } from "../../../utils/search/lineageType";
import { NavigationBackIcon } from "../../icons/NavigationBackIcon";
import { FilteredEntitiesList } from "./FilteredEntitiesList";

export type ActiveFilterProps = {
  readonly filterType: FilterType;
  readonly changeStatus: ChangeStatus;
};

export function FilteredEntitiesView({
  onReset,
  filterType,
  changeStatus,
}: ActiveFilterProps & {
  readonly onReset: () => void;
}): JSX.Element {
  return (
    <section className="flex flex-1 flex-col overflow-clip">
      <header className="flex flex-none items-center gap-x-3 border-b px-5 py-3">
        <button
          className="flex w-3 items-center justify-center"
          type="reset"
          onClick={onReset}
        >
          <NavigationBackIcon className="text-[rgb(165,173,176)]" />
        </button>
        <span className="text-sm font-semibold text-[rgb(82,100,96)]">
          {filterLabel(filterType, changeStatus)}
        </span>
      </header>
      <FilteredEntitiesList changeStatus={changeStatus} filterType={filterType} />
    </section>
  );
}
