export function ThumbIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 19 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.83301 16.5002V5.64618L9.47901 1.10418C9.75701 0.826851 10.066 0.660184 10.406 0.604184C10.7467 0.54885 11.0627 0.604517 11.354 0.771184C11.646 0.951851 11.8543 1.21218 11.979 1.55218C12.1043 1.89218 12.1183 2.25685 12.021 2.64618L11.25 5.64618H16.417C16.875 5.64618 17.281 5.82318 17.635 6.17718C17.9897 6.53118 18.167 6.93752 18.167 7.39618V9.06218C18.167 9.15952 18.16 9.26385 18.146 9.37518C18.132 9.48652 18.104 9.58385 18.062 9.66718L15.562 15.5002C15.4513 15.7922 15.2467 16.0319 14.948 16.2192C14.6493 16.4065 14.3403 16.5002 14.021 16.5002H4.83301ZM6.60401 6.37518V14.7502H13.958L16.417 9.06218V7.39618H9.00001L10.146 2.89618L6.60401 6.37518ZM2.41701 16.5002C1.93101 16.5002 1.51767 16.3302 1.17701 15.9902C0.837007 15.6495 0.667007 15.2362 0.667007 14.7502V7.39618C0.667007 6.91018 0.837007 6.49685 1.17701 6.15618C1.51767 5.81618 1.93101 5.64618 2.41701 5.64618H4.83301L4.85401 7.39618H2.41701V14.7502H4.85401V16.5002H2.41701Z" />
    </svg>
  );
}
