import type { ColumnType } from "../../api";
import { BooleanIcon } from "../icons/BooleanIcon";
import { DateIcon } from "../icons/DateIcon";
import { NumberIcon } from "../icons/NumberIcon";
import { StructIcon } from "../icons/StructIcon";
import { TextIcon } from "../icons/TextIcon";

export function ColumnTypeIcon({
  className,
  type,
}: {
  readonly type: ColumnType;
  readonly className?: string | undefined;
}): JSX.Element {
  switch (type) {
    case "enum":
      return <BooleanIcon className={className} />;
    case "integer":
      return <NumberIcon className={className} />;
    case "varchar":
      return <TextIcon className={className} />;
    case "date":
      return <DateIcon className={className} />;
    case "struct":
      return <StructIcon className={className} />;
  }
}
