import { useParams } from "react-router-dom";
import type { Edge, Node as ReactFlowNode } from "reactflow";
import { equals, filter, isDefined, map, pipe, prop, uniqWith } from "remeda";
import type { LineageRootEntity } from "../../../api";
import type { LineageRootData } from "../../graph/LineageRootNode";
import type { EdgeData } from "../../graph/edge";

const CSV_FILE_NAME_PREFIX = "foundational-lineage";
const CSV_FILE_HEADERS = [
  { label: "Table", key: "table" },
  { label: "Schema", key: "schema" },
  { label: "Database", key: "database" },
  { label: "Downstream Table", key: "downstreamTable" },
  { label: "Downstream Table Schema", key: "downstreamTableSchema" },
  { label: "Downstream Table Database", key: "downstreamTableDatabase" },
] as const;
const CSV_FILE_HEADER_ROW = map(CSV_FILE_HEADERS, prop("label"));

type GraphCsvHeader = (typeof CSV_FILE_HEADERS)[number];
type GraphCsvRow = Record<GraphCsvHeader["key"], string>;

export function getGraphAsCsvFileData(
  edges: Edge<EdgeData>[],
  nodes: ReactFlowNode<LineageRootData>[],
): string[] {
  const csvData = getGraphAsCsvObjects(edges, nodes);

  const data = map(csvData, (row) =>
    pipe(
      CSV_FILE_HEADERS,
      map(prop("key")),
      map((key) => row[key]),
    ),
  );
  return map([CSV_FILE_HEADER_ROW, ...data], (row) => `${row.join(",")}\n`);
}

function getGraphAsCsvObjects(
  edges: Edge<EdgeData>[],
  nodes: ReactFlowNode<LineageRootData>[],
): GraphCsvRow[] {
  const idToEntityMap: Record<string, LineageRootEntity> = {};
  for (const node of nodes) {
    idToEntityMap[node.id] = node.data.entity;
  }
  return pipe(
    edges,
    map(({ data: edgeData }: Edge<EdgeData>) => {
      if (edgeData === undefined) {
        return;
      }

      const {
        edgeState: {
          source: { rootId: sourceRootId },
          dest: { rootId: destRootId },
        },
      } = edgeData;

      const { [sourceRootId]: source, [destRootId]: dest } = idToEntityMap;
      if (source === undefined || dest === undefined) {
        return;
      }

      return {
        table: source.entityName,
        schema: schemaNameForEntity(source),
        database: dbNameForEntity(source),
        downstreamTable: dest.entityName,
        downstreamTableSchema: schemaNameForEntity(dest),
        downstreamTableDatabase: dbNameForEntity(dest),
      };
    }),
    filter(isDefined),
    uniqWith(equals),
  ) as GraphCsvRow[];
}

export function useGraphCsvFileName(): string {
  const { repoName, prId } = useParams();
  return repoName !== undefined && prId !== undefined
    ? `${CSV_FILE_NAME_PREFIX}-${repoName}-${prId}.csv`
    : `${CSV_FILE_NAME_PREFIX}.csv`;
}

function schemaNameForEntity(entity: LineageRootData["entity"]): string {
  switch (entity.type) {
    case "table":
      return entity.dbSchema;
    case "file":
      return entity.uri;
    case "dashboard":
      return "";
  }
}

function dbNameForEntity(entity: LineageRootData["entity"]): string {
  switch (entity.type) {
    case "table":
      return entity.dbName;
    case "file":
    case "dashboard":
      return entity.platform;
  }
}
