import { ADMIN_PATH } from "../../routes/ROUTES";
import { DashboardIcon } from "../icons/DashboardIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { UserIcon } from "../icons/UserIcon";
import { NavItem } from "./NavItem";

export function AdminNavigationItems(): JSX.Element {
  return (
    <ol className="flex-1">
      <NavItem icon={<DashboardIcon />} to={`/${ADMIN_PATH}/create-org`}>
        Create org
      </NavItem>
      <NavItem icon={<UserIcon />} to={`/${ADMIN_PATH}/invite-users`}>
        Invite Users
      </NavItem>
      <NavItem icon={<HomeIcon className="w-4" />} to="/">
        Main site
      </NavItem>
    </ol>
  );
}
