import type { ChangeStatus } from "../../api";
import type { FilterType } from "../search/lineageType";

export function filterLabel(type: FilterType, changeStatus: ChangeStatus): string {
  return `${typeLabel(type)} ${changeStatusLabel(changeStatus)}`;
}

function typeLabel(type: FilterType): string {
  switch (type) {
    case "column":
      return "Columns";

    case "dashboard":
      return "Dashboards";

    case "edge":
      return "Edges";

    case "table":
      return "Tables";
  }
}

function changeStatusLabel(changeStatus: ChangeStatus): string {
  switch (changeStatus) {
    case "new":
      return "Added";

    case "changed":
      return "Modified";

    case "removed":
      return "Removed";

    case "renamed":
      return "Renamed";
  }
}
