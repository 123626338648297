import { useEffect, useMemo } from "react";
import { useRepoNames } from "../../../api";
import { logEventRepositorySelectionClicked } from "../../../utils/analytics/eventLogging";
import { parseRepositoryName } from "../../../utils/parseRepositoryName";
import { useRequiredParam } from "../../useRequiredParam";
import { PanelLoadingMessage } from "./PanelLoadingMessage";
import { RepoItem } from "./RepoItem";
import { Selector } from "./Selector";
import { SelectorLabel } from "./SelectorLabel";

export function RepositorySelector(): JSX.Element {
  const fullRepositoryName = useRequiredParam("repoName");

  const { repositoryName } = useMemo(
    () => parseRepositoryName(fullRepositoryName),
    [fullRepositoryName],
  );

  return (
    <Selector label={<SelectorLabel>Repository</SelectorLabel>} value={repositoryName}>
      <RepositorySelectorPanel />
    </Selector>
  );
}

function RepositorySelectorPanel(): JSX.Element {
  const { data: repoNames } = useRepoNames();

  useEffect(() => {
    if (repoNames?.length === undefined) {
      return;
    }

    logEventRepositorySelectionClicked(repoNames.length);
  }, [repoNames?.length]);

  if (repoNames === undefined) {
    return <PanelLoadingMessage>Fetching repositories...</PanelLoadingMessage>;
  }

  return (
    <ol>
      {repoNames.map((repoName) => (
        <li key={repoName}>
          <RepoItem repoName={repoName} />
        </li>
      ))}
    </ol>
  );
}
