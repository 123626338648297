import { useParams } from "react-router-dom";
import { z } from "zod";

// This is hard-coded in our ROUTES file, don't change it without updating that too.
const PARAM_NAME = "prId";

const PARAM_NUMBER_SCHEMA = z.coerce.number().int().positive();

export function usePrNumberParam(): z.infer<typeof PARAM_NUMBER_SCHEMA> {
  const { [PARAM_NAME]: param } = useParams();
  return PARAM_NUMBER_SCHEMA.parse(param);
}
