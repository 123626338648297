/* eslint-disable @typescript-eslint/no-magic-numbers -- It's a huge enum */

/**
 * Common names/statusText for HTTP codes
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 */
export const HTTP_CODE = {
  // === 1XX: Informational ===
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  // WebDAV
  PROCESSING: 102,
  EARLY_HINTS: 103,

  // === 2XX: Successful ===
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  // WebDAV
  MULTI_STATUS: 207,
  // WebDAV
  ALREADY_REPORTED: 208,
  // 209 - 225 - unused
  // HTTP Delta encoding
  IM_USED: 226,

  // === 3XX: Redirection ===
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  // Deprecated
  USE_PROXY: 305,
  // 306 - unused
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,

  // === 4XX: Client Error ===
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  // Experimental
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SPECIFIED: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  // 419 - unused
  // 420 - unused
  MISDIRECT_REQUEST: 421,
  // WebDAV
  UNPROCESSABLE_ENTITY: 422,
  // WebDAV
  LOCKED: 423,
  // WebDAV
  FAILED_DEPENDANCY: 424,
  // Experimental
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 427,
  // 428 - unused
  TOO_MANY_REQUESTS: 429,
  // 430 - unused
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  // 432 - 450 - unused
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,

  // === 5XX: Server Error ===
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  // WebDAV
  INSUFFICIENT_STORAGE: 507,
  // WebDAV
  LOOP_DETECTED: 508,
  // 509 - unused
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,

  // TODO: If we support `satisfies` we should add:
  // `satisfies Record<Uppercase<string>, number>`
  // to prevent bad values from slipping in.
} as const;

// These intentionally don't use the consts above, so that adding or removing a const
// doesn't risk breaking any of these. These are defined by the standard without any
// regard to specific status code values so should be implemented the same. Prefer these
// over redefininig it yourself.
export const isClientErrorStatus = (statusCode: number): boolean =>
  statusCode >= 400 && statusCode <= 499;
export const isServerErrorStatus = (statusCode: number): boolean =>
  statusCode >= 500 && statusCode <= 599;
