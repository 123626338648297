import { pipe, sortBy } from "remeda";
import type { PrItem } from "../../api";
import type { MatchDescriptor, SearcherFunction } from "./SEARCHERS";
import { entitySearch } from "./entitiesSearch";

export type PullRequestsSearchResult = MatchDescriptor &
  PullRequestsSearchEntityDescriptor;

type PullRequestsSearchEntityDescriptor = {
  readonly item: PrItem;
  readonly entityType: "creatorLogin" | "creatorName" | "hash" | "number" | "title";
};

const MATCH_TYPE_ORDER: readonly MatchDescriptor["matchType"][] = ["exact", "contains"];

export const searchPullRequests = (
  pulls: readonly PrItem[],
  query: string,
): readonly PullRequestsSearchResult[] =>
  pipe(
    pulls,
    search(query),
    sortBy(
      // Our very crude "ranking algorithm" for search reuslts
      ({ matchType }) => MATCH_TYPE_ORDER.indexOf(matchType),
      ({ isMatchCase }) => isMatchCase,
      [({ item: { pr_number } }) => pr_number, "desc"],
    ),
  );

const search = (query: string) => (pulls: readonly PrItem[]) =>
  entitySearch(pulls, query, searchPullRequest);

// eslint-disable-next-line max-statements
function searchPullRequest(
  item: PrItem,
  searcher: SearcherFunction,
): readonly PullRequestsSearchResult[] {
  const {
    pr_text,
    pr_number,
    created_by: { name: creatorName, login },
    head_hash,
  } = item;

  const textMatch = searcher(pr_text);
  if (textMatch !== undefined) {
    return [
      {
        entityType: "title",
        item,
        ...textMatch,
      },
    ];
  }

  const numberMatch = searcher(`${pr_number}`);
  if (numberMatch !== undefined) {
    return [
      {
        entityType: "number",
        item,
        ...numberMatch,
      },
    ];
  }

  const creatorMatch = creatorName === undefined ? undefined : searcher(creatorName);
  if (creatorMatch !== undefined) {
    return [
      {
        entityType: "creatorName",
        item,
        ...creatorMatch,
      },
    ];
  }

  const loginMatch = searcher(login);
  if (loginMatch !== undefined) {
    return [
      {
        entityType: "creatorLogin",
        item,
        ...loginMatch,
      },
    ];
  }

  const hashMatch = searcher(head_hash);
  if (hashMatch !== undefined) {
    return [
      {
        entityType: "hash",
        item,
        ...hashMatch,
      },
    ];
  }

  return [];
}
