const STORAGE_KEY_BACKDOOR_MOCK_DATA = "__mockData";

export const DEMO_MODE = {
  get isEnabled(): boolean {
    return localStorage.getItem(STORAGE_KEY_BACKDOOR_MOCK_DATA) !== null;
  },

  set isEnabled(isEnabled: boolean) {
    if (isEnabled) {
      localStorage.setItem(STORAGE_KEY_BACKDOOR_MOCK_DATA, "1");
    } else {
      localStorage.removeItem(STORAGE_KEY_BACKDOOR_MOCK_DATA);
    }
  },
};
