type SnowflakeInstructionsInfo = {
  readonly database: string;
  readonly warehouse: string;
  readonly ingestionRole: string;
  readonly userName: string;
};

export function getSnowflakeInstructionsScript({
  database,
  warehouse,
  ingestionRole,
  userName,
}: SnowflakeInstructionsInfo): string {
  return `
create or replace role ${ingestionRole};

// Grant access to a warehouse to run queries to view metadata
grant operate, usage on warehouse ${warehouse} to role ${ingestionRole};

// Grant access to view database and schema in which your tables/views exist
grant usage on DATABASE ${database} to role ${ingestionRole};
grant usage on all schemas in database ${database} to role ${ingestionRole};
grant usage on future schemas in database ${database} to role ${ingestionRole};

// If you are NOT using Snowflake Profiling or Classification feature: Grant references privileges to your tables and views
grant references on all tables in database ${database} to role ${ingestionRole};
grant references on future tables in database ${database} to role ${ingestionRole};
grant references on all external tables in database ${database} to role ${ingestionRole};
grant references on future external tables in database ${database} to role ${ingestionRole};
grant references on all views in database ${database} to role ${ingestionRole};
grant references on future views in database ${database} to role ${ingestionRole};

// If you ARE using Snowflake Profiling or Classification feature: Grant select privileges to your tables
grant select on all tables in database ${database} to role ${ingestionRole};
grant select on future tables in database ${database} to role ${ingestionRole};
grant select on all external tables in database ${database} to role ${ingestionRole};
grant select on future external tables in database ${database} to role ${ingestionRole};

// Create a new Foundational user and assign the Foundational role to it
create user ${userName} display_name = 'Foundational' RSA_PUBLIC_KEY='&ltadd-your-public-key-here&gt' default_role = ${ingestionRole} default_warehouse = '${warehouse}';

// Grant the ${ingestionRole} to the new Foundational user.
grant role ${ingestionRole} to user ${userName};

// Optional - required if extracting lineage, usage or tags (without lineage)
grant imported privileges on database snowflake to role ${ingestionRole};
`;
}
