export function RightArrowIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.396 14.062C11.2153 13.882 11.1217 13.6807 11.115 13.458C11.1077 13.236 11.1943 13.0347 11.375 12.854L13.354 10.875H4.20801C3.97201 10.875 3.76734 10.7883 3.59401 10.615C3.42001 10.441 3.33301 10.236 3.33301 10C3.33301 9.764 3.42001 9.559 3.59401 9.385C3.76734 9.21167 3.97201 9.125 4.20801 9.125H13.354L11.375 7.167C11.1943 6.98633 11.1077 6.78133 11.115 6.552C11.1217 6.32267 11.2153 6.118 11.396 5.938C11.5767 5.75733 11.778 5.667 12 5.667C12.222 5.667 12.4233 5.75733 12.604 5.938L16.042 9.375C16.1393 9.45833 16.2087 9.55567 16.25 9.667C16.2913 9.77767 16.312 9.88867 16.312 10C16.312 10.1113 16.2913 10.219 16.25 10.323C16.2087 10.427 16.1393 10.5277 16.042 10.625L12.604 14.062C12.4233 14.2427 12.222 14.333 12 14.333C11.778 14.333 11.5767 14.2427 11.396 14.062Z"
        fill="currentColor"
      />
    </svg>
  );
}
