/* eslint-disable import/max-dependencies */
import { mapValues } from "remeda";
import { DEMO_MODE } from "../../utils/demo/demoMode";
import type { SettingInterface } from "../../utils/settings/types";
import { BaseConfigDrawer } from "./BaseConfigDrawer";
import { BigQueryConfigOverview, BigQuerySettings } from "./bigquery/bigquery";
import { BIGQUERY_NAME } from "./bigquery/consts";
import {
  cardOverviewComponentFactory,
  DEMO_CONNECTORS,
  type DemoConnectorProps,
} from "./demo/demoConnectors";
import { GITHUB_NAME, GithubConfigOverview, GithubSettings } from "./github";
import { LOOKER_NAME } from "./looker/consts";
import { LookerConfigOverview, LookerSettings } from "./looker/looker";
import { POWERBI_NAME } from "./powerbi/consts";
import { PowerBiConfigOverview, PowerBiConfigSettings } from "./powerbi/powerbi";
import { SNOWFLAKE_NAME } from "./snowflake/consts";
import {
  SnowflakeConfigOverview,
  SnowflakeConfigSettings,
} from "./snowflake/snowflake";
import { TABLEAU_NAME } from "./tableau/consts";
import { TableauConfigOverview, TableauSettings } from "./tableau/tableau";

const demoConnectorSettings = (props: DemoConnectorProps) => ({
  overview: cardOverviewComponentFactory(props),
  settings: () => <BaseConfigDrawer sourceName={props.displayName} />,
});

export const SETTINGS_CONFIG: Record<string, SettingInterface> = {
  [GITHUB_NAME]: {
    overview: GithubConfigOverview,
    settings: GithubSettings,
  },
  [LOOKER_NAME]: {
    overview: LookerConfigOverview,
    settings: LookerSettings,
  },
  [POWERBI_NAME]: {
    overview: PowerBiConfigOverview,
    settings: PowerBiConfigSettings,
  },
  [SNOWFLAKE_NAME]: {
    overview: SnowflakeConfigOverview,
    settings: SnowflakeConfigSettings,
  },
  [TABLEAU_NAME]: {
    overview: TableauConfigOverview,
    settings: TableauSettings,
  },
  [BIGQUERY_NAME]: {
    overview: BigQueryConfigOverview,
    settings: BigQuerySettings,
  },
  ...(DEMO_MODE.isEnabled && mapValues(DEMO_CONNECTORS, demoConnectorSettings)),
};
