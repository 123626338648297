import { createContext, useContext, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { useRouteLineage } from "../../../api";
import { isEmptyDiff } from "../../../routes/__loggedIn/pr-analysis/$repoName/pull.$prId";
import { SimpleSearchBox } from "../../SimpleSearchBox";
import { FiltersController } from "./FiltersController";

type FiltersPanelContext = {
  readonly query: string;
  readonly isDisabled: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-redeclare -- React context naming convention sucks...
const FiltersPanelContext = createContext<FiltersPanelContext | undefined>(undefined);

export function useFiltersPanelContext(): FiltersPanelContext {
  const context = useContext(FiltersPanelContext);
  invariant(
    context !== undefined,
    "Trying to use a filters panel context outside of the filters panel!",
  );

  return context;
}

export function SidebarFiltersPanel(): JSX.Element | null {
  const lineage = useRouteLineage();

  const [query, setQuery] = useState("");

  const context = useMemo(
    () => ({
      query,
      isDisabled: lineage?.diff === undefined ? true : isEmptyDiff(lineage.diff),
    }),
    [lineage?.diff, query],
  );

  if (lineage === undefined) {
    // TODO: Create a loading/empty-state indicator...
    return null;
  }

  return (
    <section className="flex flex-1 flex-col gap-y-2 overflow-hidden border-t">
      <h6 className="flex-none px-5 py-4 text-sm font-semibold text-[rgb(82,97,100)]">
        Changed Entities
      </h6>
      <div className="flex-none px-4">
        <SimpleSearchBox
          disabled={context.isDisabled}
          placeholder={
            context.isDisabled ? "No entities changed" : "Search changed entities"
          }
          onSearch={setQuery}
        />
      </div>
      <FiltersPanelContext.Provider value={context}>
        <FiltersController />
      </FiltersPanelContext.Provider>
    </section>
  );
}
