type ServerError = {
  readonly detail: string;
};

export async function exceptionToErrorMessage(error: unknown): Promise<string> {
  if (error instanceof Error) {
    // Handle errors thrown from frontend
    return error.message;
  }
  if (error instanceof Response) {
    try {
      const bodyJson = (await error.json()) as ServerError;
      if (bodyJson.detail.length > 0) {
        return `${error.status}: ${JSON.stringify(bodyJson.detail, undefined, " ")}`;
      }
    } catch {
      // Do nothing
    }
    return `${error.status}: ${error.statusText}`;
  }
  // Handle errors thrown from backend
  return String(error);
}
