import { useCallback, useRef, useState } from "react";
import type { LineageState } from "../../api";
import {
  logEventGraphItemsSearched,
  logEventGraphSearchFocused,
} from "../../utils/analytics/eventLogging";
import { useGraphType } from "../../utils/graph/useGraphType";
import { type LineageSearchResult, searchLineage } from "../../utils/search/lineage";
import { SimpleSearchBox, type SimpleSearchBoxElement } from "../SimpleSearchBox";
import { NoResults } from "../search/NoResults";
import { SearchResultsTabs } from "./SearchResultsTabs";

export function LineageSearch({
  searchBoxPlaceholder,
  lineage,
  className,
  onResultClick,
  resetOnResultClick,
  refocusOnResultClick,
}: {
  readonly searchBoxPlaceholder: string;
  readonly onResultClick: (result: LineageSearchResult) => void;
  readonly lineage?: LineageState | undefined;
  readonly className?: string | undefined;
  readonly resetOnResultClick?: boolean | undefined;
  readonly refocusOnResultClick?: boolean | undefined;
}): JSX.Element {
  const searchBoxRef = useRef<SimpleSearchBoxElement>(null);

  const [results, setResults] = useState<readonly LineageSearchResult[]>();
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const graphType = useGraphType();

  const handleSearchFocus = useCallback(() => {
    logEventGraphSearchFocused(graphType);
  }, [graphType]);

  const onFocus = useCallback(() => {
    setTimeout(() => {
      setIsHidden(false);
    });
  }, []);

  const onBlurred = useCallback(() => {
    setTimeout(() => {
      setIsHidden(true);
    });
  }, []);

  const handleSearch = useCallback(
    (query: string) => {
      const newResults =
        query === ""
          ? undefined
          : lineage?.entities === undefined
          ? []
          : searchLineage(lineage.entities, query);

      setResults(newResults);

      if (newResults === undefined) {
        return;
      }
      logEventGraphItemsSearched(graphType, query, newResults.length);
    },
    [graphType, lineage?.entities],
  );

  const handleResultClick = useCallback(
    (result: LineageSearchResult) => {
      const { current: searchBoxElement } = searchBoxRef;

      if (resetOnResultClick === true) {
        setResults(undefined);
        if (searchBoxElement !== null) {
          searchBoxElement.reset();
        }
      }

      if (refocusOnResultClick === true && searchBoxElement !== null) {
        searchBoxElement.focus();
      }

      onResultClick(result);
    },
    [onResultClick, resetOnResultClick, refocusOnResultClick],
  );

  return (
    <div className={`relative ${className ?? ""}`} onBlur={onBlurred} onFocus={onFocus}>
      <SimpleSearchBox
        ref={searchBoxRef}
        placeholder={searchBoxPlaceholder}
        onFocus={handleSearchFocus}
        onSearch={handleSearch}
      />
      {results === undefined || isHidden ? null : results.length === 0 ? (
        <NoResults className="absolute top-14 z-50 w-full rounded-md bg-white shadow">
          No elements found
        </NoResults>
      ) : (
        <SearchResultsTabs
          className="absolute top-14 z-50 max-h-96 w-full rounded-md bg-white shadow"
          results={results}
          onResultClick={handleResultClick}
        />
      )}
    </div>
  );
}
