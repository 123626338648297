export function CommitIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 18 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.00001 8.625C8.01401 8.625 7.14601 8.316 6.39601 7.698C5.64601 7.08 5.16667 6.30567 4.95801 5.375H0.833008V3.625H4.97901C5.17367 2.69433 5.64601 1.92 6.39601 1.302C7.14601 0.684 8.01401 0.375 9.00001 0.375C9.98601 0.375 10.854 0.684 11.604 1.302C12.354 1.92 12.8333 2.69433 13.042 3.625H17.167V5.375H13.021C12.8263 6.30567 12.354 7.08 11.604 7.698C10.854 8.316 9.98601 8.625 9.00001 8.625ZM9.00001 6.875C9.66667 6.875 10.2293 6.646 10.688 6.188C11.146 5.72933 11.375 5.16667 11.375 4.5C11.375 3.83333 11.146 3.27067 10.688 2.812C10.2293 2.354 9.66667 2.125 9.00001 2.125C8.33334 2.125 7.77067 2.354 7.31201 2.812C6.85401 3.27067 6.62501 3.83333 6.62501 4.5C6.62501 5.16667 6.85401 5.72933 7.31201 6.188C7.77067 6.646 8.33334 6.875 9.00001 6.875Z" />
    </svg>
  );
}
