import { Switch } from "@headlessui/react";
import type { CSSProperties } from "react";
import { useCallback } from "react";
import type { LineageState } from "../../api";
import type { logEventGraphExpandButtonClicked } from "../../utils/analytics/eventLogging";
import { stopPropagation } from "../stopPropagation";
import { useDiscoveryModeContractAction } from "./utils/useDiscoveryModeContractAction";
import {
  type Direction,
  useDiscoveryModeExpandAction,
} from "./utils/useDiscoveryModeExpandAction";

export function ConnectedEdgesToggleButton({
  clickedNodeId,
  connectedEdges,
  direction,
  fullyExpanded,
  className,
  onClick,
  style,
}: {
  readonly clickedNodeId: string;
  readonly connectedEdges: LineageState["edges"];
  readonly direction: Direction;
  readonly fullyExpanded: boolean;
  readonly onClick: (
    loggingContext: Parameters<typeof logEventGraphExpandButtonClicked>[2],
  ) => void;
  readonly className?: string;
  readonly style?: CSSProperties | undefined;
}): JSX.Element {
  const expand = useDiscoveryModeExpandAction(connectedEdges, direction, clickedNodeId);
  const contract = useDiscoveryModeContractAction(connectedEdges, clickedNodeId);

  const handleChange = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        contract();
      } else {
        expand();
      }

      onClick({
        isExpand: !isChecked,
        direction,
        numAffectedEntities: connectedEdges.length,
      });
    },
    [connectedEdges.length, contract, direction, expand, onClick],
  );

  return (
    <Switch
      checked={!fullyExpanded}
      className={`absolute z-50 flex h-4 w-4 items-center justify-center rounded-full border bg-white p-3 font-mono text-black/50 shadow-sm ${
        className ?? ""
      }`}
      style={style}
      onChange={handleChange}
      onClick={stopPropagation}
    >
      {({ checked }) => <>{checked ? "+" : "-"}</>}
    </Switch>
  );
}
