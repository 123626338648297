import { useEffect, useId, useState } from "react";
import type { PolicyNameCheckResponseModel } from "../../../api";
import { usePolicyNameCheck } from "../../../api";
import { CircledExclamationMarkIcon } from "../../icons/CircledExclamationMarkIcon";
import { useIncrementalSearch } from "../../useIncrementalSearch";

export function NewPolicyNameInput({
  onPolicyNameChange,
}: {
  readonly onPolicyNameChange: (policyName: string | undefined) => void;
}): JSX.Element {
  const [policyName, setPolicyName] = useState("");
  const { isLoading, isError, data } = usePolicyNameCheck(policyName);
  const [errorMessage, setErrorMessage] = useState<string>();
  const incrementalSearch = useIncrementalSearch(setPolicyName);
  const policyNameInputId = useId();

  useEffect(() => {
    setErrorMessage(getErrorMessage(policyName, isError, data));

    if (isError) {
      onPolicyNameChange(undefined);
      return;
    }

    if (data !== undefined) {
      onPolicyNameChange(data.isValid && data.isAvailable ? policyName : undefined);
    }
  }, [isError, data, policyName, onPolicyNameChange]);

  return (
    <div className="flex items-center gap-x-3">
      <label
        className="text-sm font-medium leading-5 text-[rgb(115,129,131)]"
        htmlFor={policyNameInputId}
      >
        Policy name
      </label>
      <div
        className={`flex items-center justify-stretch rounded-md border bg-white py-2 pl-3 pr-2 shadow md:min-w-[300px] ${
          isLoading ? "animate-pulse" : ""
        } ${
          errorMessage === undefined
            ? "border-[rgba(82,97,100,0.2)]"
            : "border-[rgba(246,111,81,0.3)]"
        }`}
      >
        <input
          ref={incrementalSearch}
          required
          className="flex-1 text-sm leading-5 outline-none"
          id={policyNameInputId}
          placeholder="Set the new policy name"
          title={
            "Policy name must be between 1 and 128 characters long, and can only contain alphanumeric characters, underscores ('_'), dashes ('-') and spaces, and cannot start with space or dash."
          }
          type="search"
        />
        {errorMessage === undefined ? null : (
          <CircledExclamationMarkIcon className="h-5 w-5 p-0.5 text-[rgb(246,111,81)]" />
        )}
      </div>
      <p className="text-sm leading-5 text-[rgb(246,111,81)] empty:hidden">
        {errorMessage ?? null}
      </p>
    </div>
  );
}

function getErrorMessage(
  policyName: string | undefined,
  queryError: boolean,
  nameCheckResponse: PolicyNameCheckResponseModel | undefined,
): string | undefined {
  if (policyName === undefined || policyName === "") {
    return undefined;
  }

  if (queryError) {
    return "Something went wrong while checking the policy name";
  }

  if (nameCheckResponse !== undefined) {
    if (!nameCheckResponse.isValid) {
      return "This policy name is invalid";
    }
    if (!nameCheckResponse.isAvailable) {
      return "This policy name is already in use";
    }
  }

  return undefined;
}
