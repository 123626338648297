import { Combobox } from "@headlessui/react";
import { type ChangeEvent, useCallback, useMemo, useState } from "react";
import { pipe, sortBy } from "remeda";
import type { SendSlackMessageAction } from "../../../api";
import { useSlackRecipients } from "../../../api";
import { stringsSearch } from "../../../utils/search/entitiesSearch";
import { SearchIcon } from "../../icons/SearchIcon";
import { usePolicyActionRecipientsSelection } from "../common/utils";
import { SelectedRecipientsSection } from "./SelectedRecipientsSection";

export function SlackAlertActionEditor({
  onActionChange,
  onError,
}: {
  readonly onActionChange: (action: SendSlackMessageAction | undefined) => void;
  readonly onError: (err: string) => void;
}): JSX.Element {
  const { data: recipients, isLoading, isError } = useSlackRecipients();
  if (isError) {
    onError("Server error - failed to load Slack recipients.");
  }

  const handleSelectedRecipientsChange = useCallback(
    (selectedRecipients: readonly string[]) => {
      onActionChange(
        selectedRecipients.length === 0
          ? undefined
          : {
              action_type: "send_slack_message",
              channels: selectedRecipients,
            },
      );
    },
    [onActionChange],
  );

  const {
    value: selectedRecipients,
    unselect: handleRecipientUnselected,
    select: handleRecipientSelected,
  } = usePolicyActionRecipientsSelection(handleSelectedRecipientsChange);

  return (
    <div className="flex flex-col gap-y-4">
      <span className="text-base font-semibold leading-4 text-[rgb(74,89,92)]">
        Recipients
      </span>
      {selectedRecipients.length === 0 ? null : (
        <SelectedRecipientsSection
          recipients={selectedRecipients}
          onDelete={handleRecipientUnselected}
        />
      )}
      <SlackRecipientSearch
        isLoading={isLoading}
        recipients={recipients ?? []}
        onRecipientSelected={handleRecipientSelected}
      />
    </div>
  );
}

function SlackRecipientSearch({
  recipients,
  onRecipientSelected,
  isLoading,
}: {
  readonly recipients: readonly string[];
  readonly onRecipientSelected: (result: string) => void;
  readonly isLoading: boolean;
}): JSX.Element {
  const [query, setQuery] = useState("");

  const results = useMemo(
    () =>
      pipe(
        recipients,
        stringsSearch(query),
        sortBy((result) => result),
      ),
    [recipients, query],
  );

  const handleDisplayValue = useCallback(() => "", []);
  const handleValueChange = useCallback(
    (changeEvent: ChangeEvent<HTMLInputElement>) => {
      setQuery(changeEvent.target.value);
    },
    [],
  );

  return (
    <Combobox disabled={isLoading} onChange={onRecipientSelected}>
      <div
        className={`relative mt-1 rounded-md border bg-white shadow-[0px_0px_1px_rgba(44,57,63,0.8),0px_2px_6px_rgba(44,57,63,0.08)] outline-none focus-within:ring-1 focus-within:ring-[rgb(101,202,132)] ${
          isLoading ? " animate-pulse" : ""
        }`}
      >
        <Combobox.Button className="flex w-full flex-row items-center justify-between pr-4 ">
          <Combobox.Input
            className="rounded-md border-none bg-none px-5 py-3 text-sm font-semibold text-[rgb(82,100,96)] outline-none placeholder:font-normal placeholder:text-[rgb(165,173,176)]"
            displayValue={handleDisplayValue}
            placeholder={isLoading ? "Loading..." : "Search channels"}
            onChange={handleValueChange}
          />
          <SearchIcon className="w-[14px] text-[rgb(185,194,195)]" />
        </Combobox.Button>
        <Combobox.Options className="absolute mt-1 max-h-[400px] w-full overflow-y-auto rounded-md bg-white shadow">
          {results.map((result) => (
            <Combobox.Option
              key={result}
              className="w-full border-b border-b-[rgba(165,173,176,0.25)] p-3 text-left text-sm font-semibold text-[rgb(74,89,92)]"
              value={result}
            >
              {result}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
