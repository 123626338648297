import { Navigate, Outlet } from "react-router-dom";
import { useIsAdmin } from "../components/login/authProvider";
import { Nav } from "../components/nav/Nav";

export function AdminLoggedInLayout(): JSX.Element {
  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex h-screen w-screen items-stretch">
      <Nav isAdmin />
      <main className="flex-1 overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}
