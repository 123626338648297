import type { LineageRootEntity } from "../../api";
import { withWordBreakOpportunities } from "../render_utils";
import { NodeDescriptor } from "./NodeDescriptor";

export function LineageRootDescriptor({
  entity,
  className,
}: {
  readonly entity: LineageRootEntity;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <div className={`flex flex-col gap-y-2 overflow-hidden ${className ?? ""}`}>
      <h4 className="break-words text-base font-semibold uppercase text-[rgb(82,100,96)]">
        {withWordBreakOpportunities(entity.entityName)}
      </h4>
      <NodeDescriptor entity={entity} />
    </div>
  );
}
