/**
 * This file contains types that represent responses from the backend with lineage data. The backend and frontend for
 * lineage is undergoing remodeling. This file should only contain schemas that match the remodeled design.
 *
 * Please note that the models in this file might miss some data, you may freely add more schemas to this file as needed
 * Just make sure that:
 * 1 - Added schemas match remodeling design.
 * 2 - The schemas should represent responses that will be returned from the backend in the future. You may add "fake"
 * api calls to the backend that return the specific schemas you need, that in the future will be turned to real api
 * calls as the processing of the lineage will be moved to be done in the backend.
 *
 * For your convenience, there is a file for converting between the deprecated models and the models in this file under
 * lineage/conversions.ts.
 */

const ALL_DEPENDENCY_TYPE = [
  "case",
  "group",
  "having",
  "join",
  "order",
  "qualify",
  "select",
  "unknown",
  "where",
  "window",
] as const;
export type DependencyType = (typeof ALL_DEPENDENCY_TYPE)[number];

export type RelationType =
  | DependencyType
  // We coalesce missing data to this value, it doesn't come from the server!
  | "<<MISSING>>"
  // The dummy type we use for (proper) relations.
  | "relation";

const ALL_CHANGE_STATUSES = ["new", "removed", "changed", "renamed"] as const;
export type ChangeStatus = (typeof ALL_CHANGE_STATUSES)[number];

const ALL_COLUMN_TYPES = ["date", "enum", "integer", "varchar", "struct"] as const;
export type ColumnType = (typeof ALL_COLUMN_TYPES)[number];

const ALL_ENTITY_TYPES = ["table", "column", "file", "dashboard"] as const;
export type LineageEntityType = (typeof ALL_ENTITY_TYPES)[number];

export const EMPTY_STATE: LineageState = {
  entities: [],
  edges: [],
};

export type LineageEntity = LineageChildEntity | LineageRootEntity;
export type LineageRootEntity = DashboardEntity | DbTableEntity | FileEntity;
export type LineageChildEntity = ColumnEntity;

export type MultiLineage = {
  readonly snapshot: LineageState;
  readonly diff: LineageState;
};

export type LineageState = {
  readonly entities: readonly LineageRootEntity[];
  readonly edges: readonly EdgeState[];
};

export type EdgeState = {
  readonly id: string;
  readonly source: EdgeEndpoint;
  readonly dest: EdgeEndpoint;
  readonly relationType: RelationType;
  readonly changeStatus: ChangeStatus | undefined;
};

export type EdgeEndpoint = {
  readonly id: string;
  readonly entityName: string;
  readonly rootId: string;
};

export type BaseEntity = {
  readonly type: LineageEntityType;
  readonly id: string;
  readonly entityName: string;
  readonly childEntities: readonly LineageChildEntity[];
  readonly changeStatus?: ChangeStatus | undefined;
};

export type DbTableEntity = BaseEntity & {
  readonly type: "table";
  readonly dbName: string;
  readonly dbSchema: string;
  readonly platform: "bigquery";
};

export type ColumnEntity = BaseEntity & {
  readonly type: "column";
  readonly colType?: ColumnType | undefined;
};

export type FileEntity = BaseEntity & {
  readonly type: "file";
  readonly platform: "hdfs" | "s3";
  readonly uri: string;
  readonly rootFolder?: string | undefined;
  readonly dirName?: string | undefined;
  readonly fileName?: string | undefined;
  readonly fileType?: string | undefined;
};

export type DashboardEntity = BaseEntity & {
  readonly type: "dashboard";
  readonly platform: "tableau";
  readonly projectName: string;
};
