import { offset } from "@floating-ui/react-dom";
import { HamburgerIcon } from "../icons/HamburgerIcon";
import { useTooltip } from "../useTooltip";
import { useNavStateContext } from "./Nav";

export function NavigationHamburgerButton({
  onClick,
}: {
  readonly onClick: () => void;
}): JSX.Element {
  const { isOpen } = useNavStateContext();

  const { setReference, tooltip } = useTooltip("Show menu", {
    placement: "right",
    middleware: [
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      offset(20),
    ],
  });

  return (
    <button
      ref={setReference}
      className={`group flex h-10 w-10 items-center justify-center rounded hover:bg-white/10 ${
        isOpen ? "mr-2" : "invisible absolute ml-3 group-hover/nav:visible"
      }`}
      type="button"
      onClick={onClick}
    >
      <HamburgerIcon className="w-[18px] text-white opacity-30 group-hover:opacity-100" />
      {!isOpen && tooltip}
    </button>
  );
}
