import { Disclosure } from "@headlessui/react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DEFAULT_ERROR_TITLE = "Got an error from the server";

export function PullRequestDiffServerError({
  errorDetails = "",
}: {
  readonly errorDetails?: string;
}): JSX.Element {
  const errorLines = errorDetails.split("\n");
  const [firstLine, secondLine, ...details] = errorLines;

  const title =
    firstLine === undefined || secondLine === undefined
      ? DEFAULT_ERROR_TITLE
      : firstLine;
  const subtitle = secondLine ?? firstLine;

  return (
    <div className="z-30 flex h-full w-full flex-col items-center justify-center gap-y-16 bg-[rgb(228,233,231)]">
      <img alt="" className="min-h-[148px] min-w-[218px]" src="/img/graph-error.svg" />
      <div className="flex w-full flex-col items-center justify-center gap-y-4">
        <div className="flex max-w-[312px] flex-col gap-y-6">
          <h1 className="text-center text-xl font-semibold text-[rgb(74,89,92)]">
            {title}
          </h1>
          {subtitle === undefined ? null : (
            <h3 className="text-center text-base font-normal text-[rgb(115,129,131)]">
              {subtitle}
            </h3>
          )}
        </div>
        {details.length > 0 ? <ExpandableErrorMessage details={details} /> : null}
      </div>
    </div>
  );
}

function ExpandableErrorMessage({
  details,
}: {
  readonly details: readonly string[];
}): JSX.Element {
  return (
    <Disclosure>
      <Disclosure.Button className="text-black/50 transition-opacity duration-75 hover:opacity-70">
        {({ open: isOpen }) =>
          isOpen ? (
            <ExpandLessIcon fontSize="medium" />
          ) : (
            <ExpandMoreIcon fontSize="medium" />
          )
        }
      </Disclosure.Button>
      <Disclosure.Panel>
        <ol className="flex max-w-[624px] flex-col text-center text-base font-normal text-gray-600">
          {details.map(
            (line: string): JSX.Element => (
              <li key={line}>{line}</li>
            ),
          )}
        </ol>
      </Disclosure.Panel>
    </Disclosure>
  );
}
