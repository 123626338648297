import type { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Avatar, type FullName } from "../Avatar";
import { Cell, DashboardList, HeaderCell } from "./DashboardList";

export function DashboardLists(): JSX.Element {
  return (
    <section className="flex flex-wrap gap-12">
      <RecentChanges />
      <WithIssues />
      <IssuesFound />
    </section>
  );
}

function RecentChanges(): JSX.Element {
  return (
    <DashboardList className="flex-1" title="Recent Big Changes">
      <PRListHeader />
      <DashboardPRListItem authorName={{ first: "Barak", last: "Fargoun" }} prId={1}>
        Update clients events
      </DashboardPRListItem>
      <DashboardPRListItem authorName={{ first: "Omri", last: "Ildis" }} prId={2}>
        Rename BI schema
      </DashboardPRListItem>
    </DashboardList>
  );
}

function WithIssues(): JSX.Element {
  return (
    <DashboardList className="flex-1" title="Changes with Issues">
      <PRListHeader />
      <DashboardPRListItem authorName={{ first: "Ofir", last: "Or" }} prId={1337}>
        Change type of: &quot;user_id&quot; to string from int
      </DashboardPRListItem>
      <DashboardPRListItem authorName={{ first: "Alon", last: "Nafta" }} prId={482}>
        Add a new table for calculating ARR
      </DashboardPRListItem>
    </DashboardList>
  );
}

function IssuesFound(): JSX.Element {
  return (
    <DashboardList title="Issues Found">
      <HeaderCell>Issue</HeaderCell>
      <HeaderCell className="col-span-2">Descriptions</HeaderCell>
      <Cell className="text-black/70">Missing Column</Cell>
      <Cell className="col-span-2 text-black/70">
        Column &apos;revenue&apos; is used in calc_all_revenus.sql, but is never defined
      </Cell>
    </DashboardList>
  );
}

function DashboardPRListItem({
  authorName,
  prId,
  children,
}: PropsWithChildren<{
  readonly authorName: FullName;
  readonly prId: number;
}>): JSX.Element {
  return (
    <>
      <Cell className="justify-center">
        <Avatar fullName={authorName} />
      </Cell>
      <Cell className="justify-center">
        <Link
          className="text-blue-500 hover:underline"
          to="/pr-analysis/foundational-io%2Fex1-jaffle_shop/pull/1"
        >
          #{prId}
        </Link>
      </Cell>
      <Cell className="text-black/70">{children}</Cell>
    </>
  );
}

function PRListHeader(): JSX.Element {
  return (
    <>
      <HeaderCell className="text-center">Author</HeaderCell>
      <HeaderCell className="text-center">PR#</HeaderCell>
      <HeaderCell>Description</HeaderCell>
    </>
  );
}
