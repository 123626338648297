export function InfoIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 12C5.28333 12 5.521 11.904 5.713 11.712C5.90433 11.5207 6 11.2833 6 11C6 10.7167 5.90433 10.479 5.713 10.287C5.521 10.0957 5.28333 10 5 10C4.71667 10 4.479 10.0957 4.287 10.287C4.09567 10.479 4 10.7167 4 11C4 11.2833 4.09567 11.5207 4.287 11.712C4.479 11.904 4.71667 12 5 12ZM5 9C5.28333 9 5.521 8.904 5.713 8.712C5.90433 8.52067 6 8.28333 6 8V5C6 4.71667 5.90433 4.479 5.713 4.287C5.521 4.09567 5.28333 4 5 4C4.71667 4 4.479 4.09567 4.287 4.287C4.09567 4.479 4 4.71667 4 5V8C4 8.28333 4.09567 8.52067 4.287 8.712C4.479 8.904 4.71667 9 5 9ZM9 11H15C15.2833 11 15.5207 10.904 15.712 10.712C15.904 10.5207 16 10.2833 16 10C16 9.71667 15.904 9.479 15.712 9.287C15.5207 9.09567 15.2833 9 15 9H9C8.71667 9 8.47933 9.09567 8.288 9.287C8.096 9.479 8 9.71667 8 10C8 10.2833 8.096 10.5207 8.288 10.712C8.47933 10.904 8.71667 11 9 11ZM9 7H15C15.2833 7 15.5207 6.904 15.712 6.712C15.904 6.52067 16 6.28333 16 6C16 5.71667 15.904 5.479 15.712 5.287C15.5207 5.09567 15.2833 5 15 5H9C8.71667 5 8.47933 5.09567 8.288 5.287C8.096 5.479 8 5.71667 8 6C8 6.28333 8.096 6.52067 8.288 6.712C8.47933 6.904 8.71667 7 9 7ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.8043 0.979333 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2ZM2 14H18V2H2V14Z" />
    </svg>
  );
}
