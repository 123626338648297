export function FoundationalIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg className={className} viewBox="0 0 19 26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.69697 25.1772V20.4699C9.69697 19.4524 10.2127 18.5042 11.0669 17.9514L17.526 13.7709C18.1767 13.3498 18.1217 12.3806 17.4275 12.0358L10.8333 8.76014C9.88465 8.28892 8.75783 8.35286 7.86861 8.92839L1.36996 13.1345C0.51575 13.6873 0 14.6355 0 15.653V19.9434C0 20.7028 0.430109 21.3968 1.11024 21.7346L8.96008 25.634C9.2991 25.8024 9.69697 25.5557 9.69697 25.1772Z"
        fill="url(#paint0_linear_0_1)"
      />
      <g filter="url(#filter0_b_0_1)">
        <path
          d="M9.69697 16.3768V12.6062C9.69697 11.5953 10.2061 10.6524 11.0513 10.0978L17.5517 5.83306C18.1953 5.41076 18.1404 4.45002 17.4529 4.10379L10.8499 0.778906C9.89267 0.296911 8.75105 0.362147 7.85497 0.950043L1.35432 5.215C0.509108 5.76954 0 6.71245 0 7.72334V11.885C0 12.6405 0.425726 13.3315 1.10051 13.6713L8.24723 17.27C8.91229 17.6049 9.69697 17.1215 9.69697 16.3768Z"
          fill="url(#paint1_linear_0_1)"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="20.9194"
          id="filter0_b_0_1"
          width="22.0032"
          x="-2"
          y="-1.5415"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_0_1"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_0_1"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_0_1"
          x1="15.3636"
          x2="4.58444"
          y1="11.15"
          y2="24.3064"
        >
          <stop stopColor="#41B980" />
          <stop offset="1" stopColor="#00919A" stopOpacity="0.63" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_0_1"
          x1="15.3636"
          x2="4.97187"
          y1="3.16667"
          y2="16.786"
        >
          <stop stopColor="#ACF28C" />
          <stop offset="1" stopColor="#41B980" stopOpacity="0.6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
