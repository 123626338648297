import { Outlet } from "react-router-dom";
import { OnlyNonLoggedIn } from "../components/login/OnlyNonLoggedIn";

export function LoggedOutLayout(): JSX.Element {
  return (
    <OnlyNonLoggedIn>
      <Outlet />
    </OnlyNonLoggedIn>
  );
}
