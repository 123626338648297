import { RadioGroup } from "@headlessui/react";
import type { PropsWithChildren } from "react";

/**
 * Creates a horizontal bar of options where only a single option could be checked at
 * any given moment. The list of options provided as `allValues` will define the type
 * for the other props, enabling coupling between all three props and providing better
 * typing.
 */
export function SimpleRadioGroup<T extends string>({
  allValues,
  value,
  onChange,
}: {
  readonly allValues: readonly T[];
  readonly value: T;
  readonly onChange: (newValue: T) => void;
}): JSX.Element {
  return (
    <RadioGroup
      className="flex cursor-pointer divide-x overflow-hidden rounded-md text-xs text-neutral-400 shadow-[0_2px_6px_0_rgba(44,57,63,0.08),0_0_0_1px_rgba(44,57,63,0.13)]"
      value={value}
      onChange={onChange}
    >
      {allValues.map((optionValue) => (
        <SimpleRadioGroupOption key={optionValue} value={optionValue}>
          {optionValue}
        </SimpleRadioGroupOption>
      ))}
    </RadioGroup>
  );
}

function SimpleRadioGroupOption<T>({
  value,
  children,
}: PropsWithChildren<{ readonly value: T }>): JSX.Element {
  return (
    <RadioGroup.Option
      className="flex-1 py-2 text-center capitalize transition-all duration-75 hover:bg-neutral-50 ui-checked:bg-neutral-500 ui-checked:font-semibold ui-checked:text-white"
      value={value}
    >
      {children}
    </RadioGroup.Option>
  );
}
