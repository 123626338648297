import { CollapsedToggle } from "./CollapsedToggle";
import { DownloadButton } from "./DownloadButton";
import { GraphSearch } from "./GraphSearch";
import { RelayoutButton } from "./RelayoutButton";
import { ResetButton } from "./ResetButton";

export function TopToolbar(): JSX.Element | null {
  return (
    <div className="absolute z-40 flex w-full justify-between p-4">
      <div />
      <div className="flex gap-x-3">
        <GraphSearch />
        <ResetButton />
        <RelayoutButton />
        <CollapsedToggle />
        <DownloadButton />
      </div>
    </div>
  );
}
