import type { PropsWithChildren, ReactElement } from "react";

export function SectionHeadline({
  icon,
  children,
}: PropsWithChildren<{
  readonly icon: ReactElement;
}>): JSX.Element {
  return (
    <header className="flex items-center gap-x-2.5">
      {icon}
      <h1 className="text-base font-medium leading-5 text-[rgb(115,129,131)]">
        {children}
      </h1>
    </header>
  );
}
