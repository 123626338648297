import { type FormEvent, useCallback, useRef } from "react";
import invariant from "tiny-invariant";
import type { SendEmailAction } from "../../../api";
import { PlusSignIcon } from "../../icons/PlusSignIcon";
import { usePolicyActionRecipientsSelection } from "../common/utils";
import { SelectedRecipientsSection } from "./SelectedRecipientsSection";

export function SendEmailActionEditor({
  onActionChange,
}: {
  readonly onActionChange: (action: SendEmailAction | undefined) => void;
}): JSX.Element {
  const handleSelectedRecipientsChange = useCallback(
    (recipients: readonly string[]) => {
      onActionChange(
        recipients.length === 0
          ? undefined
          : {
              action_type: "send_email",
              recipients,
            },
      );
    },
    [onActionChange],
  );

  const {
    value: selectedRecipients,
    unselect: handleRecipientUnselected,
    select: handleRecipientSelected,
  } = usePolicyActionRecipientsSelection(handleSelectedRecipientsChange);

  return (
    <div className="flex flex-col gap-y-4">
      <span className="text-base font-semibold leading-4 text-[rgb(74,89,92)]">
        Recipients
      </span>
      {selectedRecipients.length === 0 ? null : (
        <SelectedRecipientsSection
          recipients={selectedRecipients}
          onDelete={handleRecipientUnselected}
        />
      )}
      <EmailRecipientInput onEmailSet={handleRecipientSelected} />
    </div>
  );
}

function EmailRecipientInput({
  onEmailSet,
}: {
  readonly onEmailSet: (email: string) => void;
}): JSX.Element {
  const formRef = useRef<HTMLFormElement | null>(null);

  const handleSubmit = useCallback(
    (submitEvent: FormEvent<HTMLFormElement>) => {
      submitEvent.preventDefault();
      if (formRef.current === null) {
        return;
      }

      const formData = new FormData(submitEvent.currentTarget);
      const email = formData.get("email");
      invariant(
        typeof email === "string" && email.length > 0,
        "Email input should not be null, input validation is probably broken",
      );

      onEmailSet(email);
      formRef.current.reset();
    },
    [onEmailSet, formRef],
  );

  return (
    <form ref={formRef} className="flex flex-row gap-x-2" onSubmit={handleSubmit}>
      <input
        required
        className="peer w-full rounded-md border px-5 py-3 text-sm font-semibold text-[rgb(82,100,96)] shadow-[0px_0px_1px_rgba(44,57,63,0.8),0px_2px_6px_rgba(44,57,63,0.08)] outline-none placeholder:font-normal placeholder:text-[rgb(165,173,176)] focus:border-[rgb(101,202,132)]"
        name="email"
        placeholder="Add an email recipient"
        type="email"
      />
      <button
        className="flex flex-row items-center rounded-md bg-[rgba(143,109,241,0.2)] py-2 pl-2 pr-3 text-sm font-semibold leading-4 text-[rgb(116,85,206)] shadow-sm hover:bg-[rgba(143,109,241,0.3)] focus:outline-[rgb(72,84,76)] active:bg-[rgba(143,109,241,0.35)] peer-invalid:bg-[rgb(245,247,247)] peer-invalid:text-[rgb(185,194,195)] peer-invalid:shadow peer-invalid:outline-none"
        type="submit"
      >
        <PlusSignIcon className="h-5 w-5" />
        Add
      </button>
    </form>
  );
}
