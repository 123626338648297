import { useParams } from "react-router-dom";
import { createPipe, filter, forEach, isDefined, map, pipe, prop } from "remeda";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";
import type { EdgeState, LineageRootEntity, MultiLineage } from "./schemas";
import { usePullRequest } from "./usePullRequest";

export type Dependency = {
  /**
   * The dependent entity.
   */
  readonly entity: LineageRootEntity;

  /**
   * Distance (shortest possible) from the pivot entity.
   */
  readonly distance: number;

  /**
   * Direction of the dependency. Upstream dependencies are those that come before it
   * and affect it, and Downstream ones are those that come after it and are affected by
   * it.
   */
  readonly type: "downstream" | "upstream";
};

const rootEntityDependenciesQueryKey = (
  { id }: LineageRootEntity,
  repoName: string | undefined,
  prId: string | undefined,
) => ["rootEntityCodePointers", repoName, prId, id] as const;

/**
 * This is a polyfill! It just looks like a real query, it actually uses usePullRequest
 * and does the computation on the client.
 */
export function useRootEntityDependencies(rootEntity: LineageRootEntity) {
  const { repoName, prId } = useParams();
  const { data: prData } = usePullRequest(repoName, prId);

  return useQueryRetriesServerErrors({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- this is not a real query, so it doesn't make sense to try and make it look like one. This should be fine for our use case.
    queryKey: rootEntityDependenciesQueryKey(rootEntity, repoName, prId),
    queryFn: () => getRootEntityDependencies(prData, rootEntity),
    staleTime: STALE_TIME_MS,
    enabled: prData !== undefined,
  });
}

function getRootEntityDependencies(
  lineage: MultiLineage | undefined,
  { id: pivotId }: LineageRootEntity,
) {
  if (lineage === undefined) {
    return [];
  }

  const {
    snapshot: { edges, entities },
  } = lineage;

  // Each function runs over all edges. We might get better performance by combining
  // them, but at the cost of more complex code.
  const upstream = getDependenciesInSubtree(edges, pivotId, "upstream");
  const downstream = getDependenciesInSubtree(edges, pivotId, "downstream");

  return pipe(
    entities,
    map((entity) => {
      const downstreamDepth = downstream.get(entity.id);
      if (downstreamDepth !== undefined) {
        return {
          entity,
          distance: downstreamDepth,
          type: "downstream" as const,
        };
      }

      const upstreamDepth = upstream.get(entity.id);
      if (upstreamDepth !== undefined) {
        return {
          entity,
          distance: upstreamDepth,
          type: "upstream" as const,
        };
      }

      // eslint-disable-next-line unicorn/no-useless-undefined -- We need to return it here...
      return undefined;
    }),
    filter(isDefined.strict),
  );
}

/**
 * Find all root entities that have a path that ends at the the pivot entity.
 * @param edges All edges in the graph.
 * @param pivotId The entity we want to find dependencies for.
 * @param treeDirection Whether to find upstream or downstream entities.
 * @returns a mapping from entity to the shortest distance to that entity.
 */
function getDependenciesInSubtree(
  edges: readonly EdgeState[],
  pivotId: LineageRootEntity["id"],
  treeDirection: "downstream" | "upstream",
): ReadonlyMap<LineageRootEntity["id"], number> {
  const subtree = new Map<LineageRootEntity["id"], number>();

  // Although our pivot itself isn't part of the result, we add it to the set to "start"
  // the algorithm.
  subtree.set(pivotId, 0 /* distance */);

  // This is the pop-corn technique, once our set stops growing we know we're done.
  // This is bound to stop because we maintain a set and the number of entities is
  // finite.
  let previousSize = 0;
  for (let depth = 1; subtree.size > previousSize; depth += 1) {
    previousSize = subtree.size;

    pipe(
      // Because we don't have any index on the edges we need to re-scan all of them
      // every time we have new entities in our result. There's no way to meaningfully
      // improve this
      edges,
      // We scan all edges for any edge that have their head at an entity that is
      // already part of the subtree.
      filter(
        createPipe(
          prop(
            // To find downstream dependencies we need to "flip" the edge
            treeDirection === "upstream" ? "dest" : "source",
          ),
          prop("rootId"),
          (headId) => subtree.has(headId),
        ),
      ),
      // And add it's tail to the subtree. If we already saw this entity because of a
      // different edge there's nothing left to do, but if the entity is new to us we
      // will need another iteration to scan for any edges that end at that entity.
      forEach(
        createPipe(
          prop(
            // IMPORTANT: This should be the opposite edge endpoint from what we used in
            // the filter above!!!
            treeDirection === "upstream" ? "source" : "dest",
          ),
          prop("rootId"),
          (tailId) => subtree.set(tailId, depth),
        ),
      ),
    );
  }

  // Our pivot isn't downstream from itself.
  subtree.delete(pivotId);

  return subtree;
}
