import { Box, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { useCallback, useState, type ChangeEvent } from "react";
import { exceptionToErrorMessage } from "../../../utils/error";
import { GreenButton } from "../../Buttons";
import { CallToActions } from "../common/CallToActions";
import { useApiRequest } from "../useApiRequest";
import { BASE_URL_PREFIX } from "./consts";

type PowerBiNewSource = {
  /* eslint-disable @typescript-eslint/naming-convention -- defined by the API */
  readonly source_identifier: string;
  readonly credentials: {
    readonly tenant_id: string;
    readonly client_id: string;
    readonly client_secret: string;
  };
  /* eslint-enable @typescript-eslint/naming-convention */
};

// eslint-disable-next-line max-lines-per-function, max-statements
export function NewSourceScreen({
  onDone,
}: {
  readonly onDone: (isCanceled: boolean) => void;
}): JSX.Element {
  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState<string>();
  const [isTenantIdEdited, setIsTenantIdEdited] = useState(false);
  const [isClientIdEdited, setIsClientIdEdited] = useState(false);
  const [isClientSecretEdited, setIsClientSecretEdited] = useState(false);

  const [tenantId, setTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const api = useApiRequest();

  const handleSave = useCallback(() => {
    setError(undefined);
    setIsInProgress(true);

    const newSource: PowerBiNewSource = {
      source_identifier: `${tenantId}`,
      credentials: {
        tenant_id: tenantId,
        client_id: clientId,
        client_secret: clientSecret,
      },
    };

    const toServer = async (): Promise<void> => {
      try {
        await api(`${BASE_URL_PREFIX}/keys/`, [newSource], "PUT");
        onDone(false);
      } catch (serverError) {
        const message = await exceptionToErrorMessage(serverError);
        setError(message);
      } finally {
        setIsInProgress(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    toServer();
  }, [tenantId, clientId, clientSecret, api, onDone]);

  const handleTenantIdBlur = useCallback(() => {
    setIsTenantIdEdited(true);
  }, []);

  const handleTenantIdChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setTenantId(currentTarget.value);
    },
    [],
  );

  const handleClientIdBlur = useCallback(() => {
    setIsClientIdEdited(true);
  }, []);
  const handleClientIdChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setClientId(currentTarget.value);
    },
    [],
  );

  const handleClientSecretBlur = useCallback(() => {
    setIsClientSecretEdited(true);
  }, []);
  const handleClientSecretChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      setClientSecret(currentTarget.value);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    onDone(true);
  }, [onDone]);

  return (
    <Box
      sx={{
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
        mt: 2,
      }}
    >
      <form>
        <TextField
          fullWidth
          required
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- what's the problem here :(
            isTenantIdEdited && tenantId === ""
          }
          label="Tenant ID"
          placeholder=""
          type="url"
          value={tenantId}
          variant="standard"
          onBlur={handleTenantIdBlur}
          onChange={handleTenantIdChange}
        />
        <TextField
          fullWidth
          required
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- what's the problem here :(
            isClientIdEdited && clientId === ""
          }
          label="Client ID"
          placeholder=""
          type="text"
          value={clientId}
          variant="standard"
          onBlur={handleClientIdBlur}
          onChange={handleClientIdChange}
        />
        <TextField
          fullWidth
          required
          error={
            // eslint-disable-next-line react/jsx-no-leaked-render -- what's the problem here :(
            isClientSecretEdited && clientSecret === ""
          }
          label="Client Secret"
          placeholder=""
          type="text"
          value={clientSecret}
          variant="standard"
          onBlur={handleClientSecretBlur}
          onChange={handleClientSecretChange}
        />
        <Typography className="my-4" component="div" mt={1} variant="h6">
          Instructions
        </Typography>
        Download this instruction guide for the full details on how to set up Power BI
        <a download="" href="/Power BI Configuration Instructions.pdf">
          <GreenButton>Download Power BI Configuration Instructions</GreenButton>
        </a>
        <br />
        <br />
        <CallToActions
          disabled={tenantId === "" || clientId === "" || clientSecret === ""}
          inProgress={isInProgress}
          onCancel={handleCancel}
          onSave={handleSave}
        />
        {error !== undefined && <Alert severity="error">{error}</Alert>}
      </form>
    </Box>
  );
}
