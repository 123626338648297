import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import type {
  SettingsOverviewProps,
  SourceInstance,
} from "../../../utils/settings/types";
import { BaseConfigCard } from "../BaseConfigCard";
import { BaseConfigDrawer } from "../BaseConfigDrawer";
import { useSubSettings } from "../settingsProvider";
import { useApiRequest } from "../useApiRequest";
import { MainConfigScreen } from "./MainConfigScreen";
import { NewSourceScreen } from "./NewSourceScreen";
import { BASE_URL_PREFIX, POWERBI_DISPLAY_NAME, POWERBI_NAME } from "./consts";

export function PowerBiConfigOverview({
  onConfigure,
}: SettingsOverviewProps): JSX.Element {
  const [rawPowerBiAccounts] = useSubSettings(POWERBI_NAME);
  const accounts = rawPowerBiAccounts ?? [];

  return (
    <BaseConfigCard
      avatarPath="/img/connectors/powerbi.svg"
      configured={accounts.length > 0}
      loading={rawPowerBiAccounts === undefined}
      sourceName={POWERBI_DISPLAY_NAME}
      onConfigure={onConfigure}
    >
      <>
        <Typography gutterBottom component="div" variant="h6">
          Configured Accounts
        </Typography>
        <Typography color="text.secondary" variant="body2">
          <>
            {accounts.length > 1
              ? `... (${accounts.length - 1} more configured)`
              : "1 account configured"}
          </>
        </Typography>
      </>
    </BaseConfigCard>
  );
}

export function PowerBiConfigSettings(): JSX.Element {
  const [powerbiAccounts, setPowerBiAccounts] = useSubSettings(POWERBI_NAME);
  const [isAddAccount, setIsAddAccount] = useState(false);

  const api = useApiRequest();

  const updateAccounts = useCallback(async () => {
    const accounts = (await api(`${BASE_URL_PREFIX}/keys/`)) as [SourceInstance];
    setPowerBiAccounts(accounts);
  }, [api, setPowerBiAccounts]);

  const handleDelete = useCallback(
    (account: SourceInstance) => {
      const onServer = async () => {
        await api(`${BASE_URL_PREFIX}/keys/`, account, "DELETE");
        await updateAccounts();
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onServer();
    },
    [api, updateAccounts],
  );

  const doneHandler = useCallback(
    (canceled: boolean) => {
      setIsAddAccount(false);

      if (canceled) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateAccounts();
    },
    [updateAccounts],
  );

  const addSourceHandler = useCallback(() => {
    setIsAddAccount(true);
  }, []);

  const deleteHandler = useCallback(
    (identifier: SourceInstance) => {
      handleDelete(identifier);
    },
    [handleDelete],
  );

  return (
    <BaseConfigDrawer sourceName={POWERBI_DISPLAY_NAME}>
      {!isAddAccount && (
        <MainConfigScreen
          accounts={powerbiAccounts ?? []}
          onAddSource={addSourceHandler}
          onDelete={deleteHandler}
        />
      )}
      {isAddAccount ? <NewSourceScreen onDone={doneHandler} /> : null}
    </BaseConfigDrawer>
  );
}
