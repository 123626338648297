import { useEffect } from "react";
import { usePrInfo, useRepoPrList } from "../../../../api";
import { logEventVersionSelectionClicked } from "../../../../utils/analytics/eventLogging";
import { usePrNumberParam } from "../../../usePrNumberParam";
import { useRequiredParam } from "../../../useRequiredParam";
import { PanelLoadingMessage } from "../PanelLoadingMessage";
import { Selector } from "../Selector";
import { SelectorLabel } from "../SelectorLabel";
import { DiffSizeIndicator } from "./DiffSizeIndicator";
import { LoadedVersionsPanel } from "./LoadedVersionsPanel";

export function VersionSelector(): JSX.Element {
  const repoName = useRequiredParam("repoName");
  const prNumber = usePrNumberParam();

  const { data } = usePrInfo(repoName, prNumber);

  return (
    <Selector
      autoFocus
      label={
        <SelectorLabel
          className="relative"
          indicator={
            data?.diff_size_hint !== undefined && (
              // The positioning here is funky in order to match the specifics of the
              // design spec which had the indicator push outside of the padding for the
              // selector.
              <span className="absolute -right-2">
                <DiffSizeIndicator diffSize={data.diff_size_hint} />
              </span>
            )
          }
        >
          Pull Request
        </SelectorLabel>
      }
      value={data?.pr_text ?? `PR #${prNumber}`}
    >
      <VersionsSelectorPanel />
    </Selector>
  );
}

function VersionsSelectorPanel(): JSX.Element | null {
  const repoName = useRequiredParam("repoName");
  const { data: prItems } = useRepoPrList(repoName);

  useEffect(() => {
    logEventVersionSelectionClicked();
  }, []);

  if (prItems === undefined) {
    return <PanelLoadingMessage>Fetching versions...</PanelLoadingMessage>;
  }

  return <LoadedVersionsPanel prItems={prItems} />;
}
