import { Listbox } from "@headlessui/react";
import { ChevronDown } from "../../icons/ChevronDown";

const ALL_CHANGE_TYPES = ["direct", "indirect"] as const;
export type ChangeType = (typeof ALL_CHANGE_TYPES)[number];

export function TriggerChangeTypeSelector({
  initialChangeType,
  onChangeTypeSelect,
}: {
  readonly onChangeTypeSelect: (changeType: ChangeType) => void;
  readonly initialChangeType?: ChangeType | undefined;
}): JSX.Element {
  return (
    // We don't want to use undefined as a value for the ListBox because it marks the listbox as uncontrolled, otherwise
    // when the value is later changed (via prop) from undefined to any other value this causes a React warning.
    <Listbox value={initialChangeType ?? ("" as const)} onChange={onChangeTypeSelect}>
      {({ open: isOpen, value: selectedChangeType }) => (
        <div
          className={`relative w-full rounded-md  ${
            isOpen ? "border border-[rgb(101,202,132)]" : ""
          }`}
        >
          <SelectedChangeTypeButton
            isOpen={isOpen}
            selectedChangeType={selectedChangeType}
          />
          <ChangeTypeSelectorOptions />
        </div>
      )}
    </Listbox>
  );
}

function SelectedChangeTypeButton({
  selectedChangeType,
  isOpen,
}: {
  readonly selectedChangeType: ChangeType | "";
  readonly isOpen: boolean;
}): JSX.Element {
  return (
    <Listbox.Button
      className="flex w-full items-center justify-between rounded-md border bg-white px-5 py-3 text-sm font-semibold
          text-[rgb(82,100,96)] shadow-[0px_0px_1px_rgba(44,57,63,0.8),0px_2px_6px_rgba(44,57,63,0.08)] outline-none"
    >
      <span
        className={`text-sm leading-5 ${
          selectedChangeType === ""
            ? "font-normal text-[rgb(165,173,176)]"
            : "font-semibold text-[rgb(74,89,92)]"
        }`}
      >
        {titleForChangeType(selectedChangeType)}
      </span>
      <ChevronDown
        className={`h-3 w-3 text-[rgb(165,173,176)] ${
          isOpen ? "rotate-180 transform" : ""
        }`}
      />
    </Listbox.Button>
  );
}

function ChangeTypeSelectorOptions(): JSX.Element {
  return (
    <Listbox.Options className="max-h-90 absolute z-50 mt-1 w-full overflow-auto rounded-md bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
      {ALL_CHANGE_TYPES.map((changeType) => (
        <ChangeTypeListOption key={changeType} changeType={changeType} />
      ))}
    </Listbox.Options>
  );
}

function ChangeTypeListOption({
  changeType,
}: {
  readonly changeType: ChangeType;
}): JSX.Element {
  return (
    <Listbox.Option className="cursor-default select-none" value={changeType}>
      <div className="flex gap-x-2 border-b border-b-[rgba(165,173,176,0.25)] p-3 hover:bg-gray-50">
        <span className="text-sm font-semibold leading-5 text-[rgb(74,89,92)]">
          {titleForChangeType(changeType)}
        </span>
        <span className="text-xs font-normal leading-5 text-[rgb(165,173,176)]">
          {descriptionForChangeType(changeType)}
        </span>
      </div>
    </Listbox.Option>
  );
}

const titleForChangeType = (changeType: ChangeType | ""): string => {
  switch (changeType) {
    case "":
      return "Select change type";
    case "direct":
      return "Direct";
    case "indirect":
      return "Any change";
  }
};

const descriptionForChangeType = (changeType: ChangeType): string => {
  switch (changeType) {
    case "direct":
      return "Only direct changes";
    case "indirect":
      return "Direct or upstream changes";
  }
};
