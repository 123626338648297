import { forwardRef, type ButtonHTMLAttributes, type DetailedHTMLProps } from "react";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  readonly filled?: boolean;
};

export const InspectorButton = forwardRef<HTMLButtonElement, Props>(
  function InspectorButton({ filled = false, className, ...props }, ref): JSX.Element {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        ref={ref}
        className={`group flex items-center gap-1 rounded px-2 py-1 text-sm ${
          filled
            ? "bg-[rgba(225,220,255,0.6)] font-semibold text-[rgb(117,108,220)] hover:bg-[rgb(225,220,255)]"
            : "font-medium text-[rgb(165,173,176)] hover:bg-[rgb(237,242,242)] hover:text-[rgb(143,156,157)]"
        } ${className ?? ""}`}
        {...props}
      />
    );
  },
);
