import { filter, first, flatMap, isDefined, isEmpty, map, pipe, prop } from "remeda";
import { type MatchDescriptor, type SearcherFunction, SEARCHERS } from "./SEARCHERS";

export const stringsSearch =
  (query: string): ((entities: readonly string[]) => readonly string[]) =>
  (entities: readonly string[]) =>
    pipe(entitySearch(entities, query, stringSearcher), map(prop("entity")));

export const entitySearch = <Entity, Result extends MatchDescriptor>(
  entities: readonly Entity[],
  query: string,
  entitySearcher: (entity: Entity, searcher: SearcherFunction) => readonly Result[],
): readonly Result[] =>
  pipe(entities, flatMap(visitEntity(query, entitySearcher)), filter(isDefined.strict));

const visitEntity = <Entity, Result>(
  query: string,
  entitySearcher: (
    entity: Entity,
    searcher: SearcherFunction,
  ) => readonly (MatchDescriptor & Result)[],
): ((entity: Entity) => (MatchDescriptor & Result)[] | undefined) => {
  // During a single search the query is constant so we can prepare the searcher
  // functions once and reuse them for each specific table we query.
  const preparedSearchers = map(SEARCHERS, (searcher) => searcher(query));

  return (entity) =>
    // We are using Remeda's pipe lazy evaluation to avoid running all searchers over
    // each entity; `first` would only "pull" values from the pipe until it has
    // something to return, and then it would stop the pipe.
    pipe(
      preparedSearchers,
      map((searcher) => [...entitySearcher(entity, searcher)]),
      filter((results) => !isEmpty(results)),
      first(),
    );
};

const stringSearcher = (entity: string, searcher: SearcherFunction) => {
  const match = searcher(entity);
  if (match === undefined) {
    return [];
  }
  return [{ entity, ...match }];
};
