import type { Policy, UserInfo } from "../../../../api";
import { ToggleSwitch } from "../../../ToggleSwitch";
import {
  POLICIES_TABLE_COLUMNS_FLEX_RATIOS,
  timestampToDisplayFormat,
  userNameForDisplay,
} from "../utils";
import { PolicyActionButtonsBox } from "./PolicyActionButtons";

export function PolicyMetadataView({
  policy,
  onDelete,
  onEdit,
  onStatusChange,
}: {
  readonly policy: Policy;
  readonly onDelete: () => void;
  readonly onStatusChange: (newStatus: boolean) => void;
  readonly onEdit?: (() => void) | undefined;
}): JSX.Element {
  const wasModified = policy.lastModifiedAt !== undefined;
  return (
    <div className="min-h-10 flex items-start justify-between gap-x-2 py-4">
      <PolicyNameColumn
        className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.policyName}
        policyName={policy.name}
      />
      <UserNameColumn
        className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.createdBy}
        userInfo={policy.createdBy}
      />
      <UserNameColumn
        className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.modifiedBy}
        userInfo={wasModified ? policy.lastModifiedBy : policy.createdBy}
      />
      <TimestampColumn
        className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.modifiedAt}
        timestamp={wasModified ? policy.lastModifiedAt : policy.createdAt}
      />
      <PolicyActionButtonsColumn
        className={`flex justify-start ${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.actionButtons}`}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <PolicyStatusColumn
        className={`flex justify-end ${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.status}`}
        isEnabled={policy.isEnabled}
        onStatusChange={onStatusChange}
      />
    </div>
  );
}

export function PolicyNameColumn({
  policyName,
  className,
}: {
  readonly policyName: string;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <span
      className={`truncate text-sm font-semibold text-[rgb(74,89,92)] ${
        className ?? ""
      }`}
    >
      {policyName}
    </span>
  );
}

function UserNameColumn({
  userInfo,
  className,
}: {
  readonly userInfo: UserInfo | undefined;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <span
      className={`truncate text-sm font-semibold text-[rgb(74,89,92)] ${
        className ?? ""
      }`}
    >
      {userNameForDisplay(userInfo)}
    </span>
  );
}

function TimestampColumn({
  className,
  timestamp,
}: {
  readonly timestamp: string;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <time
      className={`truncate text-sm text-[rgb(143,156,157)] ${className ?? ""}`}
      dateTime={timestamp}
    >
      {timestampToDisplayFormat(timestamp)}
    </time>
  );
}

function PolicyStatusColumn({
  isEnabled,
  onStatusChange,
  className,
}: {
  readonly isEnabled: boolean;
  readonly onStatusChange?: ((enabled: boolean) => void) | undefined;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <div className={`items-start ${className ?? ""}`}>
      <ToggleSwitch isChecked={isEnabled} onToggle={onStatusChange} />
    </div>
  );
}

function PolicyActionButtonsColumn({
  className,
  onDelete,
  onEdit,
}: {
  readonly onDelete: () => void;
  readonly onEdit?: (() => void) | undefined;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <PolicyActionButtonsBox
      className={`invisible group-hover:visible ${className ?? ""}`}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  );
}
