import { Popover } from "@headlessui/react";
import { useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import type { PrItem } from "../../../../api";
import { logEventVersionSelected } from "../../../../utils/analytics/eventLogging";
import type { PullRequestsSearchResult } from "../../../../utils/search/pullRequests";
import { CommitIcon } from "../../../icons/CommitIcon";
import { PullRequestIcon } from "../../../icons/PullRequestIcon";
import { RepositoryIcon } from "../../../icons/RepositoryIcon";
import { HighlightedText } from "../../../search/HighlightedText";
import { PullRequestStatus } from "../../PullRequestStatus";
import { DiffSizeIndicator } from "./DiffSizeIndicator";

const COMMIT_HASH_SIGNIFICANT_CHARS = 7;

export function VersionItem({
  item,
  segments,
  entityType,
}: PullRequestsSearchResult): JSX.Element {
  const { repoName } = useParams();

  const handleVersionMenuItemClicked = useCallback(() => {
    logEventVersionSelected(`${item.pr_number}`);
  }, [item.pr_number]);

  return (
    <Popover.Button
      as={Link}
      className="flex flex-col gap-2 border-b border-[rgb(230,236,234)] p-5 text-xs text-[rgb(143,153,157)] hover:bg-[rgb(247,250,249)] [&_mark]:bg-[rgba(101,202,132,0.2)]"
      relative="route"
      to={`../pull/${item.pr_number}`}
      onClick={handleVersionMenuItemClicked}
    >
      <HighlightedText
        className="text-sm font-semibold text-[rgb(82,97,100)]"
        segments={entityType === "title" ? segments : undefined}
        text={item.pr_text}
      />
      <div className="flex items-center gap-2 font-semibold [&_svg]:text-[rgb(165,173,176)]">
        <PullRequestIcon className="w-[14px]" />
        <HighlightedText
          segments={entityType === "number" ? segments : undefined}
          text={`${item.pr_number}`}
        />
        <div className="h-4 w-px bg-[rgb(205,215,215)]" />
        <CommitIcon className="w-[14px]" />
        <HighlightedText
          className="flex-1"
          segments={entityType === "hash" ? segments : undefined}
          text={item.head_hash.slice(0, COMMIT_HASH_SIGNIFICANT_CHARS)}
          title={item.head_hash}
        />
        {item.diff_size_hint !== undefined && (
          <DiffSizeIndicator diffSize={item.diff_size_hint} />
        )}
      </div>
      <PullRequestStatus iconClassName="w-[14px]" item={item}>
        <VersionItemUser
          createdBy={item.created_by}
          entityType={entityType}
          segments={segments}
        />
      </PullRequestStatus>
      <div className="flex items-center gap-2">
        <RepositoryIcon className="w-[14px] text-[rgb(165,173,176)]" />
        {repoName}
      </div>
    </Popover.Button>
  );
}

function VersionItemUser({
  createdBy: { name: creatorName, login },
  segments,
  entityType,
}: {
  readonly createdBy: PrItem["created_by"];
  readonly entityType: PullRequestsSearchResult["entityType"];
  readonly segments?: PullRequestsSearchResult["segments"];
}): JSX.Element {
  return (
    <strong
      className="font-semibold text-[rgb(82,97,100)]"
      title={creatorName === undefined ? login : `${creatorName} (${login})`}
    >
      {creatorName === undefined ? (
        <HighlightedText
          segments={entityType === "creatorLogin" ? segments : undefined}
          text={login}
        />
      ) : (
        <HighlightedText
          segments={
            entityType === "creatorName"
              ? segments
              : entityType === "creatorLogin"
              ? [{ start: 0, len: creatorName.length }]
              : undefined
          }
          text={creatorName}
        />
      )}
    </strong>
  );
}
