import { Popover } from "@headlessui/react";
import type { LineageIssue, LineageIssueSeverity } from "../../../api";
import type { NonEmptyArray } from "../../../utils/NonEmptyArray";
import { XIcon } from "../../icons/XIcon";
import { IssuesCounter } from "./IssuesCounter";
import { IssuesDialogBody } from "./IssuesDialogBody";
import { getIssueTypeLabel } from "./issue_types";

export function IssuesDialog({
  issues,
  fixed: isFixed,
}: {
  readonly issues: NonEmptyArray<LineageIssue>;
  readonly fixed: boolean;
}): JSX.Element {
  const [{ severity, issue_type }] = issues;
  return (
    <>
      <IssuesDialogHeader
        count={issues.length}
        issueType={issue_type}
        severity={isFixed ? "fixed" : severity}
      />
      <IssuesDialogBody fixed={isFixed} issues={issues} />
    </>
  );
}

function IssuesDialogHeader({
  severity,
  count,
  issueType,
}: {
  readonly severity: LineageIssueSeverity | "fixed";
  readonly count: number;
  readonly issueType: string;
}): JSX.Element {
  return (
    <header className="flex items-center gap-2 border-b border-[rgba(82,97,100,0.15)] p-4 shadow-[0_3px_5px_rgba(115,130,133,0.1)]">
      <IssuesCounter className="flex-none" severity={severity}>
        {count}
      </IssuesCounter>
      <h2 className="flex-1 font-semibold text-[rgb(50,66,71)]">
        {getIssueTypeLabel(issueType)}
      </h2>
      <Popover.Button className="group h-6 w-6 flex-none">
        <XIcon
          className="h-3 w-3 text-[rgb(165,173,176)] group-hover:text-[rgb(115,130,133)]"
          variant="medium"
        />
      </Popover.Button>
    </header>
  );
}
