import { type CSSProperties, type PropsWithChildren, useMemo } from "react";
import { ConnectedEdgesToggleButton } from "./ConnectedEdgesToggleButton";
import type { DiscoveryModeTableContext } from "./DiscoveryModeLineageRootWrapper";

export function EdgesConnectorWrapper({
  context: { nodeId, existingEdgeIds, inbound, outbound },
  children,
  onToggleClick,
  leftStyle,
}: PropsWithChildren<{
  readonly context: DiscoveryModeTableContext;
  readonly onToggleClick: Parameters<typeof ConnectedEdgesToggleButton>[0]["onClick"];
  readonly leftStyle?: CSSProperties;
}>): JSX.Element {
  const hasMissingInboundEdges = useMemo(
    () => inbound.some((relation) => !existingEdgeIds.has(relation.id)),
    [existingEdgeIds, inbound],
  );

  const hasMissingOutboundEdges = useMemo(
    () => outbound.some((relation) => !existingEdgeIds.has(relation.id)),
    [existingEdgeIds, outbound],
  );

  return (
    <div className="flex w-full items-center">
      {inbound.length > 0 && (
        <ConnectedEdgesToggleButton
          className="-left-3"
          clickedNodeId={nodeId}
          connectedEdges={inbound}
          direction="inbound"
          fullyExpanded={!hasMissingInboundEdges}
          style={leftStyle}
          onClick={onToggleClick}
        />
      )}
      {children}
      {outbound.length > 0 && (
        <ConnectedEdgesToggleButton
          className="-right-3"
          clickedNodeId={nodeId}
          connectedEdges={outbound}
          direction="outbound"
          fullyExpanded={!hasMissingOutboundEdges}
          onClick={onToggleClick}
        />
      )}
    </div>
  );
}
