import { useCallback, type Dispatch } from "react";
import { SimpleComboBox } from "../SimpleComboBox";
import type { Action, RefactorType } from "./reducer";

const LABELS: Readonly<Record<RefactorType, string>> = {
  deleteColumn: "Delete a Column",
  deleteTable: "Delete a Table",
  renameColumn: "Rename a Column",
  renameTable: "Rename a Table",
};

export function StepOne({
  dispatch,
  disabled = false,
  className,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly disabled?: boolean;
  readonly className?: string | undefined;
}): JSX.Element {
  const changeHandler = useCallback(
    (refactorType: RefactorType) => {
      dispatch({ type: "refactorTypeSelected", data: { refactorType } });
    },
    [dispatch],
  );

  return (
    <section
      className={`flex flex-col items-start justify-center gap-3 transition duration-300 ${
        disabled ? "opacity-20" : ""
      } ${className ?? ""}`}
    >
      <h5 className="text-2xl font-medium">Step 1: What do you want to do?</h5>
      <SimpleComboBox
        disabled={disabled}
        optionsWithLabels={LABELS}
        onChange={changeHandler}
      />
    </section>
  );
}
