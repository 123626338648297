/* eslint-disable import/no-deprecated */
import { find as remedaFind, pipe, toPairs } from "remeda";
import type { Writable } from "type-fest";
import { DEMO_MODE } from "../../utils/demo/demoMode";
import { EXAMPLE_PRS, EXAMPLE_SNAPSHOT } from "../../utils/demo/example";
import { canonicalTableIdentifier } from "../../utils/graph/canonical";
import type {
  BackendLineageState,
  LineageLocation,
  LineageLocationError,
  LineageState,
  LineageTable,
  MultiLineage,
  MultiLineageLocations,
} from "../../utils/graph/type";
import { EMPTY_BACKEND_STATE } from "../../utils/graph/type";
import { queryApiCall } from "../queryApiCall";
import { convertAndEnrichBackendLineageState } from "./convertBackend";
import { normalizeSnapshot, removeIndirectChanges } from "./normalizeSnapshot";

const EMPTY_RESULTS = {
  tables: [],
  relations: [],
  version: 1,
  stateType: "SNAPSHOT" as const,
};

async function fetchSingleRepoLineageOrEmptyResults(
  repoLineageLocation: LineageLocation | LineageLocationError | undefined,
): Promise<BackendLineageState> {
  if (repoLineageLocation === undefined || "error" in repoLineageLocation) {
    return EMPTY_RESULTS;
  }

  const response = await fetch(repoLineageLocation.location);
  if (!response.ok) {
    return EMPTY_RESULTS;
  }

  // noinspection ES6MissingAwait
  return response.json() as Promise<BackendLineageState>;
}

export async function fetchLineageSnapshot(): Promise<LineageState> {
  const { results } = await queryApiCall<MultiLineageLocations>("lineage/current");

  const allLineages = Object.fromEntries(
    await Promise.all(
      toPairs(results).map(async ([repoName, locationRes]) => [
        repoName,
        convertAndEnrichBackendLineageState(
          await fetchSingleRepoLineageOrEmptyResults(locationRes),
        ),
      ]),
    ),
  ) as Record<string, LineageState>;

  const result = mergeSnapshots(allLineages);
  // We want to cache this work too, it's very time-consuming.
  return normalizeSnapshot(result);
}

async function actualFetch(
  repoName?: string,
  prNumber?: number,
): Promise<[Record<string, LineageState>, LineageState]> {
  const endpoint =
    repoName === undefined || prNumber === undefined
      ? "lineage/current"
      : `lineage/${repoName}/pull/${prNumber}`;

  if (DEMO_MODE.isEnabled) {
    const pr = pipe(
      EXAMPLE_PRS,
      remedaFind(({ prId }) => prId === prNumber),
      ($) => convertAndEnrichBackendLineageState($ ?? EMPTY_BACKEND_STATE),
    );
    return [{ snapshot: convertAndEnrichBackendLineageState(EXAMPLE_SNAPSHOT) }, pr];
  }

  const { diff, results } = await queryApiCall<MultiLineageLocations>(endpoint);

  const allLineages = Object.fromEntries(
    await Promise.all(
      toPairs(results).map(async ([receivedRepoName, locationRes]) => [
        receivedRepoName,
        convertAndEnrichBackendLineageState(
          await fetchSingleRepoLineageOrEmptyResults(locationRes),
        ),
      ]),
    ),
  ) as Record<string, LineageState>;

  const diffLineage = convertAndEnrichBackendLineageState(
    await fetchSingleRepoLineageOrEmptyResults(diff),
  );

  return [allLineages, diffLineage];
}

export async function fetchLineage(
  repoName?: string,
  prNumber?: number,
): Promise<MultiLineage> {
  const [allLineages, diffLineage] = await actualFetch(repoName, prNumber);

  allLineages["diff"] = diffLineage; // include the diff in the merged snapshot
  const merged = mergeSnapshots(allLineages);

  // We want to cache this work too, it's very time-consuming.
  const prepared = normalizeSnapshot(merged);

  const whatAHack = removeIndirectChanges(prepared);

  return { diff: whatAHack, snapshot: prepared };
}

function mergeTablesArray(input: readonly LineageTable[]) {
  const o = new Map<string, LineageTable>();
  for (const table of input) {
    const tableId = canonicalTableIdentifier(table.table_identifier);
    const existing = o.get(tableId);
    const result = {
      table_identifier: table.table_identifier,
      columns: [...table.columns, ...(existing?.columns ?? [])],
      changeStatus: existing?.changeStatus ?? table.changeStatus,
    } as Writable<LineageTable>;
    o.set(tableId, result);
  }
  return [...o.values()];
}

function mergeSnapshots(snapshotMap: Record<string, LineageState>): LineageState {
  // this is imperfect, since if a table appears twice in multiple snapshots
  // we should merge columns, however, this is a temporary solution and I want
  // it out fast rather than perfect
  const result = {
    tables: [],
    edges: [],
  } as Writable<LineageState>;

  for (const snapshot of Object.values(snapshotMap)) {
    result.tables = [...result.tables, ...snapshot.tables];
    result.edges = [...result.edges, ...snapshot.edges];
  }

  result.tables = mergeTablesArray(result.tables);

  return result;
}
