/* eslint-disable import/max-dependencies --
 * This file is more like a config, so it doesn't really matter, but if this
 * file becomes too unwieldy we can consider splitting the routes up, and that
 * would also reduce the number of direct dependencies this file has...
 */

import { redirect, type RouteObject } from "react-router-dom";
import { CreatePolicyScreen } from "../components/policies/create_policy/CreatePolicyScreen";
import { PoliciesScreen } from "../components/policies/main/PoliciesScreen";
import { Root } from "../root";
import { IS_EXERCISE } from "../utils/env";
import type { GraphTypeRouteHandle } from "../utils/graph/useGraphType";
import { AdminLoggedInLayout } from "./__adminLoggedIn";
import CreateOrgAdminPage from "./__adminLoggedIn/create-org";
import InviteUsersAdminPage from "./__adminLoggedIn/invite-users";
import { LoggedInLayout } from "./__loggedIn";
import LoggedInIndexPage from "./__loggedIn/_index";
import ConnectorsPageLoader, {
  loader as connectorsLoader,
} from "./__loggedIn/connectors";
import DashboardPage from "./__loggedIn/dashboard";
import { LineageDiscoveryPage } from "./__loggedIn/lineage/_index";
import ParseJsonPage from "./__loggedIn/parse-json/_index";
import RepoIndexPage from "./__loggedIn/pr-analysis/$repoName/_index";
import RepoPullRequestPage from "./__loggedIn/pr-analysis/$repoName/pull.$prId";
import PRAnalysisIndexPage from "./__loggedIn/pr-analysis/_index";
import RefactorPage from "./__loggedIn/refactor";
import { LoggedOutLayout } from "./__loggedOut";
import { LoginPage } from "./__loggedOut/login";
import { loader as backdoorLoader } from "./backdoor";

export const ADMIN_PATH = "admin";
export const PR_ANALYSIS_PATH = "pr-analysis";

const LOGGED_IN_ROUTES: RouteObject[] = [
  { index: true, element: <LoggedInIndexPage /> },
  { path: "dashboard", element: <DashboardPage /> },
  {
    path: PR_ANALYSIS_PATH,
    handle: { graphType: "diff" } as GraphTypeRouteHandle,
    children: [
      { index: true, element: <PRAnalysisIndexPage /> },
      {
        path: ":repoName",
        children: [
          { index: true, element: <RepoIndexPage /> },
          { path: "pull/:prId", element: <RepoPullRequestPage /> },
        ],
      },
    ],
  },
  {
    path: "lineage",
    handle: { graphType: "lineage" } as GraphTypeRouteHandle,
    children: [{ index: true, element: <LineageDiscoveryPage /> }],
  },
  { path: "refactor", element: <RefactorPage /> },
  { path: "query-explorer", loader: () => redirect("/") },
  // { path: "ofir", children: [{ index: true, element: <ParseJsonPage /> }] },
  {
    path: "policies",
    children: [
      { index: true, element: <PoliciesScreen createPolicyScreenPath="create" /> },
      {
        path: "create",
        element: <CreatePolicyScreen policiesScreenPath=".." />,
      },
    ],
  },
  {
    path: "connectors",
    loader: connectorsLoader,
    element: <ConnectorsPageLoader />,
  },
];

const LOGGED_OUT_ROUTES: RouteObject[] = [{ path: "login", element: <LoginPage /> }];

const ADMIN_ROUTES: RouteObject[] = [
  { path: "create-org", element: <CreateOrgAdminPage /> },
  { path: "invite-users", element: <InviteUsersAdminPage /> },
];

const REAL_ROUTES: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    children: [
      { path: ADMIN_PATH, element: <AdminLoggedInLayout />, children: ADMIN_ROUTES },
      { element: <LoggedInLayout />, children: LOGGED_IN_ROUTES },
      { element: <LoggedOutLayout />, children: LOGGED_OUT_ROUTES },
      { path: "backdoor", loader: backdoorLoader },
    ],
  },
];

const EXERCISE_ROUTES: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <ParseJsonPage />,
      },
    ],
  },
];

export const ROUTES = IS_EXERCISE ? EXERCISE_ROUTES : REAL_ROUTES;
