import { Box, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { PropsWithChildren } from "react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // Necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const TitleDrawerHeader = styled(DrawerHeader)(({ theme }) => ({
  justifyContent: "flex-start",
  // Necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DRAWER_WIDTH = 450;

export function BaseConfigDrawer({
  sourceName,
  children,
}: PropsWithChildren<{
  readonly sourceName: string;
}>): JSX.Element {
  return (
    <Box role="presentation" sx={{ width: DRAWER_WIDTH }}>
      {/* This is the actual title */}
      <TitleDrawerHeader>
        <Typography variant="h5">{sourceName} Config</Typography>
      </TitleDrawerHeader>
      <Divider />
      <Box sx={{ ml: 1, mr: 1 }}>{children}</Box>
    </Box>
  );
}
