import { useReducer } from "react";
import { RefactorArtifacts } from "./RefactorArtifacts";
import { StepFour } from "./StepFour";
import { StepOne } from "./StepOne";
import { StepThree } from "./StepThree";
import { StepTwo } from "./StepTwo";
import { refactorReducer } from "./reducer";

export function RefactorStepper(): JSX.Element {
  const [state, dispatch] = useReducer(refactorReducer, {});

  return (
    <section className="flex flex-col gap-8 p-8">
      <StepOne className="z-50" dispatch={dispatch} />
      <StepTwo
        className="z-40"
        disabled={state.refactorType === undefined}
        dispatch={dispatch}
      />
      <StepThree
        className="z-30"
        disabled={state.columnName === undefined}
        dispatch={dispatch}
      />
      <StepFour
        className="z-20"
        disabled={state.renameTo === undefined || (state.isGenerated ?? false)}
        dispatch={dispatch}
      />
      {state.isGenerated ?? false ? <RefactorArtifacts state={state} /> : null}
    </section>
  );
}
