import { purry } from "remeda";
import type { ArrayMinN } from "./types/ArrayMinN";

/**
 * Checks that the array has N items OR MORE. The result is typed so that accessing the
 * first N items would result in a strict type T (without the `undefined` possible
 * value).
 *
 * IMPORTANT: If combining with `hasAtMost` to define both an upper bound and a lower
 * bound on the array lenght, use `hasAtMost` BEFORE `hasAtLeast` otherwise the typing
 * would be incorrect.
 *
 * @see hasAtMost to define an upper bound on array length
 * @see isLength to define an exact length. Prefer this to defining both an
 * upper and a lower bound to length as the typing is more efficient.
 */
export function hasAtLeast<T, N extends number>(
  data: readonly T[],
  minimum: N,
): data is ArrayMinN<T, N>;

export function hasAtLeast<T, N extends number>(
  minimum: N,
): (data: readonly T[]) => data is ArrayMinN<T, N>;

export function hasAtLeast(...args: readonly unknown[]): unknown {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return purry(hasAtLeastImplementation, args);
}

const hasAtLeastImplementation = <T, N extends number>(
  data: readonly T[],
  minimum: N,
): data is ArrayMinN<T, N> => data.length >= minimum;
