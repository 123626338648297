import { PlusSignIcon } from "../../icons/PlusSignIcon";
import { ActionButton } from "../common/Button";

export function AddNewPolicyButton({
  onClick,
}: {
  readonly onClick: () => void;
}): JSX.Element {
  return (
    <ActionButton className="pl-2 pr-3" onClick={onClick}>
      <PlusSignIcon className="h-5 w-5" /> Add New Policy
    </ActionButton>
  );
}
