import { useQuery } from "@tanstack/react-query";
import { FullscreenMessage } from "./FullscreenMessage";
import { PullRequestDiffServerError } from "./PullRequestDiffServerError";

export function PullRequestDiffError({
  repoName,
  prId,
  error,
}: {
  readonly repoName: string;
  readonly prId: string;
  readonly error: unknown;
}): JSX.Element {
  return error instanceof Response ? (
    <PullRequestDiffResponseError error={error} prId={prId} repoName={repoName} />
  ) : (
    <FullscreenMessage>Unexpected error! {JSON.stringify(error)}</FullscreenMessage>
  );
}

function PullRequestDiffResponseError({
  repoName,
  prId,
  error,
}: {
  readonly repoName: string;
  readonly prId: string;
  readonly error: Response;
}): JSX.Element {
  const { data, isLoading } = useQuery({
    queryKey: ["responseError", repoName, prId],
    queryFn: async () => error.json() as Promise<{ detail?: string }>,
  });

  return isLoading ? (
    <FullscreenMessage loading>
      Server error, fetching error message...
    </FullscreenMessage>
  ) : data?.detail === undefined ? (
    <FullscreenMessage>
      Got unexpected response from server: {JSON.stringify(data)}
    </FullscreenMessage>
  ) : (
    <PullRequestDiffServerError errorDetails={data?.detail} />
  );
}
