import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { Fragment, useCallback, useState } from "react";
import { useOAuthCallback } from "../../utils/auth/oauth";
import { GITHUB_APP_URL } from "../../utils/env";
import { exceptionToErrorMessage } from "../../utils/error";
import type { GithubState, SettingsOverviewProps } from "../../utils/settings/types";
import { BaseConfigCard } from "./BaseConfigCard";
import { BaseConfigDrawer } from "./BaseConfigDrawer";
import { useSubSettings } from "./settingsProvider";
import { useApiRequest } from "./useApiRequest";

export const GITHUB_NAME = "github";
const GITHUB_DISPLAY_NAME = "Github";

export function GithubConfigOverview({
  onConfigure,
}: SettingsOverviewProps): JSX.Element {
  const [state] = useSubSettings(GITHUB_NAME);
  const installations = state?.installations ?? [];

  return (
    <BaseConfigCard
      avatarPath="/img/connectors/github64px.png"
      configured={installations.length > 0}
      sourceName={GITHUB_DISPLAY_NAME}
      onConfigure={onConfigure}
    >
      <>
        {installations.map((install) => (
          <Fragment key={`cc-${install.github_install_id}`}>
            <Typography gutterBottom component="div" variant="h6">
              {install.account_login}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {install.enabled_repos.length} repositories enabled
              <br />
              Admin: {install.registrar_login}{" "}
              {install.registrar_name === undefined
                ? ""
                : `(${install.registrar_name})`}
            </Typography>
          </Fragment>
        ))}
      </>
    </BaseConfigCard>
  );
}

// eslint-disable-next-line complexity
export function GithubSettings(): JSX.Element {
  const [state, setGithubState] = useSubSettings(GITHUB_NAME);
  const installations = state?.installations ?? [];
  const username = state?.username;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const api = useApiRequest();

  const handleAuthDone = useCallback(
    (authParams: URLSearchParams | undefined) => {
      if (authParams === undefined) {
        return;
      }

      const setupAction = authParams.get("setup_action");
      const installationId = authParams.get("installation_id");
      const code = authParams.get("code");

      setError(null);
      setLoading(true);
      const updateInstallations = async () => {
        try {
          if (installationId !== null) {
            setGithubState(
              (await api(
                "/config/github/installations/",
                {
                  installation_id: installationId,
                },
                "PUT",
              )) as GithubState,
            );
          } else if (setupAction === "request") {
            await api("/config/github/oauth_completion", {
              code,
            });
          } else {
            setGithubState((await api("/config/github/installations/")) as GithubState);
          }
        } catch (
          // eslint-disable-next-line unicorn/catch-error-name -- error is already used
          err
        ) {
          setError(await exceptionToErrorMessage(err));
        } finally {
          setLoading(false);
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateInstallations();
    },
    [setGithubState, api],
  );

  const authenticate = useOAuthCallback(handleAuthDone);

  const onConfigure = useCallback(() => {
    authenticate(`${GITHUB_APP_URL}/installations/new`);
  }, [authenticate]);

  return (
    <BaseConfigDrawer sourceName={GITHUB_DISPLAY_NAME}>
      <Typography component="div" variant="h6">
        Configured Repositories
      </Typography>
      {loading ? <CircularProgress /> : null}
      {!loading && installations.length > 0 && (
        <List dense>
          {installations.flatMap((installation) =>
            installation.enabled_repos.map((repo) => (
              <ListItem key={`${installation.github_install_id}_${repo}`}>
                <ListItemText primary={repo} />
              </ListItem>
            )),
          )}
        </List>
      )}
      {!loading && installations.length === 0 && (
        <Typography variant="body2">No configured accounts</Typography>
      )}
      {!loading && username !== undefined && (
        <Typography variant="caption">{`Signed in as: ${username}`}</Typography>
      )}
      <Box sx={{ mt: 1 }}>
        <Button type="button" variant="outlined" onClick={onConfigure}>
          Configure
        </Button>
      </Box>
      {!loading && username !== undefined && (
        <Box
          sx={{
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
            mt: 2,
          }}
        >
          If you created a request previously - your administrators should&apos;ve
          received an email requesting approval
          <br />
        </Box>
      )}
      {error !== null && <Alert severity="error">{error}</Alert>}
    </BaseConfigDrawer>
  );
}
