import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { ENV } from "./utils/env";

const DATADOG_DEF = {
  clientToken: "pube08e9b1d190145696273fa4767a8a5cc",
  site: "datadoghq.eu",
};

const RUM_SAMPLE_RATE = 100;
const LOG_SAMPLE_RATE = 100;
const SESSION_REPLAY_SAMPLE_RATE = 100;

export function initDatadogRum(): void {
  if (ENV === "test") {
    // Disable datadog in tests
    return;
  }

  datadogRum.init({
    ...DATADOG_DEF,
    applicationId: "37edb229-09d6-4587-bee7-0d922fb5ea71",
    service: "frontend",
    env: process.env.NODE_ENV,

    // Specify a version number to identify the deployed version of your
    // application in Datadog
    // version: '1.0.0',

    sampleRate: RUM_SAMPLE_RATE,
    sessionReplaySampleRate: SESSION_REPLAY_SAMPLE_RATE,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
  datadogRum.startSessionReplayRecording();
}

export function setDDUser(username: string, fullName: string, isAdmin: boolean): void {
  const user = {
    name: fullName,
    email: username,
    permissions: isAdmin ? "admin" : "user",
  };
  datadogRum.setUser(user);
  datadogLogs.setUser(user);
}

export function clearDDUser(): void {
  datadogRum.clearUser();
  datadogLogs.clearUser();
}

export function initDatadogLogs(): void {
  datadogLogs.init({
    ...DATADOG_DEF,
    forwardErrorsToLogs: true,
    sampleRate: LOG_SAMPLE_RATE,
    service: "frontend",
    env: ENV,
    forwardConsoleLogs: "all",
    forwardReports: "all",
  });

  if (ENV === "local") {
    // We want our logs visible in the browser for development
    datadogLogs.logger.setHandler(["console", "http"]);
  }
}
