import { Dialog, Transition } from "@headlessui/react";
import { Fragment, type PropsWithChildren, type ReactNode } from "react";
import { CloseDialogIcon } from "../../icons/CloseDialogIcon";
import { ActionButton, CancelButton } from "./Button";

export function ModalDialog({
  isOpen,
  title,
  onClose,
  children,
}: PropsWithChildren<{
  readonly isOpen: boolean;
  readonly title: string;
  readonly onClose: () => void;
}>): JSX.Element {
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-40" open={isOpen} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Dialog.Panel className="w-full max-w-lg rounded-md bg-white">
            <DialogHeader title={title} onClose={onClose} />
            <div className="flex flex-col gap-y-12 bg-[rgb(245,247,247)] p-6">
              {children}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}

function DialogHeader({
  title,
  onClose,
}: {
  readonly title: string;
  readonly onClose: () => void;
}): JSX.Element {
  return (
    <div className="flex items-center justify-between gap-x-3 border-b border-[rgba(82,97,100,0.15)] py-4 pl-6 pr-4 shadow-[0_3px_5px_0_rgba(115,130,133,0.1)]">
      <Dialog.Title className="text-base font-semibold leading-6 text-[rgb(50,66,71)]">
        {title}
      </Dialog.Title>
      <CloseDialogButton onClick={onClose} />
    </div>
  );
}

function CloseDialogButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <button
      className="h-7 w-7 p-2 text-[rgb(165,173,176)] hover:text-[rgba(165,173,176,0.75)] active:text-[rgb(165,173,176)]"
      type="button"
      onClick={onClick}
    >
      <CloseDialogIcon />
    </button>
  );
}

export function DialogFooter({
  actionButton,
  cancelButton,
  className,
}: {
  readonly actionButton?: ReactNode;
  readonly cancelButton?: ReactNode;
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <div className={`flex gap-x-4 ${className ?? ""}`}>
      {cancelButton}
      {actionButton}
    </div>
  );
}

export function DialogActionButton({
  onClick,
  className,
  children,
}: PropsWithChildren<{
  readonly onClick: () => void;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <ActionButton className={className} onClick={onClick}>
      {children}
    </ActionButton>
  );
}

export function DialogCancelButton({
  onClick,
  className,
  children,
}: PropsWithChildren<{
  readonly onClick: () => void;
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <CancelButton className={className} onClick={onClick}>
      {children}
    </CancelButton>
  );
}
