import { Combobox } from "@headlessui/react";
import { useCallback, useState, type ChangeEvent } from "react";
import { filter, map, pipe, toPairs } from "remeda";
import { ChevronUpDown } from "./icons/ChevronUpDown";

export function SimpleComboBox<T extends string>({
  className,
  optionsWithLabels,
  ...comboboxProps
}: {
  readonly onChange: (item: T) => void;
  readonly optionsWithLabels: Readonly<Record<T, string>>;
  readonly className?: string | undefined;
  readonly disabled?: boolean;
  readonly id?: string;
}): JSX.Element {
  const [query, setQuery] = useState("");

  const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.toLowerCase());
  }, []);

  const focusHandler = useCallback(() => {
    setQuery("");
  }, []);

  const displayValueHandler = useCallback(
    (key: T) => optionsWithLabels[key],
    [optionsWithLabels],
  );

  return (
    <Combobox
      as="div"
      className={`relative isolate ${className ?? ""}`}
      {...comboboxProps}
    >
      <div className="relative z-50 w-full cursor-default overflow-hidden rounded-xl border bg-white text-left outline-none">
        <Combobox.Button>
          <Combobox.Input
            autoComplete="off"
            className="w-full border-none py-2 pl-3 pr-10 text-sm outline-none"
            displayValue={displayValueHandler}
            onChange={changeHandler}
            onFocus={focusHandler}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDown className="h-5 w-5 text-gray-400" />
          </div>
        </Combobox.Button>
      </div>
      <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-xl border bg-white py-1 text-base shadow-md outline-none">
        {pipe(
          optionsWithLabels,
          toPairs.strict,
          filter(([, label]) => query === "" || label.toLowerCase().includes(query)),
          map(([key, label]) => (
            <Combobox.Option
              key={key}
              className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ui-active:bg-teal-600 ui-active:text-white"
              value={key}
            >
              {label}
            </Combobox.Option>
          )),
        )}
      </Combobox.Options>
    </Combobox>
  );
}
