import type { Policy } from "../../../../api";
import { PolicyActionsView } from "./PolicyActionsView";
import { PolicyMetadataView } from "./PolicyMetadataView";
import { PolicyTriggersView } from "./PolicyTrigersView";

export function PolicyView({
  policy,
  onStatusChange,
  onDelete,
  onEdit,
}: {
  readonly policy: Policy;
  readonly onStatusChange: (newStatus: boolean) => void;
  readonly onDelete: () => void;
  readonly onEdit?: (() => void) | undefined;
}): JSX.Element {
  return (
    <div className="group flex flex-col border-b border-[rgba(74,89,92,0.1)] py-4">
      <PolicyMetadataView
        policy={policy}
        onDelete={onDelete}
        onEdit={onEdit}
        onStatusChange={onStatusChange}
      />
      <div className="flex gap-x-2">
        <PolicyTriggersView className="flex-1" rulesData={policy.rulesData} />
        <PolicyActionsView actionsData={policy.actionsData} className="flex-1" />
      </div>
    </div>
  );
}
