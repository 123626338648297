import { Box, Button, CircularProgress } from "@mui/material";

export function CallToActions({
  onCancel,
  onSave,
  disabled,
  inProgress,
}: {
  readonly onCancel: () => void;
  readonly onSave: () => void;
  readonly disabled: boolean;
  readonly inProgress: boolean;
}): JSX.Element {
  return (
    <Box sx={{ mt: 1 }}>
      <Button type="reset" variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        disabled={disabled || inProgress}
        sx={{ ml: 1 }}
        type="submit"
        variant="contained"
        onClick={onSave}
      >
        {inProgress ? (
          <CircularProgress
            color="inherit"
            size={24}
            sx={{
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- This will be removed once we migrate this component to tailwind
              marginX: 0.8,
            }}
          />
        ) : (
          "Save"
        )}
      </Button>
    </Box>
  );
}
