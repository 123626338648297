export function DependenciesIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 20 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 6C2.17786 6 1.47232 5.70554 0.883392 5.11661C0.294464 4.52768 0 3.82214 0 3C0 2.17786 0.294464 1.47232 0.883392 0.883392C1.47232 0.294464 2.17786 0 3 0C3.82214 0 4.52768 0.294464 5.11661 0.883392C5.70554 1.47232 6 2.17786 6 3C6 3.82214 5.70554 4.52768 5.11661 5.11661C4.52768 5.70554 3.82214 6 3 6ZM3 4.45406C3.40518 4.45406 3.74882 4.31302 4.03092 4.03092C4.31302 3.74882 4.45406 3.40518 4.45406 3C4.45406 2.59482 4.31302 2.25118 4.03092 1.96908C3.74882 1.68698 3.40518 1.54594 3 1.54594C2.59482 1.54594 2.25118 1.68698 1.96908 1.96908C1.68698 2.25118 1.54594 2.59482 1.54594 3C1.54594 3.40518 1.68698 3.74882 1.96908 4.03092C2.25118 4.31302 2.59482 4.45406 3 4.45406Z" />
      <path d="M17 6C16.1779 6 15.4723 5.70554 14.8834 5.11661C14.2945 4.52768 14 3.82214 14 3C14 2.17786 14.2945 1.47232 14.8834 0.883392C15.4723 0.294464 16.1779 0 17 0C17.8221 0 18.5277 0.294464 19.1166 0.883392C19.7055 1.47232 20 2.17786 20 3C20 3.82214 19.7055 4.52768 19.1166 5.11661C18.5277 5.70554 17.8221 6 17 6ZM17 4.45406C17.4052 4.45406 17.7488 4.31302 18.0309 4.03092C18.313 3.74882 18.4541 3.40518 18.4541 3C18.4541 2.59482 18.313 2.25118 18.0309 1.96908C17.7488 1.68698 17.4052 1.54594 17 1.54594C16.5948 1.54594 16.2512 1.68698 15.9691 1.96908C15.687 2.25118 15.5459 2.59482 15.5459 3C15.5459 3.40518 15.687 3.74882 15.9691 4.03092C16.2512 4.31302 16.5948 4.45406 17 4.45406Z" />
      <path d="M8 3H12" stroke="currentColor" strokeLinecap="round" strokeWidth={1.6} />
    </svg>
  );
}
