import { useCallback, useEffect, useState } from "react";
import type { ChangeStatus } from "../../../api";
import type { FilterType } from "../../../utils/search/lineageType";
import { type ActiveFilterProps, FilteredEntitiesView } from "./FilteredEntitiesView";
import { FiltersView } from "./FiltersView";
import { useFiltersPanelContext } from "./SidebarFiltersPanel";

export function FiltersController(): JSX.Element {
  const { query } = useFiltersPanelContext();
  const [activeFilter, setActiveFilter] = useState<ActiveFilterProps>();

  useEffect(() => {
    // TODO: We only use the active filter for the empty search state. This
    // means that the activeFilter and the query are related. We should probably use a
    // reducer to make sure that the state is always consistent instead of relying on
    // side-effects to synchronize the two.

    if (query === "") {
      return;
    }

    setActiveFilter(undefined);
  }, [query]);

  const filterClickHandler = useCallback(
    (filterType: FilterType, changeStatus: ChangeStatus) => {
      setActiveFilter({ filterType, changeStatus });
    },
    [],
  );

  const filterResetHandler = useCallback(() => {
    setActiveFilter(undefined);
  }, []);

  return activeFilter === undefined || query !== "" ? (
    <FiltersView onClick={filterClickHandler} />
  ) : (
    <FilteredEntitiesView {...activeFilter} onReset={filterResetHandler} />
  );
}
