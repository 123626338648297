import { DEMO_MODE } from "../../utils/demo/demoMode";
import { EXAMPLE_PRS } from "../../utils/demo/example";
import { EXAMPLE_PR_ITEM } from "../../utils/demo/prItem";
import { queryApiCall } from "../queryApiCall";
import { useQueryRetriesServerErrors } from "../useQueryDefaultRetry";
import { STALE_TIME_MS } from "../utils";

type PrDiffSizeHint = "empty" | "large" | "small";

/**
 * Describes the metadata for a single pull request. A lot of the fields are fetched
 * directly and verbatim from Github, sometimes with a different name. See the field
 * comments for more context.
 * @see https://docs.github.com/en/rest/pulls/pulls?apiVersion=2022-11-28#get-a-pull-request
 * @see [PrItem](../../../backend/backend/app/core/lineage_api_models.py)
 */
export type PrItem = {
  /* eslint-disable @typescript-eslint/naming-convention -- Defined by the API */

  /**
   * From Github: "Number uniquely identifying the pull request within its repository."
   * aka `number` in the github response.
   */
  readonly pr_number: number;

  /**
   * From Github: "The title of the pull request."
   * aka `title` in the github response.
   */
  readonly pr_text: string;

  /**
   * From Github: "State of this Pull Request. Either `open` or `closed`."
   * aka `state` in the github response.
   */
  readonly pr_status: "closed" | "open";

  /**
   * aka `head.sha` in the github response.
   */
  readonly head_hash: string;

  /**
   * aka `base.sha` in the github response.
   */
  readonly base_hash: string;

  /**
   * From Github: "Indicates whether or not the pull request is a draft."
   * aka `draft` in the github response.
   */
  readonly is_draft: boolean;

  /**
   * From Github: "A GitHub user."
   * aka `user` in the github response.
   */
  readonly created_by: {
    readonly login: string;
    readonly name?: string;
  };

  /**
   * The ISO 8601 timestamp of when the PR was created.
   */
  readonly created_at: string;

  /**
   * The ISO 8601 timestamp of when the PR was last updated.
   */
  readonly updated_at: string;

  /**
   * The ISO 8601 timestamp of when the PR was closed. This is also defined when the PR
   * was merged, and in those cases it would (usually) have the same value as
   * `merged_at`.
   */
  readonly closed_at?: string;

  /**
   * The ISO 8601 timestamp of when the PR was merged. The presence of this field, when
   * `closed_at` is defined can be used to determine if the PR was merged or
   * closed/abandoned (not merged).
   */
  readonly merged_at?: string;

  // TODO: Implement this on the server, and possibly remove the optionality if we can
  // assure it's always present.
  readonly diff_size_hint?: PrDiffSizeHint;

  /* eslint-enable @typescript-eslint/naming-convention */
};

/**
 * Get the metadata for a pull request.
 * @see [get_pr_info](../../../backend/backend/app/api/lineage_v1.py)
 */
export const usePrInfo = (repoName: string, prNumber: number) =>
  useQueryRetriesServerErrors({
    queryKey: ["prInfo", repoName, prNumber],
    queryFn: async () => getPrInfo(repoName, prNumber),
    staleTime: STALE_TIME_MS,
  });

const getPrInfo = async (repoName: string, prNumber: number): Promise<PrItem> =>
  DEMO_MODE.isEnabled
    ? {
        ...EXAMPLE_PR_ITEM,
        pr_number: prNumber,
        pr_text:
          EXAMPLE_PRS.find((pr) => pr.prId === prNumber)?.prText ??
          EXAMPLE_PR_ITEM.pr_text,
      }
    : queryApiCall(`lineage/${repoName}/pull/${prNumber}/info`);
