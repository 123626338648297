export function ResetIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 16 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.00001 17.333C6.06934 17.333 4.39934 16.6977 2.99001 15.427C1.58001 14.1563 0.764006 12.5833 0.542006 10.708C0.514006 10.472 0.583339 10.2673 0.750006 10.094C0.916673 9.92 1.12501 9.833 1.37501 9.833C1.61101 9.833 1.81601 9.913 1.99001 10.073C2.16334 10.2323 2.27067 10.4373 2.31201 10.688C2.52067 12.0767 3.15634 13.2397 4.21901 14.177C5.28101 15.1143 6.54134 15.583 8.00001 15.583C9.59734 15.583 10.955 15.024 12.073 13.906C13.191 12.788 13.75 11.4303 13.75 9.833C13.75 8.23633 13.191 6.88233 12.073 5.771C10.955 4.65967 9.59734 4.104 8.00001 4.104H7.91701L8.58301 4.771C8.76367 4.95167 8.85401 5.16 8.85401 5.396C8.85401 5.632 8.76367 5.84033 8.58301 6.021C8.40234 6.20167 8.19401 6.292 7.95801 6.292C7.72201 6.292 7.51367 6.20167 7.33301 6.021L5.16701 3.854C5.06967 3.75667 5.00001 3.656 4.95801 3.552C4.91667 3.448 4.89601 3.34033 4.89601 3.229C4.89601 3.11767 4.91667 3.01 4.95801 2.906C5.00001 2.802 5.06967 2.70133 5.16701 2.604L7.33301 0.438C7.51367 0.257333 7.72201 0.167 7.95801 0.167C8.19401 0.167 8.40234 0.257333 8.58301 0.438C8.76367 0.618 8.85401 0.826333 8.85401 1.063C8.85401 1.299 8.76367 1.50733 8.58301 1.688L7.91701 2.354H8.00001C9.02801 2.354 9.99667 2.54867 10.906 2.938C11.816 3.32667 12.6113 3.86133 13.292 4.542C13.972 5.222 14.51 6.017 14.906 6.927C15.302 7.837 15.5 8.80567 15.5 9.833C15.5 10.861 15.302 11.83 14.906 12.74C14.51 13.6493 13.972 14.4443 13.292 15.125C12.6113 15.8057 11.816 16.344 10.906 16.74C9.99667 17.1353 9.02801 17.333 8.00001 17.333Z" />
    </svg>
  );
}
