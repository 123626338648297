import type { PropsWithChildren, ReactElement } from "react";

type ButtonProps = PropsWithChildren<{
  readonly className?: string;
  readonly onClick?: () => void;
}>;

export function RedButton({ className, ...props }: ButtonProps): ReactElement {
  return (
    <Button
      className={`bg-[rgb(202,101,82)] hover:bg-[rgb(161,81,56)] active:bg-[rgb(121,61,49)] ${
        className ?? ""
      }`}
      {...props}
    />
  );
}

export function GreenButton({ className, ...props }: ButtonProps): ReactElement {
  return (
    <Button
      className={`bg-[rgb(101,202,132)] hover:bg-[rgb(81,161,106)] active:bg-[rgb(61,121,79)] ${
        className ?? ""
      }`}
      {...props}
    />
  );
}

// Export when needed
function Button({ className, children, onClick }: ButtonProps): ReactElement {
  return (
    <button
      className={`transition-color mr-1 rounded p-2 text-sm font-semibold uppercase text-white shadow-[0_0_0px_rgba(44,57,63,0.4),0_2px_1px_rgba(44,57,63,0.2)] ${
        className ?? ""
      } `}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
