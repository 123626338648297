import { Combobox, Switch } from "@headlessui/react";
import { Fragment, useCallback, useState } from "react";
import type { TableOwner } from "../../api";
import { PlusSignIcon } from "../icons/PlusSignIcon";
import { SearchIcon } from "../icons/SearchIcon";
import { useIncrementalSearch } from "../useIncrementalSearch";
import { AddUserFormOptions } from "./AddUserFormOptions";
import { InspectorButton } from "./InspectorButton";

export function AddOwnerForm({
  onAdd,
  excludedEmails,
}: {
  readonly onAdd: (variables: { readonly owner: TableOwner }) => void;
  readonly excludedEmails: readonly string[];
}): JSX.Element {
  const [isHidden, setIsHidden] = useState(true);
  const [query, setQuery] = useState("");

  const handleChange = useCallback(
    (owner: TableOwner | undefined) => {
      if (owner === undefined) {
        return;
      }

      onAdd({ owner });

      // We want to make it slicker so we auto-hide after adding a single user, if the
      // user wants to add more than one they'll need to unhide every time....
      setIsHidden(true);
      setQuery("");
    },
    [onAdd],
  );

  const handleHideSwitchChange = useCallback((isChecked: boolean) => {
    setQuery("");
    setIsHidden(isChecked);
  }, []);

  return (
    <Combobox
      as="div"
      className="relative w-full"
      value={undefined}
      onChange={handleChange}
    >
      <div className="flex w-full items-center justify-start gap-3">
        <Input
          className={isHidden ? "hidden" : undefined}
          placeholder="Add owner..."
          onChange={setQuery}
        />
        <Switch as={Fragment} checked={isHidden} onChange={handleHideSwitchChange}>
          {({ checked }) => (
            <InspectorButton filled={checked} type="button">
              {checked ? (
                <PlusSignIcon className="-ml-1 h-5 w-5 text-[rgb(160,152,249)] group-hover:text-[rgb(117,108,220)]" />
              ) : null}
              {checked ? "Add owner" : "Cancel"}
            </InspectorButton>
          )}
        </Switch>
      </div>
      {isHidden ? null : (
        <Combobox.Options
          unmount
          className="absolute z-10 mt-1 max-h-60 w-full overflow-hidden overflow-y-auto rounded-md bg-white shadow-[0_0_0_1px_rgba(115,130,133,0.15),0_4px_8px_rgba(115,130,133,0.15)]"
        >
          <AddUserFormOptions excludedEmails={excludedEmails} query={query} />
        </Combobox.Options>
      )}
    </Combobox>
  );
}

function Input({
  onChange,
  placeholder,
  className,
}: {
  readonly onChange: (query: string) => void;
  readonly placeholder: string;
  readonly className?: string | undefined;
}): JSX.Element {
  const inputRef = useIncrementalSearch(onChange);

  return (
    <div
      className={`flex flex-1 gap-2 rounded-md border border-[rgba(82,97,100,0.1)] px-2 py-3 text-base shadow-[0_2px_4px_rgba(115,130,133,0.15)] focus-within:ring-1 focus-within:ring-[rgb(101,202,132)] ${
        className ?? ""
      }`}
    >
      <Combobox.Input
        ref={inputRef}
        className="flex-1 outline-none placeholder:text-[rgb(165,173,176)]"
        placeholder={placeholder}
        type="search"
      />
      <Combobox.Button className="flex flex-none items-center">
        <SearchIcon className="h-4 w-4 text-[rgb(205,215,215)]" />
      </Combobox.Button>
    </div>
  );
}
