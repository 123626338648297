import { useCallback, useState } from "react";
import type { LineageState } from "../../api";
import type { LineageSearchResult } from "../../utils/search/lineage";
import { CompactLineageSearchResult } from "./CompactLineageSearchResult";
import { LineageSearch } from "./LineageSearch";

export function LineageEntitySelector({
  searchBoxPlaceholder,
  onResultSelect,
  lineage,
  className,
}: {
  readonly searchBoxPlaceholder: string;
  readonly onResultSelect: (result: LineageSearchResult | undefined) => void;
  readonly lineage?: LineageState | undefined;

  readonly className?: string | undefined;
}): JSX.Element {
  const [selectedResult, setSelectedResult] = useState<LineageSearchResult | undefined>(
    undefined,
  );

  const handleSearchResultClick = useCallback(
    (searchResult: LineageSearchResult) => {
      setSelectedResult(searchResult);
      onResultSelect(searchResult);
    },
    [onResultSelect],
  );
  const handleSelectedResultClick = useCallback(() => {
    setSelectedResult(undefined);
    onResultSelect(undefined);
  }, [onResultSelect]);

  // We just hide the search box when a result is selected, rather than unmounting it, so that the search box
  // doesn't lose its state. Clicking the selected result (while the search box is hidden) will allow continuing
  // the search exactly where it stopped.
  return (
    <div className={`flex ${className ?? ""}`}>
      <div className={selectedResult === undefined ? "w-full" : "hidden"}>
        <LineageSearch
          refocusOnResultClick
          lineage={lineage}
          resetOnResultClick={false}
          searchBoxPlaceholder={searchBoxPlaceholder}
          onResultClick={handleSearchResultClick}
        />
      </div>
      {selectedResult === undefined ? null : (
        <CompactLineageSearchResult
          className="w-full"
          searchResult={selectedResult}
          onClick={handleSelectedResultClick}
        />
      )}
    </div>
  );
}
