import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import type { SourceInstance } from "../../../utils/settings/types";

export type ConfiguredAccountsProps = {
  readonly accounts: readonly SourceInstance[];
  readonly onEdit: (identifier: SourceInstance) => void;
  readonly onDelete: (identifier: SourceInstance) => void;
};

export function ConfiguredAccounts({
  accounts,
  onEdit,
  onDelete,
}: ConfiguredAccountsProps): JSX.Element {
  if (accounts.length === 0) {
    return (
      <>
        No configured sources!
        <br />
      </>
    );
  }

  return (
    <>
      <Typography component="div" variant="h6">
        Configured Accounts
      </Typography>
      <List dense>
        {accounts.map((account) => (
          <Account
            key={`looker-${account.source_identifier}`}
            account={account}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </List>
    </>
  );
}

function Account({
  account,
  onEdit,
  onDelete,
}: {
  readonly account: ConfiguredAccountsProps["accounts"][number];
  readonly onEdit: (identifier: SourceInstance) => void;
  readonly onDelete: (identifier: SourceInstance) => void;
}): JSX.Element {
  const handleEditClick = useCallback(() => {
    onEdit(account);
  }, [account, onEdit]);
  const handleDeleteClick = useCallback(() => {
    onDelete(account);
  }, [account, onDelete]);

  return (
    <ListItem>
      <ListItemText
        primary={account.source_identifier}
        secondary={`Key ID: ${account.username ?? ""}`}
      />
      <ListItemSecondaryAction
        sx={{
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          pr: 5,
        }}
      >
        <IconButton aria-label="edit" edge="end" onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
      <ListItemSecondaryAction>
        <IconButton aria-label="delete" edge="end" onClick={handleDeleteClick}>
          <DeleteIcon sx={{ color: "palevioletred" }} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
