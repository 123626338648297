export function DeletePolicyIcon({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className ?? ""}
      fill="currentColor"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_174_2428)">
        <rect fill="white" height="32" rx="16" width="32" x="4" y="2" />
        <path d="M16.5 25C16.0833 25 15.7293 24.854 15.438 24.562C15.146 24.2707 15 23.9167 15 23.5V13.5H14V12H18V11H22V12H26V13.5H25V23.5C25 23.9167 24.854 24.2707 24.562 24.562C24.2707 24.854 23.9167 25 23.5 25H16.5ZM23.5 13.5H16.5V23.5H23.5V13.5ZM18 22H19.5V15H18V22ZM20.5 22H22V15H20.5V22Z" />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="40"
          id="filter0_dd_174_2428"
          width="40"
          x="0"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.45098 0 0 0 0 0.509804 0 0 0 0 0.521569 0 0 0 0.15 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_174_2428"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="effect2_dropShadow_174_2428"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.45098 0 0 0 0 0.509804 0 0 0 0 0.521569 0 0 0 0.15 0"
          />
          <feBlend
            in2="effect1_dropShadow_174_2428"
            mode="normal"
            result="effect2_dropShadow_174_2428"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_174_2428"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
