import { ActionButton, CancelButton } from "../common/Button";

export function CreatePolicyScreenHeader({
  isSaveButtonEnabled,
  onCancel,
  onSave,
}: {
  readonly isSaveButtonEnabled: boolean;
  readonly onCancel: () => void;
  readonly onSave: () => void;
}): JSX.Element {
  return (
    <header className="flex items-end justify-between">
      <h1 className="text-lg font-semibold leading-7 text-[rgb(82,97,100)]">
        Create new policy
      </h1>
      <div className="flex gap-x-3">
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <ActionButton isDisabled={!isSaveButtonEnabled} onClick={onSave}>
          Save Policy
        </ActionButton>
      </div>
    </header>
  );
}
