import { useCallback } from "react";
import type { ChangeStatus } from "../../../api";
import { logEventModifiedTabCategoryClicked } from "../../../utils/analytics/eventLogging";
import { filterLabel } from "../../../utils/filters/filterLabel";
import type { FilterType } from "../../../utils/search/lineageType";
import { FilterIcon } from "../../icons/FilterIcon";
import { RightArrowIcon } from "../../icons/RightArrowIcon";

export function Filter({
  count,
  type,
  changeStatus,
  onClick,
}: {
  readonly type: FilterType;
  readonly changeStatus: ChangeStatus;
  readonly count: number;
  readonly onClick: (filterType: FilterType, change: ChangeStatus) => void;
}): JSX.Element {
  const handleClick = useCallback(() => {
    onClick(type, changeStatus);
    logEventModifiedTabCategoryClicked(type, changeStatus, count);
  }, [changeStatus, onClick, type, count]);

  return (
    <li className="border-b hover:bg-[rgb(247,250,249)]">
      <button
        className="flex w-full items-center gap-2 px-6 py-5 text-left"
        type="button"
        onClick={handleClick}
      >
        <FilterIcon changeStatus={changeStatus} className="h-7 w-7" type={type} />
        <span className="flex-1 text-sm font-semibold text-[rgb(82,97,100)]">
          {filterLabel(type, changeStatus)}
        </span>
        <span className="text-lg tabular-nums text-[rgb(165,173,176)]">{count}</span>
        <RightArrowIcon className="w-5 text-[rgb(143,153,157)]" />
      </button>
    </li>
  );
}
