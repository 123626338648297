import type {
  LineageSearchEntityDescriptor,
  LineageSearchResult,
} from "../../utils/search/lineage";
import { NodeDescriptor } from "../graph/NodeDescriptor";
import { ColumnDescriptor } from "./ColumnDescriptor";
import { SearchResultItemHeader } from "./SearchResultItemHeader";

export function SearchResultContent({
  result,
}: {
  readonly result: LineageSearchResult;
}): JSX.Element {
  return (
    <>
      <SearchResultItemHeader result={result} />
      <ResultDescriptor result={result} />
    </>
  );
}

function ResultDescriptor({
  result: {
    result: { id },
    rootEntity,
  },
}: {
  readonly result: LineageSearchEntityDescriptor;
}): JSX.Element {
  if (id === rootEntity.id) {
    return <NodeDescriptor entity={rootEntity} />;
  }
  return <ColumnDescriptor columnRoot={rootEntity} />;
}
