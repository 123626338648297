export function ChevronDown({
  className,
}: {
  readonly className?: string | undefined;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 10 7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.625 0.812012C0.458333 0.978679 0.375 1.18368 0.375 1.42701C0.375 1.67035 0.458333 1.87535 0.625 2.04201L4.375 5.79201C4.47233 5.88935 4.573 5.95868 4.677 6.00001C4.781 6.04135 4.88867 6.06201 5 6.06201C5.11133 6.06201 5.219 6.04135 5.323 6.00001C5.427 5.95868 5.52767 5.88935 5.625 5.79201L9.396 2.02101C9.56267 1.85435 9.64933 1.65635 9.656 1.42701C9.66333 1.19768 9.57667 0.992679 9.396 0.812012C9.22933 0.645346 9.02433 0.562012 8.781 0.562012C8.53833 0.562012 8.33367 0.645346 8.167 0.812012L5 3.95801L1.833 0.792012C1.66633 0.625345 1.46867 0.542012 1.24 0.542012C1.01067 0.542012 0.805667 0.632012 0.625 0.812012Z" />
    </svg>
  );
}
