import type { PropsWithChildren } from "react";
import type { LineageIssueSeverity } from "../../../api";

export function IssuesCounter({
  severity,
  className,
  children,
}: PropsWithChildren<{
  readonly severity: LineageIssueSeverity | "fixed";
  readonly className?: string | undefined;
}>): JSX.Element {
  return (
    <div
      className={`mr-1 flex h-6 w-6 flex-none items-center justify-center rounded text-xs font-semibold text-white ${counterColorClassName(
        severity,
      )} ${className ?? ""}`}
    >
      {children}
    </div>
  );
}

function counterColorClassName(severity: LineageIssueSeverity | "fixed"): string {
  switch (severity) {
    case "critical":
    case "error":
      // red
      return "bg-[rgb(246,111,81)]";

    case "warning":
      // yellow
      return "bg-[rgb(251,185,60)]";

    case "info":
      // gray
      return "bg-[rgb(115,129,131)]";

    case "fixed":
      // green
      return "bg-[rgb(101,202,132)]";
  }
}
