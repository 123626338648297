const ZERO_WIDTH_SPACE = "\u200B";

/**
 * Allow longer strings that need to be shown in a limited container to break-
 * line more naturally at more locations in the string. This is especially good
 * for the kind of strings that are used to name technical stuff, like commit
 * messages, PR titles, etc...
 * We do this by adding a zero-width-space after each character that we allow a
 * break to happen at.
 */
export const withWordBreakOpportunities = (str: string): string =>
  // This comment might become stale, but the characters we are looking for are:
  // - _ (underscore)
  // - - (hyphen)
  // - : (colon)
  // - \ (slash)
  // - / (backslash)
  // Anything else we should add?
  str.replaceAll(/(?<char>[_\-:;/\\])/gu, `$<char>${ZERO_WIDTH_SPACE}`);
