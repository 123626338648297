import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, List, ListItem, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import type { SourceInstance } from "../../../utils/settings/types";

type ConfiguredAccountsProps = {
  readonly accounts: readonly SourceInstance[];
  readonly onDelete: (identifier: SourceInstance) => void;
};

export function MainConfigScreen({
  onUploadFile,
  ...configuredAccountsProps
}: ConfiguredAccountsProps & {
  readonly onUploadFile: () => void;
}): JSX.Element {
  return (
    <>
      <ConfiguredAccounts {...configuredAccountsProps} />
      <Button className="my-4" variant="contained" onClick={onUploadFile}>
        Upload New Account Key
      </Button>
    </>
  );
}

function ConfiguredAccounts({ accounts, onDelete }: ConfiguredAccountsProps) {
  if (accounts.length === 0) {
    return (
      <>
        No configured sources!
        <br />
      </>
    );
  }

  return (
    <>
      <Typography component="div" variant="h6">
        Configured Accounts
      </Typography>
      <List dense>
        {accounts.map((account) => (
          <ConfiguredAccount
            key={account.source_identifier}
            account={account}
            onDelete={onDelete}
          />
        ))}
      </List>
    </>
  );
}

function ConfiguredAccount({
  account,
  onDelete,
}: {
  readonly account: ConfiguredAccountsProps["accounts"][number];
  readonly onDelete: ConfiguredAccountsProps["onDelete"];
}): JSX.Element {
  const clickHandler = useCallback(() => {
    onDelete(account);
  }, [account, onDelete]);

  return (
    <ListItem
      secondaryAction={
        <IconButton aria-label="delete" edge="end" onClick={clickHandler}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText primary={account.source_identifier} secondary={account.username} />
    </ListItem>
  );
}
