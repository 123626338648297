import { useCallback, useState } from "react";
import { FakeTableauInstructionsDialog } from "./FakeTableauInstructionsDialog";
import { TableauInstructionsButton } from "./TableauInstructionsButton";

export function TableauFakeInstructions(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const clickHandler = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <TableauInstructionsButton onClick={clickHandler} />
      <FakeTableauInstructionsDialog isOpen={isOpen} onClose={closeHandler} />
    </>
  );
}
