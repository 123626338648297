import { useCallback, useState, type Dispatch } from "react";
import type { Action } from "./reducer";

const GENERATE_FAKE_DELAY_MS = 2000;

export function StepFour({
  dispatch,
  disabled,
  className,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly disabled: boolean;
  readonly className?: string | undefined;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch({ type: "generateArtifacts" });
      setIsLoading(false);
    }, GENERATE_FAKE_DELAY_MS);
  }, [dispatch]);

  return (
    <section
      className={`flex flex-col items-start justify-center gap-3 transition duration-300 ${
        disabled ? "opacity-20" : ""
      } ${className ?? ""}`}
    >
      <button
        className={`rounded-xl bg-teal-600 px-4 py-3 font-medium text-white shadow-md ${
          isLoading ? "animate-pulse" : ""
        }`}
        disabled={disabled}
        type="button"
        onClick={clickHandler}
      >
        {isLoading ? "Generating..." : "Generate Artifacts"}
      </button>
    </section>
  );
}
