import { useParams } from "react-router-dom";

/**
 * Helper for react-router's `useParams` which would also check that the value exists
 * and throw so that you don't have to do that manually each time...
 */
export function useRequiredParam(parameterName: string): string {
  const { [parameterName]: value } = useParams();
  if (value === undefined) {
    throw new Error(`Parameter ${parameterName} is not in the path!`);
  }

  return value;
}
