import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query/src/types";
import { isServerErrorStatus } from "../utils/HTTP_CODE";

// 3 is the react-query default
const MAX_RETRY_ATTEMPTS = 3;

// This number should be >1 otherwise you are going faster instead of slower...
const RETRY_BACKOFF_EXPONENT = 2;
// Just pick a number that makes sense for the user experience and the server...
const RETRY_DELAY_UNIT_MS = 150;

export const useQueryRetriesServerErrors = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "retry" | "retryDelay">,
): UseQueryResult<TData, TError> =>
  useQuery({
    retry: (failureCount, error) =>
      failureCount < MAX_RETRY_ATTEMPTS && isServerErrorResponse(error),

    retryDelay: (retryAttempt) =>
      RETRY_BACKOFF_EXPONENT ** retryAttempt * RETRY_DELAY_UNIT_MS,

    ...options,
  });

const isServerErrorResponse = (response: unknown) =>
  typeof response === "object" &&
  response !== null &&
  "status" in response &&
  typeof response.status === "number" &&
  isServerErrorStatus(response.status);
