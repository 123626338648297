const ALL_REFACTOR_TYPES = [
  "renameColumn",
  "deleteColumn",
  "renameTable",
  "deleteTable",
] as const;
export type RefactorType = (typeof ALL_REFACTOR_TYPES)[number];

export type Action =
  | { type: "columnSelected"; data: { columnName: string } }
  | { type: "generateArtifacts" }
  | { type: "refactorTypeSelected"; data: { refactorType: RefactorType } }
  | { type: "renameToProvided"; data: { renameTo: string } }
  | { type: "tableSelected"; data: { tableName: string } };

export type RefactorState = {
  readonly refactorType?: RefactorType;
  readonly tableName?: string;
  readonly columnName?: string;
  readonly renameTo?: string;
  readonly isGenerated?: boolean;
};

export function refactorReducer(state: RefactorState, action: Action): RefactorState {
  switch (action.type) {
    case "refactorTypeSelected":
      return { ...state, refactorType: action.data.refactorType };

    case "tableSelected":
      return { ...state, tableName: action.data.tableName };

    case "columnSelected":
      return { ...state, columnName: action.data.columnName };

    case "renameToProvided":
      return { ...state, renameTo: action.data.renameTo };

    case "generateArtifacts":
      return { ...state, isGenerated: true };
  }
}
