import { POLICIES_TABLE_COLUMNS_FLEX_RATIOS } from "../utils";
import { PolicyNameColumn } from "./PolicyMetadataView";

export function PolicyRowPending({
  policyName,
}: {
  readonly policyName: string;
}): JSX.Element {
  return (
    <div className="min-h-10 flex items-start justify-between gap-x-2 border-b border-[rgba(74,89,92,0.1)] py-4">
      <PolicyNameColumn
        className={POLICIES_TABLE_COLUMNS_FLEX_RATIOS.policyName}
        policyName={policyName}
      />
      <PendingAttribute className={`${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.createdBy}`} />
      <PendingAttribute
        className={`${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.modifiedBy}`}
      />
      <PendingAttribute
        className={`${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.modifiedAt}`}
      />
      <div className={`${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.actionButtons}`} />
      <PendingAttribute className={`${POLICIES_TABLE_COLUMNS_FLEX_RATIOS.status}`} />
    </div>
  );
}

function PendingAttribute({ className }: { readonly className?: string }) {
  return (
    <div
      className={`m-2 h-3 animate-pulse rounded-full bg-[rgba(74,89,92,0.2)] ${
        className ?? ""
      }`}
    />
  );
}
