/* eslint-disable import/no-unassigned-import, import/order --
 * This is how tailwind is injected into our project
 */
import "./index.css";

import { initDatadogLogs, initDatadogRum } from "./datadog";
import { initReact } from "./react";
import { initMixpanel } from "./utils/analytics/mixpanel";

window.name = "parent";

initDatadogLogs();
initDatadogRum();
initMixpanel();

initReact();
